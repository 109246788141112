import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../../styles/Colors';
import { TextInput } from '../../inputs/TextInput';
import { addMonths, getBookingMonthEndDate, getCurrentDayDate, getFirstDayOfMonth, getYearAndMonth } from '../../../../utils/dateUtils';
import { Dialog } from '../Dialog';
import { Calendar } from '../../inputs/Calendar';
import { ButtonPrimary, ButtonSecondary } from '../../inputs/Button';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';
import { Toast } from '../Toast';
import { DateMonthInput } from '../../inputs/DateMonthInput';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  text-align: start;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;

  color: ${Colors.grey700};
`;

const CommentInput = styled(TextInput)`
  label {
    margin: 0;
  }

  textarea {
    max-width: none;
  }
`;

export interface ICreateBookingCompletionSubmitPayload {
  timestamp: string;
  yearAndMonth: string;
  comment: string | null;
}
interface ICreateBookingCompletionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  lastTimestamp?: string;
  onSubmit: (config: ICreateBookingCompletionSubmitPayload, setLoading: (loading: boolean) => void) => void;
}
function CreateBookingCompletionDialog(props: ICreateBookingCompletionDialogProps) {
  const { open, setOpen, lastTimestamp, onSubmit } = props;

  const [loading, setLoading] = useState(false);

  const [timestamp, setTimestamp] = useState<Date>(getFirstDayOfMonth(new Date()));

  const [comment, setComment] = useState('');

  const isNewBookingMonth = useMemo(() => {
    if (lastTimestamp) {
      return getBookingMonthEndDate(lastTimestamp) < getBookingMonthEndDate(timestamp);
    }
    return true;
  }, [lastTimestamp, timestamp]);

  const infoToast = useMemo(() => {
    const bookingMonth = timestamp.toLocaleDateString(undefined, { year: 'numeric', month: 'long' });
    return (
      <>
        {isNewBookingMonth && (
          <Toast type={'alert'}>Beim Setzen dieses Buchungsabschlusses werden neue Abrechnungen für {bookingMonth} erstellt.</Toast>
        )}
        {!isNewBookingMonth && <Toast type={'info'}>Es werden keine neuen Abrechnungen erstellt.</Toast>}
      </>
    );
  }, [isNewBookingMonth, timestamp]);

  const minMaxDate = useMemo(() => {
    const minDate = lastTimestamp ? getFirstDayOfMonth(addMonths(new Date(lastTimestamp))) : undefined;
    const maxDate = lastTimestamp ? getFirstDayOfMonth(new Date()) : undefined;
    return { minDate, maxDate };
  }, [lastTimestamp]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      headline={'Buchungsabschluss erstellen'}
      text={
        <Body>
          {infoToast}
          <GridRow>
            <div>
              <b>Buchungsmonat</b>
              <DateMonthInput
                value={timestamp.toISOString()}
                setValue={(value) => {
                  setTimestamp(new Date(getCurrentDayDate(value)));
                }}
                minDate={minMaxDate.minDate}
                maxDate={minMaxDate.maxDate}
              />
            </div>
          </GridRow>
          <CommentInput
            id={'create-booking-completion-dialog:comment'}
            type={'textarea'}
            independent
            value={comment}
            onValueChange={setComment}
            label={'Kommentar'}
            placeholder={'Kommentar hinzufügen...'}
          />
        </Body>
      }
      buttons={
        <>
          <ButtonSecondary onClick={() => setOpen(false)}>Abbrechen</ButtonSecondary>
          <ButtonPrimary
            onClick={() => onSubmit({ timestamp: timestamp.toISOString(), yearAndMonth: getYearAndMonth(timestamp), comment }, setLoading)}
          >
            {loading ? <LoadingSpinner /> : 'Erstellen'}
          </ButtonPrimary>
        </>
      }
    />
  );
}

export default React.memo(CreateBookingCompletionDialog);
