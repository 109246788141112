import { WarningButton } from '../../components/common/inputs/Button';
import { AxiosError } from 'axios';
import { useShowDialog } from '../../state/DialogState';
import { appsignal } from '../../../appsignal';

export const useErrorHandler = () => {
  const showDialog = useShowDialog();

  return (e: AxiosError) => {
    appsignal.sendError(e);

    // handle booking completion error
    if (e?.response?.status === 403 && (e?.response?.data as any)?.msg === 'booking-completion') {
      console.error('403 booking completion');
      showDialog({
        headline: 'Buchungsabschluss',
        body: 'Der Buchungsabschluss wurde bereits gesetzt. Um Abrechnungs-bezogene Daten zu ändern, muss der Buchungsabschluss zuerst zurückgesetzt werden. Bitte sprechen Sie dazu mit der Abteilung für die Buchhaltung.',
        buttons: <WarningButton onClick={() => showDialog(null)}>Verstanden</WarningButton>,
      });
    }

    // pass error
    throw e;
  };
};
