export const Calendar = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21 22H3C2.44772 22 2 21.5523 2 21V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44771 22 4V21C22 21.5523 21.5523 22 21 22Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 17H7.71429M11.1429 17H12.8571M6 12H7.71429M11.1429 12H12.8571M16.2857 12H18"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.45455 2V5M16.5455 5V2M2 8H22V4C22 3.44772 21.5523 3 21 3H12H3C2.44772 3 2 3.44772 2 4L2 8Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
