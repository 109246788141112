import styled from 'styled-components';
import { ICustomer } from '../../../../api/models/Customer';
import { IOrder } from '../../../../api/models/Order';
import { TourplanScheduledTourTile, TTourplanScheduledTour } from './tiles/TourplanScheduledTourTile';
import SplitTourDetailsTile from '../../../common/elements/tours/SplitTourDetailsTile';
import React, { useEffect, useMemo, useState } from 'react';
import { Body3, Headline2, Headline3 } from '../../../../styles/FontStyles';
import { Facilities } from '../../../icons/Facilities';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DragDropContext, useAvailableDragDropItems } from './DragDropContext';
import { IPassenger } from '../../../../api/models/Passenger';
import { IEmployee } from '../../../../api/models/Employee';
import { ButtonPrimary, ButtonTertiary } from '../../../common/inputs/Button';
import { LoadingSpinner } from '../../../common/loaders/LoadingSpinner';
import ChangeTourDialog from '../../../common/elements/tours/tour-details/ChangeTourDialog';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINT_SM } from '../../../../styles/Breakpoints';
import VirtualizedDiv from '../../../common/atoms/VirtualizedDiv';
import { EmptyTourFrame } from '../../../common/atoms/EmptyTourFrame';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  z-index: 0;
  min-block-size: 100%;
`;

const TourplanHeadRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const CustomerNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const TourplanHeadline = styled.h2`
  ${Headline3};
`;

const SplitTourDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body3};
`;

const ToursScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;

  margin: -2.5rem;
  padding: 2.5rem;
  z-index: -1;
  gap: 1rem;

  overflow-x: auto;

  body.no-scroll & {
    overflow: hidden;
  }

  & > * {
    flex-shrink: 0;
    inline-size: 22rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;

  margin-block-start: 2.25rem;
  margin-block-end: -4rem;

  ${BREAKPOINT_SM} {
    flex-direction: row;
    align-items: center;
  }

  button {
    max-width: none;
  }
`;

const PlaceholderDesktopOnly = styled.h2`
  ${Headline2};
  text-align: center;

  padding: 2rem;
  margin: auto;
`;

interface ITourplanProps {
  customer: ICustomer | null;
  direction?: 'outwards' | 'return';
  orders: IOrder[] | null;
  timestamp: string;
  setTimestamp: (timestamp: string) => void;
  onSubmit: (values: any) => Promise<void>;
  submitting: boolean;
  splitTour?: TTourplanScheduledTour | null;
}
export default function TourplanMobile(props: ITourplanProps) {
  const {
    customer,
    // eslint-disable-next-line
    orders,
    direction = 'outwards',
    timestamp,
    // eslint-disable-next-line
    setTimestamp,
    onSubmit,
    splitTour,
    submitting = false,
  } = props;

  const navigate = useNavigate();

  const [availablePassengers] = useAvailableDragDropItems<IPassenger>();

  const [changeTourDialogOpen, setChangeTourDialogOpen] = useState(false);
  const [driver, setDriver] = useState<IEmployee | null>(null);
  const [companion, setCompanion] = useState<IEmployee | null>(null);

  useEffect(() => {
    setDriver(splitTour?.driver || null);
    setCompanion(splitTour?.companion || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitTour]);

  const form = useFormContext();
  const schedules = useFieldArray({ name: 'schedules', control: form.control, keyName: 'formId' });
  const schedulesToDelete = useFieldArray({ name: 'schedulesToDelete', control: form.control, keyName: 'formId' });

  // const orderTours = useMemo(() => {
  //   return (orders || [])
  //     .flatMap(({ id, displayName, comment, tours = [] }, index) => (
  //       (tours || [])
  //         .map((tour) => ({ ...tour, order: { id, displayName, comment, index } })) as unknown as ITour[]
  //     ));
  // }, [orders]);

  const tourInfo = useMemo(
    () => (
      <>
        <TourplanHeadRow>
          <TourplanHeadline>
            Fahrt aufteilen - {splitTour?.name} {direction === 'outwards' ? 'Hin' : 'Rück'}
          </TourplanHeadline>
          <CustomerNameWrapper>
            <Facilities />
            <span>{customer?.internalId}</span>
            <span>
              <b>{customer?.displayName}</b>
            </span>
          </CustomerNameWrapper>
        </TourplanHeadRow>

        <SplitTourDate>
          <b>{new Date(timestamp).toLocaleDateString(undefined, { weekday: 'short' })}</b>{' '}
          {new Date(timestamp).toLocaleDateString(undefined, { dateStyle: 'medium' })}
        </SplitTourDate>
      </>
    ),
    [customer?.internalId, customer?.displayName, direction, splitTour?.name, timestamp],
  );

  const splitTourDetails = useMemo(
    () => (
      <>
        <h2>
          Aufzuteilende Tour {splitTour?.name} {splitTour?.direction === 'return' ? 'Rück' : 'Hin'}:
        </h2>
        <SplitTourDetailsTile schedule={splitTour as any} availablePassengers={availablePassengers as IPassenger[]} timestamp={timestamp} />
      </>
    ),
    [splitTour, availablePassengers, timestamp],
  );

  const toursList = useMemo(
    () => (
      <>
        <h2>Verfügbare Touren {splitTour?.direction === 'return' ? 'Rück' : 'Hin'}:</h2>
        <ToursScrollWrapper>
          {schedules.fields.map((scheduledTour: TTourplanScheduledTour & any, index) => (
            <VirtualizedDiv
              key={scheduledTour.formId}
              render={(visible) =>
                visible ? (
                  <TourplanScheduledTourTile
                    // provide edit timestamp to block start date if timestamp > startDate and split schedule
                    showDriverAvailabilities={!!splitTour}
                    editForTimestamp={timestamp}
                    editable={!splitTour || !scheduledTour.id}
                    promptForTimeSupplement={!!splitTour}
                    scheduledTour={scheduledTour}
                    customer={customer}
                    update={(data, hasChanged = true) => {
                      schedules.update(index, {
                        ...data,
                        hasChanged: scheduledTour.hasChanged || hasChanged, // set changed flag
                      });
                    }}
                    remove={(data, onRemoved) => {
                      const index = schedules.fields.findIndex((item) => item === data);
                      if (index >= 0) {
                        schedules.remove(index);
                        schedulesToDelete.append(data);
                        onRemoved();
                      }
                    }}
                  />
                ) : (
                  // calculate approximate size to avoid flickering
                  <EmptyTourFrame size={`${16 + (scheduledTour.passengers || []).length * 6.375}rem`} />
                )
              }
            />
          ))}
        </ToursScrollWrapper>
      </>
    ),
    [customer, schedules, schedulesToDelete, splitTour, timestamp],
  );

  const saveButtons = useMemo(
    () => (
      <ButtonsWrapper>
        <ButtonTertiary onClick={() => navigate(-1)}>Abbrechen</ButtonTertiary>

        <ButtonPrimary
          type={'submit'}
          disabled={submitting}
          onClick={(e: any) => {
            e.preventDefault();
            setChangeTourDialogOpen(true);
          }}
        >
          {submitting ? <LoadingSpinner /> : 'Änderungen speichern'}
        </ButtonPrimary>
      </ButtonsWrapper>
    ),
    [submitting, setChangeTourDialogOpen, navigate],
  );

  const saveDialog = useMemo(
    () => (
      <ChangeTourDialog
        open={changeTourDialogOpen}
        setOpen={setChangeTourDialogOpen}
        timestamp={timestamp}
        onSubmit={async (config, setLoading) => {
          // submit form
          setLoading(true);
          await onSubmit({
            ...form.getValues(),
            config,
            splitTourUpdate: {
              driver,
              companion,
            },
          });
          setLoading(false);
        }}
      />
    ),
    [changeTourDialogOpen, setChangeTourDialogOpen, timestamp, onSubmit, driver, companion, form],
  );

  // placeholder desktop only
  if (!splitTour) {
    return (
      <Wrapper>
        <PlaceholderDesktopOnly>Die Fahrtenplanung muss am PC mit einem großen Display durchgeführt werden.</PlaceholderDesktopOnly>
      </Wrapper>
    );
  }

  return (
    <DragDropContext
      dataSource={schedules as any}
      itemsKey={'passengers'}
      tourPrefix={customer?.internalId && `F ${customer.internalId}`}
      direction={direction || 'outwards'}
    >
      <Wrapper>
        {tourInfo}
        <span />
        {splitTourDetails}
        <span />

        {toursList}

        {saveButtons}
        {saveDialog}
      </Wrapper>
    </DragDropContext>
  );
}
