import { useAuthHeader } from '../../state/AuthState';
import axios from 'axios';

interface ICreateVehicleProps {
  licensePlate: string;
  hasRoomForWheelchairs: boolean;

  brandName: string;
  modelName: string;
  color: string;

  comment: string;
}

interface IGetVehiclesProps {
  offset: number;
  limit: number;
}

interface IGetVehicleByIdProps {
  id: string;
}

interface IUpdateVehicleProps extends ICreateVehicleProps {}

export const useVehiclesAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const createVehicle = (props: ICreateVehicleProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/vehicles`, props, { ...authHeader });
    });
  };

  const getVehicles = (props: IGetVehiclesProps) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams([
        ['offset', `${props.offset || 0}`],
        ['limit', `${props.limit || 25}`],
      ]).toString();
      return axios.get(`/api/vehicles?${qs}`, { ...authHeader });
    });
  };

  const getVehicleById = (props: IGetVehicleByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/vehicles/${props.id}`, { ...authHeader });
    });
  };

  const updateVehicle = (id: string, props: IUpdateVehicleProps) => {
    return checkAuth().then(() => {
      return axios.patch(`/api/vehicles/${id}`, props, { ...authHeader });
    });
  };

  const deleteVehicle = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/vehicles/${id}`, { ...authHeader });
    });
  };

  const restoreVehicle = (id: string) => {
    return checkAuth().then(() => {
      return axios.post(`/api/vehicles/${id}/restore`, {}, { ...authHeader });
    });
  };

  return {
    createVehicle,
    getVehicles,
    getVehicleById,
    updateVehicle,
    deleteVehicle,
    restoreVehicle,
  };
};
