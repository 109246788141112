export function getMapsUrl(addresses: string[]) {
  const waypointAddresses = addresses.filter((item, index, array) => array.indexOf(item) === index);
  const destinationAddress = waypointAddresses.pop();
  const originAddress = waypointAddresses.shift();

  const params = new URLSearchParams();
  params.set('api', '1');
  if (originAddress) {
    params.set('origin', originAddress);
  }
  if (destinationAddress) {
    params.set('destination', destinationAddress);
  }
  if (waypointAddresses.length > 0) {
    params.set('waypoints', waypointAddresses.join('|'));
  }
  return `https://www.google.com/maps/dir/?${params.toString()}`;
}
