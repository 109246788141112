import { FC } from 'react';
import { Info, InfoBold, ListRowWrapper, OptionsWrapper, Row } from '../../atoms/List';

import { useHasPermission } from '../../../../state/UserMeState';
import { IBulkCancellationResult } from '../../../../api/models/BulkCancellation';
import { ContextOptionsFixed } from '../ContextOptionsFixed';
import { Undo } from '../../../icons/Undo';
import { useBulkCancellationAPI } from '../../../../api/controllers/BulkCancellationAPI';

interface IBulkCancellationListRowProps {
  bulkCancellation: IBulkCancellationResult;
  refetch: () => void;
}

export const BulkCancellationListRow: FC<IBulkCancellationListRowProps> = ({ bulkCancellation, refetch }) => {
  const { id, name, startDate, endDate, region = '-', customer_name = '-', count } = bulkCancellation;

  const isAdmin = useHasPermission('admin');
  const bulkCancellationAPI = useBulkCancellationAPI();

  return (
    <ListRowWrapper>
      <Row>
        <InfoBold>{id}</InfoBold>
        <Info>{name}</Info>
        <Info>{new Date(startDate).toLocaleDateString('de-DE')}</Info>
        <Info>{new Date(endDate).toLocaleDateString('de-DE')}</Info>
        <Info>{region}</Info>
        <Info>{customer_name}</Info>
        <Info>{count}</Info>
        {isAdmin && (
          <OptionsWrapper>
            <ContextOptionsFixed
              items={
                [
                  {
                    content: (
                      <>
                        <Undo /> Rückgängig machen
                      </>
                    ),
                    onClick: () => {
                      if (window.confirm('Möchten Sie die Stornierung wirklich rückgängig machen?')) {
                        bulkCancellationAPI.undoBulkCancellationById(id).then(() => {
                          refetch();
                        });
                      }
                    },
                  },
                ].filter(Boolean) as []
              }
            />
          </OptionsWrapper>
        )}
      </Row>
    </ListRowWrapper>
  );
};
