import React from 'react';
import styled from 'styled-components';
import { Icon404 } from '../icons/Icon404';
import { Body4Bold, Headline2 } from '../../styles/FontStyles';
import { Link } from '../common/atoms/Link';
import { Colors } from '../../styles/Colors';
import { ChevronLeft } from '../icons/ChevronLeft';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 4.75rem;

  & > svg {
    inline-size: 90%;
    max-inline-size: 950px;
    block-size: auto;
  }
`;

const Headline = styled.h1`
  ${Headline2};
  margin-block-end: -0.5rem;
`;

const BackToHomeLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4Bold};
  color: ${Colors.secondary};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const Page404 = () => {
  return (
    <Wrapper>
      <Icon404 />
      <Headline>Seite nicht gefunden</Headline>
      <BackToHomeLink href={'/'}>
        <ChevronLeft /> Zurück zum Login
      </BackToHomeLink>
    </Wrapper>
  );
};
