import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Headline3 } from '../../../styles/FontStyles';
import { Board } from '../../icons/Board';
import { List } from '../../icons/List';
import { TabSwitch } from './TabSwitch';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-block-start: -2.25rem;
  padding-block-start: 2.25rem;
  margin-inline: calc(-1 * var(--content-indent));
  padding-inline: var(--content-indent);

  padding-block-end: 1.5rem;

  background-color: ${Colors.background};
  position: sticky;
  top: 0;
  z-index: 5;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.75rem;
`;

const Title = styled.h1`
  ${Headline3};
`;

interface IContentPageHeadProps {
  title: string;
  activeIndex?: number;
  onClick?: (index: number) => void;
  showTabSwitch?: boolean;
  listName?: 'tourList' | 'passengerList' | 'employeeList' | 'vehicleList' | 'customerList';
}

export const ContentPageHead: FC<PropsWithChildren<IContentPageHeadProps>> = (props) => {
  const { title, children, listName, showTabSwitch = true } = props;

  const { onClick } = props;

  const handleOnClick = (index: number) => {
    if (onClick) {
      onClick(index);
    }
  };
  return (
    <Wrapper>
      <HeadWrapper>
        <Title>{title}</Title>
        {showTabSwitch && listName && (
          <TabSwitch listName={listName} onItemClick={handleOnClick} small items={[<Board value="tile" />, <List value="list" />]} />
        )}
      </HeadWrapper>
      {children}
    </Wrapper>
  );
};
