import React, { HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

function VirtualizedDiv({ render, ...props }: HTMLAttributes<HTMLDivElement> & { render?: (visible: boolean) => ReactNode }) {
  const [renderChildren, setRenderChildren] = useState(false);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    if (wrapperRef.current) {
      observer = new IntersectionObserver(([{ isIntersecting = false }]) => {
        setRenderChildren(isIntersecting);
      });
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef.current]);

  return (
    <Wrapper ref={wrapperRef} {...props}>
      {render?.(renderChildren)}
    </Wrapper>
  );
}

export default React.memo(VirtualizedDiv);
