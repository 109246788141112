import styled from 'styled-components';
import { Body4Bold, Body5 } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';

export const ColoredLabel = styled.div<{ textColor?: string; color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body5};

  color: ${({ textColor = Colors.textInverted }) => textColor};
  background-color: ${({ color = Colors.secondary }) => color};

  padding-block: 1px;
  padding-inline: 0.25rem;

  border-radius: 2px 11px 11px 2px;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const ColoredSideLabel = styled.div<{ color?: string }>`
  display: flex;
  background-color: ${({ color = Colors.secondary }) => color};

  inline-size: 0.25rem;
  inset-block: 0;
  inset-inline-start: 0;
`;

export const ColoredSideLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  margin-block: -0.25rem;
  padding-block: 0.25rem;
  margin-inline: -0.5rem;
  padding-inline: 0.5rem;

  position: relative;

  ${ColoredSideLabel} {
    position: absolute;
  }
`;

export const ColoredOrderLabel = styled.div<{ textColor?: string; color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4Bold};
  color: ${({ textColor = Colors.secondary }) => textColor};

  &::before {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.125rem;
    background-color: ${({ color = Colors.secondary }) => color};
  }
`;

export const getColorByIndex = (index: number) => {
  // custom color palette
  const colors = ['#881E51', '#C49FEC', '#F6C4E0', '#E08087', '#F4A56B', '#86CC9B', '#4B0C2E', '#496B53', '#40707D', '#7DAFBD'];

  return colors[index % colors.length];
};
