import styled, { keyframes } from 'styled-components';
import { Clickable } from '../atoms/Clickable';
import { PropsWithChildren, useState } from 'react';
import { Body4 } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';
import WrappedTimeInput from './WrappedTimeInput';

const Wrapper = styled.div.attrs({ onClick: (e: any) => e.stopPropagation() })`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-color, ${Colors.secondary});

  overflow: hidden;
  pointer-events: auto;
`;

const PresentationLabel = styled.p`
  ${Body4};
  color: var(--text-color, ${Colors.secondary});
`;

const InputContentAppear = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const InlineTimeInput = styled(WrappedTimeInput)`
  animation: ${InputContentAppear} 150ms ease-out;
`;

interface IPassengerPickupTimeProps {
  value: string | undefined;
  onChange?: (value: string | undefined) => void;
}
export default function PassengerPickupTime(props: PropsWithChildren<IPassengerPickupTimeProps>) {
  const { value, onChange, children } = props;

  // @TODO: this should be all backend stuff
  let pickupTime = value;
  if (pickupTime) {
    if (pickupTime === 'absent') {
      pickupTime = 'abwesend';
    } else if (!pickupTime.includes('Uhr')) {
      pickupTime = new Date(pickupTime).toLocaleTimeString(undefined, { timeStyle: 'short' });
    }
  }

  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <Wrapper>
        {!onChange && <PresentationLabel>{pickupTime || 'n/A'}</PresentationLabel>}
        {onChange && !editMode && (
          <Clickable
            onClick={(e: any) => {
              e.stopPropagation();
              setEditMode(true);
            }}
          >
            <PresentationLabel>{pickupTime || 'Zeit hinzufügen'}</PresentationLabel>
          </Clickable>
        )}
        {onChange && editMode && (
          <InlineTimeInput
            direction={''}
            defaultValue={pickupTime}
            onChange={(value) => {
              onChange(value);
              setEditMode(false);
            }}
            submitOnBlur
          />
        )}
      </Wrapper>
      {(!onChange || !editMode) && <>{children}</>}
    </>
  );
}
