import { FC, PropsWithChildren, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from '../../../components/common/inputs/SearchInput';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';

import { useRecoilState } from 'recoil';
import { ICustomerOrganization } from '../../../api/models/CustomerOrganization';
import { CustomerFilterState } from '../../../state/CustomerFilterState';
import { FilterButton } from './FilterButton';

const FilterRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 1rem;
  }
  ${BREAKPOINT_XL} {
    justify-content: space-between;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;

  ${BREAKPOINT_MD} {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  ${BREAKPOINT_XL} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  ${BREAKPOINT_MD} {
    width: auto;
    flex: 1;
    min-width: 20rem;
  }

  ${BREAKPOINT_LG} {
    width: 100%;
    max-width: 29rem;
  }
`;

export const FILTERS = [
  {
    key: 'regions',
    title: 'Region',
    type: 'multi',
    options: [
      {
        key: 'Düsseldorf',
        title: 'Düsseldorf',
      },
      {
        key: 'Niederrhein',
        title: 'Niederrhein',
      },
      {
        key: 'Wuppertal',
        title: 'Wuppertal',
      },
      {
        key: 'Ruhrgebiet',
        title: 'Ruhrgebiet',
      },
    ],
  },
  {
    key: 'organization',
    title: 'Organisation',
    type: 'multi',
    options: [],
  },
];

interface ICustomerFilterProps {
  customerOrganizations: ICustomerOrganization[];
}

export const CustomerFilter: FC<ICustomerFilterProps & PropsWithChildren> = (props) => {
  const { customerOrganizations, children } = props;
  const [customerSearchParams, setCustomerSearchParams] = useRecoilState(CustomerFilterState);
  const [filterValue, setFilterValue] = useState(customerSearchParams?.query || '');

  const onSearchChange = (value: string) => {
    setFilterValue(value);
    setCustomerSearchParams({
      ...customerSearchParams,
      query: value,
    });
  };

  const finalFilters = useMemo(() => {
    return FILTERS.map((filter) => {
      if (filter.key === 'organization') {
        return {
          ...filter,
          options: customerOrganizations.map((org) => ({
            key: org.id,
            title: org.name,
          })),
        };
      }
      return filter;
    });
  }, [customerOrganizations]);

  const onReset = () => {
    setFilterValue('');
    setCustomerSearchParams({
      query: '',
      regions: [],
      page: {
        offset: 0,
        limit: 50,
      },
    });
  };

  return (
    <FilterRowWrapper>
      <Content>{children}</Content>
      <FilterRow>
        <StyledSearchInput placeholder={'nach Fahrgästen suchen'} value={filterValue} setValue={onSearchChange} onReset={onReset} />
        <FilterButton filterOptions={finalFilters} handleReset={onReset} />
      </FilterRow>
    </FilterRowWrapper>
  );
};
