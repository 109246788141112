import styled, { css } from 'styled-components';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { Body4, Body4Bold } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';
import { useFieldError, useFieldProps } from './Field';
import { useFormContext } from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  text-align: start;
`;

const Label = styled.label<{ disabled: boolean }>`
  ${Body4Bold};
  margin: 0 0 0.5rem;

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${Colors.grey700};
    `};

  span {
    color: ${Colors.grey700};
  }
`;

const Input = styled.input<{ error: boolean; $plain?: boolean; as?: any }>`
  appearance: none;
  background: none;
  width: 100%;
  height: 3rem;

  border: 1px solid ${Colors.grey500};
  border-radius: 8px;
  padding: 0.75rem 1rem;

  ${Body4};

  transition:
    border-color 150ms ease-out,
    color 150ms ease-out;
  resize: none;

  &:focus-visible {
    border-color: ${Colors.grey800};
  }

  &:disabled {
    background: ${Colors.grey300};
    color: ${Colors.grey700};
  }

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${Colors.signalRed900};
    `};

  &::placeholder {
    color: ${Colors.grey700};
  }

  ${({ $plain = false }) =>
    $plain &&
    css`
      padding: 0.25rem 0 0.5rem;
      height: 2.25rem;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid ${Colors.grey500};
    `};

  ${({ as }) =>
    as === 'textarea' &&
    css`
      max-width: 600px;
      height: 7.5rem;
      overflow: auto;
    `};
`;

const ErrorLabel = styled.p`
  ${Body4};
  color: ${Colors.signalRed900};
`;

export interface IInputProps {
  id: string;
  name?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
  optionalLabel?: boolean;
  plain?: boolean;
  className?: string;
  style?: CSSProperties;
  onFocus?: () => void;
  autoFocus?: boolean;
  independent?: boolean;
  defaultValue?: string;
  value?: string;
  onValueChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  onBlur?: () => void;
}

export const TimeInput: FC<IInputProps> = (props) => {
  const {
    id,
    name = id,
    label = '',
    placeholder = '',
    disabled = false,
    optional = false,
    optionalLabel = false,
    plain = false,
    className,
    style,
    onFocus,
    autoFocus = false,
    independent = false,
    defaultValue,
    value,
    onValueChange,
    onSubmit,
    onBlur,
  } = props;

  const formContext = useFormContext();

  // eslint-disable-next-line
  const fieldProps = useFieldProps(name, optional);
  const error = useFieldError(name);

  const [fieldValue, setFieldValue] = useState((independent || !formContext ? value : formContext?.getValues(name)) || defaultValue || '');

  useEffect(() => {
    formContext?.setValue(name, fieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  useEffect(() => {
    if (value !== undefined) {
      setFieldValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleValueChange = (e: any) => {
    const segments = (e.target.value as string).split(':').filter((_, index) => index < 2);
    let current = segments.map((segment) => segment.replace(/[^0-9]/g, '').padStart(2, '0')).join('');
    if (current.length > 2) {
      current = `${Math.max(0, Math.min(23, +current.substr(0, 2)))
        .toString()
        .padStart(2, '0')}:${Math.max(0, Math.min(59, +current.substr(2, 2)))
        .toString()
        .padStart(2, '0')}`;
    }
    e.target.value = current;
    if (independent) {
      onValueChange?.(current);
    } else {
      formContext?.setValue(name, current);
    }
    setFieldValue(current);
  };

  return (
    <Wrapper className={className} style={style}>
      {label && (
        <Label disabled={disabled}>
          {label}
          {optionalLabel && <span> (optional)</span>}
        </Label>
      )}
      <Input
        {...(independent
          ? {
              defaultValue,
              value,
              onKeyDown: onSubmit
                ? (e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                      onSubmit(e.target.value);
                    }
                  }
                : undefined,
            }
          : {})}
        value={fieldValue}
        onChange={({ target }: any) => setFieldValue(target.value)}
        onBlur={(e) => {
          handleValueChange(e);
          onBlur?.();
        }}
        id={id}
        name={name}
        placeholder={placeholder}
        type={'tel'}
        error={!!error?.message}
        disabled={disabled}
        $plain={plain}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
      <ErrorLabel>{error?.message}</ErrorLabel>
    </Wrapper>
  );
};
