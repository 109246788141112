import { FC, useState } from 'react';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';
import { Download } from '../../../icons/Download';
import { Colors } from '../../../../styles/Colors';
import { useShowDialog } from '../../../../state/DialogState';
import { useSchedulesAPI } from '../../../../api/controllers/SchedulesAPI';
import { getDayOfWeekDate } from '../../../../utils/dateUtils';
import styled from 'styled-components';
import { Clickable } from '../../atoms/Clickable';
import { Body4Bold } from '../../../../styles/FontStyles';

const PlainButton = styled(Clickable)<{ color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  block-size: 1.5rem;

  ${Body4Bold};
  color: ${({ color = Colors.grey600 }) => color};

  :disabled {
    pointer-events: none;
    color: ${Colors.grey600};
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

interface IPDFDownloadButtonProps {
  scheduleId: string;
  scheduleName: string;
  timestamp: string;
}

const PDFDownloadButton: FC<IPDFDownloadButtonProps> = (props: { scheduleId: string; scheduleName: string; timestamp: string }) => {
  const { scheduleId, scheduleName, timestamp } = props;

  const showDialog = useShowDialog();
  const schedulesAPI = useSchedulesAPI();

  const [pdfLoading, setPdfLoading] = useState(false);

  return (
    <PlainButton
      color={Colors.secondary}
      disabled={pdfLoading}
      onClick={() => {
        setPdfLoading(true);
        schedulesAPI
          .getTourplanPDF({
            id: scheduleId,
            scheduleName,
            timestamp,
            startDate: getDayOfWeekDate(1, timestamp).toISOString(),
            endDate: getDayOfWeekDate(7, timestamp).toISOString(),
          })
          .catch((err) => {
            console.log('error creating pdf', err);
            showDialog({
              headline: 'Fehlgeschlagen',
              body: 'Der Export des PDF Fahrplans ist fehlgeschlagen. Bitte versuchen Sie es später erneut.',
            });
          })
          .finally(() => setPdfLoading(false));
      }}
    >
      {pdfLoading ? <LoadingSpinner small /> : <Download />} Exportieren (.pdf)
    </PlainButton>
  );
};

export default PDFDownloadButton;
