// General
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { CustomerOrderForm } from '../../../content/customers/orders/CustomerOrderForm';
import { LoadingSpinner } from '../../../common/loaders/LoadingSpinner';
import { ContentPageLayout } from '../../../common/atoms/Layout';

// Controller
import { useCustomersAPI } from '../../../../api/controllers/CustomersAPI';

// Types
import { ICustomer } from '../../../../api/models/Customer';

export const CustomerOrdersAdd = () => {
  const { customerId } = useParams();

  const navigate = useNavigate();

  const customersAPI = useCustomersAPI();
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      customersAPI
        .getCustomerById({ id: customerId })
        .then((res) => setCustomer(res.data.data))
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);


  return <ContentPageLayout>{loading ? <LoadingSpinner /> : <CustomerOrderForm customer={customer} />}</ContentPageLayout>;
};
