import { useState } from 'react';
import styled from 'styled-components';
import { useScheduledTourException } from '../../../../../api/controllers/scheduled-tour-exceptions/ScheduledTourExceptionsAPI';
import { IScheduledTourException } from '../../../../../api/models/ScheduledTour';
import { Colors } from '../../../../../styles/Colors';
import { ButtonPrimary } from '../../../inputs/Button';
import { SimpleSwitchSmall } from '../../../inputs/Switch';
import ScheduledTourExceptionRadioButton from './ScheduledExceptionRadioButton';

export const ExceptionListItemInfo = styled.span`
  display: flex;
  gap: 0.75rem;
  grid-column-start: 2;
`;

const ActiveIndicator = styled.span`
  position: absolute;
  font-weight: 600;
  font-size: 0.875rem;
  inset-block-start: 0.25rem;
  inset-inline-end: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

const ResetCancellationButton = styled(ButtonPrimary)`
  cursor: pointer;
  grid-column-start: 2;
  margin-top: 0.5rem;
  z-index: 3;
`;

export const ExceptionListItemWrapper = styled.li`
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  column-gap: 1.25rem;
  row-gap: 0.5rem;
  padding: 1rem 0;
  border-radius: 0.5rem;
  background-color: ${Colors.grey400};
  margin-inline: -0.75rem;
  padding-inline: 1rem;
  margin-block-start: 0.5rem;
  border: 1px solid transparent;
  position: relative;

  &.active {
    background-color: ${Colors.signalGreen800};
    border: 1px solid ${Colors.signalGreen900};
  }

  & > ${ExceptionListItemInfo} {
    display: grid;
    grid-template-columns: 6rem auto;
    align-items: flex-end;

    &.full-width {
      grid-template-columns: 1fr;
    }
  }
`;

const InlcudeOnInvoiceWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

const REASON_DESCRIPTION = {
  cancelled: 'Fahrt wurde abgesagt',
  tour_split: 'Fahrt wurde aufgeteilt',
  tour_extended: 'Fahrt wurde angepasst',
};

interface IExceptionListItemProps {
  exception: IScheduledTourException;
  activeExceptionId?: string;
  isBlockedByBookingCompletion: boolean;
  selectedException: IScheduledTourException | undefined;
  active?: boolean;
  onExceptionClick: (exception: IScheduledTourException | undefined) => void;
}

const getTimeString = (startDate: Date, endDate: Date | null) => {
  const timeStamps = [startDate, endDate].map((date) => {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('de-DE', { dateStyle: 'short' });
  });

  if (timeStamps[0] === timeStamps[1]) return `am ${timeStamps[0]}`;
  if (timeStamps[1] === '-') return `ab dem ${timeStamps[0]}`;
  return timeStamps.join(' - ');
};

export default function ExceptionListItem({
  exception,
  activeExceptionId,
  isBlockedByBookingCompletion,
  selectedException,
  active,
  onExceptionClick,
}: IExceptionListItemProps) {
  const scheduledTourExceptionsAPI = useScheduledTourException();
  const [includeTourOnInvoice, setIncludeTourOnInvoice] = useState(exception.includeTourOnInvoice);

  const handleDeleteCancellation = (exceptionId: string) => {
    if (window.confirm('Möchten Sie die Stornierung wirklich zurücksetzen?')) {
      scheduledTourExceptionsAPI.deleteScheduledTourException({ id: parseInt(exceptionId) }).then(() => {
        window.location.reload();
      });
    }
  };

  const hadleIncludeTourOnInvoiceChange = (exceptionId: string) => {
    if (window.confirm('Möchten Sie die Abrechnungs Art wirklich ändern?')) {
      scheduledTourExceptionsAPI
        .updateScheduledTourException({ id: parseInt(exceptionId), includeTourOnInvoice: !includeTourOnInvoice })
        .then((res) => {
          if (res.data.success) {
            setIncludeTourOnInvoice(!includeTourOnInvoice);
          } else {
            alert('Abrechnugnsart konnte nicht geändert werden.');
          }
        });
    }
  };

  const createdDate = new Date(exception.createdAt);

  return (
    <ExceptionListItemWrapper key={exception.id} className={active ? 'active' : ''}>
      {activeExceptionId === exception.id && <ActiveIndicator>Aktuelle Tour</ActiveIndicator>}
      <ScheduledTourExceptionRadioButton
        exception={exception}
        activeExceptionId={activeExceptionId}
        selectedException={selectedException}
        onExceptionClick={onExceptionClick}
        createdDate={createdDate}
      />
      <ExceptionListItemInfo>
        <b>Gültigkeit:</b> {getTimeString(exception.startDate, exception.endDate)}
      </ExceptionListItemInfo>
      <ExceptionListItemInfo>
        <b>Grund:</b>
        {REASON_DESCRIPTION[exception.reason as keyof typeof REASON_DESCRIPTION]}
      </ExceptionListItemInfo>
      {exception.reason === 'cancelled' && !isBlockedByBookingCompletion && active && (
        <>
          <ExceptionListItemInfo>
            <b>Abrechnen:</b>
            <InlcudeOnInvoiceWrapper>
              <span>NEIN</span>
              <SimpleSwitchSmall
                style={{ zIndex: 3 }}
                value={includeTourOnInvoice}
                onValueChange={() => {
                  hadleIncludeTourOnInvoiceChange(exception.id);
                }}
              />
              <span>JA</span>
            </InlcudeOnInvoiceWrapper>
          </ExceptionListItemInfo>
          <ResetCancellationButton variant="small" onClick={() => handleDeleteCancellation(exception.id)}>
            Tour wieder erlauben
          </ResetCancellationButton>
        </>
      )}
    </ExceptionListItemWrapper>
  );
}
