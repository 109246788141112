import { IScheduledTourException } from '../api/models/ScheduledTour';
import { getCurrentDayDate } from './dateUtils';

export function isExceptionInRangeForDay(exception: IScheduledTourException, day: string) {
  return (
    getCurrentDayDate(exception.startDate) <= getCurrentDayDate(day) &&
    (!exception.endDate || getCurrentDayDate(exception.endDate) >= getCurrentDayDate(day))
  );
}

export function getExceptionsForDay(exceptions: IScheduledTourException[], day: string) {
  return exceptions.filter((exception) => isExceptionInRangeForDay(exception, day));
}

export function getLatestException(exceptionsInRange: IScheduledTourException[]) {
  return exceptionsInRange
    .filter(
      (version) => !exceptionsInRange.some(({ replacementScheduledTourId }) => replacementScheduledTourId === version.scheduledTourId),
    )
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0];
}
