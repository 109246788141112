import { FC } from 'react';

export const Filter: FC = (props: any) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4615 12.6924V15.4616M15.4615 18.2308V15.4616M18.2308 15.4616H21M15.4615 15.4616H3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.53846 11.307V8.53779M8.53846 5.76855V8.53779M11.3077 8.53779H21M8.53846 8.53779H3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
