export const Wheelchair = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="13" cy="5" r="2" stroke="currentColor" />
    <rect
      width="3.21865"
      height="7.94672"
      rx="1.60932"
      transform="matrix(0.96371 0.266952 -0.279533 0.960136 11.2207 9)"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M10.2271 11.0482C7.21622 10.6468 4.44992 12.7621 4.04844 15.773C3.64695 18.784 5.76231 21.5502 8.77323 21.9517C11.7841 22.3532 14.5504 20.2379 14.9519 17.2269C15.171 15.5838 14.6406 14.0136 13.6241 12.8607"
      stroke="currentColor"
    />
    <path
      d="M14.5 14L15 14C16.6569 14 18 15.3431 18 17V17.3684V20.5C18 21.0523 17.5523 21.5 17 21.5V21.5V21.5C16.4477 21.5 16 21.0523 16 20.5V17.9211V17.5C16 16.9477 15.5523 16.5 15 16.5V16.5"
      stroke="currentColor"
    />
  </svg>
);
