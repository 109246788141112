// General
import { FC } from 'react';
import styled from 'styled-components';

// Types
import { IEmployee } from '../../../../api/models/Employee';

// Components
import {
  CardHeadline,
  CardRow,
  CardRowContent,
  CardRowHeadline,
  CardsWrapper,
  Card,
  IconCardRow,
  IconCardRowHeadline,
  IconCardRowInner,
} from '../../atoms/Card';

// Icons
import { Checkmark } from '../../../icons/Checkmark';
import { Close } from '../../../icons/Close';
import { Wheelchair } from '../../../icons/Wheelchair';

// Styles
import { Colors } from '../../../../styles/Colors';

const WheelchairTag = styled(Wheelchair)`
  svg& {
    color: ${Colors.grey600};
    margin-inline: 0.5rem;
    margin-block: -0.375rem;
  }
`;

const InfoWork: FC<{ employee: IEmployee }> = (props) => {
  const {
    jobTitle,
    drivingLicense,
    internalId,
    hasSkillScalamobil,
    hasSkillSupport,
    isDrivingTauga,
    comment,
    vehicles = [],
  } = props.employee;

  return (
    <CardsWrapper>
      <Card>
        <CardHeadline>Daten zur Arbeit</CardHeadline>

        <CardRow>
          <CardRowHeadline>Rolle</CardRowHeadline>
          <CardRowContent>{jobTitle || '-'}</CardRowContent>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Führerscheinklasse</CardRowHeadline>
          <CardRowContent>{drivingLicense || '-'}</CardRowContent>
        </CardRow>
        <CardRow>
          <CardRowHeadline>zugehöriges KFZ</CardRowHeadline>
          <CardRowContent>
            {vehicles[0]?.licensePlate || '-'}
            {vehicles[0]?.hasRoomForWheelchairs && <WheelchairTag />}
          </CardRowContent>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Personalnummer</CardRowHeadline>
          <CardRowContent>{internalId || '-'}</CardRowContent>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Beschäftigungs&shy;verhältnis</CardRowHeadline>
          <CardRowContent>{'-'}</CardRowContent>
        </CardRow>
      </Card>

      <Card>
        <IconCardRow disabled={!hasSkillScalamobil}>
          {hasSkillScalamobil ? <Checkmark /> : <Close />}
          <IconCardRowInner>
            <IconCardRowHeadline>Scalamobil</IconCardRowHeadline>
            <CardRowContent>
              {hasSkillScalamobil
                ? 'der Fahrer hat eine Schulung für die Nutzung eines Treppenrollstuhls absolviert'
                : 'keine Schulung für die Nutzung eines Treppenrollstuhls vorhanden'}
            </CardRowContent>
          </IconCardRowInner>
        </IconCardRow>

        <IconCardRow disabled={!hasSkillSupport}>
          {hasSkillSupport ? <Checkmark /> : <Close />}
          <IconCardRowInner>
            <IconCardRowHeadline>Unterstützung</IconCardRowHeadline>
            <CardRowContent>
              {hasSkillSupport
                ? 'der Fahrer ist in der Lage, Gäste zu tragen oder beim Laufen zu unterstützen'
                : 'der Fahrer ist nicht in der Lage, Gäste zu tragen oder beim Laufen zu unterstützen'}
            </CardRowContent>
          </IconCardRowInner>
        </IconCardRow>

        <IconCardRow disabled={!isDrivingTauga}>
          {isDrivingTauga ? <Checkmark /> : <Close />}
          <IconCardRowInner>
            <IconCardRowHeadline>Tauga Fahrten</IconCardRowHeadline>
            <CardRowContent>{isDrivingTauga ? 'der Fahrer fährt Taugafahrten' : 'keine Taugafahrten vorgesehen'}</CardRowContent>
          </IconCardRowInner>
        </IconCardRow>
      </Card>

      <Card>
        <CardRow>
          <CardRowHeadline>Anmerkungen</CardRowHeadline>
          <CardRowContent>{comment || '-'}</CardRowContent>
        </CardRow>
      </Card>
    </CardsWrapper>
  );
};

export default InfoWork;
