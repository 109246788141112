import React from 'react';
import styled from 'styled-components';
import { TabStatusBarLayout } from '../../common/elements/TabStatusBarLayout';
import Accounting from '../accounting/Accounting';
import PaymentInfos from '../payment-infos/PaymentInfos';

const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2.25rem 0 0 0;
`;

export const Finance: React.FC = () => {
  return (
    <Wrapper>
      <TabStatusBarLayout
        breadcrumb={{
          title: 'Stammdaten',
          href: '/stammdaten',
        }}
        items={[
          {
            title: 'Abrechnungen',
            hash: '#accounting',
            content: <Accounting />,
          },
          {
            title: 'Lohnartenliste',
            hash: '#payment-infos',
            content: <PaymentInfos />,
          },
        ]}
      />
    </Wrapper>
  );
};
