import styled from 'styled-components';
import React from 'react';
import { CustomerCategoryList } from '../../content/customer-categories/CustomerCategoryList';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem 0 0 0;
`;

export const CustomerCategories: React.FC = () => {
  return (
    <Wrapper>
      <CustomerCategoryList />
    </Wrapper>
  );
};
