import styled from 'styled-components';
import { ContextMenu } from '../../../atoms/ContextMenu';
import { DateMonthInput } from '../../../inputs/DateMonthInput';
import { DayOfMonthInput } from '../../../inputs/DayOfMonthInput';
import { getCurrentDayDate } from '../../../../../utils/dateUtils';
import React, { useState } from 'react';
import { Colors } from '../../../../../styles/Colors';
import { Alert } from '../../../../icons/Alert';
import { IScheduledTour, IScheduledTourException } from '../../../../../api/models/ScheduledTour';
import { getExceptionsForDay, getLatestException } from '../../../../../utils/exceptionUtils';
import DateTitle from '../../../atoms/DateTitle';
import { ButtonPrimary } from '../../../inputs/Button';
import { ITourConflictsResult } from '../../../../../state/TourConflictsState';

const Popup = styled(ContextMenu)`
  position: fixed;
  top: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;

  block-size: max-content;
  max-block-size: calc(100% - 1rem);
  max-inline-size: 20rem;
  margin: auto;
  padding: 3.5rem 1.5rem;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2.5rem;

  & > button {
    flex-shrink: 0;
    align-self: center;
  }
`;

const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
  margin-inline: auto;

  & > :first-child {
    align-self: flex-end;
  }
`;

const DayActiveMarker = styled.div`
  display: flex;
  width: 0.375rem;
  height: 0.375rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${Colors.secondary};
`;

const MarkerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 0.25rem;

  & > svg {
    width: 1rem;
    height: 1rem;
    margin-block: -0.3125rem;
    margin-inline: -0.125rem;
  }
`;

const AlertIcon = styled(Alert)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
  border-radius: 50%;
`;

const GreenAlertIcon = styled(AlertIcon)`
  color: ${Colors.signalGreen900};
`;

interface IDatePickerDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  timestamp: string;
  setTimestamp: (timestamp: string) => void;
  currentSchedule?: {
    schedule: IScheduledTour;
    viewRootSchedule?: boolean;
    rootScheduleExceptions: IScheduledTourException[];
    scheduleConflicts?: ITourConflictsResult;
  };
}
export default function DatePickerDialog(props: IDatePickerDialogProps) {
  const { open, setOpen, currentSchedule } = props;

  const [timestamp, setTimestamp] = useState(props.timestamp);

  const { startDate, endDate, daysOfWeek = '' } = currentSchedule?.schedule || {};

  return (
    <Popup open={open} setOpen={setOpen}>
      <DateInputWrapper>
        <DateMonthInput value={timestamp} setValue={setTimestamp} />
        <DateTitle timestamp={timestamp} />
        <DayOfMonthInput
          value={timestamp}
          setValue={setTimestamp}
          alignWeeks
          renderMarker={(value) => {
            if (currentSchedule) {
              const day = getCurrentDayDate(value);
              const dayIsInRange = getCurrentDayDate(startDate) <= day && (!endDate || getCurrentDayDate(endDate) >= day);
              const dayOfWeekIsMatching = daysOfWeek.split(',').includes(day.getDay().toString());

              // calculate exception from original schedule
              const exceptionsInRange = getExceptionsForDay(currentSchedule.rootScheduleExceptions || [], day.toISOString());
              const exceptionForDay = getLatestException(exceptionsInRange);

              const isTourCancelled = ['cancelled'].includes(exceptionForDay?.reason);

              const hasConflict =
                currentSchedule?.scheduleConflicts?.[day.toString()] && currentSchedule?.scheduleConflicts?.[day.toString()].length > 0;

              return (
                <MarkerWrapper>
                  {dayIsInRange && dayOfWeekIsMatching && <DayActiveMarker />}
                  {hasConflict && <AlertIcon />}
                  {
                    // TODO check if action is needed
                    dayIsInRange &&
                      dayOfWeekIsMatching &&
                      exceptionForDay &&
                      (currentSchedule.viewRootSchedule || isTourCancelled) &&
                      !hasConflict && <AlertIcon />
                  }
                  {dayIsInRange &&
                    dayOfWeekIsMatching &&
                    exceptionForDay &&
                    !(currentSchedule.viewRootSchedule || isTourCancelled) &&
                    !hasConflict && <GreenAlertIcon />}
                </MarkerWrapper>
              );
            }
            return <></>;
          }}
        />
      </DateInputWrapper>

      <ButtonPrimary
        onClick={() => {
          props.setTimestamp(timestamp);
          setOpen(false);
        }}
      >
        Übernehmen
      </ButtonPrimary>
    </Popup>
  );
}
