import { atom, useRecoilValue } from 'recoil';
import { IUser } from '../api/models/User';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'cache:UserMeState',
  storage: sessionStorage,
});

export const UserMeState = atom<IUser | null>({
  key: 'UserMeState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const useUserPermissions = () => {
  const userMe = useRecoilValue(UserMeState);
  return (userMe?.permissions || []) as string[];
};

export const useHasPermission = (permission: string) => {
  const permissions = useUserPermissions();

  return permissions.includes(permission) || permissions.includes('admin');
};
