import { IPassengerRefactored } from '../../../../../api/models/Passenger';
import React, { CSSProperties, PropsWithChildren, useMemo } from 'react';
import { getDayOfWeekDate } from '../../../../../utils/dateUtils';
import PassengerPickupTime from '../../../../common/elements/PassengerPickupTime';
import { Wheelchair } from '../../../../icons/Wheelchair';
import HoverTooltip from '../../../../common/elements/HoverTooltip';
import { ContextOptions } from '../../../../common/elements/ContextOptions';
import { Edit } from '../../../../icons/Edit';
import { Trash } from '../../../../icons/Trash';
import { TourDragItem, TourDragItemContent } from './TourplanTourTile';
import styled from 'styled-components';
import { Body4, Body5 } from '../../../../../styles/FontStyles';
import { Colors } from '../../../../../styles/Colors';
import { InfoInverted } from '../../../../icons/Info';
import { ITour } from '../../../../../api/models/Tour';
import { useDragDropItem, useDragDropPositionItem } from '../DragDropContext';
import { BREAKPOINT_SM, BREAKPOINT_XS } from '../../../../../styles/Breakpoints';
import { Card } from '../../../../common/atoms/Card';
import { useMatchMediaQuery } from '../../../../../hooks/useMatchBreakpoint';
import { Link } from '../../../../common/atoms/Link';
import { Passengers } from '../../../../icons/Passengers';

const PassengerTimesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const TourPassengerItemRow = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr auto;
  align-items: flex-start;
  gap: 0.5rem;

  min-block-size: 1.75rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

const TourPassengerItemRowMobileXS = styled(TourPassengerItemRow)`
  ${BREAKPOINT_XS} {
    display: none;
  }
`;

const TourPassengerName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
`;

const TourPassengerNameSM = styled(TourPassengerName)`
  display: none;

  ${BREAKPOINT_XS} {
    display: flex;
  }
`;

const TourPassengerOptionsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;

  margin-inline-start: auto;

  flex: 1;

  & > * {
    pointer-events: auto;
  }
`;

// TODO change UI
const TourPassengerCommentWrapper = styled(Card)`
  ${Body4};
  text-align: start;

  padding-block: 0.5rem;
  padding-inline: 1rem;

  box-shadow: 0 0 24px rgba(0, 0, 0, 0.04);
`;

const TourPassengerInfoIcon = styled(InfoInverted)`
  color: ${Colors.grey400};
`;

const LoadingTime = styled.span`
  ${Body5};
  color: ${Colors.grey700};

  display: none;

  ${BREAKPOINT_SM} {
    display: flex;
  }
`;

const DragItemContainer = React.memo((props: PropsWithChildren<{ isStatic: boolean; style?: CSSProperties }>) => {
  const { isStatic, style, children } = props;

  if (isStatic) {
    return <TourDragItem style={style}>{children}</TourDragItem>;
  }

  return <>{children}</>;
});

const TourPassengerNameRow = React.memo(
  (
    props: PropsWithChildren<{
      showPickupTimes?: boolean;
      selectedDayOfWeek: string;
      onPickupDateChange?: (value: { [day: string]: string }) => void;
      passenger: Partial<IPassengerRefactored>;
    }>,
  ) => {
    // @TODO: see comment below
    // const { showPickupTimes, onPickupDateChange, passenger, children, selectedDayOfWeek } = props;
    const { showPickupTimes, passenger, children } = props;

    const loadingTime = +(passenger.loadingTime || 0);

    return (
      <>
        {showPickupTimes && (
          <>
            <PassengerTimesWrapper>
              <PassengerPickupTime
                value={passenger.pickupTime}
                // @TODO:
                // This is only used on TourDetailsTile and TourplanTourTile
                // But it's using the old passenger object
                // let's fix it when we refactor the detail and use this view over their too
                //
                // onChange={
                //   onPickupDateChange
                //     ? (value) => {
                //         onPickupDateChange?.({
                //           ...(passenger.pickupTime || {}),
                //           [new Date(selectedDayOfWeek).getDay()]: !!value?.length
                //             ? new Date(`2023-01-01 ${value}:00`).toISOString()
                //             : undefined,
                //         });
                //       }
                //     : undefined
                // }
              />
              {loadingTime > 0 && <LoadingTime>(+{loadingTime} Min.)</LoadingTime>}
            </PassengerTimesWrapper>
            {children}
          </>
        )}
        {!showPickupTimes && children}
      </>
    );
  },
);

export interface ITourplanPassengerViewProps {
  passenger: IPassengerRefactored;
  position?: number;
  markAddressBold?: boolean;
  showInfoInline?: boolean;
  showNameSingleLine?: boolean;
  passengerClickable?: boolean;
  showPickupTimes?: boolean;
  selectedDayOfWeek?: string;
  onPickupDateChange?: (value: { [day: string]: string }) => void;
  onShowClick?: () => void;
  onEditClick?: () => void;
  onRemoveClick?: () => void;
  style?: CSSProperties;
  isStatic?: boolean;
}

export const TourplanPassengerViewRefactored = (props: ITourplanPassengerViewProps) => {
  const {
    passenger,
    position,
    markAddressBold = false,
    showInfoInline = false,
    showNameSingleLine = false,
    passengerClickable = false,
    showPickupTimes = false,
    selectedDayOfWeek = getDayOfWeekDate(1).toISOString(),
    onPickupDateChange,
    onShowClick,
    onEditClick,
    onRemoveClick,
    style,
    isStatic = true,
  } = props;

  const isOnBreakpointInfoInline = useMatchMediaQuery('((min-width: 1180px) and (max-width: 1399px)) or (min-width: 1580px)');

  const LinkWrapper = useMemo(
    () => (props: PropsWithChildren) =>
      passengerClickable ? (
        <Link
          href={passenger?.id ? `/passengers/${passenger.id}` : ''}
          target={'_blank'}
          onClick={(e) => e.stopPropagation()}
          $displayContents={true}
        >
          {props.children}
        </Link>
      ) : (
        <>{props.children}</>
      ),
    [passengerClickable, passenger],
  );

  const contextMenu = useMemo(
    () => (
      <>
        {(onShowClick || onEditClick || onRemoveClick) && (
          <ContextOptions
            items={
              [
                onShowClick && {
                  content: (
                    <>
                      <Passengers /> Anzeigen
                    </>
                  ),
                  onClick: onShowClick,
                },
                onEditClick && {
                  content: (
                    <>
                      <Edit /> Bearbeiten
                    </>
                  ),
                  onClick: onEditClick,
                },
                onRemoveClick && {
                  content: (
                    <>
                      <Trash /> Entfernen
                    </>
                  ),
                  onClick: onRemoveClick,
                },
              ].filter(Boolean) as []
            }
          />
        )}
      </>
    ),
    [onShowClick, onEditClick, onRemoveClick],
  );

  const tourPassengerContent = useMemo(
    () => (
      <>
        <TourPassengerItemRow>
          {position !== undefined && <b>{position}</b>}
          <TourPassengerNameRow
            passenger={passenger}
            showPickupTimes={showPickupTimes}
            onPickupDateChange={onPickupDateChange}
            selectedDayOfWeek={selectedDayOfWeek}
          >
            {!showNameSingleLine && (
              <TourPassengerNameSM>
                {passenger.hasWheelchair && <Wheelchair />}
                {passenger.fullNameWithComma}
              </TourPassengerNameSM>
            )}

            {/* options box */}
            <TourPassengerOptionsBox>
              {passenger.comment && (
                <>
                  {showInfoInline && isOnBreakpointInfoInline ? (
                    <TourPassengerCommentWrapper>{passenger.comment}</TourPassengerCommentWrapper>
                  ) : (
                    <HoverTooltip align={'right'} renderTooltipContent={() => <>{passenger.comment}</>}>
                      <TourPassengerInfoIcon />
                    </HoverTooltip>
                  )}
                </>
              )}
              {contextMenu}
            </TourPassengerOptionsBox>
          </TourPassengerNameRow>
        </TourPassengerItemRow>
        {!showNameSingleLine && (
          <TourPassengerItemRowMobileXS>
            <TourPassengerName>
              {passenger.hasWheelchair && <Wheelchair />}
              {passenger.fullNameWithComma}
            </TourPassengerName>
          </TourPassengerItemRowMobileXS>
        )}
        {showNameSingleLine && (
          <TourPassengerItemRow>
            <TourPassengerName>
              {passenger.hasWheelchair && <Wheelchair />}
              {passenger.fullNameWithComma}
            </TourPassengerName>
          </TourPassengerItemRow>
        )}
        {markAddressBold ? <b>{passenger.plaintextAddress}</b> : <>{passenger.plaintextAddress}</>}
      </>
    ),
    [
      position,
      passenger,
      contextMenu,
      isOnBreakpointInfoInline,
      showNameSingleLine,
      showInfoInline,
      showPickupTimes,
      onPickupDateChange,
      selectedDayOfWeek,
      markAddressBold,
    ],
  );

  return (
    <DragItemContainer isStatic={isStatic} style={style}>
      <LinkWrapper>
        <TourDragItemContent $pointerEvents={passengerClickable}>{tourPassengerContent}</TourDragItemContent>
      </LinkWrapper>
    </DragItemContainer>
  );
};

/**
 * TourplanPassengerView with drag drop functionality
 */
export const TourplanAvailablePassengerViewRefactored = React.memo(
  (props: { tour?: Partial<ITour>; editable?: boolean } & ITourplanPassengerViewProps) => {
    const { tour, editable = true, passenger, ...passengerViewProps } = props;

    const dragDropItemProps = {
      ...useDragDropItem<ITour, IPassengerRefactored>(tour || 'revert', passenger),
      ...useDragDropPositionItem<ITour, IPassengerRefactored>(tour || 'revert', passenger),
    };

    const contentView = useMemo(
      () => <TourplanPassengerViewRefactored passenger={passenger} {...passengerViewProps} isStatic={!editable} />,
      [passenger, passengerViewProps, editable],
    );

    if (editable) {
      return <TourDragItem {...(dragDropItemProps as any)}>{contentView}</TourDragItem>;
    }

    return <>{contentView}</>;
  },
);
