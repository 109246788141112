export const Download = (props: any) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.66797 10.4997V13.833H8.0013H13.3346V10.4997M5.57706 8.83301L8.0013 11.6108M8.0013 11.6108L10.4255 8.83301M8.0013 11.6108V3.83301"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
