import { Dialog } from './Dialog';
import { useRecoilState } from 'recoil';
import { DialogState } from '../../../state/DialogState';

export const DialogHandler = () => {
  const [dialogState, setDialogState] = useRecoilState(DialogState);

  if (dialogState) {
    return (
      <Dialog
        open={dialogState.open}
        onClose={() => setDialogState((state: any) => ({ ...state, open: false }))}
        onClosed={() => setDialogState(null)}
        headline={dialogState.dialog?.headline}
        text={typeof dialogState.dialog?.body === 'function' ? dialogState.dialog.body(dialogState.dialog) : dialogState.dialog?.body}
        buttons={
          typeof dialogState.dialog?.buttons === 'function' ? dialogState.dialog.buttons(dialogState.dialog) : dialogState.dialog?.buttons
        }
      />
    );
  }

  return <></>;
};
