import styled from 'styled-components';
import { Body4 } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';

export const ChipTile = styled.div<{ progress?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4};

  padding-inline: 1rem;
  padding-block: 0.5rem;

  background-color: ${Colors.grey400};
  border-radius: 20px;

  position: relative;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    background-color: ${Colors.grey500};
    block-size: 100%;
    inline-size: ${({ progress = 1 }) => `${Math.max(0, Math.min(100, Math.round(progress * 100)))}%`};
  }
`;
