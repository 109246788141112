import { FC, useMemo, useState } from 'react';
import { Info, List, ListHead, ListRowControlsWrapper, ListRowWrapper, OptionsWrapper, Row } from '../../atoms/List';
import styled from 'styled-components';
import { ContextOptions } from '../ContextOptions';
import { Trash } from '../../../icons/Trash';
import { Edit } from '../../../icons/Edit';
import { Avatar } from '../../atoms/Avatar';
import { Wheelchair } from '../../../icons/Wheelchair';
import { Walker } from '../../../icons/Walker';
import { formatAddress } from '../../../../utils/addressUtils';
import { useNavigate } from 'react-router-dom';
import { useShowDeletePassengerDialog } from '../../../../hooks/passengers/useShowDeletePassengerDialog';
import { IPassenger } from '../../../../api/models/Passenger';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';
import { BREAKPOINT_XL } from '../../../../styles/Breakpoints';
import { useMatchBreakpoint } from '../../../../hooks/useMatchBreakpoint';
import { PassengerTile } from '../passenger/PassengerTile';
import { formatName } from '../../../../utils/nameUtils';
import { useHasPermission } from '../../../../state/UserMeState';
import { FetchPassengersParams, usePassengerSearchAPI } from '../../../../api/controllers/InfiniteSearchAPI';

const StyledList = styled(List)`
  padding-block-start: 2.5rem;
  --list-template: 1fr;

  ${BREAKPOINT_XL} {
    --list-template: 2.5rem 1fr 6.5rem 8.75rem 1fr 8.75rem;
  }
`;

const PassengersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  ${BREAKPOINT_XL} {
    gap: 0;
  }
`;

export const CustomerDetailsPassengers: FC<{ customerId: string; updateHook: () => void }> = (props) => {
  const { customerId, updateHook } = props;

  const isDesktop = useMatchBreakpoint();

  const [searchParams] = useState<FetchPassengersParams>({
    customerIds: [customerId],
    query: '',
    page: { limit: 50, offset: 0 },
  });

  const { data, refetch } = usePassengerSearchAPI('list', searchParams);

  const passengers = useMemo(() => {
    return (data?.pages.map((page) => page.items).flat() as IPassenger[]) || [];
  }, [data]);

  return (
    <StyledList>
      <ListHead>
        {!isDesktop && <div>Fahrgäste</div>}
        {isDesktop && (
          <>
            <div />
            <div>Name</div>
            <div>Klassenstufe</div>
            <div>Barrierefreiheit</div>
            <div>Adresse</div>
          </>
        )}
      </ListHead>
      <PassengersWrapper>
        {passengers === null && <LoadingSpinner />}
        {passengers?.map((passenger) =>
          isDesktop ? (
            <CustomerDetailsPassengerRow
              key={passenger.id}
              passenger={passenger}
              updateHook={() => {
                refetch();
                updateHook();
              }}
            />
          ) : (
            <PassengerTile
              key={passenger.id}
              passenger={passenger}
              onUpdate={() => {
                refetch();
                updateHook();
              }}
            />
          ),
        )}
      </PassengersWrapper>
    </StyledList>
  );
};

const CustomerDetailsPassengerRow: FC<{ passenger: IPassenger; updateHook: () => void }> = (props) => {
  const { passenger, updateHook } = props;

  const navigate = useNavigate();
  const showDeletePassengerDialog = useShowDeletePassengerDialog(passenger.id);
  const isAdmin = useHasPermission('admin');

  return (
    <ListRowWrapper
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          navigate(`/passengers/${passenger.id}`);
        }
      }}
    >
      <Row>
        <Avatar src={''} firstName={passenger.firstName} lastName={passenger.lastName} size={'small'} />
        <Info>{formatName(passenger) || '-'}</Info>
        <Info>{'-'}</Info>
        <Info>
          {passenger.hasWheelchair ? (
            <>
              <Wheelchair /> Rollstuhlfahrer
            </>
          ) : (
            <>
              <Walker /> Läufer
            </>
          )}
        </Info>
        <Info>{formatAddress((passenger.addresses || [])[0]) || '-'}</Info>

        <ListRowControlsWrapper>
          <span />
          <OptionsWrapper>
            <ContextOptions
              as="div"
              items={
                [
                  isAdmin && {
                    content: (
                      <>
                        <Trash /> Entfernen
                      </>
                    ),
                    onClick: () => {
                      showDeletePassengerDialog(() => {
                        updateHook();
                      });
                    },
                  },
                  {
                    content: (
                      <>
                        <Edit /> Bearbeiten
                      </>
                    ),
                    onClick: () => navigate(`/passengers/${passenger.id}/edit`),
                  },
                ].filter(Boolean) as []
              }
            />
          </OptionsWrapper>
        </ListRowControlsWrapper>
      </Row>
    </ListRowWrapper>
  );
};
