import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export default function useValueChange(effect: EffectCallback, deps: DependencyList) {
  const initRef = useRef(false);

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
    } else {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
