import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FetchVehiclesParams } from '../api/controllers/InfiniteSearchAPI';

const { persistAtom } = recoilPersist({
  key: 'vehicle-filters',
  storage: localStorage,
});

export const VehicleFilterState = atom<FetchVehiclesParams>({
  key: 'VehicleFilterState',
  default: {
    query: '',
    page: {
      offset: 0,
      limit: 50,
    },
    hasRoomForWheelchairs: 0,
  },
  effects_UNSTABLE: [persistAtom],
});
