import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Body4, Body4Bold } from '../../../styles/FontStyles';
import { MouseEventHandler } from 'react';
import { Link } from './Link';

export const List = styled.div<{ noBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  --list-template-internal: var(--list-template, 4.5rem 1fr 6.25rem 6.25rem 1fr 8.75rem);

  ${({ noBorder = false }) =>
    !noBorder &&
    css`
      & > *:not(:first-child, :last-child) {
        border-bottom: 1px solid ${Colors.grey500};
      }
    `};
`;

export const ListHead = styled.div<{ inline?: boolean }>`
  display: grid;
  grid-template-columns: var(--list-template-internal);
  gap: 1.25rem;

  padding: 0.5rem;
  padding-block-start: 0;
  padding-inline-end: 0.5rem;

  margin-block-end: 1rem;

  ${Body4};
  color: ${Colors.secondary};

  ${({ inline = false }) =>
    !inline &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: -100vw;
        inline-size: 200vw;
        block-size: 100%;
        border-bottom: 1px solid ${Colors.grey500};
        background-color: ${Colors.background};
        z-index: -1;
      }
    `};

  ${({ inline = false }) =>
    inline &&
    css`
      border-bottom: 1px solid ${Colors.grey500};
    `};
`;

export const ListRowWrapper = styled.div.attrs(
  ({ href = undefined, onClick = undefined, as = 'button' }: { href?: string; onClick?: () => void; as: string }) =>
    href ? { as: Link, target: '_blank' } : onClick ? { as, type: 'button' } : {},
)<{ href?: string; onClick?: MouseEventHandler; selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 1rem 0.5rem;

  transition: box-shadow 150ms ease-out, border-radius 150ms ease-out, background-color 150ms ease-out;

  ${({ href, onClick }) =>
    (href || onClick) &&
    css`
      @media (hover: hover) {
        &:hover {
          box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          text-decoration: inherit;
        }
      }
    `};

  ${({ selected = false }) =>
    selected &&
    css`
      background-color: ${Colors.secondaryLight};
    `};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: var(--list-template-internal);
  gap: 1.25rem;
  align-items: center;
`;

export const ListRowControlsWrapper = styled.div`
  inline-size: 8.75rem;
  block-size: 2rem;
  margin-block: -0.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Info = styled.div`
  flex: 1;
  flex-shrink: 0;

  min-inline-size: 5rem;
  min-block-size: 1.5rem;

  overflow: hidden;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4};
  text-align: start;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

export const InfoBold = styled(Info)`
  ${Body4Bold};
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0 0 0.5rem;
`;

export const ActionButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: -0.25rem 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;

  background: ${Colors.secondary};
  color: ${Colors.white50};

  ${({ href }) =>
    !href &&
    css`
      opacity: 0.5;
      background: ${Colors.grey600};
      pointer-events: none;
    `};

  svg {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
`;
