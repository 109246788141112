import { FC, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from '../../../components/common/inputs/SearchInput';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { FilterButton } from './FilterButton';
import { useRecoilState } from 'recoil';
import { EmployeeFilterState } from '../../../state/EmployeeFilterState';
import useSyncStateWithURL from '../../../hooks/useSyncStateWithURL';

const FilterRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 1rem;
  }
  ${BREAKPOINT_XL} {
    justify-content: space-between;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;

  ${BREAKPOINT_MD} {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  ${BREAKPOINT_XL} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  ${BREAKPOINT_MD} {
    width: auto;
    flex: 1;
    min-width: 20rem;
  }

  ${BREAKPOINT_LG} {
    width: 100%;
    max-width: 29rem;
  }
`;

export const FILTERS = [
  {
    key: 'regions',
    title: 'Region',
    type: 'multi',
    options: [
      {
        key: 'Düsseldorf',
        title: 'Düsseldorf',
      },
      {
        key: 'Niederrhein',
        title: 'Niederrhein',
      },
      {
        key: 'Wuppertal',
        title: 'Wuppertal',
      },
      {
        key: 'Ruhrgebiet',
        title: 'Ruhrgebiet',
      },
    ],
  },
  {
    key: 'skills',
    title: 'Eigenschaften',
    type: 'multi',
    options: [
      {
        key: 'has_skill_scalamobil?',
        title: 'Scalamobil',
      },
      {
        key: 'has_skill_support?',
        title: 'Unterstützung',
      },
      {
        key: 'drives_tauga?',
        title: 'Tauga',
      },
    ],
  },
  {
    key: 'availableOnly',
    title: 'Nur verfügbare zeigen',
    type: 'boolean',
  },
];

export const EmployeeFilter: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [employeeSearchParams, setEmployeeSearchParams] = useRecoilState(EmployeeFilterState);
  const [filterValue, setFilterValue] = useState(employeeSearchParams?.query || '');
  useSyncStateWithURL(EmployeeFilterState);

  const onSearchChange = (value: string) => {
    setFilterValue(value);
    setEmployeeSearchParams({
      ...employeeSearchParams,
      query: value,
    });
  };

  const onReset = () => {
    setFilterValue('');
    setEmployeeSearchParams({
      query: '',
      regions: [],
      skills: [],
      availableOnly: 0,
      page: {
        offset: 0,
        limit: 100,
      },
    });
  };

  return (
    <FilterRowWrapper>
      <Content>{children}</Content>
      <FilterRow>
        <StyledSearchInput
          placeholder={'nach Mitarbeitern suchen'}
          value={filterValue}
          setValue={onSearchChange}
          onReset={() => onReset()}
        />
        <FilterButton filterOptions={FILTERS} handleReset={onReset} />
      </FilterRow>
    </FilterRowWrapper>
  );
};
