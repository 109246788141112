import { Wheelchair } from '../../../../icons/Wheelchair';
import { Walker } from '../../../../icons/Walker';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IScheduledTour } from '../../../../../api/models/ScheduledTour';
import { IPassenger } from '../../../../../api/models/Passenger';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

interface ITourPassengersCountProps {
  scheduledTour: IScheduledTour;
  className?: string;
}
export default function TourPassengersCount(props: ITourPassengersCountProps) {
  const { scheduledTour, className } = props;

  const [passengersWheelchair, passengersWalking] = useMemo(() => {
    return (scheduledTour.passengers || []).reduce(
      (res, item) => {
        res[item.hasWheelchair ? 0 : 1].push(item);
        return res;
      },
      [[], []] as Partial<IPassenger>[][],
    );
  }, [scheduledTour]);

  return (
    <Wrapper className={className}>
      {passengersWheelchair.length > 0 && (
        <IconWrapper>
          {passengersWheelchair.length} <Wheelchair />
        </IconWrapper>
      )}
      {passengersWalking.length > 0 && (
        <IconWrapper>
          {passengersWalking.length} <Walker />
        </IconWrapper>
      )}
    </Wrapper>
  );
}
