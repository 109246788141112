// General
import styled from 'styled-components';
import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { Link } from './Link';

// styles
import { Body2 } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { TUserPermission } from '../../../api/models/User';

const LinkWrapper = styled.div<{ active?: boolean; collapsed?: boolean }>`
  svg {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }

  a {
    ${Body2};
    position: relative;
    display: flex;
    height: 3.5rem;
    align-items: center;
    gap: 2rem;
    padding-inline: calc(var(--content-indent) + 2.5rem) var(--content-indent);
    padding-block: 0.75rem;

    :active {
      background: var(--color-grey-400);
    }

    ${BREAKPOINT_MD} {
      align-self: stretch;
      padding-block: 0.75rem;
      padding-inline: 2rem 1rem;
      transition: padding 150ms ease-out;
      background: ${({ active }) => (active ? Colors.grey400 : 'transparent')};
      gap: 1rem;

      :active {
        background: inherit;
      }

      &::before {
        content: '';
        position: absolute;
        display: ${({ active }) => (active ? 'flex' : 'none')};
        top: 0;
        left: 0;
        width: 0.75rem;
        height: 100%;
        background: ${Colors.grey900};
        border-radius: 0 1rem 1rem 0;
        transition: width 150ms ease-out;
      }
    }
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    flex: 1;
  }
`;

const Title = styled.p<{ collapsed: boolean; active: boolean }>`
  font-weight: ${({ active }) => (active ? '700' : '400')};
  position: ${({ collapsed }) => (!collapsed ? 'absolute' : 'relative')};
  opacity: ${({ collapsed }) => (collapsed ? '0' : '1')};
  transition: ${({ collapsed }) => (!collapsed ? 'none' : 'opacity calc(var(--animation-speed) / 2) linear')};
  transition-delay: ${({ collapsed }) => (!collapsed ? '0' : 'calc(var(--animation-speed) / 2)')};
`;

export interface INavItem {
  title: string;
  href: string;
  icon: (active: boolean) => ReactNode;
  permissions: TUserPermission[];
}

interface INavItemProps extends INavItem {
  collapsed: boolean;
  onClick?: () => void;
}

export const NavItem: FC<INavItemProps> = (props) => {
  const { collapsed, onClick, icon, ...item } = props;

  const location = useLocation();
  const active = item.href.length > 1 ? location.pathname.startsWith(item.href) : location.pathname === item.href;

  return (
    <LinkWrapper active={active} collapsed={collapsed}>
      <Link {...item} onClick={onClick}>
        {icon(active)}
        <TitleWrapper>
          <Title collapsed={collapsed} active={active}>
            {item.title}
          </Title>
        </TitleWrapper>
      </Link>
    </LinkWrapper>
  );
};
