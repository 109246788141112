import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { TabStatusBarLayout } from '../../common/elements/TabStatusBarLayout';
import { VehiclesDetailsStatusCard } from '../../common/elements/vehicle/VehicleDetailsStatusCard';
import { VehicleDetailsInformation } from '../../common/elements/vehicle/VehicleDetailsInformation';
import { EntityDetailsTours } from '../../common/elements/tours/EntityDetailsTours';
import { useVehiclesAPI } from '../../../api/controllers/VehiclesAPI';
import { IVehicle } from '../../../api/models/Vehicle';
import { VehicleComments } from '../../common/elements/vehicle/VehicleComments';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
`;

export const VehicleDetails: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const vehiclesAPI = useVehiclesAPI();
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState<IVehicle | null>(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      vehiclesAPI
        .getVehicleById({ id })
        .then((res) => {
          setVehicle(res.data);
        })
        .catch(() => {
          navigate('/404');
        })
        .finally(() => setLoading(false));
    } else {
      navigate('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      {loading && <LoadingSpinner />}

      {!loading && vehicle && (
        <TabStatusBarLayout
          breadcrumb={{
            title: 'Fuhrpark Übersicht',
            href: '/vehicles',
          }}
          subPageBreadcrumb={{
            title: vehicle.licensePlate,
            href: `/vehicles/${vehicle.id}`,
          }}
          header={<VehiclesDetailsStatusCard vehicle={vehicle} />}
          items={[
            {
              title: 'Informationen',
              content: <VehicleDetailsInformation vehicle={vehicle} />,
              hash: '',
            },
            {
              title: 'Fahrten',
              content: <EntityDetailsTours stickyTop employeeId={(vehicle.drivers || []).map(({ id }) => id)} />,
              hash: '#tours',
            },
            {
              title: 'Anmerkungen',
              content: <VehicleComments comment={vehicle.comment || '-'} />,
              hash: '#comments',
            },
          ]}
        />
      )}
    </Wrapper>
  );
};
