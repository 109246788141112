import { useInfiniteQuery } from '@tanstack/react-query';
import { useAuthHeader } from '../../state/AuthState';
import axios from 'axios';
import { IPassenger } from '../models/Passenger';
import { TabType } from '../../state/UiState';

interface IFetchParams {
  query: string;
  page: {
    offset: number;
    limit: number;
  };
}

export interface FetchEmployeesParams extends IFetchParams {
  regions: string[];
  skills: string[];
  availableOnly?: number;
}
export interface FetchToursListParams extends IFetchParams {
  regions: string[];
  directions: string[];
  showOnlyCancelled: string;
  conflictsOnly: string;
  hideCancelled: string;
  customerIds: number[];
}

export interface FetchVehiclesParams extends IFetchParams {
  hasRoomForWheelchairs?: number;
}
export interface FetchCustomersParams extends IFetchParams {
  regions?: string[];
}
export interface FetchPassengersParams extends IFetchParams {
  regions?: string[];
  customerIds?: string[];
  travelTypes?: string[];
}

export const useEmployeeSearchAPI = (type: TabType, params: FetchEmployeesParams) => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  async function fetchEmployees({ pageParam, params }: { pageParam: number; params: FetchEmployeesParams }) {
    await checkAuth();
    const { data } = await axios.post(
      `/api/search/employees`,
      { ...params, page: { ...params.page, offset: pageParam } },
      { ...authHeader },
    );
    return data;
  }

  const { query, regions, skills, page } = params;

  const regionString = regions?.join('_') || '';
  const skillString = skills?.join('_') || '';
  const queryKey = [`search_employees-${type}`, query, page.limit, page.offset, regionString, skillString, params.availableOnly];

  return useInfiniteQuery({
    queryKey: queryKey,
    queryFn: async ({ pageParam }) => fetchEmployees({ pageParam, params }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      return lastPage.hasMore ? lastPageParam + lastPage.items.length : undefined;
    },
  });
};

export const useVehicleSearchAPI = (params: FetchVehiclesParams) => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  async function fetchVehicles({ pageParam, params }: { pageParam: number; params: FetchVehiclesParams }) {
    await checkAuth();
    const { data } = await axios.post(
      `/api/search/vehicles`,
      { ...params, page: { ...params.page, offset: pageParam } },
      { ...authHeader },
    );
    return data;
  }

  const { query, page } = params;

  const queryKey = ['search_vehicles', query, page.limit, page.offset];

  return useInfiniteQuery({
    queryKey: queryKey,
    queryFn: async ({ pageParam }) => fetchVehicles({ pageParam, params }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      return lastPage.hasMore ? lastPageParam + lastPage.items.length : undefined;
    },
  });
};

export const useCustomerSearchAPI = (params: FetchCustomersParams) => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  async function fetchCustomers({ pageParam, params }: { pageParam: number; params: FetchCustomersParams }) {
    await checkAuth();
    const { data } = await axios.post(
      `/api/search/customers`,
      { ...params, page: { ...params.page, offset: pageParam } },
      { ...authHeader },
    );
    return data;
  }

  const { query, page } = params;

  const queryKey = ['search_customers', query, page.limit, page.offset];

  return useInfiniteQuery({
    queryKey: queryKey,
    queryFn: async ({ pageParam }) => fetchCustomers({ pageParam, params }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      return lastPage.hasMore ? lastPageParam + lastPage.items.length : undefined;
    },
  });
};

export const usePassengerSearchAPI = (type: TabType, params: FetchPassengersParams) => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  async function fetchPassengers({ pageParam, params }: { pageParam: number; params: FetchPassengersParams }) {
    await checkAuth();
    const { data } = await axios.post(
      `/api/search/passengers`,
      { ...params, page: { ...params.page, offset: pageParam } },
      { ...authHeader },
    );
    return data as { items: IPassenger[]; hasMore: boolean; total: number };
  }

  const { query, page, customerIds, regions, travelTypes } = params;
  const queryKey = [`search_passengers-${type}`, query, page.limit, page.offset, regions, customerIds, travelTypes];

  return useInfiniteQuery({
    queryKey: queryKey,
    queryFn: async ({ pageParam }) => fetchPassengers({ pageParam, params }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      return lastPage.hasMore ? lastPageParam + lastPage.items.length : undefined;
    },
  });
};
