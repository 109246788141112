export const ChevronDown = ({ invert, ...props }: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={invert ? { transform: 'rotate(180deg)' } : {}}
    {...props}
  >
    <path d="M19.375 8.59967L12.375 15.5997L5.375 8.59967" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
