import { FC } from 'react';
import styled from 'styled-components';
import { ButtonSecondary } from '../../../inputs/Button';
import { Body4Bold } from '../../../../../styles/FontStyles';
import { Colors } from '../../../../../styles/Colors';
import { Clickable } from '../../../atoms/Clickable';
import { useNavigate } from 'react-router-dom';
import { Calendar } from '../../../../icons/Calendar';
import { Change } from '../../../../icons/Change';
import { Close } from '../../../../icons/Close';

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
`;

const PlainButton = styled(Clickable)<{ color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  block-size: 1.5rem;

  ${Body4Bold};
  color: ${({ color = Colors.grey600 }) => color};

  :disabled {
    pointer-events: none;
    color: ${Colors.grey600};
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const CalendarCancelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CalendarCancelIconWrapper = styled.div`
  position: absolute;
  inset-inline-end: -0.25rem;
  inset-block-end: -0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.background};
  border-radius: 50%;
  padding: 0.125rem;

  svg {
    width: 0.5rem;
    height: 0.5rem;
  }
`;

interface ITourDetailsHeaderButtonsProps {
  isTourDriven: boolean;
  isActiveTour: boolean;
  isBlockedByBookingCompletion: boolean;
  proceedWithoutSaving: (onProceed: () => void) => void;
  setShowCancelDialog: (show: boolean) => void;
  customerId: string;
  direction: string;
  id: string;
  timestamp: string;
}

const HeaderButtons: FC<ITourDetailsHeaderButtonsProps> = (props) => {
  const { isTourDriven, isActiveTour,isBlockedByBookingCompletion, proceedWithoutSaving, setShowCancelDialog, customerId, direction, id, timestamp } =
    props;
  const navigate = useNavigate();
  return (
    <HeaderBlock>
      {isActiveTour && isTourDriven && !isBlockedByBookingCompletion && (
        <PlainButton
          color={Colors.signalRed900}
          onClick={() => {
            proceedWithoutSaving(() => {
              setShowCancelDialog(true);
            });
          }}
        >
          <CalendarCancelWrapper>
            <Calendar />
            <CalendarCancelIconWrapper>
              <Close />
            </CalendarCancelIconWrapper>
          </CalendarCancelWrapper>
          Fahrt absagen
        </PlainButton>
      )}
      <ButtonSecondary
        disabled={!isTourDriven || isBlockedByBookingCompletion}
        onClick={() => {
          proceedWithoutSaving(() => {
            navigate(`/customers/${customerId}/tourplan?direction=${direction}&split-tour=${id}&date=${timestamp}`);
          });
        }}
      >
        <Change /> Fahrt aufteilen
      </ButtonSecondary>
    </HeaderBlock>
  );
};

export default HeaderButtons;
