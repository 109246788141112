export const IconLogin = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 101" fill="none" {...props}>
    <path
      stroke="#008AD0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M22.917 30.834h3.418c8.944 0 13.39 10.843 7.02 17.122L17.37 63.712c-6.37 6.279-1.924 17.122 7.02 17.122h33.943"
    />
    <g filter="url(#a)">
      <path
        fill="#FFB82F"
        d="M87.834 48.308C87.834 57.867 78.11 81 73.25 81c-4.86 0-14.583-23.133-14.583-32.692 0-9.56 6.53-17.308 14.583-17.308 8.055 0 14.584 7.749 14.584 17.308Z"
      />
    </g>
    <circle cx="73.25" cy="49.75" r="6.25" fill="#fff" />
    <path
      fill="#FFB82F"
      d="M21.75 10.73c0 5.927-6.027 20.27-9.041 20.27-3.014 0-9.042-14.343-9.042-20.27C3.667 4.805 7.715 0 12.709 0c4.993 0 9.041 4.804 9.041 10.73Z"
    />
    <circle cx="12.708" cy="11.625" r="3.875" fill="#fff" />
    <defs>
      <filter id="a" width="69.166" height="90" x="38.667" y="11" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.721569 0 0 0 0 0.184314 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_929_15927" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_929_15927" result="shape" />
      </filter>
    </defs>
  </svg>
);
