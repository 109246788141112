import { TourLabel } from '../../../labels/TourLabel';
import React from 'react';
import styled from 'styled-components';
import { IScheduledTour } from '../../../../../api/models/ScheduledTour';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

const DirectionHint = styled.span`
  margin-inline-end: auto;
`;

interface ITourNameProps {
  scheduledTour: IScheduledTour;
}
export default function TourName(props: ITourNameProps) {
  const { scheduledTour } = props;

  return (
    <Wrapper>
      <TourLabel>{scheduledTour.name}</TourLabel>
      <DirectionHint>{scheduledTour.direction === 'return' ? 'Rück' : 'Hin'}</DirectionHint>
    </Wrapper>
  );
}
