import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export type TabType = 'tile' | 'list';

const { persistAtom } = recoilPersist({
  key: 'uiState',
  storage: localStorage,
});

export type UiStateListNames = 'tourList' | 'passengerList' | 'employeeList' | 'customerList' | 'vehicleList';

interface IUIState {
  navigationCollapsed: boolean;
  tourList: {
    activeTab: TabType;
  };
  passengerList: {
    activeTab: TabType;
  };
  employeeList: {
    activeTab: TabType;
  };
  customerList: {
    activeTab: TabType;
  };
  vehicleList: {
    activeTab: TabType;
  };
  // Add other states as needed
}

export const UiState = atom<IUIState>({
  key: 'states',
  default: {
    navigationCollapsed: false,
    tourList: {
      activeTab: 'tile',
    },
    passengerList: {
      activeTab: 'tile',
    },
    employeeList: {
      activeTab: 'tile',
    },
    customerList: {
      activeTab: 'tile',
    },
    vehicleList: {
      activeTab: 'tile',
    },
    // Add other states as needed
  },
  effects_UNSTABLE: [persistAtom],
});
