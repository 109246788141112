import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomerCategoryAPI } from '../../../api/controllers/CustomerCategoryAPI';
import { ICustomerCategory } from '../../../api/models/CustomerCategory';
import { ContentPageLayout } from '../../common/atoms/Layout';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { CustomerCategoryForm } from '../../content/customer-categories/CustomerCategoryForm';

export const CustomerCategoryEdit: React.FC = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [customerCategory, setCustomerCategory] = useState<ICustomerCategory>();

  const customerCategoryAPI = useCustomerCategoryAPI();
  useEffect(() => {
    if (id) {
      setLoading(true);
      customerCategoryAPI
        .getCustomerCategoryById({ id })
        .then((res) => {
          setCustomerCategory(res.data.data);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CustomerCategoryForm customerCategory={customerCategory || undefined} />

      )}
    </ContentPageLayout>
  );
};
