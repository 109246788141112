import { useAuthHeader } from '../../state/AuthState';
import axios from 'axios';
import { ITour } from '../models/Tour';
import { IPickupDate } from '../models/PickupDate';
import { useErrorHandler } from './ErrorHandler';

interface ICreateOrderProps {
  displayName: string;
  comment: string | null;
  customerId: string;
  tours?: ITour[];
  timestamp?: string | null;
}

interface ICreateTourProps {
  name: string;

  startDate: Date;
  endDate: Date | null;

  departureDate?: IPickupDate | null;
  arrivalDate?: IPickupDate | null;

  comment: string | null;

  customerId: string;
}

interface IUpdateTourProps extends ICreateTourProps {
  timestamp?: string | null;
}

interface IGetTourByIdProps {
  id: string;
}

export const useOrderAPI = () => {
  const authHeader = useAuthHeader();
  const errorHandler = useErrorHandler();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const createOrder = (props: ICreateOrderProps) => {
    return checkAuth()
      .then(() => {
        return axios.post(`/api/orders`, props, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const getOrderById = (orderId: string) => {
    return checkAuth().then(() => {
      return axios.get(`/api/orders/${orderId}`, { ...authHeader }).then((res) => {
        return {
          ...res,
          data: {
            ...res.data,
            tours: (res.data.tours || []).map((tour: ITour) => ({
              ...tour,
              passengers: (tour.passengers || []).sort(
                (a, b) =>
                  ((a as any).TourPassenger?.position || 0) - ((b as any).TourPassenger?.position || 0), // sort passenger by position (not supported by api)
              ),
            })),
          },
        };
      });
    });
  };

  const updateOrder = (orderId: string, props: Partial<ICreateOrderProps>) => {
    return checkAuth()
      .then(() => {
        return axios.patch(`/api/orders/${orderId}`, props, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const deleteOrder = (orderId: string) => {
    return checkAuth()
      .then(() => {
        return axios.delete(`/api/orders/${orderId}`, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const importOrder = (file: File) => {
    return checkAuth().then(() => {
      const formData = new FormData();
      formData.append('file', file);
      return axios.post(`/api/orders/import`, formData, { ...authHeader });
    });
  };

  const createTour = (orderId: string, props: ICreateTourProps) => {
    return checkAuth()
      .then(() => {
        return axios.post(`/api/orders/${orderId}/tours`, props, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const updateTour = (id: string, orderId: string, props: Partial<IUpdateTourProps>) => {
    return checkAuth()
      .then(() => {
        return axios.patch(`/api/orders/${orderId}/tours/${id}`, props, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const getTourById = (props: IGetTourByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/tours/${props.id}`, { ...authHeader });
    });
  };

  const deleteTour = (id: string, timestamp: string) => {
    return checkAuth()
      .then(() => {
        return axios.delete(`/api/tours/${id}?timestamp=${timestamp}`, { ...authHeader });
      })
      .catch(errorHandler);
  };

  return {
    createOrder,
    updateOrder,
    getOrderById,
    deleteOrder,
    importOrder,
    createTour,
    updateTour,
    getTourById,
    deleteTour,
  };
};
