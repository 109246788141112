import { useInfiniteQuery } from '@tanstack/react-query';
import { useAuthHeader } from '../../../state/AuthState';
import axios from 'axios';
import { IScheduledTourWithMetaData } from '../../models/ScheduledTour';

export interface fetchToursParams {
  dayAsISODate: string;
  sortBy: 'tourname' | 'time';
  query: string;
  customerIds: number[];
  regions: string[];
  directions: string[];
  cancelled: string;
  page: {
    offset: number;
    limit: number;
  };
}

export const useScheduledToursForDayAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const fetchTours = (params: any) => {
    return checkAuth().then(() => {
      return axios.post(`/api/tours/scheduled_tours_for_day`, params, { ...authHeader });
    });
  };

  const getByTourIdForDay = (tourId: string, params: any) => {
    return checkAuth().then(() => {
      return axios.post(`/api/tours/scheduled_tours_detail`, { tourId, ...params }, { ...authHeader });
    });
  };

  const getExceptionByTourId = (tourId: string, params: any) => {
    return checkAuth().then(() => {
      return axios.post(`/api/tours/tour`, { tourId, ...params }, { ...authHeader });
    });
  };

  return {
    fetchTours,
    getByTourIdForDay,
    getExceptionByTourId,
  };
};

export const useScheduledToursForDay = (params: fetchToursParams) => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  async function fetchTours({ pageParam, params }: { pageParam: number; params: fetchToursParams }) {
    await checkAuth();
    const { data } = await axios.post(
      `/api/tours/scheduled_tours_for_day`,
      { ...params, page: { ...params.page, offset: pageParam } },
      { ...authHeader },
    );
    return data as { items: IScheduledTourWithMetaData[]; hasMore: boolean };
  }

  return useInfiniteQuery({
    queryKey: [
      'scheduledTours',
      params.dayAsISODate,
      params.query,
      params.customerIds,
      params.regions,
      params.directions,
      params.cancelled,
    ],
    queryFn: async ({ pageParam }) => fetchTours({ pageParam, params }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (lastPage.hasMore ? pages.length * params.page.limit : undefined),
  });
};
