import { atom, useSetRecoilState } from 'recoil';
import { ReactNode, useCallback } from 'react';

interface IDialogProps {
  headline: string;
  body: ReactNode | ((config: IDialogProps) => ReactNode);
  buttons?: ReactNode | ((config: IDialogProps) => ReactNode);
  loading?: boolean;
}

export const DialogState = atom<{ dialog: IDialogProps; open: boolean } | null>({
  key: 'DialogState',
  default: null,
});

export const useShowDialog = () => {
  const setDialogState = useSetRecoilState(DialogState);

  return useCallback(
    (config: Partial<IDialogProps> | null, update: boolean = false) => {
      if (config) {
        setDialogState((state: any) => ({
          open: true,
          dialog: update ? { ...state?.dialog, ...config } : config,
        }));
      } else {
        setDialogState((state) => (state ? { ...state, open: false } : null));
      }
    },
    [setDialogState],
  );
};
