import { FC, PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from '../../../components/common/inputs/SearchInput';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';

import { useCustomerSearchAPI } from '../../../api/controllers/InfiniteSearchAPI';
import { PassengerFilterState } from '../../../state/PassengerFilterState';
import { useRecoilState } from 'recoil';
import useSyncStateWithURL from '../../../hooks/useSyncStateWithURL';
import { FilterButton } from './FilterButton';

const FilterRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 1rem;
  }
  ${BREAKPOINT_XL} {
    justify-content: space-between;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;

  ${BREAKPOINT_MD} {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  ${BREAKPOINT_XL} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  ${BREAKPOINT_MD} {
    width: auto;
    flex: 1;
    min-width: 20rem;
  }

  ${BREAKPOINT_LG} {
    width: 100%;
    max-width: 29rem;
  }
`;

const FILTERS = [
  {
    key: 'regions',
    title: 'Region',
    type: 'multi',
    options: [
      {
        key: 'Düsseldorf',
        title: 'Düsseldorf',
      },
      {
        key: 'Niederrhein',
        title: 'Niederrhein',
      },
      {
        key: 'Wuppertal',
        title: 'Wuppertal',
      },
      {
        key: 'Ruhrgebiet',
        title: 'Ruhrgebiet',
      },
    ],
  },
  {
    key: 'customerIds',
    title: 'Einrichtung',
    type: 'multi',
    dependency: 'regions',
    options: [],
  },
  {
    key: 'travelTypes',
    title: 'Rollstuhlfahrer/Läufer',
    type: 'multi',
    options: [
      {
        key: 'walker',
        title: 'Läufer',
      },
      {
        key: 'wheelchair',
        title: 'Rollstuhlfahrer',
      },
    ],
  },
];

interface EmployeeFilterButtonProps {
  selectedRegions: string[];
}

export const PassengerFilter: FC<PropsWithChildren<EmployeeFilterButtonProps>> = (props) => {
  const { children } = props;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const [passengerSearchParams, setPassengerSearchParams] = useRecoilState(PassengerFilterState);

  // Set a default value for one property if it's not already set
  useEffect(() => {
    const urlValue = urlSearchParams.get('query') || '';
    setPassengerSearchParams((prevState) => ({
      ...prevState,
      query: urlValue
    }));
    setFilterValue(urlValue);
  }, [setPassengerSearchParams]);

  // passengerSearchParams.set('query', urlSearchParams.get('query') || '');
  const [filterValue, setFilterValue] = useState(passengerSearchParams?.query || '');
  useSyncStateWithURL(PassengerFilterState);

  const [visibleFilters, setVisibleFilters] = useState(FILTERS);

  const { data, refetch } = useCustomerSearchAPI(passengerSearchParams);

  useEffect(() => {
    refetch();
  }, [passengerSearchParams.regions, refetch]);

  useEffect(() => {
    if (data) {
      const filters = FILTERS.map((filter) => {
        if (filter.key === 'customerIds') {
          filter.options = data.pages
            .map((page) => page.items)
            .flat()
            .filter((customer) => passengerSearchParams?.regions?.includes(customer.region) || false)
            .map((customer) => ({
              key: customer.id,
              title: customer.displayName,
            }));
        }
        return filter;
      });
      setVisibleFilters(filters);
    }
  }, [data, setVisibleFilters, passengerSearchParams.regions]);

  const onSearchChange = (value: string) => {
    setFilterValue(value);
    setPassengerSearchParams({
      ...passengerSearchParams,
      query: value,
    });
  };

  const onReset = () => {
    setFilterValue('');
    setPassengerSearchParams({
      query: '',
      regions: [],
      customerIds: [],
      travelTypes: [],
      page: {
        offset: 0,
        limit: 50,
      },
    });
  };
  return (
    <FilterRowWrapper>
      <Content>{children}</Content>
      <FilterRow>
        <StyledSearchInput placeholder={'nach Fahrgästen suchen'} value={filterValue} setValue={onSearchChange} onReset={onReset} />
        <FilterButton filterOptions={visibleFilters} handleReset={onReset} />
      </FilterRow>
    </FilterRowWrapper>
  );
};
