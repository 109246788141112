import Appsignal from '@appsignal/javascript';

const key = process.env.REACT_APP_APPSIGNAL_KEY;

export const appsignal = new Appsignal({
  key: key,
  active: process.env.NODE_ENV !== 'development',
  name: 'sonnenschein',
  nameSpace: 'frontend',
});
