import { PropsWithChildren } from 'react';
import { useMatchBreakpoint } from '../../../../hooks/useMatchBreakpoint';
import { IScheduledTourWithMetaData } from '../../../../api/models/ScheduledTour';
import DayContainer from './DayContainer';
import styled from 'styled-components';
import { Body4 } from '../../../../styles/FontStyles';
import { IConflict } from '../../../../api/models/Conflict';
import TourItemRefactored from './TourItemRefactored';

const PlaceholderLabel = styled.p`
  ${Body4};
  padding-block-end: 1.5rem;
  padding-inline: 1rem;
  text-align: center;
`;

const ToursForDayRefactored = (
  props: PropsWithChildren<{ day: string; schedules: IScheduledTourWithMetaData[] | null; conflicts?: IConflict[] }>,
) => {
  const { day, schedules, children, conflicts } = props;

  const isDesktop = useMatchBreakpoint();

  if (isDesktop !== null) {
    return (
      <DayContainer day={day}>
        {schedules?.map((schedule) => (
          <TourItemRefactored key={schedule.tour.id} schedule={schedule} isDesktop={isDesktop} day={day} conflicts={conflicts} />
        ))}
        {schedules !== null && schedules.length === 0 && <PlaceholderLabel>Keine Fahrten geplant.</PlaceholderLabel>}
        {children}
      </DayContainer>
    );
  }
  return <></>;
};

export default ToursForDayRefactored;
