import React, { useEffect, useState } from 'react';
import { useCustomersAPI } from '../../../../api/controllers/CustomersAPI';
import { ICustomerInvoice } from '../../../../api/models/Invoice';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';
import { Section, SectionTitle } from '../../atoms/Form';
import styled, { css } from 'styled-components';
import { Info, List, ListHead, ListRowWrapper, Row } from '../../atoms/List';
import { Colors } from '../../../../styles/Colors';
import { Body4 } from '../../../../styles/FontStyles';
import { Alert } from '../../../icons/Alert';
import { downloadBlob } from '../../../../utils/downloadUtils';
import { Excel } from '../../../icons/Excel';
import { ExcelButton } from '../ExcelButton';
import { BREAKPOINT_XL } from '../../../../styles/Breakpoints';
import { useMatchBreakpoint } from '../../../../hooks/useMatchBreakpoint';
import { CollapsibleRow } from '../../atoms/CollapsibleRow';

const StyledSection = styled(Section)`
  gap: 1.5rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const StyledInvoicesList = styled(List)`
  --list-template: 3fr 1fr;

  ${BREAKPOINT_XL} {
    --list-template: 1.2fr 1fr 1.5fr 1.5rem;
  }

  ${Info} {
    em {
      font-style: normal;
      color: ${Colors.grey600};
      padding-inline: 0.25rem;

      &:first-child {
        padding-inline-start: 0;
      }
    }
  }
`;

const StyledListRowWrapper = styled(ListRowWrapper)`
  padding-block: 0.75rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  ${Body4};
  text-align: start;
`;

const ContentHeadline = styled.span`
  padding-block: 0.75rem 0.25rem;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  padding-block: 0.25rem;
  max-inline-size: 700px;

  & > :first-child {
    flex-shrink: 0;
  }

  & > :nth-child(2) {
    padding-inline-end: 1.5rem;
  }

  & > :not(:first-child) {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// const ContentRowSoFa = styled(ContentRow)`
//   max-inline-size: none;
// `;

const StyledTitle = styled(SectionTitle)<{ lineThrough?: boolean }>`
  ${({ lineThrough = false }) =>
    lineThrough &&
    css`
      text-decoration: line-through;
      text-decoration-color: ${Colors.signalRed900};
      &,
      em {
        color: ${Colors.grey600};
      }
    `};
`;

const AlertRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  color: ${Colors.signalRed900};
`;

interface ICustomerDetailsAccountingProps {
  customerId: string;
  invoiceId: string;
}
export default function CustomerDetailsAccountingInvoice(props: ICustomerDetailsAccountingProps) {
  const { customerId, invoiceId } = props;

  const customersAPI = useCustomersAPI();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<ICustomerInvoice | null>(null);

  const isDesktop = useMatchBreakpoint();

  useEffect(() => {
    // get invoice
    if (customerId && invoiceId) {
      setLoading(true);
      customersAPI
        .getCustomerInvoiceById({
          customerId,
          invoiceId,
        })
        .then((res) => setInvoice(res.data))
        .catch(() => setInvoice(null))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, invoiceId]);

  return (
    <StyledSection>
      {invoice?.state === 'revoked' && (
        <AlertRow>
          <Alert /> Buchungsabschluss wurde widerrufen
        </AlertRow>
      )}
      <HeaderRow>
        <StyledTitle lineThrough={invoice?.state === 'revoked'}>{invoice?.name}</StyledTitle>

        <ExcelButton
          onClick={() => {
            customersAPI
              .downloadCustomerInvoiceById({ customerId, invoiceId })
              .then((res) => {
                downloadBlob(res.data, invoice?.name || '');
              })
              .catch(() => {});
          }}
        >
          <Excel />
          Exportieren (.xlsx)
        </ExcelButton>
      </HeaderRow>

      <StyledInvoicesList>
        <ListHead>
          <div>Tournummer</div>
          <div>Anzahl</div>
          {isDesktop && <div>Fahrgäste</div>}
        </ListHead>
        {loading && <LoadingSpinner />}
        {!loading &&
          invoice?.data?.tours?.map((invoice, index) => (
            <CollapsibleRow
              key={index}
              content={
                isDesktop && (
                  <ContentWrapper>
                    <ContentHeadline>
                      Vertragszeitraum:{' '}
                      {[invoice.tourStartDate, invoice.tourEndDate]
                        .map((date) => date && new Date(date).toLocaleDateString(undefined, { dateStyle: 'medium' }))
                        .filter(Boolean)
                        .join(' - ')}
                    </ContentHeadline>
                    {invoice.tourPassengers.map((passenger, index) => (
                      <ContentRow>
                        <b>{index + 1}</b>
                        <span>{passenger.name}</span>
                        <b>{passenger.address}</b>
                      </ContentRow>
                    ))}
                  </ContentWrapper>
                )
              }
            >
              <Info>
                {invoice.tourName} {invoice.tourDirection === 'return' ? 'Rück' : 'Hin'}
              </Info>
              <Info>
                <b>{invoice.datesDriven.length}x</b>
              </Info>
              {isDesktop && <Info>{invoice.tourPassengers.length}</Info>}
            </CollapsibleRow>
          ))}
        {!loading &&
          !!invoice?.data?.additionalTours?.length &&
          invoice?.data?.additionalTours?.map((additionalTour, index) => (
            <StyledListRowWrapper key={index}>
              <Row>
                <Info>
                  <em>SoFa</em>
                  {additionalTour.tourName} {additionalTour.tourDirection === 'return' ? 'Rück' : 'Hin'}
                </Info>
                <Info>
                  <b>{additionalTour.datesDriven.length}x</b>
                </Info>
              </Row>
            </StyledListRowWrapper>
          ))}
      </StyledInvoicesList>
    </StyledSection>
  );
}
