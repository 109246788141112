import styled from 'styled-components';
import React, { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { Info, List, ListHead } from '../../common/atoms/List';
import { Colors } from '../../../styles/Colors';
import { SectionTitle } from '../../common/atoms/Form';
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from '../../common/inputs/Button';
import { Plus } from '../../icons/Plus';
import { Trash } from '../../icons/Trash';
import { Body4 } from '../../../styles/FontStyles';
import { useShowDialog } from '../../../state/DialogState';
import { IEmployeePaymentInfo } from '../../../api/models/EmployeePaymentInfo';
import { useEmployeePaymentInfosAPI } from '../../../api/controllers/EmployeePaymentInfosAPI';
import CreatePaymentInfoDialog from '../../common/elements/payment-infos/CreatePaymentInfoDialog';
import { Edit } from '../../icons/Edit';
import { SearchInput } from '../../common/inputs/SearchInput';
import { Excel } from '../../icons/Excel';
import { ExcelButton } from '../../common/elements/ExcelButton';
import FileImportDialog from '../../common/elements/FileImportDialog';
import { CollapsibleRow } from '../../common/atoms/CollapsibleRow';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  --list-template: 5rem 1fr 3.5rem 4rem 5rem 10rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const HeaderRowButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const StyledList = styled(List)`
  --list-template: 6.25rem 1fr 0.5fr 0.5fr 1fr 1.5rem;

  ${Info} {
    em {
      font-style: normal;
      color: ${Colors.signalRed900};
    }
  }
`;

const StyledListHead = styled(ListHead)`
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  overflow: auto;
`;

const ScrollContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-inline-size: max-content;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  padding-block: 0.75rem;

  p {
    ${Body4};
    text-align: start;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const TrashButton = styled(ButtonTertiary)`
  color: ${Colors.signalRed900};
  border-color: ${Colors.signalRed900};

  transition: border-color 150ms ease-out, background-color 150ms ease-out, color 150ms ease-out;

  @media (hover: hover) {
    :hover {
      color: ${Colors.textInverted};
      border-color: ${Colors.signalRed900};
      background-color: ${Colors.signalRed900};
    }
  }
`;

const EditButton = styled(ButtonTertiary)`
  color: ${Colors.textDefault};
  border-color: ${Colors.textDefault};

  transition: border-color 150ms ease-out, background-color 150ms ease-out, color 150ms ease-out;

  @media (hover: hover) {
    :hover {
      color: ${Colors.textInverted};
      border-color: ${Colors.textDefault};
      background-color: ${Colors.textDefault};
    }
  }
`;

const StyledSearchInput = styled(SearchInput)`
  margin-inline-start: auto;
  max-inline-size: 29rem;
`;

const PaymentInfoItem = React.memo((props: { paymentInfo: IEmployeePaymentInfo; onEdit: () => void; onDeleted: () => void }) => {
  const { paymentInfo, onEdit, onDeleted } = props;

  const showDialog = useShowDialog();
  const paymentInfosAPI = useEmployeePaymentInfosAPI();

  return (
    <CollapsibleRow
      content={
        <ContentWrapper>
          <p>Anmerkungen: {paymentInfo.comment || 'keine'}</p>
          <ButtonsWrapper>
            <EditButton
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                onEdit();
              }}
            >
              <Edit /> Bearbeiten
            </EditButton>
            <TrashButton
              onClick={(e: MouseEvent) => {
                e.stopPropagation();

                showDialog({
                  headline: 'Lohnart löschen',
                  body: 'Möchten Sie diese Lohnart wirklich löschen? Neue Abrechnungen werden davon möglicherweise beeinflusst.',
                  buttons: (
                    <>
                      <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
                      <TrashButton
                        onClick={() => {
                          showDialog({ loading: true }, true);
                          paymentInfosAPI
                            .deletePaymentInfo(paymentInfo.id)
                            .then(() => onDeleted())
                            .catch(() => {})
                            .finally(() => showDialog(null));
                        }}
                      >
                        <Trash /> Zurücksetzen
                      </TrashButton>
                    </>
                  ),
                });
              }}
            >
              <Trash /> Zurücksetzen
            </TrashButton>
          </ButtonsWrapper>
        </ContentWrapper>
      }
    >
      <Info>{paymentInfo.paymentKey}</Info>
      <Info>
        <b>
          {paymentInfo.title} {paymentInfo.type === 'tour_return' ? 'Rück' : paymentInfo.type === 'tour_outwards' ? 'Hin' : ''}
        </b>
      </Info>
      <Info>
        {+paymentInfo.amount < 1
          ? '-'
          : +paymentInfo.amount > 120
          ? `${(+paymentInfo.amount / 60).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Std.`
          : `${paymentInfo.amount} Min.`}
      </Info>
      <Info>{(+paymentInfo.paymentFactor).toLocaleString('de', { style: 'currency', currency: 'EUR' })}</Info>
      <Info>{paymentInfo.createdAt && new Date(paymentInfo.createdAt).toLocaleDateString(undefined, { dateStyle: 'medium' })}</Info>
    </CollapsibleRow>
  );
});

export default function PaymentInfosHome() {
  const [loading, setLoading] = useState(false);
  const [paymentInfos, setPaymentInfos] = useState<IEmployeePaymentInfo[] | null>(null);
  const paymentInfosAPI = useEmployeePaymentInfosAPI();

  const [showImportDialog, setShowImportDialog] = useState(false);

  const [showCreatePaymentInfoDialog, setShowCreatePaymentInfoDialog] = useState(false);
  const [editPaymentInfo, setEditPaymentInfo] = useState<IEmployeePaymentInfo | null>(null);
  const showDialog = useShowDialog();

  const [searchQuery, setSearchQuery] = useState('');
  const deferredQuery = useDeferredValue(searchQuery);
  const [filteredPaymentInfos, moreItemsCount] = useMemo(() => {
    const filteredItems = (paymentInfos || []).filter(
      ({ title }) => !deferredQuery.length || title.toLowerCase().includes(deferredQuery.toLowerCase()),
    );

    return [
      filteredItems.filter((_, index) => index < 25), // max 25 items
      Math.max(0, filteredItems.length - 25),
    ];
  }, [paymentInfos, deferredQuery]);

  useEffect(() => {
    if (!showCreatePaymentInfoDialog) {
      setEditPaymentInfo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCreatePaymentInfoDialog]);

  const fetchPaymentInfos = useCallback(() => {
    setLoading(true);
    setPaymentInfos(null);
    paymentInfosAPI
      .getPaymentInfos({ offset: 0, limit: -1 })
      .then((res) => setPaymentInfos(res.data.rows || []))
      .catch(() => setPaymentInfos(null))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPaymentInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchInput = useMemo(
    () => <StyledSearchInput placeholder={'Suche'} value={searchQuery} setValue={setSearchQuery} onReset={() => setSearchQuery('')} />,
    [searchQuery, setSearchQuery],
  );

  const paymentInfosList = useMemo(
    () => (
      <>
        {filteredPaymentInfos.map((paymentInfo) => (
          <PaymentInfoItem
            key={paymentInfo.id}
            paymentInfo={paymentInfo}
            onEdit={() => {
              setEditPaymentInfo(paymentInfo);
              setShowCreatePaymentInfoDialog(true);
            }}
            onDeleted={() => fetchPaymentInfos()}
          />
        ))}
        {moreItemsCount > 0 && <p style={{ paddingInline: '.5rem', paddingBlock: '1rem' }}>+ {moreItemsCount} weitere Lohnarten</p>}
      </>
    ),
    [fetchPaymentInfos, filteredPaymentInfos, moreItemsCount],
  );

  const paymentInfosDialog = useMemo(
    () => (
      <>
        {showCreatePaymentInfoDialog && (
          <CreatePaymentInfoDialog
            open={showCreatePaymentInfoDialog}
            setOpen={setShowCreatePaymentInfoDialog}
            defaultValues={(editPaymentInfo as any) || undefined}
            onSubmit={(data, setLoading) => {
              setLoading(true);
              const editId = editPaymentInfo?.id || null;
              (editId ? paymentInfosAPI.updatePaymentInfo(editId, data) : paymentInfosAPI.createPaymentInfo(data))
                .then(() => fetchPaymentInfos())
                .catch(() => {})
                .finally(() => {
                  setLoading(false);
                  setShowCreatePaymentInfoDialog(false);
                });
            }}
          />
        )}
      </>
    ),
    [showCreatePaymentInfoDialog, editPaymentInfo, fetchPaymentInfos, paymentInfosAPI],
  );

  const fileImportDialog = useMemo(
    () => (
      <>
        {showImportDialog && (
          <FileImportDialog
            onImport={(file, setLoading) => {
              setLoading(true);
              paymentInfosAPI
                .importPaymentInfosList(file)
                .then(() => fetchPaymentInfos())
                .catch(() => {
                  showDialog({
                    headline: 'Aus Excel importieren',
                    body: 'Die ausgewählte Datei konnte nicht importiert werden.',
                  });
                })
                .finally(() => {
                  setLoading(false);
                  setShowImportDialog(false);
                });
            }}
            onClose={() => setShowImportDialog(false)}
          />
        )}
      </>
    ),
    [showImportDialog, paymentInfosAPI, showDialog, fetchPaymentInfos],
  );

  return (
    <Wrapper>
      <HeaderRow>
        <SectionTitle>Lohnartenliste</SectionTitle>

        <HeaderRowButtonsWrapper>
          <ExcelButton onClick={() => setShowImportDialog(true)}>
            <Excel />
            Aus Excel importieren
          </ExcelButton>
          <ButtonPrimary onClick={() => setShowCreatePaymentInfoDialog(true)}>
            <Plus /> Lohnart hinzufügen
          </ButtonPrimary>
        </HeaderRowButtonsWrapper>
      </HeaderRow>

      {searchInput}

      <ScrollContainer>
        <ScrollContentWrapper>
          <StyledList>
            <StyledListHead>
              <div>Lohnart</div>
              <div>Bezeichnung</div>
              <div>Zeitpauschale</div>
              <div>Stundenlohn</div>
              <div>Hinzugefügt am</div>
            </StyledListHead>

            {loading && <LoadingSpinner />}

            {!loading && paymentInfosList}
            {!loading && !filteredPaymentInfos.length && <p style={{ padding: '.5rem' }}>Keine Lohnarten gefunden</p>}
          </StyledList>
        </ScrollContentWrapper>
      </ScrollContainer>

      {paymentInfosDialog}
      {fileImportDialog}
    </Wrapper>
  );
}
