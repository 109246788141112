// General
import { CSSProperties, FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Styles
import { Colors } from '../../../styles/Colors';
import { Body3 } from '../../../styles/FontStyles';

// Components
import { Clickable } from '../atoms/Clickable';

// Icons
import { Search } from '../../icons/Search';
import { Close } from '../../icons/Close';

const Wrapper = styled.form`
  appearance: none;
  border: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 0.75em 1rem;
  border-radius: 24px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  background: ${Colors.white50};
`;

const SearchIcon = styled(Search)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  flex-shrink: 0;
`;

const CloseIcon = styled(Close)`
  width: 1.5rem;
  height: 1.5rem;
`;

const Input = styled.input`
  appearance: none;
  background: none;
  border: 0;
  width: 100%;
  flex: 1;

  ${Body3};
  white-space: nowrap;
  overflow: hidden;

  &::placeholder {
    ${Body3};
    color: ${Colors.grey600};
  }
`;

interface ISearchInputProps {
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  value: string;
  setValue: (value: string) => void;
  onSubmit?: () => void;
  onChange?: () => void;
  onClick?: (e: any) => void;
  onReset?: (e: any) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  autoFocusExplicit?: boolean;
}

export const SearchInput: FC<ISearchInputProps> = (props) => {
  const {
    placeholder,
    className,
    style,
    value,
    setValue,
    onSubmit,
    onReset,
    onClick,
    onChange,
    tabIndex,
    autoFocus,
    autoFocusExplicit = false,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autoFocusExplicit) {
      setTimeout(() => {
        inputRef.current?.focus({ preventScroll: true }); // request focus
      }, 100);
    }
  }, [autoFocusExplicit]);

  return (
    <Wrapper
      as={onSubmit ? 'form' : 'div'}
      onClick={onClick}
      className={className}
      style={style}
      onChange={(e: any) => {
        e.preventDefault();
        if (onChange) {
          onChange();
        }
      }}
      onSubmit={(e: any) => {
        e.preventDefault();
        onSubmit?.();
      }}
    >
      <SearchIcon />
      <Input
        ref={inputRef}
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        type={'text'}
        autoComplete={'off'}
        placeholder={placeholder}
        value={value}
        onChange={({ target }) => setValue(target.value)}
      />
      {onReset && value.length > 0 && (
        <Clickable
          tabIndex={tabIndex}
          onClick={(e) => {
            e.stopPropagation();
            onReset(e);
            inputRef.current?.focus();
          }}
        >
          <CloseIcon />
        </Clickable>
      )}
    </Wrapper>
  );
};
