export enum Region {
  WUPPERTAL = 'Wuppertal',
  DUSSELDORF = 'Düsseldorf',
  NIEDERRHEIN = 'Niederrhein',
  RUHRGEBIET = 'Ruhrgebiet',
  ZENTRALE = 'Zentrale',
}

export const DROPDOWN_ITEMS_REGIONS_CUSTOMER = [
  { value: 'Wuppertal', label: 'Wuppertal' },
  { value: 'Düsseldorf', label: 'Düsseldorf' },
  { value: 'Niederrhein', label: 'Niederrhein' },
  { value: 'Ruhrgebiet', label: 'Ruhrgebiet' },
];

export const DROPDOWN_ITEMS_REGIONS_CUSTOMER_WITH_ALL = [
  { value: 'Alle', label: 'Alle'},
  ...DROPDOWN_ITEMS_REGIONS_CUSTOMER
];

export const DROPDOWN_ITEMS_REGIONS_EMPLOYEE = [...DROPDOWN_ITEMS_REGIONS_CUSTOMER, { value: 'Zentrale', label: 'Zentrale' }];
