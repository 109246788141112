import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Body3, Body4 } from '../../../styles/FontStyles';
import { Link } from '../atoms/Link';

export const ButtonPrimary = styled.button.attrs(({ href, target, type = 'button' }: any) =>
  !!href ? { as: Link, target } : ({ type } as any),
)<{ variant?: 'default' | 'small'; href?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border-radius: 0.5rem;
  border: none;
  appearance: none;

  height: ${({ variant = 'default' }) => (variant === 'small' ? '1.75rem' : '3.125rem')};
  min-width: ${({ variant = 'default' }) => (variant === 'small' ? 'none' : '15rem')};
  width: auto;
  max-width: fit-content;

  padding: ${({ variant = 'default' }) => (variant === 'small' ? '0 1rem' : '0 1.875rem')};

  background-color: ${Colors.primary};
  color: ${Colors.black900};

  text-decoration: none;

  ${Body3};

  ${({ variant = 'default' }) =>
    variant === 'small' &&
    css`
      ${Body4};
    `};

  :disabled {
    background-color: ${Colors.grey500};
    color: ${Colors.grey600};
    pointer-events: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${Colors.grey500};
      color: ${Colors.grey600};
      pointer-events: none;
    `};

  &:hover {
    opacity: 0.85;
  }

  &:focus-visible {
    // TODO: add focus Status to Button
  }

  &:active {
    // TODO: add active Status to Button
  }
`;

export const ButtonPrimarySmall = styled(ButtonPrimary)`
  height: 3.125rem;
  width: 3.125rem;
  max-width: none;
  padding: 0;

  > svg {
    margin: 0;
  }
`;

export const ButtonSecondary = styled(ButtonPrimary)`
  background-color: ${Colors.black900};
  color: ${Colors.white50};

  :disabled {
    background-color: ${Colors.grey500};
    color: ${Colors.grey600};
  }
`;

export const ButtonTertiary = styled(ButtonPrimary)`
  background-color: transparent;
  border: 1px solid ${Colors.black900};

  :disabled {
    background-color: transparent;
    border-color: ${Colors.grey600};
  }
`;

export const WarningButton = styled(ButtonPrimary)`
  color: ${Colors.textInverted};
  background-color: ${Colors.signalRed900};

  @media (hover: hover) {
    :hover {
      background-color: ${Colors.signalRed800};
    }
  }
`;

export const ChooseButton = styled(ButtonTertiary)<{ isActive?: boolean }>`
  max-width: none;

  background-color: ${({ isActive }) => (isActive ? Colors.primary : '')};
`;

// TODO: need to removed from basic Templates
export const Button = styled(ButtonPrimary)`
  //
`;
