import styled from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_SM } from '../../../styles/Breakpoints';

const Wrapper = styled.div`
  background-color: #fbfbfb;
  padding-block-start: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  ${BREAKPOINT_SM} {
    flex-wrap: wrap;
  }

  ${BREAKPOINT_LG} {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
    place-items: stretch;
  }
`;

export default function InfinityScrollingWrapper({ children }: { children: React.ReactNode }) {
  return <Wrapper>{children}</Wrapper>;
}
