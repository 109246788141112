// General
import { FC } from 'react';

// Types
import { IEmployee } from '../../../../api/models/Employee';

// Components
import { CardHeadline, CardRow, CardRowContent, CardRowHeadline, Card, HighlightLink } from '../../atoms/Card';

// Utils
import { getTelLink } from '../../../../utils/phoneUtils';
import { formatName } from '../../../../utils/nameUtils';

const InfoProfile: FC<{ employee: IEmployee }> = (props) => {
  const { firstName, lastName, emailAddress, phoneNumbers = [], address } = props.employee;

  return (
    <Card>
      <CardHeadline>Persönliche Daten</CardHeadline>

      <CardRow>
        <CardRowHeadline>Name</CardRowHeadline>
        <CardRowContent>{formatName({ firstName, lastName }) || '-'}</CardRowContent>
      </CardRow>

      {emailAddress && (
        <CardRow>
          <CardRowHeadline>E-Mail-Adresse</CardRowHeadline>
          <CardRowContent>
            <HighlightLink href={emailAddress ? `mailto:${emailAddress}` : ''}>
              <b>{emailAddress || '-'}</b>
            </HighlightLink>
          </CardRowContent>
        </CardRow>
      )}

      {phoneNumbers.map((phoneNumber, index) => (
        <CardRow key={index}>
          <CardRowHeadline>
            Telefonnummer{' '}
            {phoneNumbers.length > 1 && (
              <>
                <br />({phoneNumber.type})
              </>
            )}
          </CardRowHeadline>
          <CardRowContent>
            <HighlightLink href={getTelLink(phoneNumber.phoneNumber) || ''}>
              <b>{phoneNumber.formattedPhoneNumber || '-'}</b>
            </HighlightLink>
          </CardRowContent>
        </CardRow>
      ))}

      {address && (
        <>
          <CardRow>
            <CardRowHeadline>Anschrift</CardRowHeadline>
            <CardRowContent>{address.addressLine1}</CardRowContent>
          </CardRow>

          {address.addressLine2 && (
            <CardRow>
              <CardRowHeadline>Adresszusatz</CardRowHeadline>
              <CardRowContent>{address.addressLine2}</CardRowContent>
            </CardRow>
          )}

          <CardRow>
            <CardRowHeadline>Postleitzahl</CardRowHeadline>
            <CardRowContent>{address.zipCode}</CardRowContent>
          </CardRow>

          <CardRow>
            <CardRowHeadline>Stadt</CardRowHeadline>
            <CardRowContent>{address.city}</CardRowContent>
          </CardRow>
        </>
      )}
    </Card>
  );
};

export default InfoProfile;
