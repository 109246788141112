import styled from 'styled-components';
import React, { FC, MouseEventHandler } from 'react';
import { Colors } from '../../../../styles/Colors';
import { ActiveLabel } from '../../labels/ActiveLabel';
import { Bus } from '../../../icons/Bus';
import { IVehicle } from '../../../../api/models/Vehicle';
import { ContextOptions } from '../ContextOptions';
import { Trash } from '../../../icons/Trash';
import { Edit } from '../../../icons/Edit';
import { Body1Bold } from '../../../../styles/FontStyles';
import { useShowDeleteVehicleDialog } from '../../../../hooks/vehicles/useShowDeleteVehicleDialog';
import { Employee } from '../../../icons/Employee';
import { Wheelchair } from '../../../icons/Wheelchair';
import { BREAKPOINT_LG } from '../../../../styles/Breakpoints';
import { formatName } from '../../../../utils/nameUtils';
import { useHasPermission } from '../../../../state/UserMeState';
import { useNavigate } from '../../../../hooks/useNavigate';

const TileWrapper = styled.div.attrs(({ onClick = null }) => (onClick ? { as: 'button', type: 'button' } : {}))<{
  onClick?: MouseEventHandler;
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding: 1rem;

  transition: box-shadow 150ms ease-out, border-radius 150ms ease-out;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }

  ${BREAKPOINT_LG} {
    width: auto;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const Title = styled.div`
  ${Body1Bold};
`;

const ContextMenuWrapper = styled.div`
  margin-left: auto;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.25rem 2rem;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const ContextMenu = React.memo((props: { id: string; onUpdate?: () => void }) => {
  const { id, onUpdate } = props;
  const navigate = useNavigate();
  const showDeleteVehicleDialog = useShowDeleteVehicleDialog(id);
  const isAdmin = useHasPermission('admin');

  return (
    <ContextMenuWrapper>
      <ContextOptions
        as="div"
        items={
          [
            isAdmin && {
              content: (
                <>
                  <Trash /> Entfernen
                </>
              ),
              onClick: () => showDeleteVehicleDialog(onUpdate),
            },
            {
              content: (
                <>
                  <Edit /> Bearbeiten
                </>
              ),
              onClick: () => navigate(`/vehicles/${id}/edit`),
            },
          ].filter(Boolean) as []
        }
      />
    </ContextMenuWrapper>
  );
});

export const VehicleTile: FC<{ vehicle: IVehicle; onUpdate?: () => void }> = (props) => {
  const { id, licensePlate, brandName, modelName, color, hasRoomForWheelchairs, drivers = [] } = props.vehicle;

  const navigate = useNavigate();

  return (
    <TileWrapper
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          navigate(`/vehicles/${id}`);
        }
      }}
    >
      <TitleWrapper>
        <Title>{licensePlate}</Title>
        <ActiveLabel active state={'Einsatzbereit'} />
        <ContextMenu id={id} onUpdate={props.onUpdate} />
      </TitleWrapper>
      <TagsWrapper>
        <Tag>
          <Bus /> {[brandName, modelName, color].filter(Boolean).join(' / ')}
        </Tag>
        {drivers.length > 0 && (
          <Tag>
            <Employee /> {formatName(drivers[0])}
          </Tag>
        )}
        {hasRoomForWheelchairs && (
          <Tag>
            <Wheelchair /> für Rollstuhltransport
          </Tag>
        )}
      </TagsWrapper>
    </TileWrapper>
  );
};
