import { useAuthHeader } from '../../state/AuthState';
import axios from 'axios';
import { IScheduledTour } from '../models/ScheduledTour';
import { IPassenger } from '../models/Passenger';
import { useErrorHandler } from './ErrorHandler';

interface IGetScheduleProps {
  offset?: number;
  limit?: number;

  startDate?: string;
  endDate?: string;

  // filters
  driverId?: string | string[];
  customerId?: string;
  passengerId?: string;

  // options
  perDay?: boolean;
}

interface IUpdateScheduleProps {
  timestamp?: string;
  schedule: Partial<IScheduledTour>;
}

interface IRemoveScheduleProps {
  timestamp?: string;
  scheduleToDelete: string;
}

interface ISplitScheduleProps {
  splitTourId: string;
  schedulesToUpdate: Partial<IScheduledTour>[];
  splitTourStartDate: string;
  splitTourEndDate?: string;
  comment?: string | null;
}

interface ICreateScheduledTourExceptionProps {
  scheduledTourId: string;
  replacementScheduledTourId?: string;
  startDate: string;
  endDate?: string;
  reason: string;
  includeTourOnInvoice: boolean;
  comment?: string | null;
}

interface IGetConflictsProps {
  startDate: string;
  endDate: string;
  customerId?: string;
}

interface IGetTourplanPDFProps {
  id: string;
  scheduleName: string;
  startDate: string;
  endDate: string;
  timestamp?: string;
}

export interface IBulkCancellationProps {
  startDate: string;
  endDate: string;
  reason: string;
  bulkCancellationReason: string;
  region?: string;
  customerId?: number;
  customerCategoryId?: string;
  includeTourOnInvoice: boolean;
  comment: string;
}

export const useSchedulesAPI = () => {
  const authHeader = useAuthHeader();
  const errorHandler = useErrorHandler();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const getSchedule = (props: IGetScheduleProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/scheduled-tours/list`, props, { ...authHeader });
    });
  };

  const getScheduleById = (id: string) => {
    return checkAuth().then(() => {
      return axios.get(`/api/scheduled-tours/${id}`, { ...authHeader }).then((res) => {
        return {
          ...res,
          data: {
            ...res.data,
            passengers: ((res.data.passengers || []) as IPassenger[]).sort(
              (a, b) => (a.ScheduledTourPassenger?.position || 0) - (b.ScheduledTourPassenger?.position || 0),
            ),
          },
        };
      });
    });
  };

  const getTourplanPDF = (props: IGetTourplanPDFProps) => {
    return checkAuth().then(() => {
      const { id, scheduleName, ...query } = props;
      const search = new URLSearchParams(Object.entries(query)).toString();
      return axios.get(`/api/scheduled-tours/${id}/download?${search}`, { ...authHeader, responseType: 'blob' }).then((res) => {
        const href = URL.createObjectURL(res.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute(
          'download',
          props.timestamp
            ? `Fahrplan ${scheduleName} gültig ab ${new Date(props.timestamp).toLocaleDateString(undefined, { dateStyle: 'short' })}.pdf`
            : `Fahrplan ${scheduleName} ${[props.startDate, props.endDate]
                .map((date) => new Date(date).toLocaleDateString(undefined, { dateStyle: 'short' }))
                .join(' - ')}.pdf`,
        );

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(href);
      });
    });
  };

  const updateSchedule = (props: IUpdateScheduleProps) => {
    return checkAuth()
      .then(() => {
        return axios.post(`/api/scheduled-tours/update`, props, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const removeSchedule = (props: IRemoveScheduleProps) => {
    return checkAuth()
      .then(() => {
        return axios.post(`/api/scheduled-tours/remove`, props, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const splitSchedule = (props: ISplitScheduleProps) => {
    return checkAuth()
      .then(() => {
        return axios.post(
          `/api/scheduled-tours/split`,
          {
            ...props,
            timestamp: props.splitTourStartDate, // add start date as timestamp for middleware
          },
          { ...authHeader },
        );
      })
      .catch(errorHandler);
  };

  const createScheduledTourException = (props: ICreateScheduledTourExceptionProps) => {
    return checkAuth()
      .then(() => {
        return axios.post(`/api/scheduled-tours/exceptions`, props, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const getConflicts = (props: IGetConflictsProps) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams(
        [['startDate', props.startDate], ['endDate', props.endDate], props.customerId && ['customerId', props.customerId]].filter(
          Boolean,
        ) as string[][],
      );
      return axios.get(`/api/scheduled-tours/conflicts?${qs.toString()}`, { ...authHeader });
    });
  };

  const bulkCancellation = async (props: IBulkCancellationProps) => {
    await checkAuth();
    return await axios.post(`/api/scheduled-tours/exceptions/bulk_cancellation`, props, { ...authHeader });
  };

  return {
    getSchedule,
    getScheduleById,
    getTourplanPDF,
    updateSchedule,
    removeSchedule,
    splitSchedule,
    createScheduledTourException,
    getConflicts,
    bulkCancellation,
  };
};
