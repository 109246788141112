import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCalendarExceptionAPI } from '../../../api/controllers/CalendarExceptionAPI';
import { ICalendarException } from '../../../api/models/CalendarException';
import { CalendarExceptionListRow } from '../../common/elements/calendar-exceptions/CalendarExceptionListRow';
import { List, ListHead } from '../../common/atoms/List';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
`;

const StyledListHead = styled(ListHead)`
  top: 10rem;
  margin-block-start: -2.5rem;
  padding-block-start: 2.5rem;
`;

export const CalendarExceptionList = () => {
  const [calendarExceptions, setCalendarExceptions] = useState<ICalendarException[]>([]);
  const [loading, setLoading] = useState(false);

  const calendarExceptionAPI = useCalendarExceptionAPI();

  const fetchCalendarExceptions = useCallback(() => {
    setLoading(true);
    calendarExceptionAPI
      .getCalendarExceptions({})
      .then((res) => {
        setCalendarExceptions(res.data.data);
      })
      .catch(() => setCalendarExceptions([]))
      .finally(() => setLoading(false));
  }, [setLoading, setCalendarExceptions, calendarExceptionAPI]);

  useEffect(() => {
    fetchCalendarExceptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <List>
        <StyledListHead>
          <div>ID</div>
          <div>Name</div>
          <div>Beginn</div>
          <div>Ende</div>
          <div>Kundenart</div>
          <div>Aktionen</div>
        </StyledListHead>
        {calendarExceptions.length > 0 &&
          calendarExceptions.map((calendarException) => (
            <CalendarExceptionListRow key={calendarException.id} calendarException={calendarException} onRemove={fetchCalendarExceptions} />
          ))}
      </List>
      {!loading && calendarExceptions.length === 0 && <p>Keine Kalender-Ausnahmen gefunden</p>}
    </Wrapper>
  );
};
