import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { SearchableDropdown } from '../../inputs/SearchableDropdown';
import { ButtonPrimary, ButtonTertiary } from '../../inputs/Button';
import { Link } from '../../atoms/Link';
import { Dialog } from '../Dialog';
import { Colors } from '../../../../styles/Colors';
import { useSearchAPI } from '../../../../api/controllers/SearchAPI';
import { ICustomer } from '../../../../api/models/Customer';
import { Facilities } from '../../../icons/Facilities';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const InfoText = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  flex-wrap: wrap;
  font-weight: 700;

  a {
    color: ${Colors.secondary};
  }
`;

const StyledDialog = styled(Dialog)`
  ${BREAKPOINT_MD} {
    overflow: visible;
  }
`;

const StyledSearchableDropdown = styled(SearchableDropdown)`
  min-inline-size: 16rem;
`;

interface NewTourDialogProps {
  onSuccess?: () => void;
  onClosed?: () => void;
}

export const NewTourDialog: FC<NewTourDialogProps> = (props) => {
  const { onSuccess, onClosed } = props;
  const searchAPI = useSearchAPI();
  const [customers, setCustomers] = useState<Partial<ICustomer>[]>([]);
  const [customersSearchQuery, setCustomersSearchQuery] = useState('');
  const form = useForm();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(true);
  }, []);

  const submitHandler = (values: any) => {
    setOpen(false);
    onSuccess?.();
    navigate(`/customers/${values.customerId}/orders/add`);
  };

  useEffect(() => {
    setCustomers([]);
    searchAPI
      .searchCustomers({ query: customersSearchQuery, page: { offset: 0, limit: 20 }})
      .then((res) => {
        setCustomers(res.data?.items || []);
      })
      .catch(() => setCustomers([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersSearchQuery]);

  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      onClosed={onClosed}
      form={form}
      onSubmit={submitHandler}
      headline="Für welche Einrichtung soll ein Auftrag angelegt werden?"
      text={
        <DialogBody>
          <StyledSearchableDropdown
            id={'customerId'}
            onSearchInput={setCustomersSearchQuery}
            items={[
              ...(customers || []).map((customer: any) => ({
                value: customer.id,
                label: customer.displayName,
                view: (
                  <>
                    <Facilities />
                    <div>
                      <span>({customer.internalId})</span> {customer.displayName}
                    </div>
                  </>
                ),
              })),
            ]}
            placeholder={'Einrichtung auswählen'}
            label={'Einrichtung'}
            $plain
          />

          <InfoText>
            <span>Befindet sich die Einrichtung noch nicht in der Datenbank?</span>
            <Link href={'/customers/add'} onClick={() => setOpen(false)}>
              neue Einrichtung anlegen
            </Link>
          </InfoText>
        </DialogBody>
      }
      buttons={
        <>
          <ButtonTertiary type={'button'} onClick={() => setOpen(false)}>
            Abbrechen
          </ButtonTertiary>
          <ButtonPrimary type={'submit'}>Weiter</ButtonPrimary>
        </>
      }
    />
  );
};
