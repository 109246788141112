import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { ContentPageLayout } from '../../common/atoms/Layout';
import { CustomerOrganizationForm } from '../../content/customer_organizations/CustomerOrganizationForm';

export const CustomerOrganizationAdd: React.FC = () => {
  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      <CustomerOrganizationForm />
    </ContentPageLayout>
  );
};
