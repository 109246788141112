export const Excel = (props: any) => (
  <svg width="254" height="252" viewBox="0 0 254 252" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M158.633 15.75H74.3108C71.5161 15.75 68.8359 16.856 66.8598 18.8246C64.8836 20.7932 63.7734 23.4633 63.7734 26.2474V70.875L158.633 126L206.063 145.939L253.493 126V70.875L158.633 15.75Z"
      fill="#21A366"
    />
    <path d="M63.7734 70.875H158.633V126H63.7734V70.875Z" fill="#107C41" />
    <path
      d="M242.955 15.75H158.633V70.875H253.493V26.2474C253.493 23.4633 252.383 20.7932 250.406 18.8246C248.43 16.856 245.75 15.75 242.955 15.75Z"
      fill="#33C481"
    />
    <path
      d="M158.633 126H63.7734V225.753C63.7734 228.537 64.8836 231.207 66.8598 233.175C68.8359 235.144 71.5161 236.25 74.3108 236.25H242.956C245.751 236.25 248.431 235.144 250.407 233.175C252.383 231.207 253.493 228.537 253.493 225.753V181.125L158.633 126Z"
      fill="#185C37"
    />
    <path d="M158.633 126H253.493V181.125H158.633V126Z" fill="#107C41" />
    <path
      opacity="0.1"
      d="M132.286 55.125H63.7734V204.75H132.286C135.078 204.742 137.754 203.633 139.728 201.666C141.702 199.699 142.815 197.034 142.823 194.253V65.6224C142.815 62.8409 141.702 60.1756 139.728 58.2088C137.754 56.2419 135.078 55.1333 132.286 55.125Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M124.381 63H63.7734V212.625H124.381C127.173 212.617 129.849 211.508 131.823 209.541C133.797 207.574 134.91 204.909 134.918 202.128V73.4974C134.91 70.7159 133.797 68.0506 131.823 66.0838C129.849 64.1169 127.173 63.0083 124.381 63Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M124.381 63H63.7734V196.875H124.381C127.173 196.867 129.849 195.758 131.823 193.791C133.797 191.824 134.91 189.159 134.918 186.378V73.4974C134.91 70.7159 133.797 68.0506 131.823 66.0838C129.849 64.1169 127.173 63.0083 124.381 63Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M116.476 63H63.7734V196.875H116.476C119.268 196.867 121.944 195.758 123.918 193.791C125.892 191.824 127.005 189.159 127.013 186.378V73.4974C127.005 70.7159 125.892 68.0506 123.918 66.0838C121.944 64.1169 119.268 63.0083 116.476 63Z"
      fill="black"
    />
    <path
      d="M11.0706 63H116.476C119.271 63 121.951 64.106 123.927 66.0746C125.903 68.0432 127.013 70.7133 127.013 73.4974V178.503C127.013 181.287 125.903 183.957 123.927 185.925C121.951 187.894 119.271 189 116.476 189H11.0706C8.27588 189 5.59567 187.894 3.61953 185.925C1.64339 183.957 0.533203 181.287 0.533203 178.503L0.533203 73.4974C0.533203 70.7133 1.64339 68.0432 3.61953 66.0746C5.59567 64.106 8.27588 63 11.0706 63Z"
      fill="#107C41"
    />
    <path
      d="M28.4619 165.375L54.0425 125.89L30.6121 86.625H49.4655L62.2558 111.723C63.431 114.101 64.2399 115.875 64.6826 117.046H64.8486C65.6918 115.146 66.5746 113.298 67.4968 111.502L81.1645 86.625H98.4686L74.4374 125.669L99.0773 165.375H80.6586L65.8842 137.812C65.1881 136.638 64.5982 135.405 64.1214 134.127H63.9C63.4689 135.377 62.896 136.574 62.1926 137.694L46.9912 165.375H28.4619Z"
      fill="white"
    />
  </svg>
);
