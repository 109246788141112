import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { getCurrentDayDate, getDayOfWeekDate, getISODate } from '../utils/dateUtils';

const { persistAtom } = recoilPersist({
  key: 'tour-list-setting-state',
  storage: localStorage,
});

export interface Option {
  key: string;
  title: string;
}

export interface ITourListFilter {
  key: string;
  title: string;
  type: 'multi' | 'boolean';
  options?: Option[];
  dependency?: string;
}

export const TOUR_FILTERS: ITourListFilter[] = [
  {
    key: 'regions',
    title: 'Region',
    type: 'multi',
    options: [
      {
        key: 'Düsseldorf',
        title: 'Düsseldorf',
      },
      {
        key: 'Niederrhein',
        title: 'Niederrhein',
      },
      {
        key: 'Wuppertal',
        title: 'Wuppertal',
      },
      {
        key: 'Ruhrgebiet',
        title: 'Ruhrgebiet',
      },
    ],
  },
  {
    key: 'customerIds',
    title: 'Einrichtung',
    type: 'multi',
    dependency: 'regions',
    options: [],
  },
  {
    key: 'directions',
    title: 'Richtung',
    type: 'multi',
    options: [
      {
        key: 'outwards',
        title: 'Hinfahrt',
      },
      {
        key: 'return',
        title: 'Rückfahrt',
      },
    ],
  },
  {
    key: 'conflictsOnly',
    title: 'Nur Konflikte zeigen',
    type: 'boolean',
  },
  {
    key: 'hideCancelled',
    title: 'Abgesagte ausblenden',
    type: 'boolean',
  },
  {
    key: 'showOnlyCancelled',
    title: 'Nur Abgesagte zeigen',
    type: 'boolean',
  },
];

export interface ITourListSettingState {
  showConflicts: { generic: boolean; duplicates: boolean };
  query: string;
  start: string;
  end: string;
  filters: {
    regions: string[];
    customerIds: number[];
    directions: string[];
    conflictsOnly: boolean;
    hideCancelled: boolean;
    showOnlyCancelled: boolean;
  };
}

export type BooleanFilterKeys = {
  [K in keyof ITourListSettingState['filters']]: ITourListSettingState['filters'][K] extends boolean ? K : never;
}[keyof ITourListSettingState['filters']];

export type ArrayFilterKeys = {
  [K in keyof ITourListSettingState['filters']]: ITourListSettingState['filters'][K] extends boolean ? never : K;
}[keyof ITourListSettingState['filters']];

export const TourListSettingState = atom<ITourListSettingState>({
  key: 'TourSettingState',
  default: {
    showConflicts: { generic: true, duplicates: true },
    query: '',
    start: getISODate(getDayOfWeekDate(1, getCurrentDayDate())),
    end: getISODate(getDayOfWeekDate(7, getCurrentDayDate())),
    filters: {
      regions: [],
      customerIds: [],
      directions: [],
      conflictsOnly: false,
      hideCancelled: false,
      showOnlyCancelled: false,
    },
  },
  effects_UNSTABLE: [persistAtom],
});
