export const MenuHide = ({ invert, ...props }: any) => (
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={invert ? { transform: 'rotate(180deg)' } : {}}
    {...props}
  >
    <path d="M12 20.6494L5 13.2368L12 5.8242" stroke="#0F0F0F" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.5 20.6494L11.5 13.2368L18.5 5.8242" stroke="#0F0F0F" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
