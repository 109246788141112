import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomerOrganizationAPI } from '../../../api/controllers/CustomerOrganizationAPI';
import { ICustomerOrganization } from '../../../api/models/CustomerOrganization';
import { ContentPageLayout } from '../../common/atoms/Layout';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { CustomerOrganizationForm } from '../../content/customer_organizations/CustomerOrganizationForm';

export const CustomerOrganizationEdit: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const customersOrganizationAPI = useCustomerOrganizationAPI();

  const [customerOrganization, setCustomerOrganization] = useState<ICustomerOrganization | undefined>(undefined);

  const [customerOrganizationLoading, setCustomerOrganizationLoading] = useState(true);

  useEffect(() => {
    if (id) {
      customersOrganizationAPI
        .getCustomerOrganizationById({ id })
        .then((res) => {
          const data = res.data;
          setCustomerOrganization(data.data);
        })
        .catch(() => navigate('/404'))
        .finally(() => setCustomerOrganizationLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {customerOrganizationLoading ? <LoadingSpinner /> : <CustomerOrganizationForm customerOrganization={customerOrganization} />}
    </ContentPageLayout>
  );
};
