import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Card } from '../../common/atoms/Card';
import { Body4 } from '../../../styles/FontStyles';
import { Wheelchair } from '../../icons/Wheelchair';
import { Walker } from '../../icons/Walker';
import { Info, InfoBold, Row } from '../../common/atoms/List';
import React from 'react';
import { IPassengerRefactored } from '../../../api/models/Passenger';
import { Track } from '../../common/elements/tours/TourListRowRefactored';
import { TrackStop } from '../../common/elements/tours/TourListRow';

export interface IPassengersForRowViewProps {
  passenger: IPassengerRefactored;
  direction: string | null;
  isTourCancelled?: boolean;
  showBefore: boolean;
  showAfter: boolean;
  index: number;
}

export default function PassengersForRowView({
  index,
  passenger,
  showBefore,
  showAfter,
  direction,
  isTourCancelled,
}: IPassengersForRowViewProps) {
  const StyledRow = styled(Row)<{ lineThrough?: boolean }>`
    align-items: center;

    em {
      color: ${Colors.signalRed900};
      font-style: normal;
    }

    i {
      color: ${Colors.signalGreen900};
      font-style: normal;
    }

    i {
      color: ${Colors.signalGreen900};
      font-style: normal;
    }

    mark {
      color: ${Colors.signalOrange};
      font-style: normal;
      background-color: transparent;
    }
  `;

  const TrackLayout = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;

    &:first-child {
      margin-block-start: 0.5rem;
    }

    &:last-child > * {
      border-bottom-color: transparent;
    }
  `;

  const TrackContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    padding-block: 1rem;
    border-bottom: 1px solid ${Colors.grey500};
  `;

  const PassengerCommentInfo = styled(Card).attrs({ as: 'div' })`
    grid-column: -2 / -1;
    margin-inline-start: -6.25rem;
    justify-self: flex-end;

    min-inline-size: 10rem;
    max-inline-size: 16rem;

    padding-inline: 1rem;
    padding-block: 0.5rem;

    ${Body4};
    text-align: start;

    box-shadow: 0 0 24px rgba(0, 0, 0, 0.04);
  `;

  const TextBlue = styled.span`
    color: ${Colors.secondary};
  `;

  const PassengerRow = styled(StyledRow)`
    grid-template-columns: 1.25fr 1fr 1fr;

    ${({ lineThrough = false }) =>
      lineThrough &&
      css`
        & > * {
          text-decoration: line-through;
          text-decoration-color: ${Colors.signalRed900};
        }
      `};
  `;

  return (
    <TrackLayout key={passenger.id}>
      <Track showBefore={showBefore} showAfter={showAfter}>
        <TrackStop />
      </Track>
      <TrackContent>
        <PassengerRow lineThrough={isTourCancelled}>
          <Info>
            <b>{index + 1}</b>
            {passenger.pickupTime && <TextBlue>{passenger.pickupTime}</TextBlue>}
            {passenger.hasWheelchair ? <Wheelchair /> : <Walker />}
            <div>{passenger.fullNameWithComma}</div>
          </Info>
          <InfoBold>{passenger.plaintextAddress}</InfoBold>
          {passenger.comment && <PassengerCommentInfo>{passenger.comment}</PassengerCommentInfo>}
        </PassengerRow>
      </TrackContent>
    </TrackLayout>
  );
}
