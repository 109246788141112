import { useMemo } from 'react';
import { IConflict } from '../../../api/models/Conflict';
import { IScheduledTourWithMetaData } from '../../../api/models/ScheduledTour';
import { EmptyTourFrame } from '../../common/atoms/EmptyTourFrame';
import VirtualizedDiv from '../../common/atoms/VirtualizedDiv';
import { TourTileRefactored } from '../../common/elements/tours/TourTileRefactored';
import { useRecoilState } from 'recoil';
import { TourListSettingState } from '../../../state/TourListSettingState';

interface ScheduleColumnItemProps {
  scheduleDate: Date;

  scheduledTour: IScheduledTourWithMetaData;
  conflictsForDate: IConflict[];
}

export default function ScheduleColumnItem({ scheduleDate, scheduledTour, conflictsForDate }: ScheduleColumnItemProps) {
  const conflictsForTour = useMemo(
    () => conflictsForDate?.filter(({ tour }) => tour.id === scheduledTour.tour.id || tour.id === scheduledTour.originalTourId.toString()),
    [scheduledTour, conflictsForDate],
  );

  const [
    {
      filters: { conflictsOnly },
    },
  ] = useRecoilState(TourListSettingState);

  if (conflictsOnly && conflictsForTour.length === 0) {
    return null;
  }

  return (
    <VirtualizedDiv
      render={(visible) => {
        return (
          <>
            {!visible && <EmptyTourFrame size={'12.5rem'} />}
            {visible && <TourTileRefactored date={scheduleDate} schedule={scheduledTour} conflictsForTour={conflictsForTour} />}
          </>
        );
      }}
    />
  );
}
