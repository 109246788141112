// General
import { FC } from 'react';
import styled from 'styled-components';

// Components
import { Card, SectionHeadline } from '../../atoms/Card';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

interface VehicleCommentsProps {
  comment: string;
}

export const VehicleComments: FC<VehicleCommentsProps> = (props) => {
  const { comment } = props;

  return (
    <Wrapper>
      <SectionHeadline>Anmerkungen</SectionHeadline>

      <Card>{comment}</Card>
    </Wrapper>
  );
};
