import styled, { css } from 'styled-components';
import React, { FC, MouseEventHandler, useMemo, useState } from 'react';
import { Colors } from '../../../../styles/Colors';
import { Body4, Headline3 } from '../../../../styles/FontStyles';
import { IScheduledTour } from '../../../../api/models/ScheduledTour';
import { TourLabel } from '../../labels/TourLabel';
import { Close } from '../../../icons/Close';
import { getCurrentDayDate, getISODate } from '../../../../utils/dateUtils';
import { Checkmark } from '../../../icons/Checkmark';
import { formatAddress } from '../../../../utils/addressUtils';
import { Collapsible } from '../../atoms/Collapsible';
import { Clock } from '../../../icons/Clock';
import { IPassenger } from '../../../../api/models/Passenger';
import { Wheelchair } from '../../../icons/Wheelchair';
import { Walker } from '../../../icons/Walker';
import { Location } from '../../../icons/Location';
import { Clickable } from '../../atoms/Clickable';
import { ChevronDown } from '../../../icons/ChevronDown';
import PassengersTrack from './PassengersTrack';
import { IConflict } from '../../../../api/models/Conflict';
import { Link } from '../../atoms/Link';
import { Facilities } from '../../../icons/Facilities';
import { TourplanPassengerView } from '../../../content/customers/tourplan/tiles/TourplanPassengerView';
import SelectedDriverView from './molecules/SelectedDriverView';
import { useNavigate } from '../../../../hooks/useNavigate';
import { getExceptionsForDay, getLatestException } from '../../../../utils/exceptionUtils';
import { DIRECTION_LABEL } from '../../../../utils/directionLabelUtil';
import ConflictsForTourTooltips from './ConflictsForTourTooltips';

const TileWrapper = styled.div.attrs(({ onClick = null }) => (onClick ? { as: 'button', type: 'button' } : {}))<{
  onClick?: MouseEventHandler;
  cancelled?: boolean;
  conflict?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding-inline: 1rem;
  padding-block: 0.625rem;

  transition: box-shadow 150ms ease-out, border-radius 150ms ease-out;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }

  ${({ cancelled = false }) =>
    cancelled &&
    css`
      opacity: 0.5;
    `};

  ${({ conflict = false }) =>
    conflict &&
    css`
      border: 1px solid ${Colors.signalRed900};
    `};
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  ${Body4};
`;

const HeaderRowContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Title = styled.h3`
  ${Headline3};
  margin-block: 0 -0.25rem;
  text-align: start;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
    margin-block: -1rem;
    opacity: 0;
    margin-inline-end: -1.5rem;
    transition: opacity 150ms ease-out, margin-inline-end 150ms ease-out;
  }

  &:hover {
    svg {
      opacity: 1;
      margin-inline-end: 0;
    }
  }
`;

const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const IconWrapper = styled(Flexbox)`
  gap: 0;
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  margin-block-end: -0.5rem;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const CancelledIcon = styled(Close)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
`;

const CollapseButton = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  margin: -0.5rem;
  padding: 0.5rem;
`;

const SeparatorRow = styled.div`
  block-size: 1px;
  margin-block: 1rem;
  background-color: ${Colors.grey500};
`;

const Details = styled(Collapsible)`
  margin-inline: -1rem;
  padding-inline: 1rem;
  margin-block: -0.625rem;
  padding-block: 0.625rem;
`;

interface ITourTileProps {
  date?: Date;
  day?: String;
  schedule: IScheduledTour;
  conflictsForTour?: IConflict[];
}
export const TourTile: FC<ITourTileProps> = (props) => {
  const { date, schedule, conflictsForTour = [] } = props;

  const {
    id,
    name,
    direction,
    customer,
    driver,
    companion,
    departureDate,
    arrivalDate,
    passengers = [],
    exceptions = [],
    exceptionFor,
    isException = false,
  } = schedule;

  const navigate = useNavigate(true);

  const [detailsOpen, setDetailsOpen] = useState(false);

  const [passengersWheelchair, passengersWalking] = useMemo(() => {
    return passengers.reduce(
      (res, item) => {
        res[item.hasWheelchair ? 0 : 1].push(item);
        return res;
      },
      [[], []] as Partial<IPassenger>[][],
    );
  }, [passengers]);

  const timeRange = useMemo(() => {
    const weekday = (date || new Date()).getDay();

    return [
      (direction === 'return' ? departureDate : passengers[0]?.ScheduledTourPassenger?.pickupDate)?.[weekday] || null,
      (direction === 'return' ? passengers[passengers.length - 1]?.ScheduledTourPassenger?.pickupDate : arrivalDate)?.[weekday] || null,
    ] as const;
  }, [date, arrivalDate, departureDate, direction, passengers]);

  const timeAtCustomer = date && (direction === 'return' ? departureDate : arrivalDate)?.[date.getDay()];

  const activeException = useMemo(() => {
    const activeException = getLatestException(getExceptionsForDay(exceptions, getCurrentDayDate(date).toISOString()));

    if (activeException?.replacementScheduledTourId === id) {
      return null; // is exception tour
    }
    return activeException;
  }, [exceptions, date, id]);

  const isTourCancelled = useMemo(() => {
    return activeException?.reason === 'cancelled';
  }, [activeException]);

  const isTourSplit = useMemo(() => {
    return activeException?.reason === 'tour_split';
  }, [activeException]);

  return (
    <TileWrapper
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          const tourId = (isException && exceptionFor?.scheduledTourId) || id;
          navigate(`/tours/${tourId}?date=${getISODate(date!)}`); // open details for original tour
        }
      }}
      cancelled={isTourCancelled || isTourSplit}
      conflict={conflictsForTour.length > 0}
    >
      <HeaderRow>
        <HeaderRowContentWrapper>
          <TourLabel>
            {
              // finished
              date && !isTourCancelled && new Date(date) < getCurrentDayDate() && <Checkmark />
            }
            {
              // cancelled
              isTourCancelled && <CancelledIcon />
            }
            {name?.startsWith('F ') ? (
              <>
                <b>{name.substr(0, 1)}</b>
                {name.substr(1)}
              </>
            ) : (
              name
            )}
          </TourLabel>
          <ConflictsForTourTooltips
            conflictsForTour={conflictsForTour}
            date={date}
            isException={isException}
            isTourCancelled={isTourCancelled}
            activeException={activeException}
          />
          <span>{DIRECTION_LABEL[direction || 'na']}</span>
        </HeaderRowContentWrapper>
        <HeaderRowContentWrapper>
          {passengersWheelchair.length > 0 && (
            <IconWrapper>
              {passengersWheelchair.length} <Wheelchair />
            </IconWrapper>
          )}
          {passengersWalking.length > 0 && (
            <IconWrapper>
              {passengersWalking.length} <Walker />
            </IconWrapper>
          )}
        </HeaderRowContentWrapper>
      </HeaderRow>

      <Link href={`/customers/${customer?.id}`} target={'_blank'} onClick={(e) => e.stopPropagation()} $displayContents={true}>
        <Title>
          <span>{customer?.displayName}</span>
          <Facilities />
        </Title>
      </Link>
      <Flexbox>
        <Location /> {formatAddress(customer?.address)}
      </Flexbox>

      <HeaderRow>
        <Link
          href={driver?.id ? `/employees/${driver.id}` : ''}
          target={'_blank'}
          onClick={(e) => e.stopPropagation()}
          $displayContents={true}
        >
          <HeaderRowContentWrapper>
            <SelectedDriverView
              title={'Fahrer'}
              driver={driver}
              conflict={
                date &&
                driver &&
                conflictsForTour.find(({ reason }) =>
                  ['driver_duplicated', 'driver_unavailable', 'driver_not_set', 'unset_pickupdate'].includes(reason),
                )
              }
            />
          </HeaderRowContentWrapper>
        </Link>

        {companion && (
          <Link
            href={companion?.id ? `/employees/${companion.id}` : ''}
            target={'_blank'}
            onClick={(e) => e.stopPropagation()}
            $displayContents={true}
          >
            <HeaderRowContentWrapper>
              <SelectedDriverView
                title={'Begleitung'}
                driver={companion}
                conflict={
                  date &&
                  driver &&
                  conflictsForTour.find(({ reason }) => ['companion_duplicated', 'companion_unavailable'].includes(reason))
                }
              />
            </HeaderRowContentWrapper>
          </Link>
        )}
      </HeaderRow>

      <FooterRow>
        <Flexbox>
          <Clock />{' '}
          {timeRange
            .map((date) => date && new Date(date).toLocaleTimeString(undefined, { timeStyle: 'short' }))
            .filter(Boolean)
            .join(' - ')}
        </Flexbox>
        <CollapseButton
          as="div"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDetailsOpen(!detailsOpen);
          }}
        >
          <ChevronDown invert={detailsOpen} />
        </CollapseButton>
      </FooterRow>

      <Details open={detailsOpen}>
        <SeparatorRow />
        <PassengersTrack
          direction={direction}
          customer={customer}
          passengers={passengers || []}
          customerTime={timeAtCustomer && new Date(timeAtCustomer).toLocaleTimeString(undefined, { timeStyle: 'short' })}
          renderPassenger={(passenger) => (
            <TourplanPassengerView
              passengerClickable
              passenger={passenger}
              showPickupTimes={!!date}
              selectedDayOfWeek={date?.toISOString()}
            />
          )}
        />
      </Details>
    </TileWrapper>
  );
};
