import { useQuery } from '@tanstack/react-query';
import { usePassengersAPI } from '../api/controllers/PassengersAPI';
import { IPickedPassenger } from '../api/models/Passenger';

export const usePassengers = ({ customerId, organizationId, search }: { customerId?: string; organizationId?: string; search?: string }) => {
  const passengersApi = usePassengersAPI();

  return useQuery({
    queryKey: ['passengers', customerId],
    staleTime: 24 * 60 * 60 * 1000, // 1 day
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: async () => {
      const { data } = await passengersApi.getPassengersForSelect({ customerId, organizationId, search }).then((res) => {
        return res;
      });

      return data as IPickedPassenger[];
    },
  });
};
