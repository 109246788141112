import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCalendarExceptionAPI } from '../../../api/controllers/CalendarExceptionAPI';
import { useCustomerCategoryAPI } from '../../../api/controllers/CustomerCategoryAPI';
import { ICalendarException, emptyCalendarException } from '../../../api/models/CalendarException';
import { ICustomerCategory } from '../../../api/models/CustomerCategory';
import { ContentPageLayout } from '../../common/atoms/Layout';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { CalendarExceptionForm } from '../../content/calendar-exceptions/CalendarExceptionForm';

export const CalendarExceptionEdit: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const calendarExceptionAPI = useCalendarExceptionAPI();
  const [calendarException, setCalendarException] = useState<ICalendarException>(emptyCalendarException);
  const [loading, setLoading] = useState(false);

  const [customerCategories, setCustomerCategories] = useState<ICustomerCategory[]>([]);

  const customerCategoryAPI = useCustomerCategoryAPI();

  useEffect(() => {
    if (id) {
      setLoading(true);
      calendarExceptionAPI
        .getCalendarExceptionById({ id })
        .then((res) => {
          setCalendarException(res.data);
        })
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }

    customerCategoryAPI
      .getCustomerCategories({})
      .then((res) => {
        setCustomerCategories(res.data.data);
      })
      .catch(() => {})
      .finally(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CalendarExceptionForm
          calendarException={calendarException}
          customerCategories={customerCategories || []}
        />
      )}
    </ContentPageLayout>
  );
};
