import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { UserFiltersState } from '../../../state/UserFiltersState';

export const FILTERS = (pageType: TPageType) =>
  [
    {
      key: 'regions',
      title: 'Region',
      type: 'multi',
      options: [
        {
          key: 'Düsseldorf',
          title: 'Düsseldorf',
        },
        {
          key: 'Niederrhein',
          title: 'Niederrhein',
        },
        {
          key: 'Wuppertal',
          title: 'Wuppertal',
        },
        {
          key: 'Ruhrgebiet',
          title: 'Ruhrgebiet',
        },
        {
          key: 'Zentrale',
          title: 'Zentrale',
          hideFilter: !['employees'].includes(pageType),
        },
      ],
      pageType: ['tours', 'employees', 'passengers', 'customers'] as TPageType[],
    },
    {
      key: 'customerIds',
      title: 'Einrichtung',
      type: 'multi',
      dependency: 'regions',
      options: [],
      pageType: ['tours', 'passengers'] as TPageType[],
    },
    {
      key: 'directions',
      title: 'Richtung',
      type: 'multi',
      options: [
        {
          key: 'outwards',
          title: 'Hinfahrt',
        },
        {
          key: 'return',
          title: 'Rückfahrt',
        },
      ],
      pageType: ['tours'] as TPageType[],
    },
    {
      key: 'conflictsOnly',
      title: 'Nur Konflikte zeigen',
      type: 'boolean',
      pageType: ['tours'] as TPageType[],
    },
    {
      key: 'hideCanceled',
      title: 'Abgesagte ausblenden',
      type: 'boolean',
      pageType: ['tours'] as TPageType[],
    },
    {
      key: 'showOnlyCancelled',
      title: 'Nur Abgesagte zeigen',
      type: 'boolean',
      pageType: ['tours'] as TPageType[],
    },
    {
      key: 'skills',
      title: 'Eigenschaften',
      type: 'multi',
      options: [
        {
          key: 'hasSkillScalamobil',
          title: 'Scalamobil',
        },
        {
          key: 'hasSkillSupport',
          title: 'Unterstützung',
        },
        {
          key: 'isDrivingTauga',
          title: 'Tauga',
        },
      ],
      pageType: ['employees'] as TPageType[],
    },
    {
      key: 'availableOnly',
      title: 'Nur verfügbare zeigen',
      type: 'boolean',
      pageType: ['employees'] as TPageType[],
    },
    {
      key: 'hasRoomForWheelchairs',
      title: 'Rollstuhlgeeignet',
      type: 'boolean',
      pageType: ['vehicles'] as TPageType[],
    },
    {
      key: 'hasWheelchair',
      title: 'Rollstuhlfahrer/Läufer',
      type: 'multi',
      options: [
        {
          key: 'walker',
          title: 'Läufer',
        },
        {
          key: 'wheelchair',
          title: 'Rollstuhlfahrer',
        },
      ],
      pageType: ['passengers'] as TPageType[],
    },
  ].filter((item) => item.pageType.includes(pageType));

export const INITIAL_FILTERS = (pageType: TPageType) =>
  FILTERS(pageType).reduce(
    (res, item) => ({
      ...res,
      [item.key]: item.type === 'boolean' ? '0' : item.type === 'multi' ? [] : '',
    }),
    {},
  );

export type TPageType = 'tours' | 'employees' | 'vehicles' | 'passengers' | 'customers';

export const useContentPageFilter = (pageType: TPageType) => {
  const userFiltersState = useRecoilValue(UserFiltersState);

  const searchParams = new URLSearchParams(window.location.search);
  const [viewAsList, setViewAsList] = useState(searchParams.get('viewAsList') === '1' || userFiltersState.pages[pageType]?.viewAsList);
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');
  const [propertyFilters, setPropertyFilters] = useState<{
    [key: string]: string | string[];
  }>(
    Object.entries(INITIAL_FILTERS(pageType)).reduce(
      (res, [key, value]) => ({
        ...res,
        [key]:
          (Array.isArray(value) ? searchParams.get(key)?.split(',') : searchParams.get(key)) ||
          userFiltersState.pages[pageType]?.propertyFilters?.[key] ||
          value,
      }),
      {},
    ),
  );

  return useMemo(
    () =>
      ({
        pageType,
        viewAsList,
        setViewAsList,
        searchQuery,
        setSearchQuery,
        propertyFilters,
        setPropertyFilters,
        getRegionsTitles: () => {
          return ((propertyFilters?.regions as string[]) || []).map(
            (region) => (FILTERS(pageType).find(({ key }) => key === 'regions')?.options || []).find(({ key }) => key === region)?.title,
          );
        },
      } as const),
    [pageType, viewAsList, searchQuery, propertyFilters],
  );
};
