import styled from 'styled-components';
import { Body1Bold, Body4, Body4Bold } from '../../../../styles/FontStyles';
import { Colors } from '../../../../styles/Colors';
import { Card } from '../../atoms/Card';
import { Avatar } from '../../atoms/Avatar';
import { EmployeeActiveLabel } from '../../labels/ActiveLabel';
import { Phone } from '../../../icons/Phone';
import { Link } from '../../atoms/Link';
import { Mail } from '../../../icons/Mail';
import { Bus } from '../../../icons/Bus';
import { LocationTag } from '../LocationTag';
import React, { FC, useMemo } from 'react';
import { IEmployee } from '../../../../api/models/Employee';
import { Edit } from '../../../icons/Edit';
import { Trash } from '../../../icons/Trash';
import { getTelLink } from '../../../../utils/phoneUtils';
import { useShowDeleteEmployeeDialog } from '../../../../hooks/employees/useShowDeleteEmployeeDialog';
import { useNavigate } from 'react-router-dom';
import { Clickable } from '../../atoms/Clickable';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { formatName } from '../../../../utils/nameUtils';
import { useHasPermission } from '../../../../state/UserMeState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ContextOptionsWrapper = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

const ContextOption = styled(Clickable).attrs(({ onClick }: any) => (!onClick ? { as: Link } : {}))<{ href?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};
  padding: 0 1rem 0.5rem;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const StatusCard = styled(Card)`
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    padding: 2rem;
    gap: 1.5rem;
  }
`;

const StyledAvatar = styled(Avatar)`
  ${Body1Bold};
  font-size: 2.25rem;
  letter-spacing: 2px;
`;

const StatusContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;

const StatusTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const IdNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 1rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: center;
  }
`;

const StatusId = styled.p`
  line-height: 1.25;

  ${BREAKPOINT_MD} {
    font-size: 1.5rem;
  }
`;

const StyledEmployeeActiveLabel = styled(EmployeeActiveLabel)`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
  }
`;

const StatusTitle = styled.h2`
  ${Body1Bold};
`;

const StatusRow = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem 2rem;
    flex-wrap: wrap;
  }
`;

const InfoTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }

  a {
    ${Body4Bold};
    color: ${Colors.secondary};
  }
`;

const ContextOptions = React.memo((props: { employee: IEmployee }) => {
  const { employee } = props;

  const navigate = useNavigate();
  const showDeleteEmployeeDialog = useShowDeleteEmployeeDialog(employee.id);

  const isAdmin = useHasPermission('admin');

  return (
    <ContextOptionsWrapper>
      <ContextOption href={`/employees/${employee.id}/edit`}>
        <Edit /> Bearbeiten
      </ContextOption>
      {isAdmin && (
        <ContextOption href={''} onClick={() => showDeleteEmployeeDialog(() => navigate('/employees', { replace: true }))}>
          <Trash /> Entfernen
        </ContextOption>
      )}
    </ContextOptionsWrapper>
  );
});

export const EmployeeDetailsStatusCard: FC<{ employee: IEmployee }> = (props) => {
  const { employee } = props;

  const isAbsent = useMemo(() => {
    return (employee.absences || []).length > 0;
  }, [employee]);

  return (
    <Wrapper>
      <ContextOptions employee={employee} />

      <StatusCard>
        <StyledAvatar
          src={employee.image?.url || ''}
          firstName={employee.firstName}
          lastName={employee.lastName}
          size={'large'}
          variation={isAbsent ? 'inactive' : 'active'}
        />

        <StatusContentWrapper>
          <StatusTitleWrapper>
            <IdNameWrapper>
              <StatusId>{employee.id}</StatusId>
              <StatusTitle>{formatName(employee)}</StatusTitle>
            </IdNameWrapper>

            <StyledEmployeeActiveLabel employee={employee} />
          </StatusTitleWrapper>

          <StatusRow>
            <InfoTag>
              <Phone />
              <Link href={getTelLink((employee.phoneNumbers || [])[0]?.phoneNumber) || ''}>
                {(employee.phoneNumbers || [])[0]?.formattedPhoneNumber || '-'}
              </Link>
            </InfoTag>

            <InfoTag>
              <Mail />
              <Link href={employee.emailAddress ? `mailto:${employee.emailAddress}` : ''}>{employee.emailAddress || '-'}</Link>
            </InfoTag>

            <InfoTag>
              {employee.jobTitle}
              <Bus />
              {employee.drivingLicense}
            </InfoTag>

            <LocationTag address={employee.region} />
          </StatusRow>
        </StatusContentWrapper>
      </StatusCard>
    </Wrapper>
  );
};
