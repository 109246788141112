export const Fleet = ({ bold, ...props }: any) =>
  bold ? (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.9007 17.25C8.9007 18.0784 8.22912 18.75 7.4007 18.75C6.57227 18.75 5.9007 18.0784 5.9007 17.25C5.9007 16.4216 6.57227 15.75 7.4007 15.75C8.22912 15.75 8.9007 16.4216 8.9007 17.25Z"
        stroke="#0F0F0F"
      />
      <path
        d="M19.4007 17.25C19.4007 18.0784 18.7291 18.75 17.9007 18.75C17.0723 18.75 16.4007 18.0784 16.4007 17.25C16.4007 16.4216 17.0723 15.75 17.9007 15.75C18.7291 15.75 19.4007 16.4216 19.4007 17.25Z"
        stroke="#0F0F0F"
      />
      <path
        d="M8.9007 17.25C8.9007 18.0784 8.22912 18.75 7.4007 18.75C6.57227 18.75 5.9007 18.0784 5.9007 17.25M8.9007 17.25C8.9007 16.4216 8.22912 15.75 7.4007 15.75C6.57227 15.75 5.9007 16.4216 5.9007 17.25M8.9007 17.25H16.4007M5.9007 17.25H3.9007C3.34841 17.25 2.9007 16.8023 2.9007 16.25V12.25C2.9007 11.6977 3.34841 11.25 3.9007 11.25H5.9007M19.4007 17.25C19.4007 18.0784 18.7291 18.75 17.9007 18.75C17.0723 18.75 16.4007 18.0784 16.4007 17.25M19.4007 17.25C19.4007 16.4216 18.7291 15.75 17.9007 15.75C17.0723 15.75 16.4007 16.4216 16.4007 17.25M19.4007 17.25H21.4007C21.953 17.25 22.4007 16.8023 22.4007 16.25V11.25M5.9007 11.25L7.21133 6.00746C7.32262 5.5623 7.7226 5.25 8.18147 5.25H21.4007C21.953 5.25 22.4007 5.69772 22.4007 6.25V11.25M5.9007 11.25H22.4007"
        stroke="#0F0F0F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M17.2602 10.374L17.9007 5.25H13.5335C13.0292 5.25 12.6038 5.62554 12.5412 6.12597L11.9007 11.25H16.2679C16.7722 11.25 17.1976 10.8745 17.2602 10.374Z"
        stroke="#0F0F0F"
        strokeLinejoin="round"
      />
      <path
        d="M22.4007 11.25H17.1507L17.7912 6.12597C17.8538 5.62554 18.2792 5.25 18.7835 5.25H21.4007C21.953 5.25 22.4007 5.69772 22.4007 6.25V11.25Z"
        stroke="#0F0F0F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.21133 6.00746L5.9007 11.25H11.0179C11.5222 11.25 11.9476 10.8745 12.0102 10.374L12.6507 5.25H8.18147C7.7226 5.25 7.32262 5.5623 7.21133 6.00746Z"
        stroke="#0F0F0F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.54895 17.25C8.54895 18.0784 7.87738 18.75 7.04895 18.75C6.22052 18.75 5.54895 18.0784 5.54895 17.25C5.54895 16.4216 6.22052 15.75 7.04895 15.75C7.87738 15.75 8.54895 16.4216 8.54895 17.25Z"
        stroke="#0F0F0F"
      />
      <path
        d="M19.049 17.25C19.049 18.0784 18.3774 18.75 17.549 18.75C16.7205 18.75 16.049 18.0784 16.049 17.25C16.049 16.4216 16.7205 15.75 17.549 15.75C18.3774 15.75 19.049 16.4216 19.049 17.25Z"
        stroke="#0F0F0F"
      />
      <path
        d="M8.54895 17.25C8.54895 18.0784 7.87738 18.75 7.04895 18.75C6.22052 18.75 5.54895 18.0784 5.54895 17.25M8.54895 17.25C8.54895 16.4216 7.87738 15.75 7.04895 15.75C6.22052 15.75 5.54895 16.4216 5.54895 17.25M8.54895 17.25H16.049M5.54895 17.25H3.54895C2.99667 17.25 2.54895 16.8023 2.54895 16.25V12.25C2.54895 11.6977 2.99667 11.25 3.54895 11.25H5.54895M19.049 17.25C19.049 18.0784 18.3774 18.75 17.549 18.75C16.7205 18.75 16.049 18.0784 16.049 17.25M19.049 17.25C19.049 16.4216 18.3774 15.75 17.549 15.75C16.7205 15.75 16.049 16.4216 16.049 17.25M19.049 17.25H21.049C21.6012 17.25 22.049 16.8023 22.049 16.25V11.25M5.54895 11.25L6.85958 6.00746C6.97088 5.5623 7.37086 5.25 7.82973 5.25H21.049C21.6012 5.25 22.049 5.69772 22.049 6.25V11.25M5.54895 11.25H22.049"
        stroke="#0F0F0F"
        strokeLinejoin="round"
      />
      <path
        d="M16.9084 10.374L17.549 5.25H13.1817C12.6774 5.25 12.252 5.62554 12.1895 6.12597L11.549 11.25H15.9162C16.4205 11.25 16.8459 10.8745 16.9084 10.374Z"
        stroke="#0F0F0F"
        strokeLinejoin="round"
      />
      <path
        d="M22.049 11.25H16.799L17.4395 6.12597C17.502 5.62554 17.9274 5.25 18.4317 5.25H21.049C21.6012 5.25 22.049 5.69772 22.049 6.25V11.25Z"
        stroke="#0F0F0F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.85958 6.00746L5.54895 11.25H10.6662C11.1705 11.25 11.5959 10.8745 11.6584 10.374L12.299 5.25H7.82973C7.37086 5.25 6.97088 5.5623 6.85958 6.00746Z"
        stroke="#0F0F0F"
        strokeLinejoin="round"
      />
    </svg>
  );
