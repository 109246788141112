export const Details = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 6.5C10.8954 6.5 10 5.60457 10 4.5C10 3.39543 10.8954 2.5 12 2.5C13.1046 2.5 14 3.39543 14 4.5C14 5.60457 13.1046 6.5 12 6.5Z"
      stroke="#0F0F0F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z"
      stroke="#0F0F0F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 21.48C10.8954 21.48 10 20.5846 10 19.48C10 18.3754 10.8954 17.48 12 17.48C13.1046 17.48 14 18.3754 14 19.48C14 20.5846 13.1046 21.48 12 21.48Z"
      stroke="#0F0F0F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
