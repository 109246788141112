import styled, { css, keyframes } from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Trash } from '../../icons/Trash';
import { Body3, Body4 } from '../../../styles/FontStyles';
import { FC, forwardRef, useEffect, useState } from 'react';
import { Calendar as CalendarSVG } from '../../icons/Calendar';
import DatePicker from 'react-datepicker';
import { FontFamilies } from '../../../styles/Fonts';
import { Button } from './Button';
import { Clickable } from '../atoms/Clickable';
import { getFormattedValue } from '../../../utils/dateUtils';

const Appear = keyframes`
  from {
    opacity: 0;
  }
`;

const DatePickerWrapper = styled.div<{ dropdownStyling?: boolean; hideFieldValue?: boolean; open: boolean; disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-start;
  min-width: 12rem;
  height: 2.25rem;
  flex-shrink: 0;
  z-index: ${({ open }) => (open ? 5 : 3)};

  ${({ dropdownStyling = false }) =>
    dropdownStyling &&
    css`
      align-self: auto;
      border: 1px solid ${Colors.grey500};
      border-radius: 0.5rem;
      padding-inline: 0.5rem 0.75rem;
      height: auto;
      padding-block: 0.125rem;
    `};

  ${({ hideFieldValue = false }) =>
    hideFieldValue &&
    css`
      min-width: auto;
    `};

  ${({ disabled = false }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      background: ${Colors.grey500};
    `};

  .react-datepicker {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    background: ${Colors.white50};
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 0;
    font-family: ${FontFamilies.default};
    color: ${Colors.textDefault};
    ${Body4};
  }

  .react-datepicker-wrapper {
    display: flex;
  }

  .react-datepicker-popper {
    animation: ${Appear} 150ms ease-out;
  }

  .react-datepicker__header {
    background: none;
    border: 0;
  }

  .react-datepicker__current-month {
    margin: 0.5rem 0 0.75rem;
    font-weight: 400;
  }

  .react-datepicker__navigation {
    &--previous,
    &--next {
      margin-top: 0.5rem;

      & > .react-datepicker__navigation-icon {
        ::before {
          border-width: 2px 2px 0 0;
        }
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--keyboard-selected {
    font-weight: 400;
    color: inherit;
    background: none;
  }

  .react-datepicker__day {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 1.75rem;
    margin: 0.125rem 0;

    &--selected,
    &--in-range,
    &--in-selecting-range,
    &--selecting-range-start,
    &--selecting-range-end,
    &--range-start,
    &--range-end {
      font-weight: 400;
      background: ${Colors.black900};
      color: ${Colors.textInverted};
      border-radius: 15px;
      padding: 1px 5px;
    }

    &--in-range {
      border-radius: 0;
    }

    &--in-selecting-range {
      background: ${Colors.grey500};
      border-radius: 0;
    }

    &--selecting-range-start,
    &--range-start {
      background: ${Colors.black900};
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &--selecting-range-end,
    &--range-end {
      background: ${Colors.black900};
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  .react-datepicker__day--today {
    font-weight: 400;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin: -0.125rem auto 0;
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 50%;
      background: ${Colors.signalRed900};
    }

    &.react-datepicker__day--selected {
      &::after {
        margin-top: 0.125rem; // move to bottom
      }
    }
  }

  .react-datepicker__day--disabled {
    color: ${Colors.grey600};
  }

  .react-datepicker__day-name {
    color: ${Colors.grey600};
  }
`;

const PickerFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 0 0.5rem 1.5rem;

  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 100%;
    height: 1px;
    background: ${Colors.grey500};
    margin-bottom: 1rem;
  }
`;

const SelectedDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const ControlWrapper = styled.div.attrs(({ dropdownStyling = false }: any) => (dropdownStyling ? { as: Clickable } : {}))<{
  dropdownStyling?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  inline-size: 100%;
  block-size: 2.25rem;

  ${({ dropdownStyling = false }) =>
    dropdownStyling &&
    css`
      justify-content: space-between;
    `};
`;

const Title = styled.span`
  ${Body4};
  padding: 0 0.5rem;
  color: ${Colors.grey600};
`;

const ChipTile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  padding: 0.125rem 0.5rem;
  border-radius: 1.25rem;
  background: ${Colors.grey500};

  ${Body4};
`;

const TrashIcon = styled(Trash)`
  width: 1rem;
  height: 1rem;
  object-fit: contain;
`;

const CalendarIcon = styled(CalendarSVG)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const DropDownValue = styled.p`
  ${Body3};
  color: ${Colors.textDefault};
  padding-inline: 0.5rem;
`;

interface ICalendarProps {
  title?: string;
  displayValue?: (Date | null) | [Date | null, Date | null];
  value: (Date | null) | [Date | null, Date | null];
  setValue: (value: (Date | null) | [Date | null, Date | null]) => void;
  submitValue?: (value: (Date | null) | [Date | null, Date | null]) => void;
  startDate?: Date | null;
  endDate?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  selectType?: 'start' | 'end' | 'range';
  dropdownStyling?: boolean;
  hideFieldValue?: boolean;
  hideDeleteButton?: boolean;
  requireSubmit?: boolean;
  disabled?: boolean;
}

export const Calendar: FC<ICalendarProps> = (props) => {
  const {
    title,
    displayValue,
    value,
    setValue,
    submitValue = setValue,
    startDate,
    endDate,
    minDate,
    maxDate,
    selectType,
    dropdownStyling = false,
    hideFieldValue = false,
    hideDeleteButton = false,
    requireSubmit = false,
    disabled = false,
  } = props;

  const [open, setOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, open]);

  const formattedValue = getFormattedValue(displayValue || value);
  const formattedFieldValue = getFormattedValue(displayValue || fieldValue);

  return (
    <DatePickerWrapper dropdownStyling={dropdownStyling} hideFieldValue={hideFieldValue} open={open} disabled={disabled}>
      <DatePicker
        disabled={disabled}
        startDate={startDate || (selectType === 'range' && Array.isArray(fieldValue) && fieldValue[0]) || undefined}
        endDate={endDate || (selectType === 'range' && Array.isArray(fieldValue) && fieldValue[1]) || undefined}
        minDate={minDate}
        maxDate={maxDate}
        {...(selectType === 'start' ? { selectsStart: true } : {})}
        {...(selectType === 'end' ? { selectsEnd: true } : {})}
        {...(selectType === 'range' ? { selectsRange: true } : {})}
        onChange={(value) => {
          if (!disabled) {
            setFieldValue(value);
            if (!requireSubmit) {
              setValue(value);
            }
          }
        }}
        openToDate={(Array.isArray(fieldValue) ? fieldValue[0] : fieldValue) || undefined}
        selected={(Array.isArray(fieldValue) ? fieldValue[0] : fieldValue) || undefined}
        open={open}
        title={title}
        onClickOutside={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setOpen(false);
        }}
        onSelect={(value) => {
          if (selectType !== 'range') {
            setOpen(false);
            submitValue?.(value);
          }
        }}
        popperPlacement={'bottom-end'}
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true,
            },
          },
        ]}
        customInput={
          <CalendarView
            open={open}
            setOpen={setOpen}
            formattedValue={formattedValue}
            hideFieldValue={hideFieldValue}
            hideDeleteButton={hideDeleteButton}
            onClear={() => {
              setFieldValue(null);
              if (!requireSubmit) {
                setValue(null);
              }
            }}
            dropdownStyling={dropdownStyling}
          />
        }
      >
        <PickerFooterWrapper>
          {formattedFieldValue && (
            <SelectedDateWrapper>
              <p>ausgewähltes Datum:</p>
              <ChipTile>
                {formattedFieldValue}
                {(!hideDeleteButton || (selectType === 'end' && fieldValue)) && (
                  <Clickable
                    onClick={() => {
                      setFieldValue(null);
                      if (!requireSubmit || selectType !== 'range') {
                        setValue(null);
                      }
                    }}
                  >
                    <TrashIcon />
                  </Clickable>
                )}
              </ChipTile>
            </SelectedDateWrapper>
          )}
          <Button
            disabled={!fieldValue}
            onClick={() => {
              setOpen(false);
              submitValue?.(fieldValue);
            }}
          >
            Übernehmen
          </Button>
        </PickerFooterWrapper>
      </DatePicker>
    </DatePickerWrapper>
  );
};

const CalendarView = forwardRef((props: any, ref) => {
  const { title, formattedValue, open, setOpen, onClear, dropdownStyling, hideFieldValue, hideDeleteButton } = props || {};

  return (
    <ControlWrapper
      dropdownStyling={dropdownStyling}
      {...(dropdownStyling
        ? {
            // use mouse down event to stop propagation to click outside handler of calendar
            onMouseDown: (e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            },
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
            },
          }
        : {})}
    >
      {!hideFieldValue && (
        <>
          {formattedValue && (
            <>
              {dropdownStyling ? (
                <DropDownValue>{formattedValue}</DropDownValue>
              ) : (
                <ChipTile>
                  {formattedValue}
                  {!hideDeleteButton && (
                    <Clickable onClick={onClear}>
                      <TrashIcon />
                    </Clickable>
                  )}
                </ChipTile>
              )}
            </>
          )}

          {!formattedValue && <Title>{title}</Title>}
        </>
      )}

      {dropdownStyling ? (
        <CalendarIcon />
      ) : (
        <Clickable
          // use mouse down event to stop propagation to click outside handler of calendar
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(!open);
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CalendarIcon />
        </Clickable>
      )}
    </ControlWrapper>
  );
});
