import { useEffect, useMemo, useState } from 'react';
import { DIMEN_BREAKPOINT_XL } from '../styles/Breakpoints';

export const useMatchBreakpoint = (breakpoint: number = DIMEN_BREAKPOINT_XL) => {
  const query = useMemo(() => `(min-width: ${breakpoint}px)`, [breakpoint]);
  return useMatchMediaQuery(query);
};

export const useMatchMediaQuery = (query: string) => {
  const [breakpointReached, setBreakpointReached] = useState<boolean | null>(null);

  useEffect(() => {
    const listener = ({ matches }: any) => {
      setBreakpointReached(matches);
    };

    const mediaQuery = window.matchMedia(query);
    mediaQuery.addEventListener('change', listener);
    listener(mediaQuery);

    return () => {
      mediaQuery.removeEventListener('change', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return breakpointReached;
};
