import { useCallback } from 'react';
import { useCustomerOrganizationAPI } from '../../api/controllers/CustomerOrganizationAPI';
import { useCustomersAPI } from '../../api/controllers/CustomersAPI';
import { ButtonPrimary, ButtonSecondary } from '../../components/common/inputs/Button';
import { LoadingSpinner } from '../../components/common/loaders/LoadingSpinner';
import { useShowDialog } from '../../state/DialogState';

export const useShowDeleteCustomerOrganizationDialog = (id: string) => {
  const showDialog = useShowDialog();

  const customersAPI = useCustomersAPI();
  const customerOrganizationAPI = useCustomerOrganizationAPI();

  return useCallback(
    (onSuccess?: () => void) => {
      showDialog({
        headline: 'Organisation entfernen',
        body: <>Möchten Sie diese Organisation wirklich löschen?</>,
        buttons: ({ loading = false }) => (
          <>
            <ButtonPrimary
              disabled={loading}
              onClick={() => {
                showDialog({ loading: true }, true);

                customerOrganizationAPI
                  .deleteCustomerOrganization(id)
                  .then(() => {
                    showDialog(null);
                    onSuccess?.();
                  })
                  .catch((e) => {
                    console.error('delete customer', e);

                    // show error message
                    showDialog({
                      headline: 'Organisation entfernen',
                      body: <>Die Organisation konnte nicht entfernt werden. Bitte versuchen Sie es später erneut.</>,
                    });
                  });
              }}
            >
              {loading ? <LoadingSpinner /> : 'Entfernen'}
            </ButtonPrimary>
            <ButtonSecondary disabled={loading} onClick={() => showDialog(null)}>
              Abbrechen
            </ButtonSecondary>
          </>
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, showDialog, customersAPI],
  );
};
