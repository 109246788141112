export const Tours = ({ bold, ...props }: any) =>
  bold ? (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.29895 4.46154C8.29895 6.37329 6.29895 11 5.29895 11C4.29895 11 2.29895 6.37329 2.29895 4.46154C2.29895 2.54978 3.6421 1 5.29895 1C6.9558 1 8.29895 2.54978 8.29895 4.46154Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M22.299 15.1538C22.299 17.448 19.9656 23 18.799 23C17.6323 23 15.299 17.448 15.299 15.1538C15.299 12.8597 16.866 11 18.799 11C20.7319 11 22.299 12.8597 22.299 15.1538Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M8.79895 11L9.59488 11C11.3836 11 12.2728 13.1686 10.9989 14.4244L5.77293 19.5757C4.49902 20.8314 5.38818 23 7.17693 23H15.299"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="5.29895" cy="4" r="1" fill="currentColor" />
      <circle cx="18.799" cy="15.5" r="1.5" fill="currentColor" />
    </svg>
  ) : (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.6507 4.46154C8.6507 6.37329 6.6507 11 5.6507 11C4.6507 11 2.6507 6.37329 2.6507 4.46154C2.6507 2.54978 3.99384 1 5.6507 1C7.30755 1 8.6507 2.54978 8.6507 4.46154Z"
        stroke="currentColor"
      />
      <path
        d="M22.6507 15.1538C22.6507 17.448 20.3174 23 19.1507 23C17.984 23 15.6507 17.448 15.6507 15.1538C15.6507 12.8597 17.2177 11 19.1507 11C21.0837 11 22.6507 12.8597 22.6507 15.1538Z"
        stroke="currentColor"
      />
      <path
        d="M8.1507 11H9.94663C11.7354 11 12.6245 13.1686 11.3506 14.4244L6.12467 19.5757C4.85077 20.8314 5.73992 23 7.52868 23H16.6507"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="5.6507" cy="4" r="1" stroke="currentColor" />
      <circle cx="19.1507" cy="15.5" r="1.5" stroke="currentColor" />
    </svg>
  );
