import { IConflict } from '../../../../../api/models/Conflict';
import { IPassenger } from '../../../../../api/models/Passenger';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useDragDropContainer, useDragDropDataProvider } from '../../../../content/customers/tourplan/DragDropContext';
import { TTourplanScheduledTour } from '../../../../content/customers/tourplan/tiles/TourplanScheduledTourTile';
import TourPickupTimes from '../../TourPickupTimes';
import { TileItem } from '../../../../content/customers/tourplan/tiles/TourplanTourTile';
import { getCurrentDayDate } from '../../../../../utils/dateUtils';
import { IScheduledTour, IScheduledTourException } from '../../../../../api/models/ScheduledTour';
import { ButtonPrimary, ButtonSecondary } from '../../../inputs/Button';
import PassengerAdditionOverlay from './PassengerAdditionOverlay';
import styled from 'styled-components';
import { Body2 } from '../../../../../styles/FontStyles';
import { Colors } from '../../../../../styles/Colors';
import { BREAKPOINT_SM } from '../../../../../styles/Breakpoints';
import TourInfoView from './TourInfoView';
import DriverPickersView from './DriverPickersView';
import { TourDetailPassengersList } from './TourDetailPassengersList';
import { TourplanPassengerView } from '../../../../content/customers/tourplan/tiles/TourplanPassengerView';

const TourItem = styled(TileItem)`
  gap: 1rem;
  padding: 1.5rem;
  position: relative;
`;

const Separator = styled.div`
  display: flex;
  block-size: 1rem;
  border-block-start: 1px solid ${Colors.grey600};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  ${BREAKPOINT_SM} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const InactiveOverlay = styled.div.attrs({ onClick: (e: any) => e.stopPropagation() })`
  position: absolute;
  inset: 0;
  z-index: 1;

  backdrop-filter: saturate(0.2);
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 8px;
  pointer-events: all;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  ${Body2};
  text-align: center;
  color: ${Colors.textInverted};
`;

const TourItemDropContainer = React.memo(
  (props: PropsWithChildren<{ editable: boolean; schedule: IScheduledTour; passengerInAdditionState: boolean }>) => {
    const { editable, schedule, children, passengerInAdditionState } = props;

    const dragDropProps = useDragDropContainer<TTourplanScheduledTour, IPassenger>(schedule);

    return (
      <TourItem {...(editable ? dragDropProps : {})} style={{ minHeight: passengerInAdditionState ? '24rem' : 'none' }}>
        {children}
      </TourItem>
    );
  },
);

interface ITourDetailsTileProps {
  schedule: IScheduledTour;
  timestamp: string;
  editable: boolean;
  hasChanged: boolean;
  conflicts?: IConflict[];
  exceptions: IScheduledTourException[];
  onSave?: () => void;
  onEdit?: (passenger: IPassenger) => void;
  selectedException?: IScheduledTourException;
  activeExceptionId?: string;
  activeDay: boolean;
  onDiscard?: () => void;
}
export default function TourDetailsTile(props: ITourDetailsTileProps) {
  const {
    activeExceptionId,
    schedule,
    timestamp,
    editable = true,
    hasChanged,
    conflicts = [],
    exceptions,
    onSave,
    onEdit,
    selectedException,
    activeDay,
    onDiscard,
  } = props;
  const { name, direction, departureDate, arrivalDate, driver, companion, customer } = schedule;
  const { dataSource } = useDragDropDataProvider();
  const date = getCurrentDayDate(timestamp);

  const currentPassengers = useMemo(() => (dataSource.fields[0].passengers || []) as IPassenger[], [dataSource.fields]);

  const [passengersWheelchair, passengersWalking] = useMemo(() => {
    return (currentPassengers || []).reduce(
      (res, item) => {
        res[item.hasWheelchair ? 0 : 1].push(item);
        return res;
      },
      [[], []] as Partial<IPassenger>[][],
    );
  }, [currentPassengers]);

  const isCurrentDayInRange = useMemo(() => {
    const { startDate, endDate } = schedule;

    return getCurrentDayDate(startDate) <= date && (!endDate || getCurrentDayDate(endDate) >= date);
  }, [schedule, date]);

  const isCurrentWeekdayDriven = useMemo(() => {
    const { daysOfWeek = '' } = schedule;

    return daysOfWeek.split(',').includes(date.getDay().toString());
  }, [schedule, date]);

  const dayOfWeek = date.getDay().toString();

  const [isCurrentDayCancelled, isCurrentDaySplitted] = useMemo(() => {
    return [selectedException?.reason === 'cancelled', selectedException?.reason === 'tour_split'];
  }, [selectedException]);

  const [passengerInAdditionState, setPassengerInAdditionState] = useState<IPassenger | null>(null);

  const activePassengers = currentPassengers.filter(
    (passenger) => passenger.ScheduledTourPassenger?.pickupDate && passenger.ScheduledTourPassenger?.pickupDate[dayOfWeek] !== 'absent',
  );

  const inactivePassengers = currentPassengers.filter(
    (passenger) => !passenger.ScheduledTourPassenger?.pickupDate || passenger.ScheduledTourPassenger?.pickupDate[dayOfWeek] === 'absent',
  );

  let lastDropOffTime = date.toISOString();
  if (activePassengers.length > 0) {
    const lastPassenger = activePassengers[activePassengers.length - 1];
    if (lastPassenger && lastPassenger.ScheduledTourPassenger?.pickupDate && lastPassenger.ScheduledTourPassenger?.pickupDate[dayOfWeek]) {
      lastDropOffTime = new Date(lastPassenger.ScheduledTourPassenger?.pickupDate[dayOfWeek]).toISOString();
    }
  }

  const handleAdditionPassengerClick = (amount: number) => {
    if (passengerInAdditionState) {
      const existingPickupDate = passengerInAdditionState.ScheduledTourPassenger?.pickupDate || {};
      const updatedPickupDate = {
        ...existingPickupDate,
        [dayOfWeek]: lastDropOffTime,
      };

      // fill pickup date with absent if not present
      for (let i = 1; i <= 5; i++) {
        if (!updatedPickupDate[i]) {
          updatedPickupDate[i] = 'absent';
        }
      }

      dataSource.update(0, {
        ...schedule,
        passengers: [
          ...(activePassengers || []),
          {
            ...passengerInAdditionState,
            ScheduledTourPassenger: {
              ...passengerInAdditionState.ScheduledTourPassenger,
              timeSupplement: amount, // add time supplement amount
              passengerId: passengerInAdditionState.id,
              pickupDate: updatedPickupDate,
            },
          },
        ],
      });

      // close overlay
      setPassengerInAdditionState(null);
    }
  };

  const passengerAdditionOverlay = () => (
    <>
      {passengerInAdditionState && (
        <PassengerAdditionOverlay
          passengersInAdditionState={[passengerInAdditionState]}
          onSubmit={(amount) => {
            handleAdditionPassengerClick(amount);
          }}
          onCancel={() => {
            handleAdditionPassengerClick(0);
          }}
        />
      )}
    </>
  );

  const tourCancelledHints = () => {
    if (exceptions.length === 0 && activeDay) {
      return <></>;
    } else if (
      activeDay &&
      ((selectedException && selectedException.id !== activeExceptionId && !isCurrentDayCancelled && !isCurrentDaySplitted) ||
        (!schedule.isException && !isCurrentDayCancelled && !isCurrentDaySplitted))
    ) {
      return <InactiveOverlay style={{ opacity: 0.25 }} />;
    } else {
      if (!isCurrentDayInRange) {
        return (
          <InactiveOverlay>
            Diese Fahrt wird nur vom {new Date(schedule.startDate).toLocaleDateString(undefined, { dateStyle: 'medium' })}{' '}
            {!schedule.endDate ? 'an' : `bis zum ${new Date(schedule.endDate).toLocaleDateString(undefined, { dateStyle: 'medium' })}`}{' '}
            gefahren.
          </InactiveOverlay>
        );
      } else if ((isCurrentDayInRange && !isCurrentWeekdayDriven) || !activeDay) {
        return (
          <InactiveOverlay>
            Diese Fahrt wird am {new Date(timestamp).toLocaleDateString(undefined, { weekday: 'long' })} nicht gefahren.
          </InactiveOverlay>
        );
      } else if (isCurrentDayCancelled) {
        return (
          <InactiveOverlay>
            Diese Fahrt wurde für den {new Date(timestamp).toLocaleDateString(undefined, { dateStyle: 'medium' })} abgesagt.
          </InactiveOverlay>
        );
      } else if (isCurrentDaySplitted) {
        return (
          <InactiveOverlay>
            Diese Fahrt wurde für den {new Date(timestamp).toLocaleDateString(undefined, { dateStyle: 'medium' })} aufgeteilt.
          </InactiveOverlay>
        );
      }
    }
    return null;
  };

  return (
    <>
      <TourItemDropContainer editable={editable} schedule={schedule} passengerInAdditionState={!!passengerInAdditionState}>
        <TourInfoView
          name={name}
          direction={direction || undefined}
          conflicts={conflicts}
          timestamp={timestamp}
          customer={customer}
          passengersWheelchair={passengersWheelchair}
          passengersWalking={passengersWalking}
        />
        <DriverPickersView
          driver={driver}
          companion={companion}
          conflicts={conflicts}
          dataSource={dataSource}
          direction={direction || 'outwards'}
          editable={editable}
          hasChanged={hasChanged}
          schedule={schedule}
          timestamp={timestamp}
        />
        <TourPickupTimes
          pickupTimes={(direction === 'return' ? departureDate : arrivalDate) || {}}
          direction={direction || undefined}
          timestamp={timestamp}
        />
        <Separator />

        <TourDetailPassengersList
          direction={direction}
          passengers={activePassengers}
          customer={customer}
          editable={editable}
          onEdit={onEdit}
          timestamp={timestamp}
          departureDate={departureDate}
          arrivalDate={arrivalDate}
          schedule={schedule}
          setPassengerInAdditionState={setPassengerInAdditionState}
        />

        {hasChanged && editable && (
          <ButtonsWrapper>
            <ButtonSecondary
              onClick={() => {
                dataSource.update(0, null);
                onDiscard && onDiscard();
              }}
            >
              Verwerfen
            </ButtonSecondary>
            <ButtonPrimary
              onClick={() => {
                onSave?.();
              }}
            >
              Speichern
            </ButtonPrimary>
          </ButtonsWrapper>
        )}

        {passengerAdditionOverlay()}

        {tourCancelledHints()}
      </TourItemDropContainer>
      {inactivePassengers.length > 0 && (
        <TourItem>
          <b>{new Date(timestamp).toLocaleString('de-DE', { weekday: 'long' })}s abwesende Fahrgäste:</b>
          {inactivePassengers.map((passenger) => (
            <TourplanPassengerView
              key={passenger.id}
              selectedDayOfWeek={date.toISOString()}
              passenger={passenger}
              onPresentClick={() => {
                setPassengerInAdditionState(passenger);
                handleAdditionPassengerClick(0);
              }}
            />
          ))}
        </TourItem>
      )}
    </>
  );
}
