import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { ButtonSecondary } from '../../common/inputs/Button';
import { EmployeeTile } from '../../common/elements/employee/EmployeeTile';
import { EmployeeListRow } from '../../common/elements/employee/EmployeeListRow';
import { List, ListHead } from '../../common/atoms/List';
import { Plus } from '../../icons/Plus';
import { useEmployeeSearchAPI } from '../../../api/controllers/InfiniteSearchAPI';
import { IEmployee } from '../../../api/models/Employee';
import { ContentPageHead } from '../../common/elements/ContentPageHead';
import { EmployeeFilter } from './EmployeeFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState, useRecoilValue } from 'recoil';
import { EmployeeFilterState } from '../../../state/EmployeeFilterState';
import { useEmployeesAPI } from '../../../api/controllers/EmployeesAPI';
import { Link } from 'react-router-dom';
import InfinityScrollingWrapper from '../../common/elements/InfinityScrollingWrapper';
import { UiState } from '../../../state/UiState';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const StyledList = styled(List)`
  --list-template: 2.5rem 5rem 1fr 6.5rem 5.5rem 7rem 20rem;

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
`;

const StyledListHead = styled(ListHead)`
  top: 10rem;
  margin-block-start: -2.5rem;
  padding-block-start: 2.5rem;
`;

export const EmployeeList: React.FC = () => {
  const uiState = useRecoilValue(UiState);

  const [searchParams] = useRecoilState(EmployeeFilterState);

  const { status, data, hasNextPage, fetchNextPage, error, isFetching } = useEmployeeSearchAPI(
    uiState.employeeList.activeTab,
    searchParams,
  );
  const employeeAPI = useEmployeesAPI();

  const employees = useMemo(() => {
    return (data?.pages.map((page) => page.items).flat() as IEmployee[]) || [];
  }, [data]);

  const removeItem = (id: string) => {
    employeeAPI
      .deleteEmployee(id)
      .then((res) => window.location.reload())
      .catch(() => console.error('error'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [uiState.employeeList.activeTab]);

  return (
    <Wrapper>
      <ContentPageHead title={`Mitarbeiter`} listName="employeeList">
        <FlexWrapper>
          <Link to={'/employees/add'}>
            <ButtonSecondary>
              <Plus /> Mitarbeiter anlegen
            </ButtonSecondary>
          </Link>

          <EmployeeFilter />
        </FlexWrapper>
      </ContentPageHead>
      {data &&
        data.pages &&
        (uiState.employeeList.activeTab === 'list' ? (
          <StyledList>
            <StyledListHead>
              <div />
              <div>Pers. Nr.</div>
              <div>Name</div>
              <div>Rolle</div>
              <div>Führerschein-klasse</div>
              <div>Einsatzgebiet</div>
            </StyledListHead>
            <InfiniteScroll
              dataLength={employees.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              style={{ overflow: 'visible' }}
              loader={<LoadingSpinner />}
              endMessage={<p>Keine weiteren Mitarbeiter</p>}
            >
              {employees.length > 0 &&
                employees.map((employee) => (
                  <EmployeeListRow key={employee.id} employee={employee} onUpdate={() => removeItem(employee.id)} />
                ))}
            </InfiniteScroll>
          </StyledList>
        ) : (
          <InfiniteScroll
            dataLength={employees.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            style={{ overflow: 'visible' }}
            loader={<LoadingSpinner />}
            endMessage={<p>Keine weiteren Mitarbeiter</p>}
          >
            <InfinityScrollingWrapper>
              {employees.length > 0 &&
                employees.map((employee) => (
                  <EmployeeTile key={employee.id} employee={employee} onUpdate={() => removeItem(employee.id)} />
                ))}
            </InfinityScrollingWrapper>
          </InfiniteScroll>
        ))}

      {isFetching && status === 'pending' && employees.length === 0 && <LoadingSpinner />}
      {error && <div>Es ist ein Fehler aufgetreten</div>}
    </Wrapper>
  );
};
