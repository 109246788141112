import styled from 'styled-components';
import { useBulkCancellationAPI } from '../../../api/controllers/BulkCancellationAPI';
import { IBulkCancellationResult } from '../../../api/models/BulkCancellation';
import { List, ListHead } from '../../common/atoms/List';
import { BulkCancellationListRow } from '../../common/elements/bulk-cancellations/BulkCancellationListRow';
import { useHasPermission } from '../../../state/UserMeState';
import { useQuery } from '@tanstack/react-query';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
`;

const BulkList = styled(List)`
  --list-template-internal: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 30px;
`;

const StyledListHead = styled(ListHead)`
  top: 10rem;
  margin-block-start: -2.5rem;
  padding-block-start: 2.5rem;
`;

export const BulkCancellationList = () => {
  const bulkCancellationAPI = useBulkCancellationAPI();
  const isAdmin = useHasPermission('admin');

  const {
    data: bulkCancellations,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['bulkCancellations'],
    initialData: [],
    queryFn: async () => {
      const { data } = await bulkCancellationAPI.getBulkCancellations({}).then((res) => res);

      return data.data as IBulkCancellationResult[];
    },
  });

  return (
    <Wrapper>
      <BulkList>
        <StyledListHead>
          <div>ID</div>
          <div>Grund</div>
          <div>Startdatum</div>
          <div>Enddatum</div>
          <div>Region</div>
          <div>Einrichtung</div>
          <div>Anzahl</div>
          {isAdmin && <div>Aktionen</div>}
        </StyledListHead>
        {bulkCancellations.length > 0 &&
          bulkCancellations.map((bulkCancellation) => (
            <BulkCancellationListRow key={bulkCancellation.id} bulkCancellation={bulkCancellation} refetch={refetch} />
          ))}
      </BulkList>
      {!loading && bulkCancellations.length === 0 && <p>Keine Kalender-Ausnahmen gefunden</p>}
    </Wrapper>
  );
};
