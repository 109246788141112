import styled from 'styled-components';
import React, { FC } from 'react';
import { ActiveLabel } from '../../labels/ActiveLabel';
import { ChevronDown } from '../../../icons/ChevronDown';
import { IVehicle } from '../../../../api/models/Vehicle';
import { Bus } from '../../../icons/Bus';
import { ContextOptions } from '../ContextOptions';
import { Trash } from '../../../icons/Trash';
import { Edit } from '../../../icons/Edit';
import { Info, InfoBold, ListRowWrapper, OptionsWrapper, Row, ListRowControlsWrapper } from '../../atoms/List';
import { useShowDeleteVehicleDialog } from '../../../../hooks/vehicles/useShowDeleteVehicleDialog';
import { Wheelchair } from '../../../icons/Wheelchair';
import { formatName } from '../../../../utils/nameUtils';
import { useHasPermission } from '../../../../state/UserMeState';
import { useNavigate } from '../../../../hooks/useNavigate';

const ControlsWrapper = styled(ListRowControlsWrapper)`
  inline-size: 20rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ContextMenu = React.memo((props: { id: string; onUpdate?: () => void }) => {
  const { id, onUpdate } = props;
  const navigate = useNavigate();
  const showDeleteVehicleDialog = useShowDeleteVehicleDialog(id);
  const isAdmin = useHasPermission('admin');

  return (
    <ContextOptions
      items={
        [
          isAdmin && {
            content: (
              <>
                <Trash /> Entfernen
              </>
            ),
            onClick: () => showDeleteVehicleDialog(onUpdate),
          },
          {
            content: (
              <>
                <Edit /> Bearbeiten
              </>
            ),
            onClick: () => navigate(`/vehicles/${id}/edit`),
          },
        ].filter(Boolean) as []
      }
    />
  );
});

export const VehicleListRow: FC<{ vehicle: IVehicle; onUpdate?: () => void }> = (props) => {
  const { id, licensePlate, brandName, modelName, color, hasRoomForWheelchairs, drivers = [] } = props.vehicle;

  return (
    <ListRowWrapper href={`/vehicles/${id}`}>
      <Row>
        <InfoBold>{licensePlate || '-'}</InfoBold>
        <Info>{formatName(drivers[0]) || '-'}</Info>
        <InfoWrapper>
          <Info>
            <Bus /> {[brandName, modelName, color].filter(Boolean).join(' / ')}
          </Info>
          {hasRoomForWheelchairs && (
            <Info>
              <Wheelchair /> für Rollstuhltransport
            </Info>
          )}
        </InfoWrapper>

        <ControlsWrapper>
          <OptionsWrapper>
            <ActiveLabel active state={'Einsatzbereit'} />
          </OptionsWrapper>

          <OptionsWrapper>
            <ChevronDown invert={false} />
            <ContextMenu id={id} onUpdate={props.onUpdate} />
          </OptionsWrapper>
        </ControlsWrapper>
      </Row>
    </ListRowWrapper>
  );
};
