import PassengersTrack from '../PassengersTrack';
import PassengerPicker from '../../PassengerPicker';
import { TourplanAvailablePassengerView } from '../../../../content/customers/tourplan/tiles/TourplanPassengerView';
import { useNavigate } from '../../../../../hooks/useNavigate';
import { IPassenger } from '../../../../../api/models/Passenger';
import { ICustomer } from '../../../../../api/models/Customer';
import { IScheduledTour } from '../../../../../api/models/ScheduledTour';
import { IPickupDate } from '../../../../../api/models/PickupDate';
import { getCurrentDayDate } from '../../../../../utils/dateUtils';
import { useDragDropDataProvider } from '../../../../content/customers/tourplan/DragDropContext';

interface ITourDetailPassengersListProps {
  passengers: IPassenger[];
  editable?: boolean;
  onEdit?: (passenger: IPassenger) => void;
  onRemove?: (passenger: IPassenger) => void;
  direction: string | null;
  customer?: ICustomer;
  departureDate: IPickupDate | null;
  arrivalDate: IPickupDate | null;
  timestamp: string;
  schedule: IScheduledTour;
  setPassengerInAdditionState: (passenger: IPassenger) => void;
}

export function TourDetailPassengersList({
  setPassengerInAdditionState,
  customer,
  departureDate,
  arrivalDate,
  timestamp,
  schedule,
  direction,
  passengers,
  editable,
  onEdit,
  onRemove,
}: ITourDetailPassengersListProps) {
  const timeAtCustomer = ((direction === 'return' ? departureDate : arrivalDate) || {})[new Date(timestamp).getDay()];

  const navigate = useNavigate(true);
  const { dataSource } = useDragDropDataProvider();

  return (
    <PassengersTrack
      direction={direction}
      customer={customer}
      passengers={passengers || []}
      customerTime={timeAtCustomer && new Date(timeAtCustomer).toLocaleTimeString(undefined, { timeStyle: 'short' })}
      renderPassenger={(passenger, index) => (
        <TourplanAvailablePassengerView
          editable={editable}
          tour={schedule}
          passenger={passenger}
          position={index + 1}
          showPickupTimes
          showInfoInline
          selectedDayOfWeek={timestamp}
          onPickupDateChange={
            editable
              ? (pickupDate) => {
                  const dayOfWeek = getCurrentDayDate(timestamp).getDay().toString();
                  const customerPickupDates = schedule.direction === 'return' ? schedule.departureDate : schedule.arrivalDate;
                  const daysWithSameCustomerTime = Object.keys(customerPickupDates || {}).filter((key) => {
                    return key.toString() !== dayOfWeek && (customerPickupDates || {})[key] === (customerPickupDates || {})[dayOfWeek];
                  });

                  // update passenger pickup date
                  dataSource.update(0, {
                    ...schedule,
                    passengers: (schedule.passengers || [])
                      .map((item) =>
                        item !== passenger
                          ? item
                          : {
                              ...passenger,
                              ScheduledTourPassenger: {
                                ...(passenger.ScheduledTourPassenger || {}),
                                pickupDate: {
                                  ...pickupDate,
                                  ...daysWithSameCustomerTime.reduce((res, key) => {
                                    if (!pickupDate[key]) {
                                      return {
                                        ...res,
                                        [key]: pickupDate[dayOfWeek],
                                      };
                                    }
                                    return res;
                                  }, {}),
                                },
                              },
                            },
                      )
                      .sort((a, b) => {
                        // sort passengers by pickup time
                        const pickupTimeA = a.ScheduledTourPassenger?.pickupDate?.[dayOfWeek] || null;
                        const pickupTimeB = b.ScheduledTourPassenger?.pickupDate?.[dayOfWeek] || null;

                        if (pickupTimeA && pickupTimeB) {
                          return pickupTimeA.localeCompare(pickupTimeB);
                        } else if (pickupTimeA && !pickupTimeB) {
                          return schedule.direction === 'return' ? 1 : -1; // null times first on return, last on outwards tours
                        } else if (!pickupTimeA && pickupTimeB) {
                          return schedule.direction === 'return' ? -1 : 1; // null times first on return, last on outwards tours
                        }
                        return 0;
                      })
                      .map((passenger, index) => ({
                        // map index on position
                        ...passenger,
                        ScheduledTourPassenger: {
                          ...passenger.ScheduledTourPassenger,
                          position: index,
                        },
                      })),
                  } as IScheduledTour);
                }
              : undefined
          }
          onShowClick={() => navigate(`/passengers/${passenger.id}`)}
          onAbsentClick={() => {
            const dayOfWeek = getCurrentDayDate(timestamp).getDay().toString();

            // update passenger pickup date
            dataSource.update(0, {
              ...schedule,
              passengers: (schedule.passengers || [])
                .map((item) => {
                  if (item !== passenger) {
                    return item;
                  }
                  const newItem = {
                    ...passenger,
                    ScheduledTourPassenger: {
                      ...(passenger.ScheduledTourPassenger || {}),
                      pickupDate: {
                        ...passenger.ScheduledTourPassenger!.pickupDate,
                        [dayOfWeek]: 'absent',
                      },
                    },
                  };
                  return newItem;
                })
                .sort((a, b) => {
                  // sort passengers by pickup time
                  const pickupTimeA = a.ScheduledTourPassenger?.pickupDate?.[dayOfWeek] || null;
                  const pickupTimeB = b.ScheduledTourPassenger?.pickupDate?.[dayOfWeek] || null;

                  if (pickupTimeA && pickupTimeB) {
                    return pickupTimeA.localeCompare(pickupTimeB);
                  } else if (pickupTimeA && !pickupTimeB) {
                    return schedule.direction === 'return' ? 1 : -1; // null times first on return, last on outwards tours
                  } else if (!pickupTimeA && pickupTimeB) {
                    return schedule.direction === 'return' ? -1 : 1; // null times first on return, last on outwards tours
                  }
                  return 0;
                })
                .map((passenger, index) => ({
                  // map index on position
                  ...passenger,
                  ScheduledTourPassenger: {
                    ...passenger.ScheduledTourPassenger,
                    position: index,
                  },
                })),
            } as IScheduledTour);
          }}
          onEditClick={
            onEdit && editable
              ? () => {
                  onEdit(passenger);
                }
              : undefined
          }
          onRemoveClick={
            editable
              ? () => {
                  // remove passenger from data source
                  dataSource.update(0, {
                    ...schedule,
                    passengers: (schedule.passengers || []).filter(({ id }) => id?.toString() !== passenger.id?.toString()),
                  } as IScheduledTour);
                }
              : undefined
          }
        />
      )}
    >
      {customer && editable && (
        <PassengerPicker
          customerId={customer.id}
          organizationId={customer.customerOrganizationId || undefined}
          unavailablePassengers={(passengers || []).map(({ id }) => id)}
          onSelect={(passenger) => {
            passenger && setPassengerInAdditionState(passenger);
          }}
        />
      )}
    </PassengersTrack>
  );
}
