import React from 'react';
import { TItemsInAdditionState, useDragDropItemsMoveState, useDropOverItems, useMoveItems } from '../DragDropContext';
import { ButtonPrimary } from '../../../../common/inputs/Button';
import { Plus } from '../../../../icons/Plus';
import { TourDragItem, TourDragItemContent } from './TourplanTourTile';
import styled from 'styled-components';

const DropOverItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

export const DropOverItemsBaseView = React.memo((props: { tour?: Partial<any> }) => {
  const { tour } = props;
  const dropOverItems = useDropOverItems(tour || 'revert');

  return (
    <TourDragItem dragging={dropOverItems.length > 0} empty>
      <TourDragItemContent>
        <DropOverItemContent>
          <Plus /> {dropOverItems.length > 0 ? `${dropOverItems.length} hinzufügen` : 'hier reinziehen'}
        </DropOverItemContent>
      </TourDragItemContent>
    </TourDragItem>
  );
});

export const DropOverItems = React.memo(
  (props: { tour?: Partial<any>; onDropListener?: (props: TItemsInAdditionState<any, any>) => void }) => {
    const { tour, onDropListener } = props;

    const [moveItemsState] = useDragDropItemsMoveState();
    const moveItems = useMoveItems({ onDropListener });

    if (moveItemsState !== null && moveItemsState.srcContainer !== tour) {
      return (
        <ButtonPrimary
          onClick={(e: Event) => {
            e.stopPropagation();
            moveItems(tour || 'revert');
          }}
        >
          {typeof tour === 'object' ? `In ${tour.name}` : 'Hierher'} verschieben
        </ButtonPrimary>
      );
    }

    return <DropOverItemsBaseView tour={tour} />;
  },
);
