import React from 'react';
import styled from 'styled-components';
import { ICustomerOrganization } from '../../../api/models/CustomerOrganization';
import { List, ListHead } from '../../common/atoms/List';
import { ContentPageHead } from '../../common/elements/ContentPageHead';
import { CustomerOrganizationListRow } from '../../common/elements/customer-organizations/CustomerOrganizationListRow';
import { ButtonSecondary } from '../../common/inputs/Button';
import { Plus } from '../../icons/Plus';
import { useCustomerOrganizationAPI } from '../../../api/controllers/CustomerOrganizationAPI';
import { useQuery } from '@tanstack/react-query';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const Wrapper = styled.section`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  --list-template: 2fr 1fr auto 1rem;
`;

const StyledListHead = styled(ListHead)`
  top: 10rem;
  margin-block-start: -2.5rem;
  padding-block-start: 2.5rem;
`;

export const CustomerOrganizationsList: React.FC = () => {
  const customerOrganizationAPI = useCustomerOrganizationAPI();
  const {
    data: customerOrganizations,
    status,
    error,
    isFetching,
  } = useQuery({
    queryKey: ['customerOrganizations'],
    queryFn: async () => {
      const { data } = await customerOrganizationAPI.getCustomerOrganizations().then((res) => res.data);

      return data as ICustomerOrganization[];
    },
  });

  const removeItem = (id: string) => {};

  return (
    <Wrapper>
      <ContentPageHead title={`Organisationen`}>
        <FlexWrapper>
          <ButtonSecondary href={'/customer-organizations/add'}>
            <Plus /> Organisation anlegen
          </ButtonSecondary>
        </FlexWrapper>
      </ContentPageHead>

      <List>
        <StyledListHead>
          <div>Name</div>
          <div>Beschreibung</div>
          <div>Anzahl Einrichtungen</div>
          <div></div>
        </StyledListHead>
        {customerOrganizations !== undefined &&
          customerOrganizations?.map((customerOrganization) => (
            <CustomerOrganizationListRow
              key={customerOrganization.id}
              customerOrganization={customerOrganization}
              onUpdate={() => removeItem(customerOrganization.id)}
            />
          ))}
      </List>
      {isFetching || (status === 'pending' && <LoadingSpinner />)}
      {error && <div>Es ist ein Fehler aufgetreten</div>}
    </Wrapper>
  );
};
