export const Alert = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1091_18619)">
      <mask id="mask0_1091_18619" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <ellipse cx="12.0002" cy="12" rx="12.0002" ry="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1091_18619)">
        <rect x="-1.09082" y="-1.09091" width="26.1822" height="26.1818" fill="currentColor" />
      </g>
      <path
        d="M11.4474 6.06003C11.6217 5.98327 11.8101 5.94372 12.0006 5.94388C12.1909 5.94392 12.3791 5.98364 12.5532 6.06048C12.7273 6.13732 12.8835 6.24961 13.0118 6.39018C13.1401 6.53075 13.2377 6.69652 13.2983 6.87691C13.359 7.0573 13.3814 7.24835 13.3641 7.43788L12.8181 13.4469C12.7968 13.6489 12.7015 13.8359 12.5506 13.9718C12.3996 14.1078 12.2037 14.183 12.0006 14.183C11.7974 14.183 11.6015 14.1078 11.4505 13.9718C11.2996 13.8359 11.2043 13.6489 11.1831 13.4469L10.6356 7.43788C10.6182 7.24823 10.6407 7.05705 10.7014 6.87656C10.7622 6.69606 10.8599 6.53022 10.9883 6.38963C11.1168 6.24904 11.2732 6.13678 11.4474 6.06003Z"
        fill="white"
      />
      <path
        d="M13.3091 16.7476C13.3091 17.4703 12.7233 18.0561 12.0006 18.0561C11.278 18.0561 10.6921 17.4703 10.6921 16.7476C10.6921 16.025 11.278 15.4392 12.0006 15.4392C12.7233 15.4392 13.3091 16.025 13.3091 16.7476Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1091_18619">
        <rect width="24.0004" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
