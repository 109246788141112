export const Trash = (props: any) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.5655 14H4.76782C4.23424 14 3.79464 13.5811 3.76898 13.0481L3.33333 4H12L11.5644 13.0481C11.5387 13.5811 11.0991 14 10.5655 14Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 10.6667V6.66666M9.33333 6.66666V10.6667" stroke="currentColor" strokeLinecap="round" />
    <path d="M2 4H13.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.33333 3.66666V4H10V3.66666C10 3.11438 9.55228 2.66666 9 2.66666H6.33333C5.78105 2.66666 5.33333 3.11438 5.33333 3.66666Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
