import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Close } from '../../icons/Close';
import { Clickable } from './Clickable';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Link } from './Link';
import { Headline4 } from '../../../styles/FontStyles';

const Wrapper = styled.div.attrs(({ open = false }: any) => ({ tabIndex: open ? undefined : -1 }))<{ open: boolean }>`
  position: absolute;
  z-index: 5;
  bottom: 100%;
  left: 0;

  margin: 0 0 1.25rem;
  padding: 1.5rem;

  min-width: 17rem;

  background: ${Colors.white50};
  border-radius: 8px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);

  transition: opacity 150ms ease-out, visibility 150ms ease-out;

  ${({ open = false }) =>
    !open &&
    css`
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    `};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  svg {
    flex-shrink: 0;
  }
`;

const CloseIcon = styled(Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

interface IContextMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  hideCloseButton?: boolean;
  className?: string;
}

export const ContextMenu: FC<PropsWithChildren<IContextMenuProps>> = (props) => {
  const { open, setOpen, hideCloseButton = false, className, children } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [openState, setOpenState] = useState(false);

  useEffect(() => {
    let listener = (e: MouseEvent) => {
      if (!(e.target === wrapperRef.current || wrapperRef.current?.contains(e.target as Node))) {
        setOpen(false);
      }
    };

    if (open) {
      setTimeout(() => {
        document.addEventListener('click', listener, { passive: true });
      }, 100);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open) {
      setOpenState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {(open || openState) && (
        <Wrapper ref={wrapperRef} open={open && openState} className={className} onTransitionEnd={() => !open && setOpenState(false)}>
          {!hideCloseButton && (
            <Clickable
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
              }}
            >
              <CloseIcon />
            </Clickable>
          )}
          {children}
        </Wrapper>
      )}
    </>
  );
};

export const ContextMenuItem = styled(Link).attrs(({ href, onClick }: any) => (!href && onClick ? { as: 'button', type: 'button' } : {}))<
  { href?: string } & any
>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;

  ${Headline4};

  margin: -0.5rem 0;
  padding: 0.5rem 0;

  pointer-events: all;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContextMenuSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.grey500};
`;
