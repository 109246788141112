import { useAuthHeader } from '../../state/AuthState';
import axios from 'axios';

interface IGetBulkCancellationsProps {}

interface IGetBulkCancellationByIdProps {
  id: string;
}

export const useBulkCancellationAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const getBulkCancellations = async (_props: IGetBulkCancellationsProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/bulk-cancellations`, { ...authHeader });
    });
  };

  const getBulkCancellationById = async (props: IGetBulkCancellationByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/bulk-cancellations/${props.id}`, { ...authHeader });
    });
  };

  const undoBulkCancellationById = async (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/bulk-cancellations/${id}/undo`, { ...authHeader });
    });
  };

  return {
    getBulkCancellations,
    getBulkCancellationById,
    undoBulkCancellationById,
  };
};
