import { IEmployee } from '../../../api/models/Employee';
import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Employee } from '../../icons/Employee';
import { Body4 } from '../../../styles/FontStyles';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ContextMenu } from '../atoms/ContextMenu';
import { useSearchAPI } from '../../../api/controllers/SearchAPI';
import { SearchInput } from '../inputs/SearchInput';
import { LoadingSpinner } from '../loaders/LoadingSpinner';
import { Avatar } from '../atoms/Avatar';
import { Trash } from '../../icons/Trash';
import { useEmployeesAPI } from '../../../api/controllers/EmployeesAPI';
import { getCurrentDayDate } from '../../../utils/dateUtils';
import { BREAKPOINT_LG } from '../../../styles/Breakpoints';
import { Close } from '../../icons/Close';
import { Clickable } from '../atoms/Clickable';
import { formatName } from '../../../utils/nameUtils';

const Wrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  z-index: ${({ open }) => (open ? 2 : 0)};
`;

const AvatarWrapper = styled.div<{ empty: boolean; error: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;

  position: relative;
  z-index: 0;

  width: 2rem;
  height: 2rem;

  border-radius: 50%;

  cursor: ${({ onClick }) => (onClick !== undefined ? 'pointer' : 'auto')};

  ${({ empty }) =>
    empty &&
    css`
      color: ${Colors.grey500};
      border: 1px dashed currentColor;
    `};

  ${({ error }) =>
    error &&
    css`
      color: ${Colors.signalRed900};
      border: 1px dashed currentColor;
    `};
`;

const EmployeeIcon = styled(Employee)`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
`;

const TextWrapper = styled.div<{ error: boolean; driverNameLineThrough: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.125rem;

  ${Body4};

  em {
    color: ${Colors.grey600};
    font-style: normal;
  }

  ${({ error }) =>
    error &&
    css`
      color: ${Colors.signalRed900};

      em {
        color: currentColor;
      }
    `};

  ${({ driverNameLineThrough }) =>
    driverNameLineThrough &&
    css`
      text-decoration: line-through;
      text-decoration-color: ${Colors.signalRed900};
    `};
`;

const Popup = styled(ContextMenu).attrs({ hideCloseButton: true })`
  position: fixed;
  top: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0 auto;
  max-inline-size: 24rem;

  ${BREAKPOINT_LG} {
    position: absolute;
    top: 100%;
    bottom: auto;
    left: 0;
    right: auto;
    margin: 0.5rem 0 0;
    max-inline-size: none;
  }

  align-items: stretch;
  padding: 0.5rem;

  color: ${Colors.textDefault};
  cursor: auto;
`;

const CloseButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  & > :first-child {
    flex: 1;
  }

  ${BREAKPOINT_LG} {
    & > :not(:first-child) {
      display: none;
    }
  }
`;

const DriversList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75rem;

  margin: -0.5rem;
  padding: 0.5rem;

  overflow: auto;
  overflow-x: hidden;

  ${BREAKPOINT_LG} {
    max-height: 20rem;
  }
`;

const DriverItem = styled.div<{ active: boolean; showDivider?: boolean; unavailable?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  margin: -0.5rem;
  padding: 0.5rem;

  ${Body4};

  transition: background-color 150ms ease-out;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      background-color: ${Colors.grey400};
    }
  }

  ${({ active }) =>
    active &&
    css`
      & > :first-child {
        box-shadow: 0 0 0 2px ${Colors.secondary};
      }

      color: ${Colors.grey700};
      opacity: 0.85;
    `};

  ${({ showDivider = false }) =>
    showDivider &&
    css`
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset-inline: 0.5rem;
        inset-block: 100% 0;
        block-size: 1px;
        background-color: ${Colors.grey500};
      }
    `};

  ${({ unavailable }) =>
    unavailable &&
    css`
      & > :first-child {
        box-shadow: 0 0 0 2px ${Colors.grey700};
      }

      background-color: ${Colors.grey400};
      color: ${Colors.grey700};
      opacity: 0.4;

      &:hover {
        cursor: auto;
        background-color: ${Colors.grey400};
      }
    `};
`;

const TrashButton = styled.div.attrs({ role: 'button', tabIndex: 0 })`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};
  color: ${Colors.secondary};

  margin: -0.75rem;
  padding: 0.75rem;
  margin-inline-start: auto;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

interface IDriverPickerProps {
  title?: string;
  unavailableDrivers?: string[];
  driver?: IEmployee | null;
  onSelect?: (driver: IEmployee | null) => void;
  hasError?: boolean;
  driverNameLineThrough?: boolean;
  timestamp?: string;
  direction?: string;
}

export default function DriverPicker(props: PropsWithChildren<IDriverPickerProps>) {
  const {
    title,
    unavailableDrivers = [],
    driver,
    onSelect,
    hasError = false,
    driverNameLineThrough = false,
    timestamp,
    direction,
    children,
  } = props;

  const [popupOpen, setPopupOpen] = useState(false);

  const searchAPI = useSearchAPI();
  const employeesAPI = useEmployeesAPI();
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [employeeAvailabilities, setEmployeeAvailabilities] = useState<string[] | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (popupOpen) {
      setEmployees([]);
      searchAPI
        .searchEmployees({ query: searchQuery, regions: [], skills: [], page: { limit: 20, offset: 0 } })
        .then((res) => {
          const employeeData = res.data?.items || [];
          setEmployees(employeeData);

          // fetch availabilities if timestamp is provided
          if (timestamp) {
            setEmployeeAvailabilities(null);
            employeesAPI
              .getEmployeeAvailabilities({
                ids: employeeData.map((employee: any) => employee.id),
                timestamp: getCurrentDayDate(timestamp).toISOString(),
                direction,
              })
              .then((res) => {
                setEmployeeAvailabilities(res.data);
              })
              .catch(() => {});
          }
        })
        .catch(() => setEmployees([]));
    } else if (searchQuery !== '') {
      setSearchQuery('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, popupOpen]);

  const driversList = useMemo(() => {
    return employees
      ?.filter((employee) => driver?.id !== employee.id)
      .map((employee) => {
        const available = !unavailableDrivers.includes(employee.id);
        return (
          <DriverItem
            key={employee.id}
            {...(available
              ? {
                  tabIndex: 0,
                  role: 'button',
                  onClick: (e) => {
                    e.stopPropagation();
                    onSelect?.(employee);
                    setPopupOpen(false);
                  },
                }
              : {})}
            active={driver?.id === employee.id}
            unavailable={!available}
          >
            <Avatar
              firstName={employee.firstName}
              lastName={employee.lastName}
              size={'mini'}
              src={employee.image?.url}
              variation={employeeAvailabilities ? (employeeAvailabilities.includes(employee.id) ? 'active' : 'inactive') : 'none'}
              alwaysShowActiveState
            />
            <span>{formatName(employee)}</span>
          </DriverItem>
        );
      });
  }, [employees, driver, unavailableDrivers, onSelect, employeeAvailabilities]);

  return (
    <Wrapper open={popupOpen}>
      <AvatarWrapper
        {...(onSelect
          ? {
              tabIndex: 0,
              role: 'button',
              onClick: () => setPopupOpen(true),
            }
          : {})}
        empty={!driver}
        error={hasError}
      >
        {driver && <Avatar firstName={driver.firstName} lastName={driver.lastName} size={'mini'} src={driver.image?.url} />}
        {!driver && <EmployeeIcon />}
        {onSelect && (
          <Popup open={popupOpen} setOpen={setPopupOpen}>
            <CloseButtonRow>
              <SearchInput
                autoFocusExplicit
                placeholder={'nach Mitarbeitern suchen'}
                value={searchQuery}
                setValue={setSearchQuery}
                onReset={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSearchQuery('');
                }}
              />

              <Clickable
                onClick={(e: any) => {
                  e.stopPropagation();
                  setPopupOpen(false);
                }}
              >
                <Close />
              </Clickable>
            </CloseButtonRow>
            {driver && (
              <DriverItem
                tabIndex={0}
                role="button"
                active
                showDivider
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect?.(driver);
                  setPopupOpen(false);
                }}
              >
                <Avatar firstName={driver.firstName} lastName={driver.lastName} size={'mini'} src={driver.image?.url} />
                <span>{formatName(driver)}</span>
                <TrashButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelect?.(null);
                  }}
                >
                  entfernen <Trash />
                </TrashButton>
              </DriverItem>
            )}
            <DriversList>
              {employees === null && <LoadingSpinner />}
              {driversList}
            </DriversList>
          </Popup>
        )}
      </AvatarWrapper>
      <TextWrapper error={hasError} driverNameLineThrough={driverNameLineThrough}>
        {title && <b>{title}</b>}
        {driver && <span>{formatName(driver)}</span>}
        {!driver && <em>Person hinzufügen</em>}
      </TextWrapper>
      {children}
    </Wrapper>
  );
}
