import { useAuthHeader } from '../../state/AuthState';
import axios from 'axios';

interface ICreateBookingCompletionProps {
  timestamp: string;
  comment: string | null;
}

interface IGetBookingCompletionsProps {
  offset: number;
  limit: number;
}

interface IGetBookingCompletionByIdProps {
  id: string;
}

interface IUpdateBookingCompletionProps extends ICreateBookingCompletionProps {}

export const useBookingCompletionsAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const createBookingCompletion = (props: ICreateBookingCompletionProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/booking-completions`, props, { ...authHeader });
    });
  };

  const getBookingCompletions = (props: IGetBookingCompletionsProps) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams([
        ['offset', `${props.offset || 0}`],
        ['limit', `${props.limit || 25}`],
      ]).toString();
      return axios.get(`/api/booking-completions?${qs}`, { ...authHeader });
    });
  };

  const getBookingCompletionById = (props: IGetBookingCompletionByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/booking-completions/${props.id}`, { ...authHeader });
    });
  };

  const updateBookingCompletion = (id: string, props: IUpdateBookingCompletionProps) => {
    return checkAuth().then(() => {
      return axios.patch(`/api/booking-completions/${id}`, props, { ...authHeader });
    });
  };

  const deleteBookingCompletion = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/booking-completions/${id}`, { ...authHeader });
    });
  };

  const restoreBookingCompletion = (id: string) => {
    return checkAuth().then(() => {
      return axios.post(`/api/booking-completions/${id}/restore`, {}, { ...authHeader });
    });
  };

  const downloadEmployeeInvoices = (id: string) => {
    return checkAuth().then(() => {
      return axios.get(`/api/booking-completions/${id}/download/employees`, { ...authHeader, responseType: 'blob' });
    });
  };

  const downloadCustomerInvoices = (id: string) => {
    return checkAuth().then(() => {
      return axios.get(`/api/booking-completions/${id}/download/customers`, { ...authHeader, responseType: 'blob' });
    });
  };

  return {
    createBookingCompletion,
    getBookingCompletions,
    getBookingCompletionById,
    updateBookingCompletion,
    deleteBookingCompletion,
    restoreBookingCompletion,
    downloadEmployeeInvoices,
    downloadCustomerInvoices,
  };
};
