// General
import styled from 'styled-components';
import React from 'react';

// Components
import { EmployeeDetails } from '../../content/employees/EmployeeDetails';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const EmployeeDetail = () => {
  return (
    <Wrapper>
      <EmployeeDetails />
    </Wrapper>
  );
};
