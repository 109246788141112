import { ICustomerCategory } from './CustomerCategory';
import { IAPIModelBase } from './index';

export interface ICalendarException extends IAPIModelBase {
  name: string;
  beginDate: Date;
  endDate: Date;
  customerCategory?: ICustomerCategory;
  customerCategoryId?: string;
}


export const emptyCalendarException: ICalendarException = {
  id: '',
  name: '',
  beginDate: new Date(),
  endDate: new Date(),
  customerCategoryId: '',
  createdAt: '',
  updatedAt: ''
}
