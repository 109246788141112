import styled, { css } from 'styled-components';

export const EmptyTourFrame = styled.div<{ size: string; $withBorder?: boolean }>`
  block-size: ${({ size }) => size};
  flex-shrink: 0;

  ${({ $withBorder = true }) =>
    $withBorder &&
    css`
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.15);
    `};
`;
