import styled from 'styled-components';
import React from 'react';
import { VehiclesList } from '../../content/vehicles/VehiclesList';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const Vehicles = () => {
  return (
    <Wrapper>
      <VehiclesList />
    </Wrapper>
  );
};
