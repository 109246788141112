import styled, { css } from 'styled-components';
import { Body4, Body4Bold } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';
import { MouseEventHandler } from 'react';
import { Link } from '../atoms/Link';

export const TourLabel = styled.div.attrs(({ onClick = null, href = null }: any) => ({
  ...(href ? { as: Link, target: '_blank' } : {}),
  ...(onClick && !href ? { as: 'button', type: 'button' } : {}),
}))<{ date?: Date; onClick?: MouseEventHandler; href?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  width: max-content;
  height: 1.75rem;
  padding: 0.25rem 0.5rem;

  background: ${Colors.grey400};
  border-radius: 2px;
  position: relative;

  ${Body4};
  color: ${Colors.textDefault};

  &::before {
    content: '${({ date = null }) => date?.toLocaleDateString('de', { weekday: 'short' }) || ''}';
    display: ${({ date = null }) => (!!date ? 'flex' : 'none')};
    ${Body4Bold};
  }

  &:hover {
    text-decoration: inherit;
  }

  ${({ onClick = null, href = null }) =>
    (onClick || href) &&
    css`
      &:hover {
        background: ${Colors.secondary};
        color: ${Colors.textInverted};
      }
    `};
`;

export const OrderTourLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  overflow: hidden;

  background-color: ${Colors.textDefault};
  color: ${Colors.textInverted};
  line-height: 1;
`;
