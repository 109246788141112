import React from 'react';
import { IScheduledTourException } from '../../../../../api/models/ScheduledTour';
import { RadioButton } from '../../../atoms/RadioButton';
import ExceptionListItem, { ExceptionListItemWrapper } from './ExceptionListItem';
import { RadioButtonWrapper } from './ScheduledExceptionRadioButton';

interface ScheduledExceptionsProps {
  activeExceptionId?: string;
  selectedException?: IScheduledTourException;
  forceOriginalTour: boolean;
  setForceOriginalTour: (forceOriginalTour: boolean) => void;
  exceptionsForDate: IScheduledTourException[];
  onExceptionClick: (exception: IScheduledTourException | undefined) => void;
  isBlockedByBookingCompletion: boolean;
}

const ScheduledExceptions: React.FC<ScheduledExceptionsProps> = (props) => {
  const {
    isBlockedByBookingCompletion,
    exceptionsForDate,
    forceOriginalTour,
    onExceptionClick,
    setForceOriginalTour,
    activeExceptionId,
    selectedException,
  } = props;

  const isCancelled = exceptionsForDate[0]?.reason === 'cancelled' || false;
  const isTourSplit = exceptionsForDate[0]?.reason === 'tour_split' || false;

  const handleOnBaseTourClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceOriginalTour(true);
    onExceptionClick(undefined);
  };

  return (
    <ul>
      {exceptionsForDate.map((exception, index) => {
        return (
          <ExceptionListItem
            key={exception.id}
            active={index === 0}
            isBlockedByBookingCompletion={isBlockedByBookingCompletion}
            exception={exception}
            activeExceptionId={activeExceptionId}
            selectedException={selectedException}
            onExceptionClick={onExceptionClick}
          />
        );
      })}
      {(((isCancelled || isTourSplit) && exceptionsForDate.length > 1) || !isCancelled) && (
        <ExceptionListItemWrapper>
          <RadioButtonWrapper>
            <RadioButton
              value=""
              checked={forceOriginalTour ? '' : undefined}
              name="exception"
              onChange={(event) => handleOnBaseTourClick(event)}
            >
              Originaltour
            </RadioButton>
          </RadioButtonWrapper>
        </ExceptionListItemWrapper>
      )}
    </ul>
  );
};

export default ScheduledExceptions;
