import { TooltipCentered } from '../atoms/Tooltip';
import styled, { css } from 'styled-components';
import { PropsWithChildren, ReactNode, useState } from 'react';

const Wrapper = styled.div<{ tooltipVisible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  z-index: ${({ tooltipVisible }) => (tooltipVisible ? 5 : 0)};
`;

const Tooltip = styled(TooltipCentered)<{ align?: TAlignment }>`
  padding: 0.5rem;
  padding-inline: 1rem;
  inline-size: max-content;
  max-inline-size: 17.5rem;

  ${({ align }) =>
    align === 'right' &&
    css`
      right: 0;
      transform: translate(0.5rem, -0.25rem);

      &::before {
        right: 0;
        transform: translateX(-0.825rem);
      }
    `};

  ${({ align }) =>
    align === 'top' &&
    css`
      top: auto;
      bottom: 100%;
      transform: translate(50%, -0.5rem);

      &::before {
        transform: rotateX(180deg) translate(50%, -0.5rem);
        top: auto;
        bottom: 0;
      }
    `};
`;

type TAlignment = 'right' | 'top';

interface IHoverTooltipProps {
  renderTooltipContent: () => ReactNode;
  align?: TAlignment;
}
export default function HoverTooltip(props: PropsWithChildren<IHoverTooltipProps>) {
  const { renderTooltipContent, align, children } = props;

  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <Wrapper tooltipVisible={tooltipVisible} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
      {children}
      <Tooltip open={tooltipVisible} setOpen={setTooltipVisible} hideCloseButton align={align}>
        {renderTooltipContent()}
      </Tooltip>
    </Wrapper>
  );
}
