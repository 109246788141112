import React from 'react';
import styled from 'styled-components';

import { IScheduledTour } from '../../../../../api/models/ScheduledTour';
import { BREAKPOINT_XL } from '../../../../../styles/Breakpoints';
import { Colors } from '../../../../../styles/Colors';
import { Alert } from '../../../../icons/Alert';
import { DateMonthInput } from '../../../inputs/DateMonthInput';
import { DayOfMonthInput } from '../../../inputs/DayOfMonthInput';
import { getCurrentDayDate } from '../../../../../utils/dateUtils';
import { ITourConflictsResult } from '../../../../../state/TourConflictsState';

const AlertIcon = styled(Alert)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
`;

const DateInputWrapper = styled.div`
  display: none;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  & > :first-child {
    align-self: flex-end;
  }

  ${BREAKPOINT_XL} {
    display: flex;
  }
`;

const DayActiveMarker = styled.div`
  display: flex;
  width: 0.375rem;
  height: 0.375rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${Colors.secondary};
`;

const MarkerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 0.25rem;

  & > svg {
    width: 1rem;
    height: 1rem;
    margin-block: -0.3125rem;
    margin-inline: -0.125rem;
  }
`;

interface ITourDetailInputProps {
  timestamp: string;
  proceedWithoutSaving: (onProceed: () => void) => void;
  setTimestamp: (value: string) => void;
  tour: IScheduledTour;
  id: string;
  scheduleConflicts: ITourConflictsResult | undefined;
  startDate: string;
  endDate: string;
  daysOfWeek: string;
}

const DateInput: React.FC<ITourDetailInputProps> = (props) => {
  const { timestamp, proceedWithoutSaving, setTimestamp, tour, scheduleConflicts, id, startDate, endDate, daysOfWeek } = props;
  return (
    <DateInputWrapper>
      <DateMonthInput
        value={timestamp}
        setValue={(value) => {
          proceedWithoutSaving(() => {
            setTimestamp(value);
          });
        }}
      />
      <DayOfMonthInput
        value={timestamp}
        setValue={(value) => {
          proceedWithoutSaving(() => {
            setTimestamp(value);
          });
        }}
        renderMarker={(value) => {
          const day = getCurrentDayDate(value);
          const isInRange = ({ startDate, endDate }: any) =>
            getCurrentDayDate(startDate) <= day && (!endDate || getCurrentDayDate(endDate) >= day);
          // get tour id for current day
          const tourIdForDay =
            tour.exceptions?.filter(isInRange)?.sort((a, b) => b.updatedAt?.localeCompare(a.updatedAt))?.[0]?.replacementScheduledTourId ||
            tour.id;

          const dayIsInRange = tourIdForDay === id ? isInRange({ startDate, endDate }) : isInRange(tour);
          const dayOfWeekIsMatching = (tourIdForDay === id ? daysOfWeek : tour.daysOfWeek).split(',').includes(day.getDay().toString());

          const hasConflict = scheduleConflicts?.[day.toString()] && scheduleConflicts?.conflicts.length > 0;

          return (
            <MarkerWrapper>
              {dayIsInRange && dayOfWeekIsMatching && <DayActiveMarker />}
              {hasConflict && <AlertIcon />}
            </MarkerWrapper>
          );
        }}
      />
    </DateInputWrapper>
  );
};
export default DateInput;
