import { css } from 'styled-components';

export const Colors = {
  primary: 'var(--color-primary-900)',
  primaryLight: 'var(--color-primary-200)',

  secondary: 'var(--color-secondary-900)',
  secondaryLight: 'var(--color-secondary-200)',

  background: 'var(--color-grey-300)',

  textDefault: 'var(--color-text-default)',
  textInverted: 'var(--color-text-inverted)',
  textInactive: 'var(--color-text-inactive)',

  grey900: 'var(--color-grey-900)',
  grey800: 'var(--color-grey-800)',
  grey700: 'var(--color-grey-700)',
  grey600: 'var(--color-grey-600)',
  grey500: 'var(--color-grey-500)',
  grey400: 'var(--color-grey-400)',
  grey300: 'var(--color-grey-300)',

  white50: 'var(--color-white-50)',
  black900: 'var(--color-black-900)',

  signalRed900: 'var(--color-signal-red-900)',
  signalRed800: 'var(--color-signal-red-900)',
  signalRed300: 'var(--color-signal-red-300)',
  signalGreen900: 'var(--color-signal-green-900)',
  signalGreen800: 'var(--color-signal-green-800)',
  signalYellow900: 'var(--color-signal-yellow-900)',
  signalYellow800: 'var(--color-signal-yellow-800)',
  signalOrange: 'var(--color-signal-orange)',
};

export const ColorTheme = css`
  :root {
    // base colors
    --color-grey-900: #1a1a1a;
    --color-grey-800: #373737;
    --color-grey-700: #666666;
    --color-grey-600: #adadad;
    --color-grey-500: #d9d9d9;
    --color-grey-400: #f0f0f0;
    --color-grey-300: #fafafa;

    --color-white-50: #ffffff;

    --color-black-900: #0f0f0f;

    // signal colors
    --color-signal-red-900: #b40808;
    --color-signal-red-800: #d92a2a;
    --color-signal-red-300: #fff3f3;
    --color-signal-yellow-900: #ffe713;
    --color-signal-yellow-800: #fefbf3;
    --color-signal-orange: #ffa800;
    --color-signal-green-900: #276545;
    --color-signal-green-800: #f7fcf9;

    // theme colors
    --color-primary-900: #ffb82f;
    --color-primary-200: #fff3db;
    --color-secondary-900: #1857a9;
    --color-secondary-200: #e8f7ff;

    // font colors
    --color-text-default: var(--color-black-900);
    --color-text-inverted: var(--color-white-50);
    --color-text-inactive: var(--color-secondary-600);
  }
`;
