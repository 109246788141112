import styled, { css, keyframes } from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Clickable } from '../atoms/Clickable';
import { Body3 } from '../../../styles/FontStyles';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { BREAKPOINT_MD, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { ChevronDown } from '../../icons/ChevronDown';
import { Breadcrumb } from './Breadcrumb';
import { TabStatusBar } from './TabStatusBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMatchBreakpoint } from '../../../hooks/useMatchBreakpoint';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;
`;

const BreadcrumbStyles = css`
  height: 3rem;
  margin-block-start: -0.75rem;
  width: calc(100% - 3rem);

  ${BREAKPOINT_MD} {
    width: auto;
  }
`;

const DefaultBreadcrumb = styled(Breadcrumb)<{ currentItem: number }>`
  ${BreadcrumbStyles};
  display: ${({ currentItem }) => (currentItem === -1 ? 'flex' : 'none')};

  ${BREAKPOINT_MD} {
    display: flex;
  }
`;

const SubPageBreadcrumb = styled(Breadcrumb)<{ currentItem: number }>`
  ${BreadcrumbStyles};
  display: ${({ currentItem }) => (currentItem === -1 ? 'none' : 'flex')};

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const HeaderWrapper = styled.div<{ currentItem: number }>`
  display: ${({ currentItem }) => (currentItem === -1 ? 'initial' : 'none')};

  ${BREAKPOINT_MD} {
    display: contents;
  }
`;

const ItemWrapper = styled.div<{ currentItem: number }>`
  display: ${({ currentItem }) => (currentItem === -1 ? 'flex' : 'none')};
  flex-direction: column;
  gap: 0.5rem;
`;

const Item = styled(Clickable)<{ active: boolean }>`
  ${Body3};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);

  background: ${Colors.white50};
  border-radius: 1.5rem;
  padding-inline: 1rem;
  padding-block: 0.75rem;

  gap: 0.5rem;
  color: ${({ active }) => (active ? Colors.secondary : Colors.textDefault)};
  transition: color 300ms ease-out;
`;

const ChevronIcon = styled(ChevronDown)`
  transform: rotate(-90deg);

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const Appear = keyframes`
  from {
    opacity: 0;
  }
`;

const ContentWrapper = styled.div<{ currentItem: number }>`
  display: ${({ currentItem }) => (currentItem === -1 ? 'none' : 'contents')};

  ${BREAKPOINT_MD} {
    display: contents;
  }

  & > * {
    animation: ${Appear} 450ms ease-out;
  }
`;

const BreadcrumbWrapper = styled.div<{ withTopRightContent: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ withTopRightContent }) => (withTopRightContent ? 'center' : 'flex-start')};
`;

interface ITabStatusBarLayoutProps {
  breadcrumb: { title: string; href: string };
  subPageBreadcrumb?: { title: string; href: string };
  header?: ReactNode;
  topRightContent?: ReactNode;
  items: {
    title: string;
    content: ReactNode | string;
    hash: string;
  }[];
}

export const TabStatusBarLayout: FC<ITabStatusBarLayoutProps> = (props) => {
  const { header, items, breadcrumb, subPageBreadcrumb, topRightContent } = props;
  const [currentItem, setCurrentItem] = useState<number>(-1);
  const isDesktop = useMatchBreakpoint(DIMEN_BREAKPOINT_MD);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentItem >= 0) {
      const { hash } = (items || [])[currentItem] || {};
      navigate(hash, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {
    const itemIndex = (items || []).findIndex(({ hash }) => hash === location.hash);
    if (itemIndex > 0) {
      setCurrentItem(itemIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (items.length === 0) {
    return <Item active={false}>Du besitzt gerade keine Rechte</Item>;
  }

  return (
    <Wrapper>
      {subPageBreadcrumb && (
        <BreadcrumbWrapper withTopRightContent={!!topRightContent}>
          <div>
            <DefaultBreadcrumb currentItem={currentItem} items={[breadcrumb]} />
            <SubPageBreadcrumb
              currentItem={currentItem}
              items={[{ ...subPageBreadcrumb, onClick: () => setCurrentItem(-1) }]}
              onBackButtonClick={() => setCurrentItem(-1)}
            />
          </div>
          <div>{topRightContent}</div>
        </BreadcrumbWrapper>
      )}

      {header && <HeaderWrapper currentItem={currentItem}>{header}</HeaderWrapper>}

      {isDesktop !== null && (
        <>
          {isDesktop && (
            <TabStatusBar
              currentItem={currentItem === -1 ? 0 : currentItem}
              onCurrentItemChange={setCurrentItem}
              items={items.map((item) => item.title)}
            />
          )}
          {!isDesktop && (
            <ItemWrapper currentItem={currentItem}>
              {items.map(({ title, content }, index) => (
                <Item key={index} active={index === currentItem} onClick={() => setCurrentItem(index)}>
                  {title} <ChevronIcon />
                </Item>
              ))}
            </ItemWrapper>
          )}
        </>
      )}

      <ContentWrapper currentItem={currentItem}>{currentItem === -1 ? items[0].content : items[currentItem].content}</ContentWrapper>
    </Wrapper>
  );
};
