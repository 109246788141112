import styled from 'styled-components';
import { FC } from 'react';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Colors } from '../../../styles/Colors';
import { Clickable } from '../atoms/Clickable';
import React from 'react';

const Wrapper = styled(Clickable)<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background: ${({ collapsed }) => (!collapsed ? 'transparent' : Colors.grey500)};
  transition: background-color var(--animation-speed) ease-out;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const Line = styled.span<{ collapsed: boolean }>`
  top: 50%;
  left: 50%;
  display: block;
  height: 2px;
  width: 1rem;
  background: ${Colors.black900};

  &:nth-child(1) {
    width: ${({ collapsed }) => (!collapsed ? '1.375rem' : '1rem')};
    transform: ${({ collapsed }) => (!collapsed ? 'rotate(45deg) translate(.25rem, .25rem)' : 'none')};
    transition: transform var(--animation-speed) linear, width var(--animation-speed) linear;
    transition-delay: ${({ collapsed }) => (!collapsed ? 'calc(var(--animation-speed) / 2) ' : '0s')};
  }

  &:nth-child(2) {
    width: ${({ collapsed }) => (!collapsed ? '0' : '1rem')};
    transition: width var(--animation-speed) linear;
    transition-delay: ${({ collapsed }) => (!collapsed ? '0s' : 'calc(var(--animation-speed) / 2) ')};
  }

  &:nth-child(3) {
    width: ${({ collapsed }) => (!collapsed ? '1.375rem' : '1rem')};
    transform: ${({ collapsed }) => (!collapsed ? 'rotate(-45deg) translate(.25rem, -.25rem)' : 'none')};
    transition: transform var(--animation-speed) linear, width var(--animation-speed) linear;
    transition-delay: ${({ collapsed }) => (!collapsed ? 'calc(var(--animation-speed) / 2) ' : '0s')};
  }
`;

interface BurgerMenuButtonProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const BurgerMenuButton: FC<BurgerMenuButtonProps> = ({ collapsed, setCollapsed }) => (
  <Wrapper collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
    <Line collapsed={collapsed} />
    <Line collapsed={collapsed} />
    <Line collapsed={collapsed} />
  </Wrapper>
);

export default BurgerMenuButton;
