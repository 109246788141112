import styled from 'styled-components';
import { FC } from 'react';
import { DropdownLabel, ErrorLabel } from './Dropdown';
import { Calendar } from './Calendar';
import { useFormContext } from 'react-hook-form';
import { useFieldError, useFieldProps } from './Field';
import { getCurrentDayDate } from '../../../utils/dateUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

interface DateInputProps {
  label?: string;
  placeholder?: string;
  name: string;
  optional?: boolean;
  className?: string;
  plain?: boolean;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  selectType?: 'start' | 'end';
  disabled?: boolean;
}

export const DateInput: FC<DateInputProps> = (props) => {
  const {
    label,
    placeholder = 'TT.MM.JJJJ',
    className,
    name,
    optional = false,
    plain = false,
    startDate,
    endDate,
    minDate,
    maxDate,
    selectType,
    disabled = false,
  } = props;
  const formContext = useFormContext();
  // eslint-disable-next-line
  const fieldProps = useFieldProps(name, optional); // required for validation --> DON'T DELETE
  const error = useFieldError(name);

  const fieldValue = formContext.watch(name);
  const setFieldValue = (value: Date | null) => formContext.setValue(name, value ? getCurrentDayDate(value) : null);

  return (
    <Wrapper className={className}>
      {label && <DropdownLabel>{label}</DropdownLabel>}
      <Calendar
        title={placeholder}
        value={(fieldValue && new Date(fieldValue)) || null}
        setValue={(date) => {
          setFieldValue(date ? new Date(date as Date) : null);
        }}
        dropdownStyling={!plain}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        selectType={selectType}
        disabled={disabled}
      />
      <ErrorLabel>{error?.message}</ErrorLabel>
    </Wrapper>
  );
};
