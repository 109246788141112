export const Change = (props: any) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5 16.5L16.5 14M16.5 14L14.5 11.5M16.5 14H9.5M7.5 5.5L5.5 8M5.5 8L7.5 10.5M5.5 8H12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
