// General
import { FC, useState } from 'react';
import styled from 'styled-components';
import { useFieldArray, useForm } from 'react-hook-form';

// Components
import { Card, CardColumn, CardRow, CardRowHeadline, CardsWrapper, HighlightLink } from '../../atoms/Card';
import { Clickable } from '../../atoms/Clickable';
import { ButtonPrimary, ButtonSecondary } from '../../inputs/Button';
import { TextInput } from '../../inputs/TextInput';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';

// Types
import { ICustomer } from '../../../../api/models/Customer';

// Utils
import { getTelLink } from '../../../../utils/phoneUtils';
import { useCustomersAPI } from '../../../../api/controllers/CustomersAPI';

// Styles
import { Body3Bold, Body4 } from '../../../../styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../../styles/Breakpoints';

// Icons
import { Edit } from '../../../icons/Edit';
import { Plus } from '../../../icons/Plus';
import { Form } from '../../inputs/Form';
import { formatName } from '../../../../utils/nameUtils';

const OptionsWrapper = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    min-block-size: 1.5rem;
  }
`;

const Button = styled(Clickable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};

  svg {
    block-size: 1.5rem;
    inline-size: 1.5rem;
  }
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-inline-start: 2.5rem;
  margin-block-start: -2rem;

  ${BREAKPOINT_MD} {
    margin: 0;
  }
`;

const EditTitle = styled.h4`
  ${Body3Bold};
  inline-size: 8.75rem;
  flex-shrink: 0;
`;

const Title = styled.h4`
  ${Body3Bold};
  inline-size: 8.75rem;
  flex-shrink: 0;
  margin-block-end: 1.5rem;

  ${BREAKPOINT_MD} {
    margin-block-start: -1.5rem;
    margin-block-end: 0;
  }

  ${BREAKPOINT_XL} {
    margin-block-start: 0;
  }
`;

const CTASecondary = styled(ButtonSecondary)`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    align-self: flex-end;
  }
`;

const EditLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  align-items: flex-start;

  ${BREAKPOINT_LG} {
    grid-template-columns: repeat(2, 1fr);

    & > *:first-child,
    & > *:last-child {
      grid-column: 1 / -1;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 1.5rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const CustomerDetailsContacts: FC<{ customer: ICustomer; updateHook: () => void }> = (props) => {
  const { customer, updateHook } = props;

  const [editIndex, setEditIndex] = useState<number | null>(null);

  const customersAPI = useCustomersAPI();
  const [isSubmitting, setSubmitting] = useState(false);

  const form = useForm({
    defaultValues: {
      displayName: customer?.displayName || '',
      internalId: customer?.internalId || '',
      type: customer?.type || '',
      debtorNumber: customer?.debtorNumber || '',
      address: {
        addressLine1: customer?.address?.addressLine1 || '',
        addressLine2: customer?.address?.addressLine2 || '',
        zipCode: customer?.address?.zipCode || '',
        city: customer?.address?.city || '',
        country: customer?.address?.country || 'de',
      },
      region: customer?.region || '',
      comment: customer?.comment || '',
      contactPersons: (customer?.contactPersons || [])
        .map(({ formattedPhoneNumber, ...item }) => ({
          ...item,
          escapedPhoneNumber: item.phoneNumber,
          phoneNumber: formattedPhoneNumber,
        }))
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
    },
  });

  const contacts = useFieldArray({ name: 'contactPersons' as never, control: form.control, keyName: 'form-id' });

  return (
    <CardsWrapper>
      <CTASecondary
        onClick={async () => {
          setEditIndex(contacts.fields.length);
          contacts.append({ firstName: '', lastName: '', emailAddress: '', phoneNumber: '' });
        }}
      >
        <Plus /> Ansprechpartner hinzufügen
      </CTASecondary>
      {contacts.fields.map((field, index) => {
        const contact = form.getValues(`contactPersons.${index}`);

        return (
          <Form
            form={form}
            onSubmit={(values) => {
              console.log('submit', values);

              setSubmitting(true);
              (customer?.id ? customersAPI.updateCustomer(customer?.id, values) : customersAPI.createCustomer(values))
                .then((res) => {
                  console.log(customer?.id ? 'update' : 'create', 'customer', res.data);
                  setEditIndex(null);
                  updateHook();
                })
                .catch((err) => {
                  // TODO add generic error message
                })
                .finally(() => setSubmitting(false));
            }}
          >
            <Card key={index}>
              {editIndex !== index && (
                <>
                  <OptionsWrapper>
                    {editIndex === null && (
                      <Button onClick={() => setEditIndex(index)}>
                        <Edit /> Bearbeiten
                      </Button>
                    )}
                  </OptionsWrapper>
                  <CardContentWrapper>
                    <Title>{index + 1}. Kontakt</Title>
                    <RowWrapper>
                      <CardRow>
                        <CardRowHeadline>Name</CardRowHeadline>
                        <CardColumn>{formatName(contact) || '-'}</CardColumn>
                      </CardRow>
                      <CardRow>
                        <CardRowHeadline>E-Mail-Adresse</CardRowHeadline>
                        <CardColumn>
                          <HighlightLink href={contact.emailAddress ? `mailto:${contact.emailAddress}` : ''}>
                            {contact.emailAddress || '-'}
                          </HighlightLink>
                        </CardColumn>
                      </CardRow>
                      <CardRow>
                        <CardRowHeadline>Telefonnummer</CardRowHeadline>
                        <CardColumn>
                          <HighlightLink href={getTelLink(contact.escapedPhoneNumber || contact.phoneNumber) || ''}>
                            {contact.phoneNumber || '-'}
                          </HighlightLink>
                        </CardColumn>
                      </CardRow>
                      <CardRow>
                        <CardRowHeadline>Rolle</CardRowHeadline>
                        <CardColumn>{contact.role || '-'}</CardColumn>
                      </CardRow>
                    </RowWrapper>
                  </CardContentWrapper>
                </>
              )}
              {editIndex === index && (
                <EditLayout>
                  <EditTitle>{index + 1}. Kontakt</EditTitle>
                  <TextInput
                    optional
                    optionalLabel
                    id={`contactPersons.${index}.firstName`}
                    placeholder={'Vorname'}
                    label={'Vorname'}
                    $plain
                  />
                  <TextInput
                    optional
                    optionalLabel
                    id={`contactPersons.${index}.lastName`}
                    placeholder={'Nachname'}
                    label={'Nachname'}
                    $plain
                  />
                  <TextInput
                    optional
                    id={`contactPersons.${index}.emailAddress`}
                    placeholder={'beispiel@gmail.com'}
                    label={'E-Mail-Adresse'}
                    $plain
                  />
                  <TextInput
                    optional
                    id={`contactPersons.${index}.phoneNumber`}
                    placeholder={'z.B. 01578-2983456'}
                    label={'Telefonnummer'}
                    $plain
                  />
                  <TextInput
                    optional
                    optionalLabel
                    id={`contactPersons.${index}.role`}
                    placeholder={'Rolle der Person eingeben'}
                    label={'Rolle in der Einrichtung'}
                    $plain
                  />
                  <ButtonsWrapper>
                    <ButtonSecondary
                      disabled={isSubmitting}
                      onClick={() => {
                        form.reset();
                        setEditIndex(null);
                      }}
                    >
                      Abbrechen
                    </ButtonSecondary>
                    <ButtonPrimary disabled={isSubmitting || !form.formState.isDirty} type={'submit'}>
                      {isSubmitting ? <LoadingSpinner /> : 'Speichern'}
                    </ButtonPrimary>
                  </ButtonsWrapper>
                </EditLayout>
              )}
            </Card>
          </Form>
        );
      })}
    </CardsWrapper>
  );
};
