import styled from 'styled-components';
import React from 'react';
import { CustomersList } from '../../content/customers/CustomersList';
import { TabStatusBarLayout } from '../../common/elements/TabStatusBarLayout';
import { CustomerOrganizationsList } from '../../content/customer_organizations/CustomerOrganizationsList';

const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
  margin-bottom: 2.25rem;
`;

export const Customers: React.FC = () => {
  return (
    <Wrapper>
      <TabStatusBarLayout
        breadcrumb={{
          title: 'Einrichtungen',
          href: '/customers',
        }}
        header={'Einrichtungen und Organisationen'}
        items={[
          {
            title: 'Einrichtungen',
            content: <CustomersList />,
            hash: '#customers',
          },
          {
            title: 'Organisationen',
            content: <CustomerOrganizationsList />,
            hash: '#organizations',
          },
        ]}
      />
    </Wrapper>
  );
};
