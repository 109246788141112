import React, { useEffect, useState } from 'react';
import { useCustomersAPI } from '../../../../api/controllers/CustomersAPI';
import { getCurrentDayDate } from '../../../../utils/dateUtils';
import { ICustomerInvoice } from '../../../../api/models/Invoice';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';
import { Section, SectionTitle } from '../../atoms/Form';
import styled, { css } from 'styled-components';
import { Info, List, ListHead, ListRowWrapper, Row } from '../../atoms/List';
import { Colors } from '../../../../styles/Colors';
import { Link } from '../../atoms/Link';
import { Body4 } from '../../../../styles/FontStyles';
import { Alert } from '../../../icons/Alert';
import { DateYearInput } from '../../inputs/DateYearInput';
import { BREAKPOINT_MAX_XL, BREAKPOINT_XL } from '../../../../styles/Breakpoints';

const StyledSection = styled(Section)`
  gap: 1.5rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const StyledInvoicesList = styled(List)`
  --list-template: 1fr;

  ${BREAKPOINT_XL} {
    --list-template: 1.2fr 1fr 1.5fr 1.5rem;
  }
`;

const StyledListHead = styled(ListHead)`
  display: none;

  ${BREAKPOINT_XL} {
    display: grid;
  }
`;

const StyledListRowWrapper = styled(ListRowWrapper).attrs({ as: Link })<{ href: string }>`
  padding-block: 0.75rem;

  ${Body4};

  em {
    font-style: normal;
    color: ${Colors.secondary};
  }

  &:hover {
    text-decoration: none;
  }

  ${BREAKPOINT_MAX_XL} {
    & > :only-child {
      gap: 0;

      & > :nth-child(3) {
        margin-block-start: 0.5rem;
      }
    }
  }
`;

const StyledInfo = styled(Info)<{ lineThrough?: boolean }>`
  ${({ lineThrough = false }) =>
    lineThrough &&
    css`
      text-decoration: line-through;
      text-decoration-color: ${Colors.signalRed900};
      &,
      em {
        color: ${Colors.grey600};
      }
    `};
`;

const AlertRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  color: ${Colors.signalRed900};
`;

interface ICustomerDetailsAccountingProps {
  customerId: string;
}
export default function CustomerDetailsAccounting(props: ICustomerDetailsAccountingProps) {
  const { customerId } = props;

  const customersAPI = useCustomersAPI();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<ICustomerInvoice[] | null>(null);

  const [yearDate, setYearDate] = useState(getCurrentDayDate().toISOString());

  useEffect(() => {
    // get invoices
    if (customerId && yearDate) {
      setLoading(true);
      customersAPI
        .getCustomerInvoices({
          id: customerId,
          year: getCurrentDayDate(yearDate).getFullYear().toString(),
        })
        .then((res) => setInvoices(res.data.rows))
        .catch(() => setInvoices(null))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, yearDate]);

  return (
    <StyledSection>
      <HeaderRow>
        <SectionTitle>Abrechnungen</SectionTitle>
        <DateYearInput value={yearDate} setValue={setYearDate} />
      </HeaderRow>

      <StyledInvoicesList>
        <StyledListHead>
          <div>Name</div>
          <div>Datum</div>
        </StyledListHead>
        {loading && <LoadingSpinner />}
        {!loading &&
          invoices?.map((invoice, index) => (
            <StyledListRowWrapper key={index} href={`/customers/${invoice.customerId}/invoice/${invoice.id}`}>
              <Row>
                <StyledInfo lineThrough={invoice.state === 'revoked'}>
                  <em>
                    <b>{invoice.name}</b>
                  </em>
                </StyledInfo>
                <Info>{new Date(invoice.createdAt).toLocaleDateString(undefined, { dateStyle: 'medium' })}</Info>
                {invoice.state === 'revoked' && (
                  <Info>
                    <AlertRow>
                      <Alert /> Buchungsabschluss wurde widerrufen
                    </AlertRow>
                  </Info>
                )}
              </Row>
            </StyledListRowWrapper>
          ))}
        {!loading && !invoices?.length && (
          <p style={{ padding: '.5rem' }}>Es gibt noch keine Abrechnungen für das Jahr {new Date(yearDate).getFullYear()}</p>
        )}
      </StyledInvoicesList>
    </StyledSection>
  );
}
