import styled from 'styled-components';
import AccountingHome from '../../content/accounting/AccountingHome';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export default function Accounting() {
  return (
    <Wrapper>
      <AccountingHome />
    </Wrapper>
  );
}
