import React from 'react';
import styled from 'styled-components';
import DriverPicker from '../../DriverPicker';
import HoverTooltip from '../../HoverTooltip';
import { Alert } from '../../../../icons/Alert';
import { Colors } from '../../../../../styles/Colors';

const AlertIcon = styled(Alert)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
  border-radius: 50%;
`;

const DriversRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  z-index: 1;

  & > * {
    flex: 1;
    flex-shrink: 0;
  }

  margin-block-end: -0.5rem;
`;

interface IDriverPickersViewProps {
  driver: any;
  companion: any;
  conflicts: any[];
  dataSource: any;
  direction: string;
  editable: boolean;
  hasChanged: boolean;
  schedule: any;
  timestamp: string;
}

const DriverPickersView: React.FC<IDriverPickersViewProps> = ({
  driver,
  companion,
  conflicts,
  dataSource,
  direction = 'outwards',
  editable,
  hasChanged,
  schedule,
  timestamp,
}) => {

  return (
    <DriversRow>
      <DriverPicker
        title={'Fahrer*in'}
        driver={driver}
        hasError={conflicts.some(({ reason }) => reason === 'driver_not_set') && !hasChanged && !driver}
        driverNameLineThrough={conflicts.some(({ reason, tour }) => tour?.driverId === driver?.id && reason === 'driver_unavailable')}
        unavailableDrivers={[driver?.id, companion?.id].filter(Boolean) as string[]}
        timestamp={timestamp}
        direction={direction || undefined}
        onSelect={
          editable
            ? (driver) => {
                dataSource.update(0, {
                  ...schedule,
                  driverId: driver?.id,
                  driver: driver || undefined,
                });
              }
            : undefined
        }
      >
        {conflicts.some(({ reason }) => reason === 'driver_duplicated') && !hasChanged && (
          <HoverTooltip renderTooltipContent={() => <>Fahrer mehrfach geplant</>}>
            <AlertIcon style={{ marginInlineStart: '.25rem' }} />
          </HoverTooltip>
        )}
      </DriverPicker>
      <DriverPicker
        title={'Begleitung'}
        driver={companion}
        driverNameLineThrough={conflicts.some(
          ({ reason, tour }) => tour?.companionId === companion?.id && reason === 'companion_unavailable',
        )}
        unavailableDrivers={[driver?.id, companion?.id].filter(Boolean) as string[]}
        timestamp={timestamp}
        direction={direction || undefined}
        onSelect={
          editable
            ? (companion) => {
                dataSource.update(0, {
                  ...schedule,
                  companionId: companion?.id,
                  companion: companion || undefined,
                });
              }
            : undefined
        }
      >
        {conflicts.some(({ reason }) => reason === 'companion_duplicated') && !hasChanged && (
          <HoverTooltip align="right" renderTooltipContent={() => <>Begleitung mehrfach geplant</>}>
            <AlertIcon style={{ marginInlineStart: '.25rem' }} />
          </HoverTooltip>
        )}
      </DriverPicker>
    </DriversRow>
  );
};

export default DriverPickersView;
//
