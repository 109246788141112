import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomerCategoryAPI } from '../../../api/controllers/CustomerCategoryAPI';
import { useCustomerOrganizationAPI } from '../../../api/controllers/CustomerOrganizationAPI';
import { useCustomersAPI } from '../../../api/controllers/CustomersAPI';
import { ICustomer } from '../../../api/models/Customer';
import { ICustomerCategory } from '../../../api/models/CustomerCategory';
import { ICustomerException } from '../../../api/models/CustomerException';
import { ICustomerOrganization } from '../../../api/models/CustomerOrganization';
import { ContentPageLayout } from '../../common/atoms/Layout';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { CustomerForm } from '../../content/customers/CustomerForm';

export const CustomerEdit: React.FC = () => {
  const { id } = useParams();

  const customersAPI = useCustomersAPI();
  const customerOrganizationAPI = useCustomerOrganizationAPI();
  const customerCategoryAPI = useCustomerCategoryAPI();

  const [customer, setCustomer] = useState<ICustomer | undefined>(undefined);
  const [customerCategories, setCustomerCategories] = useState<ICustomerCategory[]>([]);
  const [customerOrganizations, setCustomerOrganizations] = useState<ICustomerOrganization[]>([]);

  const [categoryLoading, setCategoryLoading] = useState(true);
  const [customerLoading, setCustomerLoading] = useState(true);

  const customerExceptions: ICustomerException[] = [];
  useEffect(() => {
    if (id) {
      customersAPI
        .getCustomerById({ id })
        .then((res) => {
          // TODO: CustomerExceptions and CustomerTypeExceptions should be fetched from the API
          const customerData = res.data.data;

          // customerData.customerTypeExceptions = customerTypeExceptions;
          customerData.customerExceptions = customerExceptions;
          setCustomer(customerData);
        })
        .catch(() => {}) //navigate('/404'))
        .finally(() => setCustomerLoading(false));
    }
    customerCategoryAPI
      .getCustomerCategories({})
      .then((res) => {
        setCustomerCategories(res.data.data);
      })
      .catch(() => {})
      .finally(() => setCategoryLoading(false));

    customerOrganizationAPI.getCustomerOrganizations().then((res) => {
      setCustomerOrganizations(res.data.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {categoryLoading || customerLoading ? (
        <LoadingSpinner />
      ) : (
        <CustomerForm customer={customer} customerCategories={customerCategories} customerOrganizations={customerOrganizations} />
      )}
    </ContentPageLayout>
  );
};
