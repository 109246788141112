// General
import { FC, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Styles
import { Colors } from '../../../styles/Colors';
import { Body3 } from '../../../styles/FontStyles';

// Components
import { Clickable } from '../atoms/Clickable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  height: 2.5rem;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 3px;
    border-radius: 8px;
    background: ${Colors.secondary};
    left: var(--x, 0);
    width: var(--width, 0);
    transition:
      left 300ms ease-out,
      width 300ms ease-out;
  }
`;

const Item = styled(Clickable)<{ active: boolean }>`
  ${Body3};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 2rem;
  color: ${({ active }) => (active ? Colors.secondary : Colors.textDefault)};
  transition: color 300ms ease-out;
`;

interface ITabStatusBarProps {
  items: (ReactNode | string)[];
  currentItem: number;
  onCurrentItemChange: (currentItem: number) => void;
  className?: string;
}

export const TabStatusBar: FC<ITabStatusBarProps> = (props) => {
  const { items, currentItem, onCurrentItemChange, className } = props;
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      const item = wrapperRef.current.children.item(currentItem) as HTMLDivElement;
      wrapperRef.current.style.setProperty('--x', `${item?.offsetLeft || 0}px`);
      wrapperRef.current.style.setProperty('--width', `${item?.offsetWidth || 0}px`);
    }
  }, [currentItem]);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      {items.map((item, index) => (
        <Item active={index === currentItem} key={index} onClick={() => onCurrentItemChange(index)}>
          {item}
        </Item>
      ))}
    </Wrapper>
  );
};
