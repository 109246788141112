import styled from 'styled-components';
import React from 'react';
import { PassengerDetails } from '../../content/passengers/PassengerDetails';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const PassengerDetail = () => {
  return (
    <Wrapper>
      <PassengerDetails />
    </Wrapper>
  );
};
