import React, { useMemo, useState } from 'react';
import { IPassenger } from '../../../../../api/models/Passenger';
import { useAvailableDragDropItems, useDragDropItemsMoveState, useMoveItems, useSelectedDragDropItems } from '../DragDropContext';
import { getDayOfWeekDate, getFormattedValue } from '../../../../../utils/dateUtils';
import { TourLabel } from '../../../../common/labels/TourLabel';
import { Wheelchair } from '../../../../icons/Wheelchair';
import { Walker } from '../../../../icons/Walker';
import TourPickupTimes from '../../../../common/elements/TourPickupTimes';
import DriverPicker from '../../../../common/elements/DriverPicker';
import { TourplanAvailablePassengerView } from '../tiles/TourplanPassengerView';
import { Checkmark } from '../../../../icons/Checkmark';
import { IScheduledTour } from '../../../../../api/models/ScheduledTour';
import { IEmployee } from '../../../../../api/models/Employee';
import { AvailablePassengersList, ButtonsWrapper, TaskCompletedHint, TourplanOverlay } from './TourplanOverlay';
import styled from 'styled-components';
import { Body4, Headline3 } from '../../../../../styles/FontStyles';
import { Colors } from '../../../../../styles/Colors';
import { ChipTile } from '../../../../common/atoms/ChipTile';
import { ButtonPrimary, ButtonTertiary } from '../../../../common/inputs/Button';
import { ITour } from '../../../../../api/models/Tour';

const TourItemHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
  margin-block-start: 1.5rem;
`;

const TourItemHeadContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const DirectionHint = styled.span`
  margin-inline-end: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const TourItemTitle = styled.h3`
  ${Headline3};
  padding-block-end: 1rem;
`;

const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  z-index: 2;

  & > span {
    ${Body4};
    color: ${Colors.grey600};
  }
`;

const DateChipTile = styled(ChipTile)`
  margin-block-end: 0.5rem;
`;

const InfoBlock = styled(AvailablePassengersList)`
  flex-grow: 0;
  gap: 0.5rem;
  padding-block: 0.5rem;

  z-index: 1;

  & > :last-child {
    z-index: -1;
  }
`;

interface ITourplanSplitTourOverlayProps {
  splitTour: Partial<IScheduledTour>;
  driver?: IEmployee | null;
  setDriver?: (value: IEmployee | null) => void;
  companion?: IEmployee | null;
  setCompanion?: (value: IEmployee | null) => void;
  timestamp?: string;
}

/**
 * for split tour view
 */
export const TourplanSplitTourOverlay = React.memo((props: ITourplanSplitTourOverlayProps) => {
  const { splitTour, driver, setDriver, companion, setCompanion, timestamp } = props;

  const [passengersWheelchair, passengersWalking] = useMemo(() => {
    return (splitTour.passengers || []).reduce(
      (res, item) => {
        res[item.hasWheelchair ? 0 : 1].push(item);
        return res;
      },
      [[], []] as Partial<IPassenger>[][],
    );
  }, [splitTour]);

  // const { dataSource } = useDragDropDataProvider();
  const [availablePassengers] = useAvailableDragDropItems<IPassenger>();

  const [selectedItems, resetSelectedItems] = useSelectedDragDropItems<Partial<ITour>, IPassenger>('revert');
  const [moveItemsState, setMoveItemsState] = useDragDropItemsMoveState();
  const moveItems = useMoveItems({});

  // const plannedDrivers = useMemo(() => {
  //   return dataSource.fields.flatMap((tour) => [tour.driverId, tour.companionId].filter(Boolean) as unknown as string[]);
  // }, [dataSource.fields]);

  const [pickupTimesDay, setPickupTimesDay] = useState(timestamp || getDayOfWeekDate(1).toISOString());

  const splitTourInfoView = useMemo(
    () => (
      <TourItemHead>
        <TourItemHeadContent>
          <TourLabel>
            {splitTour.name?.startsWith('F ') ? (
              <span>
                <b>F</b>
                {splitTour.name?.substr(1)}
              </span>
            ) : (
              splitTour.name
            )}
          </TourLabel>
          <DirectionHint>{splitTour.direction === 'return' ? 'Rück' : 'Hin'}</DirectionHint>
          {passengersWheelchair.length > 0 && (
            <IconWrapper>
              {passengersWheelchair.length} <Wheelchair />
            </IconWrapper>
          )}
          {passengersWalking.length > 0 && (
            <IconWrapper>
              {passengersWalking.length} <Walker />
            </IconWrapper>
          )}
        </TourItemHeadContent>
        <TourItemTitle>{splitTour.customer?.displayName}</TourItemTitle>
        <DateRow>
          <span>Laufzeit:</span>
          <DateChipTile>
            {getFormattedValue(
              [splitTour.startDate, splitTour.endDate].map((date) => (date ? new Date(date) : null)) as [Date | null, Date | null],
            )}
          </DateChipTile>
        </DateRow>
      </TourItemHead>
    ),
    [splitTour, passengersWheelchair, passengersWalking],
  );

  const pickupTimesView = useMemo(
    () => (
      <TourPickupTimes
        pickupTimes={(splitTour.direction === 'return' ? splitTour.departureDate : splitTour.arrivalDate) || {}}
        direction={splitTour.direction || undefined}
        timestamp={pickupTimesDay}
        onTimestampChange={setPickupTimesDay}
      />
    ),
    [splitTour, pickupTimesDay, setPickupTimesDay],
  );

  const driverPickersView = useMemo(
    () => (
      <>
        <DriverPicker
          title={'Fahrer'}
          unavailableDrivers={[companion?.id].filter(Boolean) as string[]}
          timestamp={timestamp}
          driver={driver}
          onSelect={setDriver}
        />
        <DriverPicker
          title={'Begleitung'}
          unavailableDrivers={[driver?.id].filter(Boolean) as string[]}
          timestamp={timestamp}
          driver={companion}
          onSelect={setCompanion}
        />
      </>
    ),
    [timestamp, driver, companion, setDriver, setCompanion],
  );

  const availablePassengersList = useMemo(
    () => (
      <AvailablePassengersList>
        {availablePassengers.map((passenger) => (
          <TourplanAvailablePassengerView
            key={passenger.id}
            passenger={passenger}
            markAddressBold
            showPickupTimes
            showNameSingleLine // has small width
            selectedDayOfWeek={pickupTimesDay}
          />
        ))}
        {availablePassengers.length === 0 && (
          <TaskCompletedHint>
            <Checkmark /> alle Fahrgäste wurden verteilt
          </TaskCompletedHint>
        )}
      </AvailablePassengersList>
    ),
    [availablePassengers, pickupTimesDay],
  );

  const revertButtonView = useMemo(
    () => (
      <>
        {moveItemsState !== null && moveItemsState.srcContainer !== 'revert' && (
          <ButtonsWrapper>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                moveItems('revert');
              }}
            >
              In Planung verschieben
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [moveItemsState, moveItems],
  );

  const moveSelectionButtonsView = useMemo(
    () => (
      <>
        {selectedItems.length > 0 && (
          <ButtonsWrapper>
            <ButtonTertiary
              disabled={moveItemsState !== null}
              onClick={(e: Event) => {
                e.stopPropagation();
                resetSelectedItems();
              }}
            >
              Auswahl verwerfen
            </ButtonTertiary>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                if (moveItemsState !== null) {
                  setMoveItemsState(null);
                } else {
                  setMoveItemsState({ srcContainer: 'revert', items: selectedItems });
                }
              }}
            >
              {moveItemsState !== null
                ? 'Verschieben abbrechen'
                : `${selectedItems.length} ${selectedItems.length === 1 ? 'Fahrgast' : 'Fahrgäste'} verschieben`}
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [selectedItems, moveItemsState, resetSelectedItems, setMoveItemsState],
  );

  return (
    <TourplanOverlay title={'Fahrt aufteilen'}>
      {splitTourInfoView}

      <InfoBlock>
        {pickupTimesView}
        {driverPickersView}
      </InfoBlock>

      {availablePassengersList}

      {revertButtonView}
      {moveSelectionButtonsView}
    </TourplanOverlay>
  );
});
