import { useEffect, useState } from 'react';
import { ICustomer } from '../api/models/Customer';
import { IPassengerRefactored } from '../api/models/Passenger';
import { useScheduledTourPassengers } from '../api/controllers/tours/ScheduledTourPassengersAPI';
import { IPickupDate } from '../api/models/PickupDate';
import { LoadingSpinner } from '../components/common/loaders/LoadingSpinner';
import PassengersTrackRefactored from '../components/common/elements/tours/PassengersTrackRefactored';
import { getISODate } from '../utils/dateUtils';
import { TourplanPassengerViewRefactored } from '../components/content/customers/tourplan/tiles/TourplanPassengerViewRefactored';
import PassengerForRowView from '../components/content/passengers/PassengerForRowView';

interface IPassengersContainerProps {
  originalTourId: number;
  direction: string | null;
  departureDate: IPickupDate | null;
  arrivalDate: IPickupDate | null;
  isTourCancelled?: boolean;
  date: Date;
  customer?: ICustomer;
  show: boolean;
  type: 'tile' | 'row';
}

export default function PassengersContainer({
  customer,
  originalTourId,
  direction,
  departureDate,
  arrivalDate,
  date,
  show,
  type,
  isTourCancelled,
}: IPassengersContainerProps) {
  const timeAtCustomer = (direction === 'return' ? departureDate : arrivalDate)?.[date.getDay()];

  const forDay = getISODate(date);

  const passengersApi = useScheduledTourPassengers();
  const [passengers, setPassengers] = useState([] as IPassengerRefactored[]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setError] = useState<string | null>(null);

  useEffect(() => {
    if (show) {
      setIsLoading(true);
      passengersApi
        .getPassengers({ id: originalTourId, forDay: forDay })
        .then((response) => {
          setPassengers(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setError('Fahrgäste konnten nicht geladen werden.');
          setPassengers([]);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalTourId, forDay, show]);

  if (errors) {
    return <div>{errors}</div>;
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : type === 'tile' ? (
    <PassengersTrackRefactored
      passengers={passengers}
      direction={direction}
      customer={customer}
      customerTime={timeAtCustomer && new Date(timeAtCustomer).toLocaleTimeString(undefined, { timeStyle: 'short' })}
      renderPassenger={(passenger) => (
        <TourplanPassengerViewRefactored passenger={passenger} showPickupTimes={true} selectedDayOfWeek={date?.toISOString()} />
      )}
    />
  ) : (
    <>
      {passengers.map((passenger, index) => (
        <PassengerForRowView
          index={index}
          key={index}
          showBefore={index > 0 || direction === 'return'}
          showAfter={index < passengers.length - 1 || direction === 'outwards'}
          passenger={passenger}
          direction={direction}
          isTourCancelled={isTourCancelled}
        />
      ))}
    </>
  );
}
