import { useEffect } from 'react';
import { useRecoilValue, RecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// Helper function to determine if a value should lead to a deletion of the key
const shouldDeleteKey = (value: unknown): boolean =>
  value === undefined || value === null || value === '' || value === 0 || value === false;

// Function to handle array values
const handleArrayValue = (key: string, value: unknown[], searchParams: URLSearchParams): boolean => {
  if (value.length > 0) {
    searchParams.set(key, value.join(','));
    return false;
  }
  return true;
};

// Function to handle object values
const handleObjectValue = (key: string, value: Record<string, unknown>, searchParams: URLSearchParams): boolean => {
  let shouldDelete = true;
  Object.entries(value).forEach(([innerKey, innerValue]) => {
    if (!shouldDeleteKey(innerValue)) {
      if (Array.isArray(innerValue)) {
        shouldDelete = handleArrayValue(innerKey, innerValue, searchParams);
      } else {
        searchParams.set(innerKey, String(innerValue));
        shouldDelete = false;
      }
    }
  });
  return shouldDelete;
};

const useSyncStateWithURL = <T,>(state: RecoilState<T>) => {
  const filter = useRecoilValue(state);
  const navigate = useNavigate();

  const searchParams = new URLSearchParams();

  // Processes each filter key-value pair to modify search parameters accordingly
  const processFilterEntries = () => {
    Object.entries(filter as Record<string, unknown>).forEach(([key, value]) => {
      if (['page', 'showConflicts'].includes(key)) return;

      let deleteKey = true;

      if (Array.isArray(value)) {
        deleteKey = handleArrayValue(key, value, searchParams);
      } else if (typeof value === 'object' && value !== null) {
        deleteKey = handleObjectValue(key, value as Record<string, unknown>, searchParams);
      } else if (!shouldDeleteKey(value)) {
        searchParams.set(key, String(value));
        deleteKey = false;
      }

      if (deleteKey) {
        searchParams.delete(key);
      }
    });

    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    processFilterEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
};
export default useSyncStateWithURL;
