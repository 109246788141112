import { useAuthHeader } from '../../state/AuthState';
import axios from 'axios';

interface ICreatePaymentInfoProps {
  paymentKey: string;
  type: 'tour_outwards' | 'tour_return';
  title: string;

  amount: number;
  paymentFactor: number;
}

interface IGetListProps {
  offset: number;
  limit: number;
}

interface IGetByIdProps {
  id: string;
}

interface IUpdatePaymentInfoProps extends ICreatePaymentInfoProps {}

export const useEmployeePaymentInfosAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const createPaymentInfo = (props: ICreatePaymentInfoProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/employee-payment-infos`, props, { ...authHeader });
    });
  };

  const getPaymentInfos = (props: IGetListProps) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams([
        ['offset', `${props.offset || 0}`],
        ['limit', `${props.limit || 25}`],
      ]).toString();
      return axios.get(`/api/employee-payment-infos?${qs}`, { ...authHeader });
    });
  };

  const getPaymentInfoById = (props: IGetByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/employee-payment-infos/${props.id}`, { ...authHeader });
    });
  };

  const updatePaymentInfo = (id: string, props: IUpdatePaymentInfoProps) => {
    return checkAuth().then(() => {
      return axios.patch(`/api/employee-payment-infos/${id}`, props, { ...authHeader });
    });
  };

  const deletePaymentInfo = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/employee-payment-infos/${id}`, { ...authHeader });
    });
  };

  const restorePaymentInfo = (id: string) => {
    return checkAuth().then(() => {
      return axios.post(`/api/employee-payment-infos/${id}/restore`, {}, { ...authHeader });
    });
  };

  const importPaymentInfosList = (file: File) => {
    return checkAuth().then(() => {
      const formData = new FormData();
      formData.append('file', file);
      return axios.post(`/api/employee-payment-infos/import`, formData, { ...authHeader });
    });
  };

  return {
    createPaymentInfo,
    getPaymentInfos,
    getPaymentInfoById,
    updatePaymentInfo,
    deletePaymentInfo,
    restorePaymentInfo,
    importPaymentInfosList,
  };
};
