import React from 'react';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { VehicleForm } from '../../content/vehicles/VehicleForm';
import { ContentPageLayout } from '../../common/atoms/Layout';

export const VehiclesAdd = () => {
  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Fuhrpark Übersicht',
            href: '/vehicles',
          },
        ]}
      />
      <VehicleForm />
    </ContentPageLayout>
  );
};
