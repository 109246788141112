import React from 'react';
import { EmployeeForm } from '../../content/employees/EmployeeForm';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { ContentPageLayout } from '../../common/atoms/Layout';

export const EmployeesAdd = () => {
  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Mitarbeiter Übersicht',
            href: '/employees',
          },
        ]}
      />
      <EmployeeForm />
    </ContentPageLayout>
  );
};
