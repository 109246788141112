export const Facilities = ({ bold, ...props }: any) =>
  bold ? (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.0685 21.2797V5.27966C19.0685 4.17509 18.1731 3.27966 17.0685 3.27966H7.06848C5.96391 3.27966 5.06848 4.17509 5.06848 5.27966V21.2797M19.0685 21.2797L21.0685 21.2796M19.0685 21.2797H14.0685M5.06848 21.2797L3.06848 21.2796M5.06848 21.2797H10.0685M9.06848 7.27964H10.0685M9.06848 11.2796H10.0685M14.0685 7.27964H15.0685M14.0685 11.2796H15.0685M10.0685 21.2797V16.2796C10.0685 15.7274 10.5162 15.2796 11.0685 15.2796H13.0685C13.6208 15.2796 14.0685 15.7274 14.0685 16.2796V21.2797M10.0685 21.2797H14.0685"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.6507 21V5C19.6507 3.89543 18.7553 3 17.6507 3H7.6507C6.54613 3 5.6507 3.89543 5.6507 5V21M19.6507 21L21.6507 21M19.6507 21H14.6507M5.6507 21L3.6507 21M5.6507 21H10.6507M9.6507 6.99998H10.6507M9.6507 11H10.6507M14.6507 6.99998H15.6507M14.6507 11H15.6507M10.6507 21V16C10.6507 15.4477 11.0984 15 11.6507 15H13.6507C14.203 15 14.6507 15.4477 14.6507 16V21M10.6507 21H14.6507"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
