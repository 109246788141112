import { SearchInput } from '../inputs/SearchInput';
import { Clickable } from '../atoms/Clickable';
import { Close } from '../../icons/Close';
import { Trash } from '../../icons/Trash';
import { LoadingSpinner } from '../loaders/LoadingSpinner';
import React, { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ContextMenu } from '../atoms/ContextMenu';
import { BREAKPOINT_LG } from '../../../styles/Breakpoints';
import { Colors } from '../../../styles/Colors';
import { Body4, Body4Bold } from '../../../styles/FontStyles';
import { ChevronDown } from '../../icons/ChevronDown';
import { useSearchAPI } from '../../../api/controllers/SearchAPI';
import { IVehicle } from '../../../api/models/Vehicle';

const Wrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;
  position: relative;

  z-index: ${({ open }) => (open ? 2 : 0)};
`;

export const DropdownLabel = styled.label<{ disabled?: boolean }>`
  ${Body4Bold};
  margin: 0;

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${Colors.grey700};
    `};

  span {
    color: ${Colors.grey700};
  }
`;

const DropdownView = styled(Clickable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;

  block-size: 2.75rem;

  padding-block: 0.75rem;
  padding-inline: 1rem;

  border: 1px solid ${Colors.grey500};
  border-radius: 8px;

  ${Body4};
  color: ${Colors.textDefault};
`;

const ChevronDownIcon = styled(ChevronDown)`
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  pointer-events: none;
`;

const Popup = styled(ContextMenu).attrs({ hideCloseButton: true })`
  position: fixed;
  top: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0 auto;
  max-inline-size: 24rem;

  ${BREAKPOINT_LG} {
    position: absolute;
    top: 100%;
    bottom: auto;
    left: 0;
    right: auto;
    margin: 0.5rem 0 0;
    max-inline-size: none;
  }

  align-items: stretch;
  padding: 0.5rem;

  color: ${Colors.textDefault};
  cursor: auto;
`;

const CloseButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  & > :first-child {
    flex: 1;
  }

  ${BREAKPOINT_LG} {
    & > :not(:first-child) {
      display: none;
    }
  }
`;

const DriversList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75rem;

  margin: -0.5rem;
  padding: 0.5rem;

  overflow: auto;
  overflow-x: hidden;

  ${BREAKPOINT_LG} {
    max-height: 20rem;
  }
`;

const ResultItem = styled.div<{ active: boolean; showDivider?: boolean; unavailable?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  margin: -0.5rem;
  padding: 0.5rem;

  ${Body4};

  transition: background-color 150ms ease-out;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      background-color: ${Colors.grey400};
    }
  }

  ${({ active }) =>
    active &&
    css`
      // & > :first-child {
      //   box-shadow: 0 0 0 2px $ {Colors.secondary};
      // }

      color: ${Colors.grey700};
      opacity: 0.85;
    `};

  ${({ showDivider = false }) =>
    showDivider &&
    css`
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset-inline: 0.5rem;
        inset-block: 100% 0;
        block-size: 1px;
        background-color: ${Colors.grey500};
      }
    `};

  ${({ unavailable }) =>
    unavailable &&
    css`
      & > :first-child {
        box-shadow: 0 0 0 2px ${Colors.grey700};
      }

      background-color: ${Colors.grey400};
      color: ${Colors.grey700};
      opacity: 0.4;

      &:hover {
        cursor: auto;
        background-color: ${Colors.grey400};
      }
    `};
`;

const TrashButton = styled.div.attrs({ role: 'button', tabIndex: 0 })`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};
  color: ${Colors.secondary};

  margin: -0.75rem;
  padding: 0.75rem;
  margin-inline-start: auto;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

interface IVehiclePickertProps<T> {
  className?: string;
  style?: CSSProperties;
  title: string;
  selectedItem: T | null;
  onSelect: (value: T | null) => void;
  renderItemContent: (value: T) => ReactNode;
  searchInputPlaceholder?: string;
  indices?: string[];
  disabled?: boolean;
  label?: string;
  optionalLabel?: boolean;
}
export default function VehiclePicker<T = { id: string } & any>(props: IVehiclePickertProps<T & any>) {
  const {
    className,
    style,
    title,
    selectedItem,
    onSelect,
    renderItemContent,
    searchInputPlaceholder = 'suchen',
    disabled = false,
    label,
    optionalLabel = false,
  } = props;

  const searchAPI = useSearchAPI();
  const [popupOpen, setPopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [searchResults, setSearchResults] = useState<IVehicle[]>([]);

  useEffect(() => {
    if (popupOpen) {
      setSearchResults([]);
      searchAPI
        .searchVehicles({ query: searchQuery, page: { offset: 0, limit: 20 } })
        .then((res) => {
          setSearchResults(res.data?.items || []);
        })
        .catch(() => setSearchResults([]));
    } else if (searchQuery !== '') {
      setSearchQuery('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, popupOpen]);

  const itemsList = useMemo(() => {
    return searchResults
      ?.filter((vehicle) => selectedItem?.id !== vehicle.id)
      .map((vehicle) => {
        return (
          <ResultItem
            {...{
              tabIndex: 0,
              role: 'button',
              onClick: (e) => {
                e.stopPropagation();
                onSelect?.(vehicle);
                setPopupOpen(false);
              },
            }}
            active={selectedItem?.id === vehicle.id}
          >
            {renderItemContent(vehicle)}
          </ResultItem>
        );
      });
  }, [searchResults, selectedItem, renderItemContent, onSelect, setPopupOpen]);

  return (
    <Wrapper open={popupOpen} className={className} style={style}>
      {label && (
        <DropdownLabel disabled={disabled}>
          {label}
          {optionalLabel && <span> (optional)</span>}
        </DropdownLabel>
      )}

      <DropdownView onClick={() => setPopupOpen(true)}>
        <span>{title}</span>
        <ChevronDownIcon />
      </DropdownView>

      <Popup open={popupOpen} setOpen={setPopupOpen}>
        <CloseButtonRow>
          <SearchInput
            autoFocusExplicit
            placeholder={searchInputPlaceholder}
            value={searchQuery}
            setValue={setSearchQuery}
            onReset={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSearchQuery('');
            }}
          />

          <Clickable
            onClick={(e: any) => {
              e.stopPropagation();
              setPopupOpen(false);
            }}
          >
            <Close />
          </Clickable>
        </CloseButtonRow>
        {selectedItem && (
          <ResultItem
            tabIndex={0}
            role="button"
            active
            showDivider
            onClick={(e) => {
              e.stopPropagation();
              onSelect?.(selectedItem);
              setPopupOpen(false);
            }}
          >
            {renderItemContent(selectedItem)}
            <TrashButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect?.(null);
              }}
            >
              entfernen <Trash />
            </TrashButton>
          </ResultItem>
        )}
        <DriversList>
          {searchResults === null && <LoadingSpinner />}
          {itemsList}
        </DriversList>
      </Popup>
    </Wrapper>
  );
}
