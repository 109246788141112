import styled from 'styled-components';
import PaymentInfosHome from '../../content/payment-infos/PaymentInfosHome';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem 0 0 0;
`;

export default function PaymentInfos() {
  return (
    <Wrapper>
      <PaymentInfosHome />
    </Wrapper>
  );
}
