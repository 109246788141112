import { FC } from 'react';
import { IEmployee } from '../../../../api/models/Employee';
import AnchorSectionView from '../AnchorSectionView';
import EmployeeInvoicesTimeSupplements from './EmployeeInvoicesTimeSupplements';

export const EmployeeDetailsDocuments: FC<{ employee: IEmployee }> = (props) => {
  return (
    <AnchorSectionView
      sectionHeadline={'Abrechnung'}
      items={[
        {
          title: 'Zeitzuschläge',
          content: <EmployeeInvoicesTimeSupplements employee={props.employee} />,
        }
      ]}
    />
  );
};
