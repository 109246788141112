import { IConflict } from '../../../../api/models/Conflict';
import { getFormattedReasonForConflict } from '../../../../utils/tourUtils';
import HoverTooltip from '../HoverTooltip';
import styled from 'styled-components';
import { Colors } from '../../../../styles/Colors';
import { Alert } from '../../../icons/Alert';
import { IScheduledTourException } from '../../../../api/models/ScheduledTour';

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
`;

const AlertIcon = styled(Alert)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
`;

const GreenAlertIcon = styled(AlertIcon)`
  color: ${Colors.signalGreen900};
`;

export default function ConflictsForTourTooltips({
  conflictsForTour,
  isTourCancelled,
  isException,
  activeException,
  date,
  reason,
}: {
  conflictsForTour: IConflict[];
  isTourCancelled: boolean;
  isException: boolean;
  activeException?: IScheduledTourException | null; // get rid of this if all Refactored views are used
  reason?: string;
  date?: Date;
}) {
  if (conflictsForTour.length === 0 && !isException) {
    return null;
  }

  return (
    <>
      {(conflictsForTour.length > 0 || isTourCancelled) && (
        <HoverTooltip
          renderTooltipContent={() => (
            <TooltipContent>
              {(() => {
                if (conflictsForTour.length > 0) {
                  return (
                    <div style={{ textAlign: 'start' }}>
                      {conflictsForTour.map((conflict, index) => (
                        <div key={index}>{getFormattedReasonForConflict(conflict, date?.toISOString())}</div>
                      ))}
                    </div>
                  );
                }

                switch (activeException?.reason || reason || '') {
                  case 'tour_split':
                    return 'Diese Fahrt wurde aufgeteilt.';
                  case 'tour_extended':
                    return 'Diese Fahrt wurde angepasst.';
                  case 'driver_changed':
                    return 'Der Fahrer dieser Fahrt wurde geändert.';
                  case 'cancelled':
                  default:
                    return 'Diese Fahrt wurde abgesagt.';
                }
              })()}
            </TooltipContent>
          )}
        >
          <AlertIcon />
        </HoverTooltip>
      )}
      {isException && (
        <HoverTooltip renderTooltipContent={() => <TooltipContent>Diese Fahrt wurde angepasst.</TooltipContent>}>
          <GreenAlertIcon />
        </HoverTooltip>
      )}
    </>
  );
}
