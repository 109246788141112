import React from 'react';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const Home = () => {
  return (
    <Wrapper>
      <Navigate replace to={'/tours'} />
    </Wrapper>
  );
};
