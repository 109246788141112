// General
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { PassengerForm } from '../../content/passengers/PassengerForm';
import { ContentPageLayout } from '../../common/atoms/Layout';

export const PassengersAdd = () => {
  const navigate = useNavigate();

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Fahrgäste Übersicht',
            href: '/passengers',
          },
        ]}
      />
      <PassengerForm onSuccess={(id) => navigate(`/passengers/${id}`, { replace: true })} />
    </ContentPageLayout>
  );
};
