import styled from 'styled-components';

const PageFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Footer = () => {
  return <PageFooter>{/* empty */}</PageFooter>;
};
