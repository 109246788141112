import { FC } from 'react';
import { getCurrentDayDate } from '../../../../utils/dateUtils';
import ChangeTourDialog from './tour-details/ChangeTourDialog';
import { useSchedulesAPI } from '../../../../api/controllers/SchedulesAPI';
import { IScheduledTour } from '../../../../api/models/ScheduledTour';
import { useNavigate } from 'react-router-dom';

interface IChangeTourDialogOverlayProps {
  schedule: IScheduledTour;
  originalSchedule: IScheduledTour;
  timestamp: string;
  open: boolean;
  showChangeDurationDialog: boolean;
  onUpdate?: () => void;
  setShowChangeDurationDialog: (show: boolean) => void;
}
const ChangeTourDialogOverlay: FC<IChangeTourDialogOverlayProps> = (props) => {
  const { timestamp, schedule, originalSchedule, showChangeDurationDialog, setShowChangeDurationDialog } = props;

  const { id, direction } = schedule;
  const schedulesAPI = useSchedulesAPI();
  const navigate = useNavigate();

  return (
    <ChangeTourDialog
      open={showChangeDurationDialog}
      setOpen={setShowChangeDurationDialog}
      timestamp={timestamp}
      maxDate={originalSchedule.endDate}
      onSubmit={(config, setLoading) => {
        setLoading(true);
        schedulesAPI
          .splitSchedule({
            splitTourId: id?.toString() as string,
            schedulesToUpdate: [
              {
                ...schedule,
                daysOfWeek: Object.keys((direction === 'return' ? schedule.departureDate : schedule.arrivalDate) || {}).join(','),
                passengers: (schedule.passengers || []).map((item, index) => ({
                  ...item,
                  ScheduledTourPassenger: {
                    ...item.ScheduledTourPassenger,
                    position: index,
                  },
                })),
                // reset populated data for transfer
                driver: undefined,
                companion: undefined,
                customer: undefined,
                destinations: undefined,
                exceptions: undefined,
              },
            ],
            splitTourStartDate: getCurrentDayDate(config.startDate || timestamp).toISOString(),
            splitTourEndDate:
              config.endDate || originalSchedule.endDate
                ? getCurrentDayDate((config.endDate || originalSchedule.endDate)!).toISOString()
                : undefined,
            comment: config.comment || null,
          })
          .then((res) => {
            setShowChangeDurationDialog(false);
            navigate(`/tours/${originalSchedule.id}?date=${timestamp}`);
            props.onUpdate?.();
          })
          .catch((err) => {
            console.error('could not adjust tour', err);
          })
          .finally(() => setLoading(false));
      }}
    />
  );
};

export default ChangeTourDialogOverlay;
