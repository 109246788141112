import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { IPassenger } from '../../../../../api/models/Passenger';
import { useDragDropItem, useMoveItemsToContainer } from '../DragDropContext';
import { ColoredLabel, ColoredSideLabel, ColoredSideLabelWrapper, getColorByIndex } from '../../../../common/labels/ColoredLabel';
import { Clickable } from '../../../../common/atoms/Clickable';
import { OrderTourLabel, TourLabel } from '../../../../common/labels/TourLabel';
import { Wheelchair } from '../../../../icons/Wheelchair';
import { Walker } from '../../../../icons/Walker';
import { ChevronDown } from '../../../../icons/ChevronDown';
import TourPickupTimes from '../../../../common/elements/TourPickupTimes';
import { Clock } from '../../../../icons/Clock';
import { TourplanAvailablePassengerView, TourplanPassengerView } from './TourplanPassengerView';
import { TourDragItem, TourDragItemContent } from './TourplanTourTile';
import styled, { css } from 'styled-components';
import { Body5 } from '../../../../../styles/FontStyles';
import { Colors } from '../../../../../styles/Colors';
import { Collapsible } from '../../../../common/atoms/Collapsible';
import { Tours } from '../../../../icons/Tours';
import { Plus } from '../../../../icons/Plus';
import { getCurrentDayDate } from '../../../../../utils/dateUtils';
import { ITour } from '../../../../../api/models/Tour';
import { ICustomer } from '../../../../../api/models/Customer';

const TourDragItemContentOrderTour = styled(TourDragItemContent)<{ overflowHidden?: boolean }>`
  pointer-events: auto;
  gap: 0;

  ${({ overflowHidden = false }) =>
    overflowHidden &&
    css`
      overflow: hidden;
    `};
`;

const TourPassengerItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  block-size: 1.75rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

const TourLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

const TourLabelEndWrapper = styled.div`
  flex: 1;
  padding-inline-start: 0.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
`;

const DirectionHint = styled.span`
  margin-inline-end: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const FlexWrapRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const TimespanRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.125rem;

  ${Body5};
  color: ${Colors.grey600};

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const OrderTourCollapsibleNoOverflow = styled(Collapsible)`
  overflow: visible;
`;

const OrderTourCollapsedView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  margin-block: 0.5rem 0;
`;

const OrderTourPassengerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;
  padding-block-end: 0.25rem;

  &::before {
    content: '';
    border-top: 1px solid ${Colors.grey500};
  }

  * {
    box-shadow: none;
  }
`;

const OrderTourColoredSideLabel = styled(ColoredSideLabel)`
  inset-block: 0 -1.75rem;
  border-top-left-radius: 4px;
`;

const AddCompleteTourHover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;
  border-radius: 2px;
  background-color: ${Colors.secondary};
  color: ${Colors.white50};
  opacity: 0;

  button:hover > & {
    opacity: 1;

    svg {
      opacity: 1;
    }
  }

  svg {
    inline-size: 1.25rem;
    block-size: 1.25rem;

    transition: opacity 300ms ease-out;
    opacity: 0;

    &:last-child {
      inline-size: 1rem;
      block-size: 1rem;
    }
  }
`;

const TourItemDropContainer = React.memo((props: PropsWithChildren<{ availablePassengersInTour: Partial<IPassenger>[] }>) => {
  const { dragging, selected } = useDragDropItem('revert', props.availablePassengersInTour);

  return (
    <TourDragItem dragging={dragging} selected={selected}>
      {props.children}
    </TourDragItem>
  );
});

const ColoredSideLabelLayout = React.memo((props: PropsWithChildren<{ index: number }>) => {
  const { index, children } = props;

  return (
    <ColoredSideLabelWrapper>
      <OrderTourColoredSideLabel color={getColorByIndex(index)} />
      {children}
    </ColoredSideLabelWrapper>
  );
});

/**
 * for tourplan view
 */
export const TourplanOrderTourView: FC<{
  customer: ICustomer | null;
  tour: Partial<ITour>;
  activeSearch?: boolean;
  availablePassengers: Partial<IPassenger>[];
}> = (props) => {
  const { activeSearch = false, customer, tour, availablePassengers = [] } = props;

  const [passengersVisible, setPassengersVisible] = useState(activeSearch);

  const availablePassengersInTour = useMemo(() => {
    return availablePassengers.filter((passenger) => !!(tour.passengers || []).find(({ id }) => id === passenger.id));
  }, [tour, availablePassengers]);

  useEffect(() => {
    setPassengersVisible(activeSearch);
  }, [activeSearch]);

  const [passengersWheelchair, passengersWalking] = useMemo(() => {
    return (tour.passengers || []).reduce(
      (res, item) => {
        res[item.hasWheelchair ? 0 : 1].push(item);
        return res;
      },
      [[], []] as Partial<IPassenger>[][],
    );
  }, [tour]);

  const moveItemsToContainer = useMoveItemsToContainer({
    getCreationAttributes: () => {
      const {
        name = tour?.name || (customer?.internalId && `${customer.internalId} ${(1).toString().padStart(2, '0')}`),
        startDate = getCurrentDayDate(),
        endDate = getCurrentDayDate(),
        arrivalDate,
        departureDate,
      } = tour || {};

      return {
        hasChanged: true, // set changed flag
        customerId: customer?.id,
        customer,
        isException: false,
        startDate,
        endDate,
        arrivalDate,
        departureDate,
        name: `F ${name}`,
      };
    },
  });

  const tourLabelWrapper = useMemo(
    () => (
      <TourLabelWrapper>
        <OrderTourLabel>V</OrderTourLabel>
        <TourLabel
          onClick={
            availablePassengersInTour.length > 0
              ? (e: any) => {
                  e.stopPropagation();

                  // move order tour to scheduled tours as it is
                  moveItemsToContainer({
                    srcContainer: 'revert',
                    targetContainer: 'new',
                    itemsToMove: availablePassengersInTour,
                  });
                }
              : undefined
          }
        >
          {tour.name}
          {availablePassengersInTour.length > 0 && (
            <AddCompleteTourHover>
              <Tours />
              <Plus />
            </AddCompleteTourHover>
          )}
        </TourLabel>
        <DirectionHint>{tour.direction === 'return' ? 'Rück' : 'Hin'}</DirectionHint>
      </TourLabelWrapper>
    ),
    [availablePassengersInTour, moveItemsToContainer, tour.name, tour.direction],
  );

  const collapseButtonView = useMemo(
    () => (
      <Clickable
        onClick={(e: any) => {
          e.stopPropagation();
          setPassengersVisible(!passengersVisible);
        }}
      >
        <TourLabelEndWrapper>
          {passengersWheelchair.length > 0 && (
            <IconWrapper>
              {passengersWheelchair.length} <Wheelchair />
            </IconWrapper>
          )}
          {passengersWalking.length > 0 && (
            <IconWrapper>
              {passengersWalking.length} <Walker />
            </IconWrapper>
          )}
          <ChevronDown invert={passengersVisible} />
        </TourLabelEndWrapper>
      </Clickable>
    ),
    [passengersWheelchair.length, passengersWalking.length, passengersVisible, setPassengersVisible],
  );

  const tourPickupTimes = useMemo(
    () => (
      <TourPickupTimes
        pickupTimes={(tour.direction === 'return' ? tour.departureDate : tour.arrivalDate) || {}}
        direction={tour.direction || undefined}
      />
    ),
    [tour.direction, tour.departureDate, tour.arrivalDate],
  );

  const tourInfo = useMemo(
    () => (
      <FlexWrapRow>
        {tour.order?.displayName && (
          <ColoredLabel color={getColorByIndex((tour.order as any).index)}>{tour.order.displayName}</ColoredLabel>
        )}
        <TimespanRow>
          <Clock />{' '}
          {[tour.startDate, tour.endDate]
            .map((date) => date && new Date(date).toLocaleDateString(undefined, { dateStyle: 'short' }))
            .filter(Boolean)
            .join(' - ')}
        </TimespanRow>
      </FlexWrapRow>
    ),
    [tour.order, tour.startDate, tour.endDate],
  );

  const passengersList = useMemo(
    () => (
      <>
        {tour.passengers?.map((passenger, index) => {
          const availablePassenger = availablePassengersInTour.find(({ id }) => id === passenger.id);
          if (availablePassenger) {
            return (
              <OrderTourPassengerWrapper key={passenger.id}>
                <TourplanAvailablePassengerView markAddressBold position={index + 1} passenger={availablePassenger} />
              </OrderTourPassengerWrapper>
            );
          }

          return (
            <OrderTourPassengerWrapper key={passenger.id}>
              <TourplanPassengerView markAddressBold position={index + 1} style={{ opacity: 0.5 }} passenger={passenger} />
            </OrderTourPassengerWrapper>
          );
        })}
      </>
    ),
    [tour.passengers, availablePassengersInTour],
  );

  return (
    <TourItemDropContainer availablePassengersInTour={availablePassengersInTour}>
      <TourDragItemContentOrderTour overflowHidden={!passengersVisible}>
        <ColoredSideLabelLayout index={(tour.order as any).index}>
          <TourPassengerItemRow>
            {tourLabelWrapper}
            {collapseButtonView}
          </TourPassengerItemRow>
          {tourPickupTimes}
        </ColoredSideLabelLayout>
        <OrderTourCollapsibleNoOverflow open={passengersVisible}>
          <OrderTourCollapsedView>
            {tourInfo}
            {passengersList}
          </OrderTourCollapsedView>
        </OrderTourCollapsibleNoOverflow>
      </TourDragItemContentOrderTour>
    </TourItemDropContainer>
  );
};
