import styled from 'styled-components';
import { Headline2, Headline3 } from '../../../styles/FontStyles';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';

export const Title = styled.h1`
  ${Headline2};
  margin-bottom: 2.5rem;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  margin-bottom: 4rem;
`;

export const SectionTitle = styled.h2`
  ${Headline3};
`;

// const ContactTitle = styled.h4`
//   ${Body2Bold};
// `;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const GridRow = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`;

export const GridRowInline = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    align-items: flex-start;
  }
`;
