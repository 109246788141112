import { useDragDropContainer } from '../DragDropContext';
import { ITour } from '../../../../../api/models/Tour';
import { IPassenger } from '../../../../../api/models/Passenger';
import { Add } from '../../../../icons/Add';
import React from 'react';
import styled from 'styled-components';
import { Clickable } from '../../../../common/atoms/Clickable';
import { Colors } from '../../../../../styles/Colors';
import { TileItem } from './TourplanTourTile';
import BlockedByBookingCompletionOverlay from '../../../../common/elements/BlockedByBookingCompletionOverlay';

const AddTourItem = styled(TileItem).attrs({ as: Clickable })`
  padding: 2rem;
  align-items: center;
  justify-items: center;

  position: relative;

  color: ${Colors.secondary};

  svg {
    width: 3.5rem;
    height: 3.5rem;
    margin: 1.5rem;
  }
`;

export const TourplanNewTourTile = React.memo((props: { getCreationAttributes?: any; timestamp?: string }) => {
  const { timestamp, getCreationAttributes } = props;

  return (
    <AddTourItem {...useDragDropContainer<ITour, IPassenger>('new', getCreationAttributes)}>
      <Add />
      <span>neue Fahrt erstellen</span>
      <BlockedByBookingCompletionOverlay timestamp={timestamp} style={{ borderRadius: 8 }} />
    </AddTourItem>
  );
});
