import { IPassenger } from '../../../../../api/models/Passenger';
import { Colors } from '../../../../../styles/Colors';
import styled, { css } from 'styled-components';
import { Body2Bold, Body4Bold, Body5 } from '../../../../../styles/FontStyles';
import { Wheelchair } from '../../../../icons/Wheelchair';
import { Walker } from '../../../../icons/Walker';
import HoverTooltip from '../../HoverTooltip';
import React, { PropsWithChildren } from 'react';
import { InfoInverted } from '../../../../icons/Info';
import { useDragDropItem, useDragDropPositionItem } from '../../../../content/customers/tourplan/DragDropContext';
import { ITour } from '../../../../../api/models/Tour';
import { IScheduledTour } from '../../../../../api/models/ScheduledTour';
import { formatName } from '../../../../../utils/nameUtils';

const Wrapper = styled.div`
  position: relative;
  padding-top: 100%;
`;

const Content = styled.div<{ canBeDragged?: boolean; dragging?: boolean; selected?: boolean; selectMode?: boolean }>`
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.125rem;

  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-color: ${Colors.secondaryLight};
  padding: 0.25rem;

  ${Body5};

  b {
    ${Body4Bold};
  }

  i {
    ${Body2Bold};
    font-style: normal;
  }

  ${({ canBeDragged = false }) =>
    canBeDragged &&
    css`
      & > * {
        pointer-events: none;
      }

      &:hover {
        cursor: move;
      }
    `};

  ${({ selectMode = false }) =>
    selectMode &&
    css`
      opacity: 0.3;
    `};

  ${({ selected = false }) =>
    selected &&
    css`
      color: ${Colors.secondary};
      border-color: ${Colors.secondary};
      opacity: 1;
    `};

  ${({ dragging = false }) =>
    dragging &&
    css`
      opacity: 0.3;
    `};

  &#dragging-node {
    background-color: ${Colors.secondary};
    --text-color: ${Colors.textInverted};
    color: var(--text-color);
  }
`;

const HeadRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const PassengerInfoWrapper = styled.div`
  margin-block-start: auto;
  align-self: flex-start;
`;

const TourPassengerInfoIcon = styled(InfoInverted)`
  color: ${Colors.grey400};
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
`;

const DragDropBox = React.memo((props: PropsWithChildren<IPassengerTileProps>) => {
  const { tour, passenger, children } = props;

  const dragDropItemProps = {
    ...useDragDropItem<ITour, IPassenger>(tour || 'revert', passenger),
    ...useDragDropPositionItem<ITour, IPassenger>(tour || 'revert', passenger),
  };

  return <Content {...dragDropItemProps}>{children}</Content>;
});

interface IPassengerTileProps {
  tour?: IScheduledTour | ITour;
  passenger: IPassenger;
  timestamp?: string;
}
function PassengerTile(props: IPassengerTileProps) {
  const { tour, passenger, timestamp } = props;

  return (
    <Wrapper>
      <DragDropBox tour={tour} passenger={passenger}>
        <HeadRow>
          <i>{(passenger.ScheduledTourPassenger?.position || 0) + 1}</i>
          {timestamp && passenger.ScheduledTourPassenger?.pickupDate?.[new Date(timestamp).getDay()] && (
            <span>
              {new Date(passenger.ScheduledTourPassenger?.pickupDate?.[new Date(timestamp).getDay()]!).toLocaleTimeString(undefined, {
                timeStyle: 'short',
              })}
            </span>
          )}
          {passenger.hasWheelchair ? <Wheelchair /> : <Walker />}
        </HeadRow>

        <b>{formatName(passenger)}</b>
        <span
          dangerouslySetInnerHTML={{
            __html: [
              passenger.addresses?.[0]?.addressLine1,
              passenger.addresses?.[0]?.addressLine2,
              [passenger.addresses?.[0]?.zipCode, passenger.addresses?.[0]?.city].filter(Boolean).join(' '),
            ]
              .filter(Boolean)
              .join('<br/>'),
          }}
        />

        {passenger.comment && (
          <PassengerInfoWrapper>
            <HoverTooltip align={'top'} renderTooltipContent={() => <>{passenger.comment}</>}>
              <TourPassengerInfoIcon />
            </HoverTooltip>
          </PassengerInfoWrapper>
        )}
      </DragDropBox>
    </Wrapper>
  );
}

export default React.memo(PassengerTile);
