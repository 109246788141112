import { keyframes } from 'styled-components';

const Appear = keyframes`
  from {
    opacity: 0;
  }
`;

export const Animations = {
  Appear,
};
