import React from 'react';
import { ContextOptions } from '../ContextOptions';
import { Trash } from '../../../icons/Trash';
import { Edit } from '../../../icons/Edit';
import { ICustomer } from '../../../../api/models/Customer';
import { getTelLink } from '../../../../utils/phoneUtils';
import { Phone } from '../../../icons/Phone';
import { Mail } from '../../../icons/Mail';
import { ActionButton, Info, InfoBold, ListRowWrapper, OptionsWrapper, Row, ListRowControlsWrapper } from '../../atoms/List';
import { useShowDeleteCustomerDialog } from '../../../../hooks/customers/useShowDeleteCustomerDialog';
import { useHasPermission } from '../../../../state/UserMeState';
import { useNavigate } from '../../../../hooks/useNavigate';

export const CustomerListRow: React.FC<{ customer: ICustomer; onUpdate?: () => void }> = (props) => {
  const { id, internalId, displayName, region, contactPersons = [], scheduledTours = [] } = props.customer;

  const navigate = useNavigate();
  const showDeleteCustomerDialog = useShowDeleteCustomerDialog(id);
  const isAdmin = useHasPermission('admin');

  const contactPhone = contactPersons.find(({ phoneNumber }) => !!phoneNumber);
  const contactEmail = contactPersons.find(({ emailAddress }) => !!emailAddress);

  return (
    <ListRowWrapper
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          navigate(`/customers/${id}`);
        }
      }}
    >
      <Row>
        <InfoBold>{internalId || '-'}</InfoBold>
        <Info>{displayName || '-'}</Info>
        <Info>{scheduledTours.length}</Info>
        <Info>{'-'}</Info>
        <Info>{region || '-'}</Info>

        <ListRowControlsWrapper>
          <OptionsWrapper>
            <ActionButton href={getTelLink(contactPhone?.phoneNumber) || ''} onClick={(e) => e.stopPropagation()}>
              <Phone />
            </ActionButton>
            <ActionButton href={contactEmail ? `mailto:${contactEmail.emailAddress}` : ''} onClick={(e) => e.stopPropagation()}>
              <Mail />
            </ActionButton>
          </OptionsWrapper>

          <OptionsWrapper>
            <ContextOptions
              items={
                [
                  isAdmin && {
                    content: (
                      <>
                        <Trash /> Entfernen
                      </>
                    ),
                    onClick: () => showDeleteCustomerDialog(props.onUpdate),
                  },
                  {
                    content: (
                      <>
                        <Edit /> Bearbeiten
                      </>
                    ),
                    onClick: () => navigate(`/customers/${id}/edit`),
                  },
                ].filter(Boolean) as []
              }
            />
          </OptionsWrapper>
        </ListRowControlsWrapper>
      </Row>
    </ListRowWrapper>
  );
};
