import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useCustomerCategoryAPI } from '../../../api/controllers/CustomerCategoryAPI';
import { ICustomerCategory } from '../../../api/models/CustomerCategory';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Headline2, Headline3 } from '../../../styles/FontStyles';
import { ButtonPrimary } from '../../common/inputs/Button';
import { Form } from '../../common/inputs/Form';
import { TextInput } from '../../common/inputs/TextInput';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${Headline2};
  margin-bottom: 2.5rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  ${Headline3};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const GridRow = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`;

export const CustomerCategoryForm: FC<{
  customerCategory: ICustomerCategory | undefined;
}> = (props) => {
  const { customerCategory = undefined } = props;
  const navigate = useNavigate();

  const customerCategoryAPI = useCustomerCategoryAPI();
  const [isSubmitting, setSubmitting] = useState(false);

  const form = useForm({
    defaultValues: {
      name: customerCategory?.name || '',
    },
  });

  return (
    <Wrapper>
      <Form
        form={form}
        onSubmit={(values) => {
          setSubmitting(true);
          const apiCall = customerCategory?.id
            ? customerCategoryAPI.updateCustomerCategory(customerCategory.id, { customer_category: values })
            : customerCategoryAPI.createCustomerCategory({ customer_category: values });
          apiCall
            .then((res) => {
              console.log(customerCategory?.id ? 'update' : 'create', 'customer', res.data);
              navigate(`/customer-categories`, { replace: true });
            })
            .catch((err) => {
              // TODO add generic error message
            })
            .finally(() => setSubmitting(false));
        }}
      >
        <Title>Einrichtungstypen</Title>

        <Section>
          <SectionTitle>Allgemeine Informationen</SectionTitle>
          <GridRow>
            <TextInput
              id={'name'}
              placeholder={'Schule, Werkstatt etc.'}
              label={'Name'}
              $plain
              {...form.register('name', { required: 'Bitte wählen Sie eine Namen an.' })}
            />
          </GridRow>
        </Section>

        <ButtonPrimary type={'submit'}>{isSubmitting ? <LoadingSpinner /> : 'Speichern'}</ButtonPrimary>
      </Form>
    </Wrapper>
  );
};
