import axios from 'axios';
import { useAuthHeader } from '../../state/AuthState';

interface ICustomerOrganizationProps {
  name: string;
  description: string;
}

export interface IGetResourceByIdProps {
  id: string;
}

export const useCustomerOrganizationAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const createCustomerOrganization = (props: ICustomerOrganizationProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/customer-organizations`, props, { ...authHeader });
    });
  };

  const getCustomerOrganizations = () => {
    return checkAuth().then(() => {
      return axios.get(`/api/customer-organizations`, { ...authHeader });
    });
  };

  const getCustomerOrganizationById = (props: IGetResourceByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/customer-organizations/${props.id}`, { ...authHeader });
    });
  };

  const updateCustomerOrganization = (id: string, props: ICustomerOrganizationProps) => {
    return checkAuth().then(() => {
      return axios.patch(`/api/customer-organizations/${id}`, props, { ...authHeader });
    });
  };

  const deleteCustomerOrganization = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/customer-organizations/${id}`, { ...authHeader });
    });
  };

  return {
    createCustomerOrganization,
    deleteCustomerOrganization,
    updateCustomerOrganization,
    getCustomerOrganizations,
    getCustomerOrganizationById,
  };
};
