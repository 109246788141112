// General
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { PassengerForm } from '../../content/passengers/PassengerForm';
import { ContentPageLayout } from '../../common/atoms/Layout';

// Types
import { IPassenger } from '../../../api/models/Passenger';

// Controller
import { usePassengersAPI } from '../../../api/controllers/PassengersAPI';

export const PassengerEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const passengersAPI = usePassengersAPI();
  const [passenger, setPassenger] = useState<IPassenger | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      passengersAPI
        .getPassengerById({ id })
        .then((res) => setPassenger(res.data))
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Fahrgäste Übersicht',
            href: '/passengers',
          },
        ]}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <PassengerForm passenger={passenger || undefined} onSuccess={(id) => navigate(`/passengers/${id}`, { replace: true })} />
      )}
    </ContentPageLayout>
  );
};
