import { useMemo } from 'react';
import { IScheduledTourWithMetaData } from '../../../../api/models/ScheduledTour';
import VirtualizedDiv from '../../atoms/VirtualizedDiv';
import { EmptyTourFrame } from '../../atoms/EmptyTourFrame';
import { getCurrentDayDate } from '../../../../utils/dateUtils';
import { IConflict } from '../../../../api/models/Conflict';
import { useRecoilValue } from 'recoil';
import { TourListRowRefactored } from './TourListRowRefactored';
import { TourTileRefactored } from './TourTileRefactored';
import { TourListSettingState } from '../../../../state/TourListSettingState';

const TourItemRefactored = ({
  schedule,
  isDesktop,
  day,
  conflicts,
}: {
  schedule: IScheduledTourWithMetaData;
  isDesktop: boolean;
  day: string;
  conflicts?: IConflict[];
}) => {
  const { isTourCancelled } = schedule;

  const conflictsForTour = useMemo(() => conflicts?.filter(({ tour }) => tour.id === schedule.tour.id), [schedule, conflicts]);

  const toursSettings = useRecoilValue(TourListSettingState);

  const { showOnlyCancelled, hideCancelled, conflictsOnly } = toursSettings.filters;

  if ((conflictsOnly && conflictsForTour?.length === 0) || (hideCancelled && isTourCancelled) || (showOnlyCancelled && !isTourCancelled)) {
    return null;
  }

  const renderTourComponent = (visible: boolean) => {
    if (!visible) {
      return <EmptyTourFrame size={isDesktop ? '7rem' : '12.5rem'} $withBorder={!isDesktop} />;
    }

    if (isDesktop) {
      return <TourListRowRefactored day={day} schedule={schedule} conflictsForTour={conflictsForTour} />;
    } else {
      return <TourTileRefactored schedule={schedule} date={getCurrentDayDate(day)} conflictsForTour={conflictsForTour} />;
    }
  };

  return <VirtualizedDiv render={renderTourComponent} />;
};

export default TourItemRefactored;
