import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { IScheduledTour, IScheduledTourException } from '../../../api/models/ScheduledTour';
import TourDetailsView from '../../common/elements/tours/TourDetailsView';
import { useScheduledToursForDayAPI } from '../../../api/controllers/tours/ScheduledToursForDayAPI';
import { getCurrentDayDate } from '../../../utils/dateUtils';

export interface IScheduleWithMetaDataDetail {
  tour: IScheduledTour;
  isTourDriven: boolean;
  exceptionTour: IScheduledTour | undefined;
  activeException?: IScheduledTourException | null;
  conflicts?: IScheduledTourException[];
  activeDay: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
`;

export const TourDetails: FC = () => {
  const { id } = useParams();

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const defaultDate = getCurrentDayDate(urlSearchParams.get('date') || new Date()).toISOString();

  const [timestamp, setTimestamp] = useState(defaultDate);
  const scheduledToursForDayAPI = useScheduledToursForDayAPI();

  const [loading, setLoading] = useState(false);
  const [exceptionLoading, setExceptionLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [scheduledTourWithMetaData, setScheduledTourWithMetaData] = useState<IScheduleWithMetaDataDetail | null>(null);
  const [forceOriginalTour, setForceOriginalTour] = useState<boolean>(false);
  const [selectedException, setSelectedException] = useState<IScheduledTourException>();
  const [activeException, setActiveException] = useState<IScheduledTourException>();

  const fetchScheduledTour = useCallback(
    (newDate: string) => {
      if (id) {
        setLoading(true);
        if (initLoading) {
          setInitLoading(false);
        }
        scheduledToursForDayAPI
          .getByTourIdForDay(id.toString(), { dayAsISODate: newDate })
          .then((res) => {
            setScheduledTourWithMetaData(res.data);
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      } else {
      }
    },
    [id, initLoading, scheduledToursForDayAPI],
  );

  const fetchExceptionTour = async (exception: IScheduledTourException, newDate: string) => {
    setExceptionLoading(true);
    scheduledToursForDayAPI
      .getExceptionByTourId(exception.replacementScheduledTourId!, { dayAsISODate: newDate })
      .then((res) => {
        const mergedData = {
          ...scheduledTourWithMetaData,
          exceptionTour: res.data.tour,
        };
        // @ts-ignore
        setScheduledTourWithMetaData(mergedData);
      })
      .catch(() => {})
      .finally(() => {
        setExceptionLoading(false);
      });
  };

  useEffect(() => {
    setSelectedException(undefined);
    setActiveException(undefined);
    setForceOriginalTour(false);
    fetchScheduledTour(timestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, timestamp]);

  // Refactor: TO be filtered by backend
  const exceptionsForDate = useMemo(() => {
    const exceptions =
      scheduledTourWithMetaData?.tour?.exceptions?.filter((exception) => {
        const currentDate = new Date(timestamp);

        const startDate = new Date(exception.startDate);
        const endDate = exception.endDate ? new Date(exception.endDate) : undefined;

        if (endDate === undefined) {
          return startDate <= currentDate;
        } else {
          return startDate <= currentDate && endDate >= currentDate;
        }
      }) || [];

    // Check if there are any exceptions with reason "cancelled" or "tour_split"
    const firstCancelledOrTourSplitIndex = exceptions.findIndex(
      (exception) => exception.reason === 'cancelled' || exception.reason === 'tour_split',
    );

    // If such an exception exists and it is not the first one, shift the array
    if (firstCancelledOrTourSplitIndex > 0) {
      exceptions.splice(0, firstCancelledOrTourSplitIndex);
    }

    return exceptions;
  }, [scheduledTourWithMetaData?.tour?.exceptions, timestamp]);

  useEffect(() => {
    if (exceptionsForDate && exceptionsForDate.length > 0) {
      setActiveException(exceptionsForDate[0]);
      setSelectedException(exceptionsForDate[0]);
    }
  }, [exceptionsForDate]);

  return (
    <Wrapper>
      {(loading || exceptionLoading) && initLoading && <LoadingSpinner />}

      {scheduledTourWithMetaData && (
        <TourDetailsView
          scheduledTourWithMetaData={scheduledTourWithMetaData}
          onExceptionClick={async (exception) => {
            if (!exception) {
              setSelectedException(undefined);
              return;
            } else {
              if (exception?.replacementScheduledTourId) {
                await fetchExceptionTour(exception, timestamp);
              }

              setForceOriginalTour(false);
              setSelectedException(exception);
            }
          }}
          activeException={activeException}
          selectedException={selectedException}
          exceptionsForDate={exceptionsForDate || []}
          onUpdate={fetchScheduledTour}
          timestamp={timestamp}
          setTimestamp={setTimestamp}
          forceOriginalTour={forceOriginalTour}
          setForceOriginalTour={setForceOriginalTour}
        />
      )}
    </Wrapper>
  );
};
