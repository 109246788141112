import { Fragment, useEffect, useMemo, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { css } from 'styled-components';
import { fetchToursParams, useScheduledToursForDay } from '../../../api/controllers/tours/ScheduledToursForDayAPI';
import { Colors } from '../../../styles/Colors';
import { Body3, Body4, Headline2, Headline4 } from '../../../styles/FontStyles';
import { getCurrentDayDate, getISODate } from '../../../utils/dateUtils';
import HoverTooltip from '../../common/elements/HoverTooltip';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { Alert } from '../../icons/Alert';
import ScheduleColumnItem from './ScheduleColumnItem';
import { IConflict } from '../../../api/models/Conflict';
import { TourListSettingState } from '../../../state/TourListSettingState';
import { useRecoilValue } from 'recoil';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;

  // space for scrollbar
  margin: -1.25rem;
  padding: 1.25rem;

  margin-block-start: 0;
  padding-block-start: 0;

  max-block-size: 100%;
`;

const DateWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-block-end: 1.75rem;

  ${Body3};

  color: ${({ active }) => (active ? Colors.secondary : Colors.textDefault)};

  h2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.25rem;

    ${Headline4};

    span {
      ${Headline2};
      margin-block-end: -0.25rem;
    }

    ${({ active }) =>
      active &&
      css`
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -1rem;
          top: 1rem;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background: ${Colors.secondary};
        }

        &::after {
          content: 'heute';
          ${Body4};
          color: ${Colors.secondary};
          padding: 0.25rem 1rem;
          border-radius: 20px;
          background: ${Colors.grey500};
          margin-inline-start: 0.5rem;
        }
      `};
  }
`;

const BaseWrapper = styled.div`
  width: 24rem;
  margin: -1.25rem;
  padding: 1.25rem;
  overflow: auto;
`;

const CalendarTilesWrapper = styled(BaseWrapper)`
  padding-block-end: var(--content-indent);

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }
`;

const Placeholder = styled.p`
  &,
  &:not(:hover) {
    box-shadow: none;
  }

  ${Body4};
  color: ${Colors.grey700};
`;

const AlertIcon = styled(Alert)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
`;

interface IScheduleColumnProps {
  day: string;
  conflicts: IConflict[];
}

export default function ScheduleColumn({ day, conflicts }: IScheduleColumnProps) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [date, formattedDate] = useMemo(() => {
    const date = new Date(day);
    const formattedDate = date.toLocaleDateString(undefined, {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
    return [date, formattedDate];
  }, [day]);

  const {
    query,
    filters,
    filters: { showOnlyCancelled, hideCancelled, conflictsOnly, regions, directions, customerIds },
  } = useRecoilValue(TourListSettingState);

  const cancelled = (function () {
    if (showOnlyCancelled) {
      return 'only';
    } else if (hideCancelled) {
      return 'hide';
    } else {
      return 'include';
    }
  })();

  const limit = conflictsOnly ? 9999 : 10; // @TODO: this is a workaround to fetch all tours with conflicts.

  const params = {
    dayAsISODate: getISODate(day),
    sortBy: 'tourname',
    sort: 'grouped',
    query,
    customerIds,
    regions,
    directions,
    cancelled,
    page: {
      offset: 0,
      limit: limit,
    },
  } as fetchToursParams;

  const { status, data, hasNextPage, fetchNextPage, error, isFetching, refetch } = useScheduledToursForDay(params);

  // Handles changes from filters
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filters]);

  return (
    <Column data-day={getCurrentDayDate(date).toISOString()}>
      <DateWrapper active={getCurrentDayDate(date).toISOString() === getCurrentDayDate().toISOString()}>
        <h2>
          <span>{formattedDate.substring(0, 3)}</span>
          {formattedDate.substring(3)}
          {conflicts.length > 0 && (
            <HoverTooltip
              renderTooltipContent={() => {
                return (
                  <>
                    {conflicts.length} {conflicts.length === 1 ? 'Konflikt' : 'Konflikte'}
                  </>
                );
              }}
            >
              <AlertIcon />
            </HoverTooltip>
          )}
        </h2>
        {date.toLocaleDateString(undefined, {
          weekday: 'short',
          day: undefined,
          month: undefined,
          year: undefined,
        })}
      </DateWrapper>

      <CalendarTilesWrapper id={`scrollWrapper${day}`} ref={wrapperRef}>
        {(isFetching || status === 'pending') && <LoadingSpinner />}
        {error && <Placeholder>Es ist ein Fehler aufgetreten</Placeholder>}
        {data && data.pages ? (
          <InfiniteScroll
            dataLength={data.pages.length * limit} //This is important field to render the next data
            next={() => {
              fetchNextPage();
            }}
            hasMore={hasNextPage}
            scrollableTarget={`scrollWrapper${day}`}
            loader={<LoadingSpinner />}
            style={{ overflow: 'inherit' }}
            endMessage={<Placeholder style={{ textAlign: 'center' }}>Keine weiteren Fahrten geplant</Placeholder>}
          >
            {data.pages.map((group, i) => (
              <Fragment key={i}>
                {group.items.map((schedule) => (
                  <ScheduleColumnItem key={schedule.tour.id} scheduledTour={schedule} scheduleDate={date} conflictsForDate={conflicts} />
                ))}
              </Fragment>
            ))}
          </InfiniteScroll>
        ) : (
          <Placeholder>Keine Fahrten geplant</Placeholder>
        )}
      </CalendarTilesWrapper>
    </Column>
  );
}
