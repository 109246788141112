import React, { CSSProperties } from 'react';
import { useLatestBookingCompletionDate } from '../../../state/LatestBookingCompletionState';
import { getCurrentDayDate } from '../../../utils/dateUtils';
import { Toast } from './Toast';

interface IBookingCompletionHintProps {
  timestamp: string;
  style?: CSSProperties;
  className?: string;
}
function BookingCompletionHint(props: IBookingCompletionHintProps) {
  const { timestamp, className, style } = props;

  const bookingCompletionDate = useLatestBookingCompletionDate();

  return (
    <>
      {bookingCompletionDate && bookingCompletionDate >= getCurrentDayDate(timestamp) && (
        <Toast type={'error'} className={className} style={style}>
          Letzter Buchungsabschluss: {bookingCompletionDate.toLocaleDateString(undefined, { dateStyle: 'medium' })}. Änderungen in diesem
          Zeitraum sind nicht mehr möglich.
        </Toast>
      )}
    </>
  );
}

export default React.memo(BookingCompletionHint);
