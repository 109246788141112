export const Success = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1091_18619)">
      <mask id="mask0_1091_18619" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <ellipse cx="12.0002" cy="12" rx="12.0002" ry="12" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_1091_18619)">
        <rect x="-1.09082" y="-1.09091" width="26.1822" height="26.1818" fill="currentColor" />
      </g>
      <path d="m5 13 4 4L19 7" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1091_18619">
        <rect width="24.0004" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
