import React, { useState } from 'react';
import { ICustomerCategory } from '../../../api/models/CustomerCategory';
import { ContentPageLayout } from '../../common/atoms/Layout';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { CustomerCategoryForm } from '../../content/customer-categories/CustomerCategoryForm';

export const CustomerCategoryAdd: React.FC = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [customerCategory, setCustomerCategory] = useState<ICustomerCategory>({
    id: '',
    name: '',
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
  });

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {loading ? <LoadingSpinner /> : <CustomerCategoryForm customerCategory={customerCategory || undefined} />}
    </ContentPageLayout>
  );
};
