export const Bus = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.25 17.25C8.25 18.0784 7.57843 18.75 6.75 18.75C5.92157 18.75 5.25 18.0784 5.25 17.25C5.25 16.4216 5.92157 15.75 6.75 15.75C7.57843 15.75 8.25 16.4216 8.25 17.25Z"
      stroke="currentColor"
    />
    <path
      d="M18.75 17.25C18.75 18.0784 18.0784 18.75 17.25 18.75C16.4216 18.75 15.75 18.0784 15.75 17.25C15.75 16.4216 16.4216 15.75 17.25 15.75C18.0784 15.75 18.75 16.4216 18.75 17.25Z"
      stroke="currentColor"
    />
    <path
      d="M8.25 17.25C8.25 18.0784 7.57843 18.75 6.75 18.75C5.92157 18.75 5.25 18.0784 5.25 17.25M8.25 17.25C8.25 16.4216 7.57843 15.75 6.75 15.75C5.92157 15.75 5.25 16.4216 5.25 17.25M8.25 17.25H15.75M5.25 17.25H3.25C2.69772 17.25 2.25 16.8023 2.25 16.25V12.25C2.25 11.6977 2.69772 11.25 3.25 11.25H5.25M18.75 17.25C18.75 18.0784 18.0784 18.75 17.25 18.75C16.4216 18.75 15.75 18.0784 15.75 17.25M18.75 17.25C18.75 16.4216 18.0784 15.75 17.25 15.75C16.4216 15.75 15.75 16.4216 15.75 17.25M18.75 17.25H20.75C21.3023 17.25 21.75 16.8023 21.75 16.25V11.25M5.25 11.25L6.56063 6.00746C6.67193 5.5623 7.07191 5.25 7.53078 5.25H20.75C21.3023 5.25 21.75 5.69772 21.75 6.25V11.25M5.25 11.25H21.75"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M16.6095 10.374L17.25 5.25H12.8828C12.3785 5.25 11.9531 5.62554 11.8905 6.12597L11.25 11.25H15.6172C16.1215 11.25 16.5469 10.8745 16.6095 10.374Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M21.75 11.25H16.5L17.1405 6.12597C17.2031 5.62554 17.6285 5.25 18.1328 5.25H20.75C21.3023 5.25 21.75 5.69772 21.75 6.25V11.25Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.56063 6.00746L5.25 11.25H10.3672C10.8715 11.25 11.2969 10.8745 11.3595 10.374L12 5.25H7.53078C7.07191 5.25 6.67193 5.5623 6.56063 6.00746Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
