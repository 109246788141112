import { CSSProperties, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/Colors';
import { Body4, Body4Bold } from '../../../styles/FontStyles';
import { Field, useFieldError, useFieldProps } from './Field';
import { useFormContext } from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  text-align: start;
`;

const Label = styled.label<{ disabled: boolean }>`
  ${Body4Bold};
  margin: 0 0 0.5rem;

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${Colors.grey700};
    `};

  span {
    color: ${Colors.grey700};
  }
`;

const Input = styled(Field)<{ $error: boolean; $plain?: boolean; as?: any }>`
  appearance: none;
  background: none;
  width: 100%;
  height: 3rem;

  border: 1px solid ${Colors.grey500};
  border-radius: 8px;
  padding: 0.75rem 1rem;

  ${Body4};

  transition: border-color 150ms ease-out, color 150ms ease-out;
  resize: none;

  &:focus-visible {
    border-color: ${Colors.grey800};
  }

  &:disabled {
    background: ${Colors.grey300};
    color: ${Colors.grey700};
  }

  ${({ $error = false }) =>
    $error &&
    css`
      border-color: ${Colors.signalRed900};
    `};

  &::placeholder {
    color: ${Colors.grey700};
  }

  ${({ $plain = false }) =>
    $plain &&
    css`
      padding: 0.25rem 0 0.5rem;
      height: 2.25rem;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid ${Colors.grey500};
    `};

  ${({ as }) =>
    as === 'textarea' &&
    css`
      max-width: 600px;
      height: 7.5rem;
      overflow: auto;
    `};
`;

const ErrorLabel = styled.p`
  ${Body4};
  color: ${Colors.signalRed900};
`;

export interface IInputProps<T = string> {
  id: string;
  name?: string;
  autoComplete?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
  optionalLabel?: boolean;
  $plain?: boolean;
  className?: string;
  style?: CSSProperties;
  onFocus?: () => void;
  autoFocus?: boolean;
  independent?: boolean;
  defaultValue?: T;
  value?: T;
  onValueChange?: (value: T) => void;
  onSubmit?: (value: T) => void;
}

export const TextInput = forwardRef((props: IInputProps, ref) => {
  const {
    id,
    name = id,
    autoComplete = 'off',
    type = 'text',
    label = '',
    placeholder = '',
    disabled = false,
    optional = false,
    optionalLabel = false,
    $plain = false,
    className,
    style,
    onFocus,
    autoFocus = false,
    independent = false,
    defaultValue,
    value,
    onValueChange,
    onSubmit,
  } = props;

  const fieldProps = useFieldProps(name, optional);
  const error = useFieldError(name);

  return (
    <Wrapper className={className} style={style}>
      {label && (
        <Label disabled={disabled}>
          {label}
          {optionalLabel && <span> (optional)</span>}
        </Label>
      )}
      <Input
        {...(independent
          ? {
              as: 'input',
              defaultValue,
              value,
              onChange: onValueChange ? ({ target }: any) => onValueChange(target.value) : undefined,
              onKeyDown: onSubmit
                ? (e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                      onSubmit(e.target.value);
                    }
                  }
                : undefined,
            }
          : { ...fieldProps })}
        {...(type === 'textarea' ? { as: 'textarea' as any } : {})}
        id={id}
        autoComplete={autoComplete}
        placeholder={placeholder}
        type={type}
        $error={!!error?.message}
        disabled={disabled}
        optional={optional}
        $plain={$plain}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
      <ErrorLabel>{error?.message}</ErrorLabel>
    </Wrapper>
  );
});
