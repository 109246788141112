import { Info, InfoBold, ListRowControlsWrapper, ListRowWrapper, OptionsWrapper, Row } from '../../atoms/List';
import { TourLabel } from '../../labels/TourLabel';
import { getCurrentDayDate, getISODate } from '../../../../utils/dateUtils';
import { Checkmark } from '../../../icons/Checkmark';
import { ChevronDown } from '../../../icons/ChevronDown';
import React, { FC, useState } from 'react';
import { IScheduledTourWithMetaData } from '../../../../api/models/ScheduledTour';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../styles/Colors';
import { Close } from '../../../icons/Close';
import { Body3 } from '../../../../styles/FontStyles';
import { Collapsible } from '../../atoms/Collapsible';
import { Wheelchair } from '../../../icons/Wheelchair';
import { Walker } from '../../../icons/Walker';
import { Facilities } from '../../../icons/Facilities';
import { Clickable } from '../../atoms/Clickable';
import SelectedDriverView from './molecules/SelectedDriverView';
import { IConflict } from '../../../../api/models/Conflict';
import { DIRECTION_LABEL } from '../../../../utils/directionLabelUtil';
import ConflictsForTourTooltips from './ConflictsForTourTooltips';
import PassengersContainer from '../../../../container/PassengersContainer';

const StyledRow = styled(Row)<{ lineThrough?: boolean }>`
  align-items: center;

  em {
    color: ${Colors.signalRed900};
    font-style: normal;
  }

  i {
    color: ${Colors.signalGreen900};
    font-style: normal;
  }

  i {
    color: ${Colors.signalGreen900};
    font-style: normal;
  }

  mark {
    color: ${Colors.signalOrange};
    font-style: normal;
    background-color: transparent;
  }
`;

const TourRow = styled(StyledRow)<{ active: boolean }>`
  margin-inline: -0.5rem;
  padding-inline: 0.5rem;
  margin-block: -1rem -0.5rem;
  padding-block: 1rem 0.5rem;

  transition: background-color 100ms ease-out;
  background-color: ${({ active }) => (active ? Colors.grey400 : 'transparent')};

  ${({ lineThrough = false }) =>
    lineThrough &&
    css`
      & > *:not(:first-child) {
        text-decoration: line-through;
        text-decoration-color: ${Colors.signalRed900};
      }
    `};
`;

const InfoColumn = styled(Info)`
  flex-direction: column;
  align-items: flex-start;
`;

const CancelledIcon = styled(Close)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
`;

const DriverInfo = styled(Info)`
  ${Body3};
  overflow: visible;
`;

const CapacityInfo = styled(Info)`
  svg {
    margin-inline-start: -0.25rem;
  }
`;

const LargeClickArea = styled(Clickable)`
  display: flex;
  margin: -1.25rem;
  padding: 1.25rem;
`;

const TourRowCollapsible = styled(Collapsible)`
  overflow: visible;
`;

const TargetInfo = styled(Info)`
  padding-inline: 0.5rem;
  color: ${Colors.grey600};
`;

const TrackLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &:first-child {
    margin-block-start: 0.5rem;
  }

  &:last-child > * {
    border-bottom-color: transparent;
  }
`;

const TrackContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  padding-block: 1rem;
  border-bottom: 1px solid ${Colors.grey500};
`;

export const Track = styled.div<{ showBefore?: boolean; showAfter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  inline-size: 1.5rem;

  color: ${Colors.grey600};

  &::before {
    content: '';
    inline-size: 0;
    flex: 1;
    border-left: 1px dashed ${({ showBefore }) => (showBefore ? Colors.grey600 : 'transparent')};
  }

  &::after {
    content: '';
    inline-size: 0;
    flex: 1;
    border-left: 1px dashed ${({ showAfter }) => (showAfter ? Colors.grey600 : 'transparent')};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-block: 0.5rem;
  }
`;

export const TourListRowRefactored: FC<{ day: string; schedule: IScheduledTourWithMetaData; conflictsForTour?: IConflict[] }> = (props) => {
  const { schedule, day, conflictsForTour = [] } = props;
  const date = new Date(day);

  const { startTimeStr, endTimeStr, isTourCancelled, originalTourId, numberOfWalkers, numberOfWheelchairs, tour, reason } = schedule;

  const { name, direction, customer, driver, companion, departureDate, arrivalDate, isException, endDate } = tour;

  const endDateString = endDate ? ` bis ${new Date(endDate).toLocaleDateString(undefined, { dateStyle: 'medium' })}` : undefined;

  const [showDetails, setShowDetails] = useState(false);

  return (
    <ListRowWrapper href={`/tours/${originalTourId}?date=${getISODate(day)}`}>
      <TourRow lineThrough={isTourCancelled} active={showDetails}>
        <InfoColumn style={{ overflow: 'visible' }}>
          <Info style={{ overflow: 'visible' }}>
            <TourLabel>
              {
                // finished
                !isTourCancelled && date < getCurrentDayDate() && <Checkmark />
              }
              {
                // cancelled
                isTourCancelled && <CancelledIcon />
              }
              {name}
            </TourLabel>
            <ConflictsForTourTooltips
              conflictsForTour={conflictsForTour}
              date={date}
              isException={isException}
              isTourCancelled={isTourCancelled}
              reason={reason}
            />
            {DIRECTION_LABEL[direction || 'na']}
          </Info>
          {isTourCancelled && originalTourId && (
            <Info>
              <em>
                fällt aus
                {endDateString && endDateString}
              </em>
            </Info>
          )}
          {!isTourCancelled && isException && (
            <Info>
              <i>vorübergehend{endDateString && endDateString}</i>
            </Info>
          )}
        </InfoColumn>
        <InfoColumn>
          <InfoBold>{customer?.displayName}</InfoBold>
          <div>
            <Info>{customer?.address?.addressLine1}</Info>
            <Info>
              {customer?.address?.zipCode} {customer?.address?.city}
            </Info>
          </div>
        </InfoColumn>
        <DriverInfo>
          <SelectedDriverView
            title={'Fahrer'}
            driver={driver}
            conflict={
              (day &&
                driver &&
                conflictsForTour.find(({ reason }) =>
                  ['driver_duplicated', 'driver_unavailable', 'driver_not_set', 'unset_pickupdate'].includes(reason),
                )) ||
              undefined
            }
          />
        </DriverInfo>
        <DriverInfo>
          {companion && (
            <SelectedDriverView
              title={'Begleitung'}
              driver={companion}
              conflict={
                (day &&
                  driver &&
                  conflictsForTour.find(({ reason }) => ['companion_duplicated', 'companion_unavailable'].includes(reason))) ||
                undefined
              }
            />
          )}
        </DriverInfo>
        <Info>{[startTimeStr, endTimeStr].filter(Boolean).join(' - ')}</Info>
        <CapacityInfo>
          {numberOfWheelchairs > 0 && (
            <>
              {numberOfWheelchairs} <Wheelchair />
            </>
          )}
          {numberOfWalkers > 0 && (
            <>
              {numberOfWalkers} <Walker />
            </>
          )}
        </CapacityInfo>

        <ListRowControlsWrapper>
          <OptionsWrapper>
            <LargeClickArea
              onClick={(e) => {
                e.preventDefault();
                setShowDetails(!showDetails);
              }}
            >
              <ChevronDown invert={showDetails} />
            </LargeClickArea>
          </OptionsWrapper>
        </ListRowControlsWrapper>
      </TourRow>
      <TourRowCollapsible open={showDetails}>
        {direction === 'return' && (
          <TrackLayout>
            <Track showBefore={false} showAfter={true}>
              <Facilities />
            </Track>
            <TrackContent>
              <TargetInfo>
                <b>Start: {customer?.displayName}</b>
              </TargetInfo>
            </TrackContent>
          </TrackLayout>
        )}
        <PassengersContainer
          date={date}
          show={showDetails}
          direction={direction}
          customer={customer}
          originalTourId={originalTourId}
          departureDate={departureDate}
          arrivalDate={arrivalDate}
          type="row"
        />
        {direction === 'outwards' && (
          <TrackLayout>
            <Track showBefore={true} showAfter={false}>
              <Facilities />
            </Track>
            <TrackContent>
              <TargetInfo>
                <b>Ziel: {customer?.displayName}</b>
              </TargetInfo>
            </TrackContent>
          </TrackLayout>
        )}
      </TourRowCollapsible>
    </ListRowWrapper>
  );
};
