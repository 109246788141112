import { recoilPersist } from 'recoil-persist';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

const { persistAtom } = recoilPersist({
  key: 'auth',
  storage: localStorage,
});

interface ISession {
  authToken: string;
}

export const AuthState = atom<ISession | null>({
  key: 'AuthState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const useAuthState = () => useRecoilState(AuthState);
export const useAuthToken = () => useRecoilValue(AuthState)?.authToken;
export const useAuthHeader = () => {
  const authToken = useAuthToken();

  if (authToken) {
    return {
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    };
  }

  return null;
};
