import React from 'react';
import { ICustomerOrganization } from '../../../../api/models/CustomerOrganization';
import { useShowDeleteCustomerOrganizationDialog } from '../../../../hooks/customer_organizations/useShowDeleteCustomerOrganizationDialog';
import { useNavigate } from '../../../../hooks/useNavigate';
import { useHasPermission } from '../../../../state/UserMeState';
import { Edit } from '../../../icons/Edit';
import { Trash } from '../../../icons/Trash';
import { Info, InfoBold, ListRowControlsWrapper, ListRowWrapper, OptionsWrapper, Row } from '../../atoms/List';
import { ContextOptions } from '../ContextOptions';

export const CustomerOrganizationListRow: React.FC<{ customerOrganization: ICustomerOrganization; onUpdate?: () => void }> = (props) => {
  const { id, name, description, customers } = props.customerOrganization;

  const showDeleteCustomerOrganizationDialog = useShowDeleteCustomerOrganizationDialog(id);

  const navigate = useNavigate();
  const isAdmin = useHasPermission('admin');

  return (
    <ListRowWrapper>
      <Row>
        <InfoBold>{name || '-'}</InfoBold>
        <Info>{description || '-'}</Info>
        <Info>{customers?.length || '-'}</Info>

        <ListRowControlsWrapper>
          <OptionsWrapper>
            <ContextOptions
              items={
                [
                  isAdmin && {
                    content: (
                      <>
                        <Trash /> Entfernen
                      </>
                    ),
                    onClick: () => showDeleteCustomerOrganizationDialog(props.onUpdate),
                  },
                  {
                    content: (
                      <>
                        <Edit /> Bearbeiten
                      </>
                    ),
                    onClick: () => navigate(`/customer-organizations/${id}/edit`),
                  },
                ].filter(Boolean) as []
              }
            />
          </OptionsWrapper>
        </ListRowControlsWrapper>
      </Row>
    </ListRowWrapper>
  );
};
