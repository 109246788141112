export const Mail = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21 6H12H3C2.44772 6 2 6.44772 2 7V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V7C22 6.44772 21.5523 6 21 6Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.99998 8.1L2 6.99998C2.00001 6.4477 2.44773 6 3 6H21C21.5523 6 22 6.44773 22 7.00002L22 8.1L12 13L1.99998 8.1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
