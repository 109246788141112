export const Walker = (props: any) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.334" cy="4" r="2" stroke="currentColor" />
    <rect
      width="3.92753"
      height="7.94672"
      rx="1"
      transform="matrix(1 0 -0.0130782 0.999914 10.4375 9)"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path d="M10.334 13V22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.334 10L8.33398 16" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.334 10L16.334 16" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.334 13V22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
