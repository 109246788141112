import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IBulkCancellationProps, useSchedulesAPI } from '../../../../api/controllers/SchedulesAPI';
import { Region } from '../../../../api/models/Region';
import { useCustomers } from '../../../../state/CustomerState';
import { useLatestBookingCompletionDate } from '../../../../state/LatestBookingCompletionState';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { Colors } from '../../../../styles/Colors';
import { getCurrentDayDate, getISODate } from '../../../../utils/dateUtils';
import { ButtonPrimary, ButtonSecondary, ChooseButton } from '../../inputs/Button';
import { Calendar } from '../../inputs/Calendar';
import { Dropdown } from '../../inputs/Dropdown';
import { TextInput } from '../../inputs/TextInput';
import { LoadingSpinner } from '../../loaders/LoadingSpinner';
import { Dialog } from '../Dialog';

const ButtonRed = styled(ButtonPrimary)`
  background-color: ${Colors.signalRed900};
  color: ${Colors.textInverted};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  text-align: start;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;

  color: ${Colors.grey700};
`;

const CommentInput = styled(TextInput)`
  label {
    margin: 0;
  }

  textarea {
    max-width: none;
  }
`;

const StyledDialog = styled(Dialog)`
  ${BREAKPOINT_MD} {
    overflow: visible;
  }
`;

interface BulkCancellationDialogProps {
  onClose: () => void;
}
export default function BulkCancellationDialog(props: BulkCancellationDialogProps) {
  const { onClose } = props;

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(getCurrentDayDate(new Date()));
  const [endDate, setEndDate] = useState<Date | null>(getCurrentDayDate(new Date()));

  const [includeTourOnInvoice, setIncludeTourOnInvoice] = useState(undefined as boolean | undefined);
  const [comment, setComment] = useState('');
  const [region, setRegion] = useState<Region>();
  // const [customers, setCustomers] = useState(Array<{ id: string; label: string }>()); // [Customer]
  const [customer, setCustomer] = useState<number>();
  const [bulkCancellationReason, setBulkCancellationReason] = useState<string>('');

  const schedulesAPI = useSchedulesAPI();
  const queryClient = useQueryClient();
  const bookingCompletionDate = useLatestBookingCompletionDate();

  const { data: customers, refetch } = useCustomers(region);

  useEffect(
    () => {
      if (region) {
        refetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [region],
  );

  const maxDate = useMemo(() => {
    if (startDate) {
      setEndDate(startDate);
      const newMaxDate = new Date(startDate);
      newMaxDate.setDate(newMaxDate.getDate() + 5);
      return newMaxDate;
    }
  }, [startDate]);

  const minDate = useMemo(() => {
    if (bookingCompletionDate) {
      const newMinDate = new Date(bookingCompletionDate);
      newMinDate.setDate(newMinDate.getDate() + 1);
      return newMinDate;
    }
  }, [bookingCompletionDate]);

  const handleSubmit = () => {
    if (!startDate || !endDate || !bulkCancellationReason) {
      alert('Bitte wählen Sie einen gültigen Zeitraum aus.');
      return;
    }

    if (includeTourOnInvoice === undefined) {
      alert('Bitte legen sie Fest, ob die Fahrten abgerechnet werden sollen.');
      return;
    }
    setLoading(true);

    const cancellationParams: IBulkCancellationProps = {
      startDate: getISODate(startDate!),
      endDate: getISODate(endDate!),
      reason: 'cancelled',
      bulkCancellationReason: bulkCancellationReason,
      includeTourOnInvoice: includeTourOnInvoice,
      comment: comment,
    };

    if (customer) {
      cancellationParams.customerId = customer;
    }

    if (region) {
      cancellationParams.region = region;
    }

    Promise.all([
      // create exception for schedule
      schedulesAPI.bulkCancellation(cancellationParams),
    ])
      .then(() => {
        setIncludeTourOnInvoice(undefined);
        onClose();
        queryClient.resetQueries();
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <StyledDialog
      open={true}
      onClosed={onClose}
      headline="Alle Fahrten absagen"
      text={
        <Body>
          <p>Sollen alle Fahrten im folgenden Zeitraum ersatzlos abgesagt werden?</p>
          <GridRow>
            <Label>
              <CommentInput
                id={'cancel-tour-dialog:bulk-cancellation-reason'}
                independent
                value={bulkCancellationReason}
                onValueChange={setBulkCancellationReason}
                label={'Grund für die Absage'}
                placeholder={'Absagegrund...'}
              />
            </Label>
          </GridRow>
          <GridRow>
            <Label>
              <b>Von</b>
              <Calendar title={'TT/MM/JJJJ'} value={startDate} setValue={setStartDate as any} minDate={minDate} dropdownStyling />
            </Label>
            <Label>
              <b>Bis</b>
              <Calendar
                title={'TT/MM/JJJJ'}
                value={endDate}
                setValue={setEndDate as any}
                minDate={startDate}
                maxDate={maxDate}
                dropdownStyling
              />
            </Label>
            <Label>
              <Dropdown
                id="type"
                label="Region"
                defaultValue="all"
                value={region as Region}
                onValueChange={(item) => {
                  setRegion(item as Region);
                }}
                items={[
                  { label: 'Alle', value: '' },
                  { label: 'Düsseldorf', value: 'Düsseldorf' },
                  { label: 'Niederrhein', value: 'Niederrhein' },
                  { label: 'Wuppertal', value: 'Wuppertal' },
                  { label: 'Ruhrgebiet', value: 'Ruhrgebiet' },
                ]}
              />
            </Label>
            {customers && customers.length > 0 && (
              <Label>
                <Dropdown
                  id="type"
                  label="Einrichtung"
                  defaultValue=""
                  placeholder="Alle"
                  onValueChange={(item) => {
                    setCustomer(parseInt(item));
                  }}
                  items={customers || []}
                />
              </Label>
            )}
          </GridRow>
          <CommentInput
            id={'cancel-tour-dialog:comment'}
            type={'textarea'}
            independent
            value={comment}
            onValueChange={setComment}
            label={'Kommentar für den/die Fahrer'}
            placeholder={'Kommentar hinzufügen...'}
          />
          <b>Sollen diese Fahrten trotzdem abgerechnet werden?</b>
          <GridRow>
            <ChooseButton onClick={() => setIncludeTourOnInvoice(true)} isActive={includeTourOnInvoice}>
              Ja
            </ChooseButton>
            <ChooseButton onClick={() => setIncludeTourOnInvoice(false)} isActive={includeTourOnInvoice === false}>
              Nein
            </ChooseButton>
          </GridRow>
        </Body>
      }
      buttons={
        <>
          <ButtonSecondary
            onClick={() => {
              setIncludeTourOnInvoice(undefined);
              onClose();
            }}
          >
            Abbrechen
          </ButtonSecondary>
          <ButtonRed onClick={handleSubmit} disabled={includeTourOnInvoice === undefined}>
            {loading ? <LoadingSpinner /> : 'Fahrten absagen'}
          </ButtonRed>
        </>
      }
    />
  );
}
