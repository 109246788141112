export const Board = (props: any) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.5 5.77778V11.1111M19.5 5.77778V14.6667C19.5 15.6485 18.53 16.4444 17.3333 16.4444H15.1667C13.97 16.4444 13 15.6485 13 14.6667V5.77778M19.5 5.77778C19.5 4.79594 18.53 4 17.3333 4H15.1667C13.97 4 13 4.79594 13 5.77778M13 5.77778V18.2222C13 19.2041 12.0299 20 10.8333 20H8.66667C7.47005 20 6.5 19.2041 6.5 18.2222V5.77778C6.5 4.79594 7.47005 4 8.66667 4H10.8333C12.0299 4 13 4.79594 13 5.77778Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
