import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { IPassenger } from '../../../../api/models/Passenger';
import { useShowDeletePassengerDialog } from '../../../../hooks/passengers/useShowDeletePassengerDialog';
import { useNavigate } from '../../../../hooks/useNavigate';
import { useHasPermission } from '../../../../state/UserMeState';
import { Colors } from '../../../../styles/Colors';
import { Body4Bold } from '../../../../styles/FontStyles';
import { formatAddress } from '../../../../utils/addressUtils';
import { formatName } from '../../../../utils/nameUtils';
import { getTelLink } from '../../../../utils/phoneUtils';
import { Checkmark } from '../../../icons/Checkmark';
import { Edit } from '../../../icons/Edit';
import { Mail } from '../../../icons/Mail';
import { Phone } from '../../../icons/Phone';
import { Trash } from '../../../icons/Trash';
import { Walker } from '../../../icons/Walker';
import { Wheelchair } from '../../../icons/Wheelchair';
import { Avatar } from '../../atoms/Avatar';
import { ActionButton, Info, ListRowControlsWrapper, ListRowWrapper, OptionsWrapper, Row } from '../../atoms/List';
import { ContextOptions } from '../ContextOptions';

const Checkbox = styled.div<{ checked: boolean | undefined }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;

  border: 1px solid ${Colors.grey700};
  border-radius: 6px;

  color: ${Colors.white50};

  transition: border-color 150ms ease-out, background-color 150ms ease-out;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    opacity: 0;

    transition: opacity 150ms ease-out;
  }

  ${({ checked = false }) =>
    checked &&
    css`
      border-color: ${Colors.grey900};
      background-color: ${Colors.grey900};

      svg {
        opacity: 1;
      }
    `};
`;

const StyledAvatar = styled(Avatar)`
  ${Body4Bold};
  font-size: 0.75rem;
  margin-block: -0.25rem;
`;

interface IPassengerListRowProps {
  passenger: IPassenger;
  selectable?: boolean;
  showCustomer?: boolean;
  showAddress?: boolean;
  selected?: boolean;
  onSelect?: () => void;
  onUpdate?: () => void;
}

export const PassengerListRow: FC<IPassengerListRowProps> = React.memo((props) => {
  const { selectable = false, showCustomer = true, showAddress = false, selected, onSelect, onUpdate } = props;

  const { id, firstName, lastName, hasWheelchair, customer, contactPersons = [], addresses = [] } = props.passenger;

  const navigate = useNavigate();
  const showDeletePassengerDialog = useShowDeletePassengerDialog(id);
  const isAdmin = useHasPermission('admin');

  const content = useMemo(
    () => (
      <>
        <StyledAvatar src={''} firstName={firstName} lastName={lastName} size={'mini'} />
        <Info>{formatName({ firstName, lastName }) || '-'}</Info>
        <Info>{'-'}</Info>
        <Info>
          {hasWheelchair ? (
            <>
              <Wheelchair /> Rollstuhlfahrer
            </>
          ) : (
            <>
              <Walker /> Läufer
            </>
          )}
        </Info>
        {showCustomer && <Info>{customer?.displayName || '-'}</Info>}
        {showAddress && <Info>{formatAddress(addresses[0])}</Info>}
      </>
    ),
    [firstName, lastName, hasWheelchair, showCustomer, customer?.displayName, showAddress, addresses],
  );

  const contextOptions = useMemo(() => {
    const contactPhone = contactPersons.find(({ phoneNumber }) => !!phoneNumber);
    const contactEmail = contactPersons.find(({ emailAddress }) => !!emailAddress);

    return (
      <>
        {!selectable && (
          <ListRowControlsWrapper>
            <OptionsWrapper>
              <ActionButton href={getTelLink(contactPhone?.phoneNumber) || ''} onClick={(e) => e.stopPropagation()}>
                <Phone />
              </ActionButton>
              <ActionButton href={contactEmail ? `mailto:${contactEmail.emailAddress}` : ''} onClick={(e) => e.stopPropagation()}>
                <Mail />
              </ActionButton>
            </OptionsWrapper>

            <OptionsWrapper>
              <ContextOptions
                as="div"
                items={
                  [
                    isAdmin && {
                      content: (
                        <>
                          <Trash /> Entfernen
                        </>
                      ),
                      onClick: () => showDeletePassengerDialog(onUpdate),
                    },
                    {
                      content: (
                        <>
                          <Edit /> Bearbeiten
                        </>
                      ),
                      onClick: () => navigate(`/passengers/${id}/edit`),
                    },
                  ].filter(Boolean) as []
                }
              />
            </OptionsWrapper>
          </ListRowControlsWrapper>
        )}
      </>
    );
  }, [selectable, contactPersons, isAdmin, id, navigate, onUpdate, showDeletePassengerDialog]);

  return (
    <ListRowWrapper
      selected={selected}
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          if (selectable) {
            onSelect?.();
          } else {
            navigate(`/passengers/${id}`);
          }
        }
      }}
    >
      <Row>
        {selectable && (
          <Checkbox checked={selected}>
            <Checkmark />
          </Checkbox>
        )}
        {content}
        {contextOptions}
      </Row>
    </ListRowWrapper>
  );
});
