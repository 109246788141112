import styled from 'styled-components';
import React, { FC } from 'react';
import { Avatar } from '../../atoms/Avatar';
import { IEmployee } from '../../../../api/models/Employee';
import { Body4Bold } from '../../../../styles/FontStyles';
import { EmployeeActiveLabel } from '../../labels/ActiveLabel';
import { EmployeeContextOptions } from './EmployeeContextOptions';
import { LocationTag } from '../LocationTag';
import { getTelLink } from '../../../../utils/phoneUtils';
import { ActionButton, Info, InfoBold, ListRowWrapper, OptionsWrapper, Row, ListRowControlsWrapper } from '../../atoms/List';
import { Phone } from '../../../icons/Phone';
import { formatName } from '../../../../utils/nameUtils';
import { useNavigate } from '../../../../hooks/useNavigate';

const ControlsWrapper = styled(ListRowControlsWrapper)`
  inline-size: 20rem;
`;

const EmployeeAvatar = styled(Avatar)`
  ${Body4Bold};
  font-size: 0.75rem;
  margin-block: -0.25rem;
`;

const Name = styled(Info)`
  flex-wrap: wrap;
  white-space: initial;
  text-align: start;
`;

interface IEmployeeTileProps {
  employee: IEmployee;
  onUpdate?: () => void;
}

export const EmployeeListRow: FC<IEmployeeTileProps> = (props) => {
  const {
    id,
    firstName,
    lastName,
    image,
    jobTitle = 'Mitarbeiter',
    drivingLicense = '-',
    region,
    internalId,
    phoneNumbers = [],
  } = props.employee;

  const navigate = useNavigate();

  return (
    <ListRowWrapper
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          navigate(`/employees/${id}`);
        }
      }}
    >
      <Row>
        <EmployeeAvatar src={image?.url} firstName={firstName} lastName={lastName} size={'mini'} />

        <InfoBold>{internalId || '-'}</InfoBold>
        <Name>{formatName({ firstName, lastName }) || '-'}</Name>
        <Info>{jobTitle}</Info>
        <Info>{drivingLicense || '-'}</Info>
        <LocationTag address={region} />

        <ControlsWrapper>
          <OptionsWrapper>
            <ActionButton href={getTelLink(phoneNumbers[0]?.phoneNumber) || ''} onClick={(e) => e.stopPropagation()}>
              <Phone />
            </ActionButton>
            <EmployeeActiveLabel employee={props.employee} />
          </OptionsWrapper>

          <OptionsWrapper>
            <EmployeeContextOptions employee={props.employee} onUpdate={props.onUpdate} />
          </OptionsWrapper>
        </ControlsWrapper>
      </Row>
    </ListRowWrapper>
  );
};
