import React from 'react';
import styled from 'styled-components';

import { IScheduledTour } from '../../../../../api/models/ScheduledTour';
import { Clickable } from '../../../atoms/Clickable';

import DateTitle from '../../../atoms/DateTitle';
import DatePickerDialog from './DatePickerDialog';

import { ITourConflictsResult } from '../../../../../state/TourConflictsState';
import { BREAKPOINT_XL } from '../../../../../styles/Breakpoints';
import { Calendar } from '../../../../icons/Calendar';


const DatePickerIcon = styled(Calendar)`
  margin-block: -1rem -0.5rem;

  ${BREAKPOINT_XL} {
    display: none;
  }
`;

interface ITourDetailDateTileProps {
  timestamp: string;
  proceedWithoutSaving: (onProceed: () => void) => void;
  setTimestamp: (value: string) => void;
  showDatePickerDialog: boolean;
  setShowDatePickerDialog: (value: boolean) => void;
  isCurrentDayDriven: boolean;
  startTime: string;
  endTime: string;
  tour: IScheduledTour;
  scheduleConflicts: ITourConflictsResult | undefined;
}

const DateTile: React.FC<ITourDetailDateTileProps> = (props) => {
  const {
    timestamp,
    setTimestamp,
    proceedWithoutSaving,
    showDatePickerDialog,
    setShowDatePickerDialog,
    isCurrentDayDriven,
    startTime,
    endTime,
    tour,
    scheduleConflicts,
  } = props;
  return (
    <DateTitle
      timestamp={timestamp}
      before={
        <>
          <DatePickerDialog
            open={showDatePickerDialog}
            setOpen={setShowDatePickerDialog}
            timestamp={timestamp}
            setTimestamp={(value) => {
              proceedWithoutSaving(() => {
                setTimestamp(value);
              });
            }}
            currentSchedule={{
              schedule: tour,
              rootScheduleExceptions: tour.exceptions || [],
              scheduleConflicts,
            }}
          />
          <Clickable onClick={() => setShowDatePickerDialog(true)}>
            <DatePickerIcon />
          </Clickable>
        </>
      }
    >
      {isCurrentDayDriven && (
        <span>
          {[startTime, endTime]
            .map((date) => date && new Date(date).toLocaleTimeString(undefined, { timeStyle: 'short' }))
            .filter(Boolean)
            .join(' - ')}
        </span>
      )}
    </DateTitle>
  );
};
export default DateTile;
