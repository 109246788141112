import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { fetchToursParams, useScheduledToursForDayAPI } from '../../../api/controllers/tours/ScheduledToursForDayAPI';
import { IConflict } from '../../../api/models/Conflict';
import { IScheduledTourWithMetaData } from '../../../api/models/ScheduledTour';
import { TourListSettingState } from '../../../state/TourListSettingState';
import { getCurrentDayDate, getISODate } from '../../../utils/dateUtils';
import { Clickable } from '../../common/atoms/Clickable';
import { HighlightedLink } from '../../common/atoms/Link';
import ToursForDayRefactored from '../../common/elements/tours/ToursForDayRefactored';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';

const LoadMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-inline: auto;
  padding: 1rem;
  padding-block-end: 1.5rem;
`;

const LoadMoreButton = styled(HighlightedLink).attrs({ as: Clickable })<{ onClick: () => void } & any>`
  display: flex;
  pointer-events: auto;
  user-select: auto;
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
`;

export default function ScheduleGroupedByDayList({ day, conflicts }: { day: string; conflicts?: IConflict[] }) {
  const [loading] = useState(false);
  const [schedules, setSchedules] = useState<{ hits: IScheduledTourWithMetaData[]; hasMore: boolean }>({
    hits: [],
    hasMore: false,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const scrollToCurrentDay = useRef(false);
  const [initLoadDone, setInitLoadDone] = useState(false);
  const { fetchTours } = useScheduledToursForDayAPI();

  const [toursSettings] = useRecoilState(TourListSettingState);

  const handleFetchTours = (refresh: boolean = false) => {
    const { customerIds, regions, directions, showOnlyCancelled, hideCancelled } = toursSettings.filters;

    const cancelled = (function () {
      if (showOnlyCancelled) {
        return 'only';
      } else if (hideCancelled) {
        return 'hide';
      } else {
        return 'include';
      }
    })();

    const limit = 10;

    const params = {
      dayAsISODate: getISODate(day),
      sortBy: 'time',
      query: toursSettings.query,
      customerIds,
      regions,
      directions,
      cancelled,
      page: {
        offset: currentPage * limit,
        limit: limit,
      },
    } as fetchToursParams;

    fetchTours(params).then((res) => {
      if (refresh) {
        setInitLoadDone(true);
        setCurrentPage(0);
        setSchedules({ hits: res.data.items, hasMore: res.data.hasMore });
      } else {
        setSchedules({
          hits: [...schedules.hits, ...res.data.items],
          hasMore: res.data.hasMore,
        });
      }
    });
  };

  // Handles changes from pagination
  useEffect(() => {
    if (initLoadDone && currentPage > 0) {
      handleFetchTours();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Handles changes from filters
  useEffect(() => {
    if (initLoadDone) {
      setSchedules({ hits: [], hasMore: true });
      handleFetchTours(true);
    } else {
      setInitLoadDone(true);
      handleFetchTours();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toursSettings.filters, toursSettings.query]);

  useEffect(() => {
    if (scrollToCurrentDay.current) {
      scrollToCurrentDay.current = false;

      // scroll to current day
      const calendarListWrapper = document.querySelector('[data-calendar-list]') as HTMLDivElement;
      const currentDayView = calendarListWrapper?.querySelector(`[data-day="${getCurrentDayDate().toISOString()}"]`) as HTMLDivElement;
      if (currentDayView) {
        calendarListWrapper?.scrollTo({
          top: currentDayView.offsetTop - 24,
          behavior: 'auto',
        });
      }
    }
  }, [schedules]);

  return (
    <ToursForDayRefactored day={day} schedules={schedules.hits} conflicts={conflicts}>
      <LoadMoreWrapper>
        {loading && <LoadingSpinner />}
        {!loading && schedules.hasMore && <LoadMoreButton onClick={() => setCurrentPage(currentPage + 1)}>Mehr anzeigen</LoadMoreButton>}
        {!schedules.hasMore && <>Keine weiteren Fahrten</>}
      </LoadMoreWrapper>
    </ToursForDayRefactored>
  );
}
