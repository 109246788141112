import styled, { css } from 'styled-components';
import { Body3, Body4, Body4Bold } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';
import { ReactNode, useCallback, useState } from 'react';
import Upload from '../../icons/Upload';
import { Clickable } from '../atoms/Clickable';
import { useShowDialog } from '../../../state/DialogState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;
`;

const TitleText = styled.p`
  ${Body4Bold};
`;

const FileDropContainer = styled(Clickable)<{ dragHover: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;

  block-size: 7rem;
  margin-block-end: 0.25rem;

  border: 1px dashed ${Colors.grey600};
  border-radius: 0.5rem;

  ${Body3};

  transition: border-color 150ms ease-out;

  ${({ dragHover }) =>
    dragHover &&
    css`
      border-color: ${Colors.grey800};
    `};
`;

const HintText = styled.p`
  ${Body4};
  color: ${Colors.grey700};

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

interface FileUploaderProps {
  title?: string;
  file: File | null;
  setFile: (file: File | null) => void;
  hint?: string | string[] | ReactNode;
  acceptedFileTypes?: string[]; // ['png', 'jpg', 'jpeg']
  acceptedFileSize?: number;
}
export default function FileUploader(props: FileUploaderProps) {
  const {
    title,
    file,
    setFile,
    hint,
    acceptedFileTypes = ['png', 'jpg', 'jpeg'],
    acceptedFileSize = 1000000, // 1 MB
  } = props;

  const showDialog = useShowDialog();
  const [dragHover, setDragHover] = useState(false);

  const selectFile = useCallback(
    (file: File | null) => {
      if (file && acceptedFileTypes.some((type) => file.type.includes(type)) && file.size <= acceptedFileSize) {
        setFile(file);
      } else {
        showDialog({
          headline: 'Datei hochladen',
          body: 'Die ausgewählte Datei erfüllt nicht die Auswahlkriterien. Bitte beachten Sie den Dateityp und die Dateigröße.',
        });
      }
    },
    [acceptedFileTypes, acceptedFileSize, setFile, showDialog],
  );

  return (
    <Wrapper>
      {title && <TitleText>{title}</TitleText>}
      <FileDropContainer
        dragHover={dragHover}
        onClick={() => {
          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.name = 'file';
          fileInput.accept = acceptedFileTypes.map((type) => `.${type}`).join(',');
          fileInput.size = acceptedFileSize;
          fileInput.addEventListener('change', (e) => {
            e.preventDefault();
            if ((fileInput.files || []).length > 0) {
              selectFile(fileInput.files![0]);
            }
          });
          fileInput.click();
        }}
        onDrop={(e) => {
          e.preventDefault();
          setDragHover(false);
          if (e.dataTransfer.files.length > 0) {
            selectFile(e.dataTransfer.files[0]);
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setDragHover(true);
        }}
        onDragLeave={() => {
          setDragHover(false);
        }}
      >
        {(file === null || dragHover) && (
          <>
            <Upload />
            <span>{dragHover ? 'hier ablegen' : 'Datei auswählen'}</span>
          </>
        )}
        {file && !dragHover && <span>{file.name}</span>}
      </FileDropContainer>
      {hint && <HintText>{Array.isArray(hint) ? hint.map((item, index) => <span key={index}>{item}</span>) : hint}</HintText>}
    </Wrapper>
  );
}
