import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Body4 } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';

const Text = styled.p`
  ${Body4};
  color: ${Colors.grey500};

  display: flex;
  text-align: center;

  margin-inline: auto;
  margin-block: 0.5rem -1rem;

  max-inline-size: 100%;
  block-size: 1.5rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IAppVersionTitleProps {
  className?: string;
  style?: CSSProperties;
}

function AppVersionTitle(props: IAppVersionTitleProps) {
  const { REACT_APP_COMMIT_TIMESTAMP = '2024-02-08T12:35:44.667Z', REACT_APP_COMMIT_SHORT_SHA = '1b90cdf7' } = process.env;

  const version = REACT_APP_COMMIT_TIMESTAMP
    ? [
        new Date(REACT_APP_COMMIT_TIMESTAMP).toLocaleTimeString('de', { timeStyle: 'short' }).replace(/:/g, ''),
        new Date(REACT_APP_COMMIT_TIMESTAMP).toLocaleDateString('de', { dateStyle: 'short' }).replace(/\./g, ''),
      ].join('')
    : '';
  const commitSHA = REACT_APP_COMMIT_SHORT_SHA || '';

  const title = `eFDL v1.0 ${version}`;

  if (version) {
    return (
      <Text {...props} title={`${title} - Build: ${commitSHA}`}>
        {title}
      </Text>
    );
  }

  return <></>;
}

export default React.memo(AppVersionTitle);
