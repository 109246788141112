import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { VehicleForm } from '../../content/vehicles/VehicleForm';
import { useVehiclesAPI } from '../../../api/controllers/VehiclesAPI';
import { IVehicle } from '../../../api/models/Vehicle';
import { ContentPageLayout } from '../../common/atoms/Layout';

export const VehicleEdit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const vehiclesAPI = useVehiclesAPI();
  const [vehicle, setVehicle] = useState<IVehicle | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      vehiclesAPI
        .getVehicleById({ id })
        .then((res) => setVehicle(res.data))
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Fuhrpark Übersicht',
            href: '/vehicles',
          },
        ]}
      />
      {loading ? <LoadingSpinner /> : <VehicleForm vehicle={vehicle || undefined} />}
    </ContentPageLayout>
  );
};
