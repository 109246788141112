export const Info = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83909 11.2832C9.83909 10.731 10.2868 10.2832 10.8391 10.2832H12.2059C12.7582 10.2832 13.2059 10.731 13.2059 11.2832V15.94H14.4117C14.964 15.94 15.4117 16.3877 15.4117 16.94C15.4117 17.4923 14.964 17.94 14.4117 17.94H10C9.44772 17.94 9 17.4923 9 16.94C9 16.3877 9.44772 15.94 10 15.94H11.2059V12.2832H10.8391C10.2868 12.2832 9.83909 11.8355 9.83909 11.2832Z"
      fill="#FFFFFF"
    />
    <path
      d="M13.4385 8.30848C13.4385 9.03114 12.8527 9.61697 12.13 9.61697C11.4073 9.61697 10.8215 9.03114 10.8215 8.30848C10.8215 7.58583 11.4073 7 12.13 7C12.8527 7 13.4385 7.58583 13.4385 8.30848Z"
      fill="#FFFFFF"
    />
  </svg>
);

export const InfoInverted = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83909 11.2832C9.83909 10.731 10.2868 10.2832 10.8391 10.2832H12.2059C12.7582 10.2832 13.2059 10.731 13.2059 11.2832V15.94H14.4117C14.964 15.94 15.4117 16.3877 15.4117 16.94C15.4117 17.4923 14.964 17.94 14.4117 17.94H10C9.44772 17.94 9 17.4923 9 16.94C9 16.3877 9.44772 15.94 10 15.94H11.2059V12.2832H10.8391C10.2868 12.2832 9.83909 11.8355 9.83909 11.2832Z"
      fill="#0F0F0F"
    />
    <path
      d="M13.4385 8.30848C13.4385 9.03114 12.8527 9.61697 12.13 9.61697C11.4073 9.61697 10.8215 9.03114 10.8215 8.30848C10.8215 7.58583 11.4073 7 12.13 7C12.8527 7 13.4385 7.58583 13.4385 8.30848Z"
      fill="#0F0F0F"
    />
  </svg>
);
