import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { TabStatusBarLayout } from '../../common/elements/TabStatusBarLayout';
import { PassengerDetailsStatusCard } from '../../common/elements/passenger/PassengerDetailsStatusCard';
import { PassengerDetailsInformation } from '../../common/elements/passenger/PassengerDetailsInformation';
import { PassengerDetailsContacts } from '../../common/elements/passenger/PassengerDetailsContacts';
import { EntityDetailsTours } from '../../common/elements/tours/EntityDetailsTours';
import { usePassengersAPI } from '../../../api/controllers/PassengersAPI';
import { IPassenger } from '../../../api/models/Passenger';
import { formatName } from '../../../utils/nameUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
`;

export const PassengerDetails: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const passengersAPI = usePassengersAPI();
  const [loading, setLoading] = useState(false);
  const [passenger, setPassenger] = useState<IPassenger | null>(null);

  const fetchPassenger = useCallback(() => {
    if (id) {
      setLoading(true);
      passengersAPI
        .getPassengerById({ id })
        .then((res) => {
          setPassenger(res.data);
        })
        .catch(() => {
          navigate('/404');
        })
        .finally(() => setLoading(false));
    } else {
      navigate('/404');
    }
  }, [id, setLoading, passengersAPI, setPassenger, navigate]);

  useEffect(() => {
    fetchPassenger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      {loading && <LoadingSpinner />}

      {!loading && passenger && (
        <TabStatusBarLayout
          breadcrumb={{
            title: 'Fahrgäste Übersicht',
            href: '/passengers',
          }}
          subPageBreadcrumb={{
            title: formatName(passenger)!,
            href: `/passengers/${passenger.id}`,
          }}
          header={<PassengerDetailsStatusCard passenger={passenger} />}
          items={[
            {
              title: 'Informationen',
              content: <PassengerDetailsInformation passenger={passenger} />,
              hash: '',
            },
            {
              title: 'Kontakt',
              content: <PassengerDetailsContacts passenger={passenger} updateHook={fetchPassenger} />,
              hash: '#contact',
            },
            {
              title: 'Fahrten',
              content: <EntityDetailsTours stickyTop passengerId={passenger.id} />,
              hash: '#tours',
            },
          ]}
        />
      )}
    </Wrapper>
  );
};
