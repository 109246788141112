// General
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { EmployeeDetailsStatusCard } from '../../common/elements/employee/EmployeeDetailsStatusCard';
import { TabStatusBarLayout } from '../../common/elements/TabStatusBarLayout';
import { EmployeeDetailsInformation } from '../../common/elements/employee/EmployeeDetailsInformation';
import { EntityDetailsTours } from '../../common/elements/tours/EntityDetailsTours';
import { EmployeeDetailsAbsences } from '../../common/elements/employee/EmployeeDetailsAbsences';
import { EmployeeDetailsDocuments } from '../../common/elements/employee/EmployeeDetailsDocuments';

// Controller
import { useEmployeesAPI } from '../../../api/controllers/EmployeesAPI';

// Types
import { IEmployee } from '../../../api/models/Employee';
import { formatName } from '../../../utils/nameUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
`;

export const EmployeeDetails: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const employeesAPI = useEmployeesAPI();
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState<IEmployee | null>(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      employeesAPI
        .getEmployeeById({ id })
        .then((res) => {
          setEmployee(res.data);
        })
        .catch(() => {
          navigate('/404');
        })
        .finally(() => setLoading(false));
    } else {
      navigate('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      {loading && <LoadingSpinner />}

      {!loading && employee && (
        <TabStatusBarLayout
          breadcrumb={{
            title: 'Mitarbeiter Übersicht',
            href: '/employees',
          }}
          subPageBreadcrumb={{
            title: formatName(employee)!,
            href: `/employees/${employee.id}`,
          }}
          header={<EmployeeDetailsStatusCard employee={employee} />}
          items={[
            {
              title: 'Informationen',
              content: <EmployeeDetailsInformation employee={employee} />,
              hash: '',
            },
            {
              title: 'Fahrten',
              content: <EntityDetailsTours stickyTop employeeId={employee.id} />,
              hash: '#tours',
            },
            {
              title: 'Abwesenheiten',
              content: (
                <EmployeeDetailsAbsences
                  employee={employee}
                  onAbsencesChange={(absences) => {
                    setEmployee((state) => {
                      if (state) {
                        const currentAbsences = (state.absences || [])
                          .map(({ id }) => id)
                          .sort()
                          .join(',');
                        const updatedAbsences = absences
                          .map(({ id }) => id)
                          .sort()
                          .join(',');
                        if (currentAbsences !== updatedAbsences) {
                          return {
                            ...state,
                            absences,
                          };
                        }
                      }
                      return state;
                    });
                  }}
                />
              ),
              hash: '#absences',
            },
            {
              title: 'Abrechnung',
              content: <EmployeeDetailsDocuments employee={employee} />,
              hash: '#accounting',
            },
          ]}
        />
      )}
    </Wrapper>
  );
};
