import styled from 'styled-components';
import React from 'react';
import { ToursList } from '../../content/tours/ToursList';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
  flex-grow: 1;
`;

export const Tours = () => {
  return (
    <Wrapper>
      <ToursList />
    </Wrapper>
  );
};
