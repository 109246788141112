import { IConflict } from '../api/models/Conflict';
import { useSchedulesAPI } from '../api/controllers/SchedulesAPI';
import { useQuery } from '@tanstack/react-query';

export interface ITourConflictsResult {
  [day: string]: IConflict[];
}

export interface IDayConflicts {
  tourIds: number[];
  conflicts?: IConflict[];
}

export const useTourConflicts = (startDate: string, endDate: string) => {
  const schedulesAPI = useSchedulesAPI();

  return useQuery({
    queryKey: ['conflicts', startDate, endDate],
    staleTime: 1000 * 5,
    queryFn: async () => {
      const { data } = await schedulesAPI
        .getConflicts({
          startDate,
          endDate,
        })
        .then((res) => {
          return res;
        });

      return data as ITourConflictsResult;
    },
  });
};
