// General
import { Outlet } from 'react-router-dom';
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Components
import { Header } from './Header';
import { Footer } from './Footer';
import { Navigation } from './Navigation';
import { DialogHandler } from '../common/elements/DialogHandler';

// States
import { useAuthToken } from '../../state/AuthState';
import { BREAKPOINT_XL, BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM } from '../../styles/Breakpoints';
import { useRecoilState } from 'recoil';
import { NavigationCollapsedState } from '../../state/NavigationState';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  & > * {
    flex-shrink: 0;
  }

  --content-indent: 1rem;
  --animation-speed: 300ms;
  --gutter-width: 0.75rem;

  ${BREAKPOINT_SM} {
    --content-indent: 2rem;
  }

  ${BREAKPOINT_MD} {
    --gutter-width: 1.5rem;
  }

  ${BREAKPOINT_LG} {
    --gutter-width: 2rem;
    --content-indent: 4.75rem;
  }
`;

const NavigationLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;

  ${BREAKPOINT_XL} {
    display: grid;
    grid-template-columns: minmax(max-content, 1fr) minmax(0, 1500px) minmax(0, 1fr);
  }
`;

const PageContentLayout = styled(PageWrapper)`
  flex: 1;
  inline-size: 100%;
  align-self: stretch;
`;

const Appear = keyframes`
  from {
    opacity: 0;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;

  & > *:first-child {
    animation: ${Appear} 450ms ease-out;
  }
`;

export const PageLayout = () => {
  const authToken = useAuthToken();
  const [collapsed, setCollapsed] = useRecoilState(NavigationCollapsedState);

  const pageContent = (
    <PageContentLayout>
      {authToken && <Header navCollapsed={collapsed} setNavCollapsed={setCollapsed} />}

      <Main>
        <Outlet />
        <DialogHandler />
      </Main>

      <Footer />
    </PageContentLayout>
  );

  return (
    <PageWrapper>
      {authToken && (
        <NavigationLayout>
          <Navigation navCollapsed={collapsed} setNavCollapsed={setCollapsed} />
          {pageContent}
        </NavigationLayout>
      )}
      {!authToken && pageContent}
    </PageWrapper>
  );
};
