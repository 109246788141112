export const PasswordLogin = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 103" fill="none" {...props}>
    <path
      stroke="#008AD0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M23.283 31.834h3.418c8.944 0 13.39 10.843 7.02 17.122L17.737 64.712c-6.37 6.279-1.924 17.122 7.02 17.122H58.7"
    />
    <g filter="url(#a)">
      <path
        fill="#FFB82F"
        d="M88.6 43.003a17.382 17.382 0 0 1 1.255 6.49c0 5.654-7.328 13.191-9.16 20.73H66.038c-1.832-7.539-9.16-15.076-9.16-20.73 0-2.227.426-4.432 1.255-6.49a17 17 0 0 1 3.574-5.502 16.467 16.467 0 0 1 5.35-3.677c2-.852 4.144-1.29 6.31-1.29 2.165 0 4.309.438 6.31 1.29 2 .852 3.818 2.102 5.349 3.677a17 17 0 0 1 3.574 5.502Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M73.366 70.222h7.329c1.832-7.538 9.16-15.075 9.16-20.729 0-2.227-.426-4.432-1.255-6.49a17 17 0 0 0-3.574-5.502 16.465 16.465 0 0 0-5.35-3.677c-2-.852-4.144-1.29-6.31-1.29-2.165 0-4.31.438-6.31 1.29-2 .852-3.818 2.102-5.35 3.677a17 17 0 0 0-3.573 5.502 17.38 17.38 0 0 0-1.256 6.49c0 5.654 7.329 13.191 9.16 20.73h7.33Zm0 0V51.378m0 0 3.665-3.77m-3.665 3.77-3.664-3.77"
      />
      <path
        fill="#FFB82F"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M77.805 82h-8.877a1 1 0 0 1-.99-.852L66.3 70.222h14.133l-1.639 10.926a1 1 0 0 1-.989.852Z"
      />
      <path
        stroke="#FFB82F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m94.095 29-3.769 3.805M52.637 29l3.77 3.805m45.226 20.928H95.98m-45.227 0H45.1"
      />
    </g>
    <path
      fill="#FFB82F"
      d="M22.914 7.561c.52 1.29.786 2.672.786 4.068 0 3.542-4.592 8.266-5.74 12.99H8.774c-1.148-4.724-5.74-9.448-5.74-12.99 0-1.396.267-2.778.786-4.068.52-1.29 1.28-2.461 2.24-3.448a10.32 10.32 0 0 1 3.353-2.304A10.085 10.085 0 0 1 13.367 1c1.357 0 2.7.275 3.954.809a10.319 10.319 0 0 1 3.353 2.304c.96.987 1.72 2.159 2.24 3.448Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.367 24.619h4.593c1.148-4.724 5.74-9.448 5.74-12.99 0-1.396-.267-2.778-.786-4.068a10.655 10.655 0 0 0-2.24-3.448 10.319 10.319 0 0 0-3.353-2.304A10.085 10.085 0 0 0 13.367 1c-1.357 0-2.7.275-3.954.809A10.32 10.32 0 0 0 6.06 4.113C5.1 5.1 4.34 6.272 3.82 7.561a10.893 10.893 0 0 0-.786 4.068c0 3.542 4.592 8.266 5.74 12.99h4.593Zm0 0v-11.81m0 0 2.296-2.361m-2.296 2.361-2.296-2.361"
    />
    <path
      fill="#FFB82F"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.828 32h-4.92a1 1 0 0 1-.99-.852L8.94 24.62h8.857l-.98 6.53a1 1 0 0 1-.988.851Z"
    />
    <defs>
      <filter id="a" width="98.533" height="94.999" x="24.1" y="8" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.721569 0 0 0 0 0.184314 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_946_17066" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_946_17066" result="shape" />
      </filter>
    </defs>
  </svg>
);
