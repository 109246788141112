import { FC } from 'react';
import styled from 'styled-components';
import { ICalendarException } from '../../../api/models/CalendarException';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
`;

interface ICalendarExceptionDetailsProps {
  calendarException: ICalendarException;
}

export const CalendarExceptionDetails: FC<ICalendarExceptionDetailsProps> = (props) => {
  const { calendarException } = props;

  return (
    <Wrapper>
      {calendarException.name}
      <b>Info:</b>
      <br />
      Hier muss noch definiert werden, was genau angezeigt werden soll. z.B.: Welche Einrichtungen sind betroffen?
    </Wrapper>
  );
};
