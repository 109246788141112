export const downloadBlob = (blob: Blob, filename: string) => {
  // create object url
  const objectUrl = URL.createObjectURL(blob);

  // create link
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = `${filename.replace(/\.xlsx$/g, '')}.xlsx`;
  link.href = objectUrl;
  document.body.append(link);
  link.click();

  // cleanup
  document.body.removeChild(link);
  URL.revokeObjectURL(objectUrl);
};
