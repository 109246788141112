export default function Upload(props: any) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.1367 9.66699L12.5004 5.50033M12.5004 5.50033L8.86399 9.66699M12.5004 5.50033L12.5004 17.167"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.5 15.5V20.5H12.5H20.5V15.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
