import styled from 'styled-components';
import { TextInput } from '../../common/inputs/TextInput';
import { ButtonPrimary } from '../../common/inputs/Button';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { HighlightedLink } from '../../common/atoms/Link';
import { useAuthAPI } from '../../../api/controllers/AuthAPI';
import { Form } from '../../common/inputs/Form';
import { useForm } from 'react-hook-form';
import { FC, PropsWithChildren, useState } from 'react';
import { IconLogin } from '../../icons/Login';
import { LogoIcon } from '../../icons/Logo';
import { BREAKPOINT_MAX_MD, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { GridMain } from '../../common/layout/Grid';
import { Headline2 } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';

const Image = styled.div`
  grid-column: 1 / -1;
  margin-inline-start: -1rem;
  margin-inline-end: -1rem;

  ${BREAKPOINT_MD} {
    min-block-size: 100%;
    grid-column: 1 / 7;
    grid-row: 1;
    margin-inline-start: -2rem;
    margin-inline-end: unset;
  }

  ${BREAKPOINT_XL} {
    grid-column: 1 / 8;
    margin: unset;
  }
`;

const LogoIconCustom = styled(LogoIcon)`
  position: absolute;
  inline-size: 6.5rem;
  block-size: auto;
  inset-block-start: 1rem;
  inset-inline-start: 1rem;

  ${BREAKPOINT_MD} {
    inline-size: 10rem;
    inset-block-start: 2rem;
    inset-inline-start: 2rem;
  }

  ${BREAKPOINT_XL} {
    inline-size: 11.25rem;
    inset-block-start: 2.5rem;
    inset-inline-start: 2.5rem;
  }
`;

const ImageStyle = styled.div`
  position: relative;
  display: flex;

  block-size: 100%;
  inline-size: 100%;

  flex-grow: 0;
  overflow: hidden;

  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 375 248' viewBox='0 0 375 248'%3E%3Cpath d='M0 0h375v60.5C375 164.1 291.1 248 187.5 248S0 164.1 0 60.5V0z'/%3E%3C/svg%3E");
  mask-repeat: no-repeat;
  mask-size: 100% auto;

  padding-bottom: 66%;
  z-index: -1;

  img {
    position: absolute;
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
    object-position: top center;
  }

  ${BREAKPOINT_MD} {
    block-size: 100vh;
    padding-bottom: unset;
    border-start-end-radius: 2.5rem;
    border-end-end-radius: 2.5rem;
    box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.1);
    mask-image: unset;
  }
`;

const Content = styled.div`
  grid-column: 2 / -2;
  margin-block-start: -4rem;
  margin-block-end: 5rem;

  ${BREAKPOINT_MD} {
    margin-block-start: unset;
    grid-column: 8 / -1;
    grid-row: 1;
  }

  ${BREAKPOINT_XL} {
    grid-column: 9 / -2;
  }
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-block-size: 100%;

  form {
    inline-size: 100%;
    margin-block-start: 1.5rem;
    gap: 1.5rem;
  }
`;

export const IconWrapper = styled.div`
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-end: 3rem;

  ${BREAKPOINT_MAX_MD} {
    display: flex;
    justify-content: center;
    align-items: center;
    block-size: 6.5rem;
    inline-size: 6.5rem;
    background-color: ${Colors.white50};
    border-radius: 100%;
  }

  svg {
    width: 4rem;
    height: auto;

    ${BREAKPOINT_MD} {
      width: 5.5rem;
    }
  }
`;

const Title = styled.h1`
  ${Headline2};
  margin-block-end: 1rem;
`;

const LinkCustom = styled(HighlightedLink)`
  margin-block-start: 0.5rem;
  align-self: flex-start;
`;

const LoginScreenFooter = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;

  pointer-events: none;

  & > * {
    pointer-events: auto;
  }

  ${BREAKPOINT_MD} {
    grid-area: 1 / 8 / -1 / -1;
  }

  ${BREAKPOINT_XL} {
    grid-area: 1 / 9 / -1 / -2;
  }
`;

const LoginScreenFooterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-block: 2rem;
`;

export const LoginScreenWrapper: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <GridMain>
      <LogoIconCustom />
      {children}
      <LoginScreenFooter>
        <LoginScreenFooterContent>
          <a href={'/datenschutz'}>Datenschutz</a>
          <a href={'/impressum'}>Impressum</a>
        </LoginScreenFooterContent>
      </LoginScreenFooter>
    </GridMain>
  );
};

export const Login = () => {
  const navigate = useNavigate();

  const authAPI = useAuthAPI();

  const form = useForm({ defaultValues: { email: '', password: '' } });
  const [loading, setLoading] = useState(false);

  return (
    <LoginScreenWrapper>
      <Image>
        <ImageStyle>
          <img src="images/sonnenshein_login_screen.jpg" alt="" />
        </ImageStyle>
      </Image>
      <Content>
        <ContentInner>
          <IconWrapper>
            <IconLogin />
          </IconWrapper>
          <Title>Willkommen zurück!</Title>
          <p>Bitte geben Sie Ihre Zugangsdaten ein.</p>
          <Form
            form={form}
            onSubmit={(values) => {
              setLoading(true);
              authAPI
                .login(values)
                .then((res) => {
                  navigate('/');
                })
                .catch((e) => {
                  // set errors
                  form.setError('email', { message: 'Die Kombination aus Email und Passwort konnten keinem Account zugeordnet werden.' });
                  form.setError('password', {
                    message: 'Die Kombination aus Email und Passwort konnten keinem Account zugeordnet werden.',
                  });
                })
                .finally(() => setLoading(false));
            }}
          >
            <TextInput
              id={'email'}
              autoComplete={'current-user'}
              label={'Email'}
              placeholder={'beispiel@sonnenschein.com'}
              {...form.register('email', {
                required: 'Bitte geben Sie eine E-Mail ein.',
              })}
            />
            <div>
              <TextInput
                id={'password'}
                autoComplete={'current-password'}
                type={'password'}
                label={'Passwort'}
                placeholder={'Passwort eingeben'}
                {...form.register('password', {
                  required: 'Bitte geben Sie ein Passwort ein.',
                })}
              />
              <LinkCustom href={'/forgot-password'}>Passwort vergessen?</LinkCustom>
            </div>
            <ButtonPrimary type={'submit'}>{loading ? <LoadingSpinner /> : 'Login'}</ButtonPrimary>
          </Form>
        </ContentInner>
      </Content>
    </LoginScreenWrapper>
  );
};
