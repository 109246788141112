import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'nav-collapsed',
  storage: localStorage,
});

export const NavigationCollapsedState = atom<boolean>({
  key: 'NavigationCollapsedState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
