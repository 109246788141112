import { FC, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from '../../../components/common/inputs/SearchInput';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { FilterButton } from './FilterButton';
import { VehicleFilterState } from '../../../state/VehicleFilterState';
import { useRecoilState } from 'recoil';

const FilterRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 1rem;
  }
  ${BREAKPOINT_XL} {
    justify-content: space-between;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;

  ${BREAKPOINT_MD} {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  ${BREAKPOINT_XL} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  ${BREAKPOINT_MD} {
    width: auto;
    flex: 1;
    min-width: 20rem;
  }

  ${BREAKPOINT_LG} {
    width: 100%;
    max-width: 29rem;
  }
`;

export const FILTERS = [
  {
    key: 'hasRoomForWheelchairs',
    title: 'Rollstuhlgeeignet',
    type: 'boolean',
  },
];

export const VehicleFilter: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [filterValue, setFilterValue] = useState('');

  const [vehicleSearchParams, setVehicleSearchParams] = useRecoilState(VehicleFilterState);

  const onSearchChange = (value: string) => {
    setFilterValue(value);
    setVehicleSearchParams({
      ...vehicleSearchParams,
      query: value,
    });
  };

  const onReset = () => {
    setFilterValue('');
    setVehicleSearchParams({
      query: '',
      hasRoomForWheelchairs: 0,
      page: {
        offset: 0,
        limit: 50,
      }
    });
  };

  return (
    <FilterRowWrapper>
      <Content>{children}</Content>
      <FilterRow>
        <StyledSearchInput placeholder={'nach Fahrzeugen suchen'} value={filterValue} setValue={onSearchChange} onReset={onReset} />
        <FilterButton filterOptions={FILTERS} handleReset={onReset} />
      </FilterRow>
    </FilterRowWrapper>
  );
};
