import { useEffect, useRef } from 'react';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 20rem;

  & > * {
    position: absolute;
    inset: 0;
  }
`;

interface IMapProps {
  geometry?: any | null;
}
export default function MapView(props: IMapProps) {
  const { geometry } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const drawRoute = () => {
    if (geometry && map.current) {
      const addRoute = (map: mapboxgl.Map) => {
        map.addSource('geometry', {
          type: 'geojson',
          data: geometry,
        });

        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'geometry',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#1857A9', // color secondary
            'line-width': 4,
          },
        });

        const bounds = (geometry.coordinates || []).reduce((res: any, coord: any) => {
          if (!res) {
            return new LngLatBounds(coord, coord);
          } else {
            res.extend(coord);
            return res;
          }
        }, null);
        map.fitBounds(bounds, { padding: 32, animate: false });
      };

      if (map.current.loaded()) {
        addRoute(map.current);
      } else {
        map.current.on('load', () => addRoute(map.current!));
      }
    }
  };

  useEffect(() => {
    if (containerRef.current && map.current === null) {
      mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXMtYnJhbmRuZW8iLCJhIjoiY2xvNWo2YmdzMGNuNDJvcGhpOXNpZ2I2eiJ9.g6h_nl5zeuwuJaZw-xzfQw';
      map.current = new mapboxgl.Map({
        container: containerRef.current,
        style: 'mapbox://styles/mapbox/streets-v12', // style URL
      });
    }

    // draw route
    drawRoute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // draw route
    drawRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometry]);

  return (
    <Wrapper>
      <div ref={containerRef} />
    </Wrapper>
  );
}
