import React, { ReactNode, useState } from 'react';
import { SectionHeadline } from '../atoms/Card';
import styled, { css } from 'styled-components';
import { BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { Clickable } from '../atoms/Clickable';
import { Body3, Body3Bold } from '../../../styles/FontStyles';
import { Colors } from '../../../styles/Colors';

const DesktopWrapper = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.5rem 3rem;
  }

  ${BREAKPOINT_XL} {
    flex-direction: row;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const AnchorsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  column-gap: 1rem;

  ${BREAKPOINT_XL} {
    flex-direction: column;
    padding: 2rem 0;
  }
`;

const Anchor = styled(Clickable)<{ active: boolean }>`
  display: flex;
  white-space: nowrap;

  ${Body3};
  color: ${Colors.grey700};

  ${({ active }) =>
    active &&
    css`
      ${Body3Bold};
      color: ${Colors.textDefault};
    `};

  transition: color 300ms ease-out;

  &:hover {
    text-decoration: underline;
    color: ${Colors.textDefault};
  }

  ${BREAKPOINT_XL} {
    padding: 0.5rem 2rem;
  }
`;

interface IAnchorSectionViewProps {
  sectionHeadline?: string;
  items: {
    title: string;
    content: ReactNode;
  }[];
}

export default function AnchorSectionView(props: IAnchorSectionViewProps) {
  const { sectionHeadline = 'Informationen', items = [] } = props;

  const [page, setPage] = useState(0);

  return (
    <>
      <MobileWrapper>
        <SectionHeadline>{sectionHeadline}</SectionHeadline>
        {items.map(({ content }, index) => (
          <React.Fragment key={index}>{content}</React.Fragment>
        ))}
      </MobileWrapper>

      <DesktopWrapper>
        <AnchorsWrapper>
          {items.map(({ title }, index) => (
            <Anchor key={index} active={index === page} onClick={() => setPage(index)}>
              {title}
            </Anchor>
          ))}
        </AnchorsWrapper>
        {items[page]?.content}
      </DesktopWrapper>
    </>
  );
}
