export const Employee = ({ bold, ...props }: any) =>
  bold ? (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.9007 17.75V6.25C2.9007 5.69772 3.34841 5.25 3.9007 5.25H21.4007C21.953 5.25 22.4007 5.69772 22.4007 6.25V17.75C22.4007 18.3023 21.953 18.75 21.4007 18.75H3.9007C3.34841 18.75 2.9007 18.3023 2.9007 17.75Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14.9007 13.25H19.4007M14.9007 10.25H19.4007M11.9007 16C11.9007 15.1716 11.9007 14.25 10.4007 14.25H7.4007C5.9007 14.25 5.9007 15.1716 5.9007 16M10.4007 10C10.4007 10.8284 9.72912 11.5 8.9007 11.5C8.07227 11.5 7.4007 10.8284 7.4007 10C7.4007 9.17157 8.07227 8.5 8.9007 8.5C9.72912 8.5 10.4007 9.17157 10.4007 10Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.25 17.75V6.25C2.25 5.69772 2.69772 5.25 3.25 5.25H20.75C21.3023 5.25 21.75 5.69772 21.75 6.25V17.75C21.75 18.3023 21.3023 18.75 20.75 18.75H3.25C2.69772 18.75 2.25 18.3023 2.25 17.75Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M14.25 13.25H18.75M14.25 10.25H18.75M11.25 16C11.25 15.1716 11.25 14.25 9.75 14.25H6.75C5.25 14.25 5.25 15.1716 5.25 16M9.75 10C9.75 10.8284 9.07843 11.5 8.25 11.5C7.42157 11.5 6.75 10.8284 6.75 10C6.75 9.17157 7.42157 8.5 8.25 8.5C9.07843 8.5 9.75 9.17157 9.75 10Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
