// General
import React, { FC } from 'react';
import styled from 'styled-components';

// Styles
import { Body1Bold } from '../../../../styles/FontStyles';

// Components
import { Card } from '../../../common/atoms/Card';
import { DateInput } from '../../../common/inputs/DateInput';

// Styles
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { useFormContext } from 'react-hook-form';
import { getCurrentDayDate } from '../../../../utils/dateUtils';
import { Toast } from '../../../common/elements/Toast';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
`;

const CardWrapper = styled(Card)`
  gap: 1rem;
  overflow: auto;
`;

const Title = styled.h2`
  ${Body1Bold};
  text-align: center;
`;

const InputLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-items: flex-start;

  ${BREAKPOINT_MD} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CustomerOrderFormPageDateRange: FC = React.memo(() => {
  const form = useFormContext();
  const startDate = form.watch('startDate');
  const endDate = form.watch('endDate');
  const isExistingOrder = form.watch('isExistingOrder');
  const timestamp = form.watch('timestamp');

  const shouldChangeOrder = isExistingOrder && timestamp !== null && getCurrentDayDate(timestamp) > getCurrentDayDate(startDate);

  return (
    <Wrapper>
      <CardWrapper>
        <Title>Für welchen Zeitraum soll der Auftrag angelegt werden?</Title>
        {shouldChangeOrder && (
          <Toast type={'alert'}>
            Dieser Auftrag läuft bereits. Änderungen an einem bestehenden Auftrag schließen die bestehende Version des Auftrags ab und legen
            einen neue Version des Auftrags mit den Änderungen ab dem Änderungsdatum an.
          </Toast>
        )}
        {isExistingOrder && (
          <InputLayout>
            <DateInput
              name={'timestamp'}
              placeholder={'TT/MM/JJJJ'}
              label={'Änderungen gültig ab'}
              minDate={startDate ? getCurrentDayDate(startDate) : undefined}
              maxDate={endDate ? getCurrentDayDate(endDate) : undefined}
            />
          </InputLayout>
        )}
        <InputLayout>
          <DateInput
            name={'startDate'}
            placeholder={'TT/MM/JJJJ'}
            label={'Von'}
            maxDate={endDate ? getCurrentDayDate(endDate) : undefined}
            disabled={shouldChangeOrder}
          />

          <DateInput
            optional
            name={'endDate'}
            placeholder={'TT/MM/JJJJ'}
            label={'Bis'}
            minDate={startDate ? getCurrentDayDate(startDate) : undefined}
          />
        </InputLayout>
      </CardWrapper>
    </Wrapper>
  );
});
