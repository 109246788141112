export default function Settings({ bold }: { bold?: boolean }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={bold ? 0.5 : 0}
        d="m19.485 8.499.365-.98-1.042-2.515-.951-.434-1.101.367-.275-.086a8.319 8.319 0 0 0-1.206-1.204l-.086-.277.367-1.102-.434-.951L12.607.275l-.979.366-.52 1.039-.256.134a8.295 8.295 0 0 0-1.704-.002l-.256-.133L8.373.642 7.394.275 4.878 1.317l-.434.951.367 1.1-.087.276c-.44.358-.845.763-1.204 1.206l-.276.086-1.101-.367-.951.434L.15 7.519l.365.98 1.039.52.134.256a8.366 8.366 0 0 0-.002 1.704l-.133.256-1.037.519-.365.98 1.042 2.515.951.434 1.1-.366.275.086c.358.44.763.844 1.206 1.204l.086.275-.367 1.102.435.951 2.515 1.042.98-.366.519-1.038.256-.134c.568.059 1.14.059 1.705.001l.255.134.519 1.037.979.365 2.515-1.042.434-.951-.366-1.1.086-.275a8.319 8.319 0 0 0 1.204-1.206l.275-.086 1.102.367.951-.434 1.042-2.516-.365-.98-1.039-.52-.134-.255a8.375 8.375 0 0 0 .002-1.705l.133-.256 1.038-.518Zm-1.719-.175-.403.772.017.142a7.39 7.39 0 0 1-.002 1.771l-.017.143.404.773.98.49.111.298-.764 1.843-.289.132-1.04-.346-.832.261-.089.113a7.385 7.385 0 0 1-1.251 1.254l-.113.088-.261.832.346 1.039-.132.289-1.843.764-.298-.111-.49-.979-.772-.404-.142.017c-.585.07-1.181.07-1.772-.002l-.142-.017-.773.404-.49.981-.298.111-1.843-.763-.132-.289.346-1.04-.261-.832-.113-.089a7.391 7.391 0 0 1-1.254-1.252l-.088-.113-.831-.261-1.039.347-.289-.132-.764-1.843.111-.298.846-.424.151-.101.386-.738-.017-.142a7.39 7.39 0 0 1 .002-1.771l.017-.143-.404-.773-.98-.49-.112-.298.764-1.843.289-.132 1.04.346.832-.261.089-.113A7.385 7.385 0 0 1 5.408 4.28l.113-.089.26-.832-.345-1.037.132-.289 1.843-.764.298.111.49.979.772.404.142-.017a7.39 7.39 0 0 1 1.771.002l.143.017.773-.405.491-.981.298-.111 1.843.764.132.289-.346 1.04.26.832.113.089c.467.366.889.787 1.254 1.252l.088.113.832.261 1.039-.346.289.132.764 1.843-.111.298-.98.489Z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={bold ? 0.5 : 0}
        d="M9.994 4.998a5.086 5.086 0 0 0-5.085 5.077c0 2.8 2.281 5.077 5.085 5.077a5.086 5.086 0 0 0 5.085-5.077c0-2.8-2.281-5.077-5.085-5.077Zm0 9.23a4.161 4.161 0 0 1-4.16-4.153 4.161 4.161 0 0 1 8.32 0 4.161 4.161 0 0 1-4.16 4.153Z"
      />
    </svg>
  );
}
