export const Passengers = ({ bold, ...props }: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
    <path
      fill="currentColor"
      d="M7.438 6.031a2.734 2.734 0 1 1 0-5.468 2.734 2.734 0 0 1 0 5.468Zm0-3.906a1.172 1.172 0 1 0 0 2.344 1.172 1.172 0 0 0 0-2.344Zm9.354 21.338-2.448-5.713H8.796a3.13 3.13 0 0 1-3.021-2.32l-1.277-4.787a3.046 3.046 0 0 1 5.887-1.57l.646 2.427h4.61v1.563H9.83l-.955-3.586a1.484 1.484 0 0 0-2.867.764l1.275 4.786a1.566 1.566 0 0 0 1.511 1.16h6.578l2.856 6.66-1.437.616Zm5.88-19.776h-3.125V.563h-1.563v3.125H14.86V5.25h3.125v3.125h1.563V5.25h3.125V3.687Z"
      strokeWidth={bold ? 1 : 0}
      stroke="currentColor"
    />
    <path
      fill="currentColor"
      d="M13.297 20.875H5.303a1.564 1.564 0 0 1-1.51-1.16L.823 8.577l1.51-.404 2.97 11.14h7.994v1.562Z"
      strokeWidth={bold ? 1 : 0}
      stroke="currentColor"
    />
  </svg>
);
