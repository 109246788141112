// General
import { FC, useMemo } from 'react';
import styled from 'styled-components';

// Icons
import { ChevronDown } from '../../../icons/ChevronDown';

// Types
import { IEmployeeAbsence } from '../../../../api/models/EmployeeAbsence';

// Utils
import { EmployeeAbsenceAccordionItem } from './EmployeeAbsenceAccordionItem';
import { getCurrentDayDate } from '../../../../utils/dateUtils';
import { Colors } from '../../../../styles/Colors';

const AccordionHead = styled.button<{ open: boolean }>`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 1.5rem;
  font-size: 1rem;
  line-height: 1.375;
  margin-block-start: 1.5rem;
  padding-inline: 1rem;
  padding-block: 0.75rem;

  svg {
    transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
    transition: transform 250ms ease-out;
  }
`;

const AccordionBody = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-rows: ${({ open }) => (open ? '1fr' : '0fr')};
  margin-block-start: ${({ open }) => (open ? '1rem' : '0')};
  transition:
    grid-template-rows 250ms ease-out,
    margin 250ms ease-out;
`;

const AccordionOverflowWrapper = styled.div<{ open: boolean }>`
  overflow: hidden;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  background: ${Colors.white50};
`;

const AccordionContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem 10rem;
  padding-inline: 1rem;
  padding-block: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  padding-block-end: 0.5rem;
  padding-inline: 0.5rem 1.5rem;
  border-block-end: 1px solid ${Colors.grey500};
`;

const AccordionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
`;

interface EmployeeAbsenceAccordionProps {
  id: string;
  open: boolean;
  toggle: () => void;
  headline: string;
  items: IEmployeeAbsence[];
  handleOpenDialog: (value?: IEmployeeAbsence) => void;
}

const EmployeeAbsenceAccordion: FC<EmployeeAbsenceAccordionProps> = (props) => {
  const { open, toggle, headline, items, id, handleOpenDialog } = props;

  const pastAbsences = useMemo(() => {
    return items.filter((item) => new Date(item.startDate) <= getCurrentDayDate());
  }, [items]);

  const futureAbsences = useMemo(() => {
    return items.filter((item) => new Date(item.startDate) > getCurrentDayDate());
  }, [items]);

  const calculateDateSum = (items: IEmployeeAbsence[]) => {
    const sum = items.reduce((acc, curr) => {
      return acc + curr.workDays;
    }, 0);
    return `${sum} ${sum === 1 ? 'Tag' : 'Tage'}`;
  };

  return (
    <>
      <AccordionHead onClick={toggle} open={open}>
        {headline} <ChevronDown />
      </AccordionHead>

      <AccordionBody open={open}>
        <AccordionOverflowWrapper open={open}>
          <AccordionContent>
            <AccordionColumn>
              <ContentHeader>
                <p>genommen</p>
                <p>{calculateDateSum(pastAbsences)}</p>
              </ContentHeader>
              {pastAbsences.map((item, index) => (
                <EmployeeAbsenceAccordionItem key={index} id={id} item={item} onClick={handleOpenDialog} />
              ))}
            </AccordionColumn>

            <AccordionColumn>
              <ContentHeader>
                <p>geplant</p>
                <p>{calculateDateSum(futureAbsences)}</p>
              </ContentHeader>
              {futureAbsences.map((item, index) => (
                <EmployeeAbsenceAccordionItem key={index} id={id} item={item} onClick={handleOpenDialog} />
              ))}
            </AccordionColumn>
          </AccordionContent>
        </AccordionOverflowWrapper>
      </AccordionBody>
    </>
  );
};

export default EmployeeAbsenceAccordion;
