import { useMemo } from 'react';
import { IConflict } from '../../../../api/models/Conflict';
import { IScheduledTour } from '../../../../api/models/ScheduledTour';
import { getCurrentDayDate } from '../../../../utils/dateUtils';
import { EmptyTourFrame } from '../../atoms/EmptyTourFrame';
import VirtualizedDiv from '../../atoms/VirtualizedDiv';
import { TourListRow } from './TourListRow';
import { TourTile } from './TourTile';

const TourItem = ({
  schedule,
  isDesktop,
  day,
  conflicts,
}: {
  schedule: IScheduledTour;
  isDesktop: boolean;
  day: string;
  conflicts?: IConflict[];
}) => {
  const conflictsForTour = useMemo(() => conflicts?.filter(({ tour }) => tour.id === schedule.id), [schedule, conflicts]);

  const renderTourComponent = (visible: boolean) => {
    if (!visible) {
      return <EmptyTourFrame size={isDesktop ? '7rem' : '12.5rem'} $withBorder={!isDesktop} />;
    }

    if (isDesktop) {
      return <TourListRow day={day} schedule={schedule} conflictsForTour={conflictsForTour} />;
    } else {
      return <TourTile schedule={schedule} date={getCurrentDayDate(day)} conflictsForTour={conflictsForTour} />;
    }
  };

  return <VirtualizedDiv render={renderTourComponent} />;
};

export default TourItem;
