export const DIMEN_BOXED_CONTENT_WIDTH = 1280;

export const DIMEN_BREAKPOINT_XS = 480;
export const DIMEN_BREAKPOINT_SM = 576;
export const DIMEN_BREAKPOINT_MD = 768;
export const DIMEN_BREAKPOINT_LG = 992;
export const DIMEN_BREAKPOINT_XL = 1400;
export const DIMEN_BREAKPOINT_XXL = 1900;

export const BREAKPOINT = (breakpoint: number) => `@media (min-width: ${breakpoint}px)`;
export const BREAKPOINTMAX = (breakpoint: number) => `@media (max-width: ${breakpoint - 1}px)`;

export const BREAKPOINT_XS = BREAKPOINT(DIMEN_BREAKPOINT_XS);
export const BREAKPOINT_SM = BREAKPOINT(DIMEN_BREAKPOINT_SM);
export const BREAKPOINT_MD = BREAKPOINT(DIMEN_BREAKPOINT_MD);
export const BREAKPOINT_LG = BREAKPOINT(DIMEN_BREAKPOINT_LG);
export const BREAKPOINT_XL = BREAKPOINT(DIMEN_BREAKPOINT_XL);
export const BREAKPOINT_XXL = BREAKPOINT(DIMEN_BREAKPOINT_XXL);

export const BREAKPOINT_MAX_MD = BREAKPOINTMAX(DIMEN_BREAKPOINT_MD);
export const BREAKPOINT_MAX_XL = BREAKPOINTMAX(DIMEN_BREAKPOINT_XL);
