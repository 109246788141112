import styled from 'styled-components';
import { Clickable } from './Clickable';
import { Body3 } from '../../../styles/FontStyles';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { ChevronLeft } from '../../icons/ChevronLeft';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { Link } from './Link';

const ButtonWrapper = styled(Clickable).attrs(({ type = 'button', href }: any) => ({ type, ...(!!href ? { as: Link } : {}) }))`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body3};

  ${BREAKPOINT_MD} {
    margin-inline-start: auto;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export default function BackButton(props: PropsWithChildren<HTMLAttributes<HTMLButtonElement> & { href?: string }>) {
  const { children, ...buttonProps } = props;

  return (
    <ButtonWrapper {...buttonProps}>
      <ChevronLeft /> {children || 'Zurück'}
    </ButtonWrapper>
  );
}
