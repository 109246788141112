import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FetchCustomersParams } from '../api/controllers/InfiniteSearchAPI';

const { persistAtom } = recoilPersist({
  key: 'customer-filters',
  storage: localStorage,
});

export const CustomerFilterState = atom<FetchCustomersParams>({
  key: 'CustomerFilterState',
  default: {
    query: '',
    page: {
      offset: 0,
      limit: 50,
    },
    regions: [],
  },
  effects_UNSTABLE: [persistAtom],
});
