import { IOrder } from '../../../../api/models/Order';
import { TourplanScheduledTourTile, TTourplanScheduledTour } from './tiles/TourplanScheduledTourTile';
import { useRecoilValue } from 'recoil';
import { NavigationCollapsedState } from '../../../../state/NavigationState';
import { useNavigate, useLocation } from 'react-router-dom';
import { DragDropContext, useAvailableDragDropItems } from './DragDropContext';
import { IPassenger } from '../../../../api/models/Passenger';
import { useShowDialog } from '../../../../state/DialogState';
import React, { PropsWithChildren, useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { IEmployee } from '../../../../api/models/Employee';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ITour } from '../../../../api/models/Tour';
import { Section } from '../../../common/atoms/Form';
import { Facilities } from '../../../icons/Facilities';
import { DateMonthInput } from '../../../common/inputs/DateMonthInput';
import { DayOfMonthInput } from '../../../common/inputs/DayOfMonthInput';
import { ColoredOrderLabel, getColorByIndex } from '../../../common/labels/ColoredLabel';
import { TourplanToursWrapper } from './tiles/TourplanTourTile';
import { TourplanNewTourTile } from './tiles/TourplanNewTourTile';
import { getCurrentDayDate } from '../../../../utils/dateUtils';
import { ChipTile } from '../../../common/atoms/ChipTile';
import BackButton from '../../../common/atoms/BackButton';
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from '../../../common/inputs/Button';
import { LoadingSpinner } from '../../../common/loaders/LoadingSpinner';
import ChangeTourDialog from '../../../common/elements/tours/tour-details/ChangeTourDialog';
import { ICustomer } from '../../../../api/models/Customer';
import styled from 'styled-components';
import { Body2, Body3, Body4, Headline3 } from '../../../../styles/FontStyles';
import { Colors } from '../../../../styles/Colors';
import { TourplanAvailableOrdersOverlay } from './overlays/TourplanAvailableOrdersOverlay';
import { TourplanSplitTourOverlay } from './overlays/TourplanSplitTourOverlay';
import VirtualizedDiv from '../../../common/atoms/VirtualizedDiv';
import { EmptyTourFrame } from '../../../common/atoms/EmptyTourFrame';
import { SearchInput } from '../../../common/inputs/SearchInput';
import { formatName } from '../../../../utils/nameUtils';
import { IScheduledTour } from '../../../../api/models/ScheduledTour';
import { Trash } from '../../../icons/Trash';
import BookingCompletionHint from '../../../common/elements/BookingCompletionHint';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  flex: 1;

  position: relative;

  ${Body2};
`;

const TourplanHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
  z-index: 3;

  margin-block-start: -4.75rem;
  padding-block-start: 4.75rem;
  margin-inline: calc(-1 * var(--content-indent));
  padding-inline: var(--content-indent);

  padding-block-end: 0.5rem;
  margin-block-end: 0.5rem;

  background-color: ${Colors.background};
  position: sticky;
  top: 0;
`;

const TourplanHeadRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const CustomerNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const TourplanHeadline = styled.h2`
  ${Headline3};
`;

const SplitTourDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body3};
`;

const OrdersRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  ${Body4};
`;

const ButtonsWrapper = styled.div<{ navCollapsed: boolean }>`
  position: fixed;
  z-index: 2;
  inset-inline: ${({ navCollapsed }) => (navCollapsed ? '5.5rem' : '20rem')} 20rem;
  inset-block-end: 0;

  transition: inset-inline 300ms ease-out;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  padding-block: 0.75rem;
  padding-inline: 4.75rem 1rem;
  background-color: ${Colors.white50};
`;

const CancelButton = styled(ButtonTertiary)`
  margin-inline-start: auto;
`;

const TourplanSearchWrapper = styled.div`
  margin-block: 0.5rem;
`;

const SubmitButtonsWrapper = (props: PropsWithChildren) => {
  const navCollapsed = useRecoilValue(NavigationCollapsedState);

  return <ButtonsWrapper navCollapsed={navCollapsed}>{props.children}</ButtonsWrapper>;
};

const SplitTourSubmitButtons = React.memo((props: { submitting: boolean; timestamp: string; onSubmit: (data: any) => Promise<void> }) => {
  const { submitting, timestamp, onSubmit } = props;

  const navigate = useNavigate();

  const [changeTourDialogOpen, setChangeTourDialogOpen] = useState(false);

  return (
    <>
      <SubmitButtonsWrapper>
        <CancelButton onClick={() => navigate(-1)}>Abbrechen</CancelButton>

        <ButtonPrimary
          type={'submit'}
          disabled={submitting}
          onClick={(e: any) => {
            e.preventDefault();
            setChangeTourDialogOpen(true);
          }}
        >
          {submitting ? <LoadingSpinner /> : 'Änderungen speichern'}
        </ButtonPrimary>
      </SubmitButtonsWrapper>

      <ChangeTourDialog
        open={changeTourDialogOpen}
        setOpen={setChangeTourDialogOpen}
        timestamp={timestamp}
        onSubmit={async (config, setLoading) => {
          // submit form
          setLoading(true);
          await onSubmit({ config }); // add config object
          setLoading(false);
        }}
      />
    </>
  );
});

interface ITourplanProps {
  customer: ICustomer | null;
  direction?: 'outwards' | 'return';
  orders: IOrder[] | null;
  timestamp: string;
  setTimestamp: (timestamp: string) => void;
  onSubmit: (values: any) => Promise<void>;
  submitting: boolean;
  splitTour?: TTourplanScheduledTour | null;
}
export default function TourplanDesktop(props: ITourplanProps) {
  const { customer, orders, direction = 'outwards', timestamp, setTimestamp, onSubmit, splitTour, submitting = false } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const splitTourView = new URLSearchParams(location.search).get('split-tour');

  const [availablePassengers] = useAvailableDragDropItems<IPassenger>();

  const showDialog = useShowDialog();

  const [driver, setDriver] = useState<IEmployee | null>(null);
  const [companion, setCompanion] = useState<IEmployee | null>(null);

  useEffect(() => {
    setDriver(splitTour?.driver || null);
    setCompanion(splitTour?.companion || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitTour]);

  const form = useFormContext();
  const schedules = useFieldArray({ name: 'schedules', control: form.control, keyName: 'formId' });
  const schedulesToDelete = useFieldArray({ name: 'schedulesToDelete', control: form.control, keyName: 'formId' });

  const orderTours = useMemo(() => {
    return (orders || []).flatMap(
      ({ id, displayName, comment, tours = [] }, index) =>
        (tours || []).map((tour) => ({ ...tour, order: { id, displayName, comment, index } })) as unknown as ITour[],
    );
  }, [orders]);

  const plannedOrderTours = useMemo(() => {
    const planned: Partial<ITour>[] = [];

    orderTours.forEach((tour) => {
      const isUnplanned = (tour.passengers || []).some(({ id }) => availablePassengers.some((passenger) => id === passenger.id));
      if (!isUnplanned) {
        planned.push(tour);
      }
    });

    return planned;
  }, [orderTours, availablePassengers]);

  const hasChanges = useMemo(() => {
    return schedules.fields.some(({ hasChanged = false }: any) => hasChanged) || schedulesToDelete.fields.length > 0;
  }, [schedules, schedulesToDelete]);

  const proceedWithoutSaving = useCallback(
    (onProceed: () => void) => {
      if (hasChanges) {
        showDialog({
          headline: 'Änderungen gehen verloren',
          body: 'Möchten Sie fortfahren ohne die Änderungen zu speichern?',
          buttons: (
            <>
              <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
              <ButtonPrimary
                onClick={() => {
                  showDialog(null);
                  onProceed();
                }}
              >
                Fortfahren ohne Speichern
              </ButtonPrimary>
            </>
          ),
        });
      } else {
        onProceed();
      }
    },
    [showDialog, hasChanges],
  );

  const proceedWithUnplannedOrderTours = useCallback(
    (onProceed: () => void) => {
      const hasUnplannedOrderTours = orderTours.some((tour) =>
        (tour.passengers || []).some(({ id }) => availablePassengers.some((passenger) => id === passenger.id)),
      );
      if (hasUnplannedOrderTours) {
        // show dialog
        showDialog({
          headline:
            direction === 'outwards'
              ? 'Achtung! Es wurden noch nicht alle Hinfahrten geplant.'
              : 'Achtung! Es wurden noch nicht alle Rückfahrten geplant.',
          body:
            direction === 'outwards' ? (
              <>
                <p>
                  Es gibt noch Vertragstouren, die auf Hinfahrten aufgeteilt werden müssen. Sie können mit der Planung zu einem späteren
                  Zeitpunkt fortfahren.
                </p>
                <br />
                <p>
                  <b>Möchten Sie die Bearbeitung der Hinfahrtenpläne beenden und zu einem späteren Zeitpunkt fortfahren?</b>
                </p>
              </>
            ) : (
              <>
                <p>
                  Es gibt noch Vertragstouren, die auf Rückfahrten aufgeteilt werden müssen. Sie können mit der Planung zu einem späteren
                  Zeitpunkt fortfahren.
                </p>
                <br />
                <p>
                  <b>Möchten Sie die Bearbeitung der Rückfahrtenpläne wirklich abschließen?</b>
                </p>
              </>
            ),
          buttons: (
            <>
              <ButtonPrimary onClick={() => showDialog(null)}>
                {direction === 'outwards' ? 'Hinfahrten Planung fortsetzen' : 'Rückfahrten Planung fortsetzen'}
              </ButtonPrimary>
              <ButtonSecondary
                onClick={() => {
                  showDialog(null);
                  onProceed();
                }}
              >
                {direction === 'outwards' ? 'Weiter zu Rückfahrten' : 'Wirklich abschließen'}
              </ButtonSecondary>
            </>
          ),
        });
      } else {
        onProceed();
      }
    },
    [showDialog, orderTours, direction, availablePassengers],
  );

  const proceedWithTourSplitting = useCallback(
    (scheduledTour: TTourplanScheduledTour, onProceed: () => void) => {
      if (!scheduledTour.hasChanged && getCurrentDayDate(scheduledTour.startDate) < getCurrentDayDate(timestamp)) {
        showDialog({
          headline: 'Neuen Fahrplan erstellen',
          body: `Möchten Sie eine neue Regel-Fahrt ab dem ${getCurrentDayDate(timestamp).toLocaleDateString(undefined, {
            dateStyle: 'medium',
          })} erstellen und die existierende Regel-Fahrt zum Vortag beenden?`,
          buttons: (
            <>
              <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
              <ButtonPrimary
                onClick={() => {
                  showDialog(null);
                  onProceed();
                }}
              >
                Ja, neu erstellen
              </ButtonPrimary>
            </>
          ),
        });
      } else {
        onProceed();
      }
    },
    [showDialog, timestamp],
  );

  const proceedWithTourDeletionOrEnding = useCallback(
    (scheduledTour: TTourplanScheduledTour, onProceed: () => void) => {
      if (scheduledTour.id !== undefined && getCurrentDayDate(scheduledTour.startDate) < getCurrentDayDate(timestamp)) {
        showDialog({
          headline: 'Fahrplan beenden',
          body: `Möchten Sie diese Regel-Fahrt ab dem ${getCurrentDayDate(timestamp).toLocaleDateString(undefined, {
            dateStyle: 'medium',
          })} löschen und die existierende Regel-Fahrt zum Vortag beenden?`,
          buttons: (
            <>
              <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
              <ButtonPrimary
                onClick={() => {
                  showDialog(null);
                  onProceed();
                }}
              >
                Ja, beenden
              </ButtonPrimary>
            </>
          ),
        });
      } else {
        onProceed();
      }
    },
    [showDialog, timestamp],
  );

  // calls onSubmit with all data
  const submitForm = useCallback(
    (data?: any) => {
      return onSubmit({
        ...form.getValues(),
        ...(!!splitTour
          ? {
              splitTourUpdate: {
                driver,
                companion,
              },
            }
          : {}),
        ...(data || {}),
      });
    },
    [splitTour, onSubmit, form, driver, companion],
  );

  const [searchQuery, setSearchQuery] = useState(new URLSearchParams(window.location.search).get('tour') || '');
  const searchFilter = useDeferredValue(searchQuery);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (searchFilter.length > 0) {
      urlParams.set('tour', searchFilter);
    } else {
      urlParams.delete('tour');
    }
    navigate({ search: urlParams.toString() }, { replace: true });
  }, [navigate, searchFilter]);

  const visibleFields = useMemo(() => {
    return schedules.fields.filter(
      ({ name }: any) => searchFilter.length < 1 || (name || '').toLowerCase().includes(searchFilter.toLowerCase()),
    );
  }, [schedules.fields, searchFilter]);

  const headRowView = useMemo(
    () => (
      <TourplanHeadRow>
        {!splitTour ? (
          <TourplanHeadline>Fahrtenplanung - {direction === 'outwards' ? 'Hinfahrten' : 'Rückfahrten'}</TourplanHeadline>
        ) : (
          <TourplanHeadline>
            Fahrt aufteilen - {splitTour.name} {direction === 'outwards' ? 'Hin' : 'Rück'}
          </TourplanHeadline>
        )}
        <CustomerNameWrapper>
          <Facilities />
          <span>{customer?.internalId}</span>
          <span>
            <b>{customer?.displayName}</b>
          </span>
        </CustomerNameWrapper>
      </TourplanHeadRow>
    ),
    [splitTour, direction, customer],
  );

  const timestampInputView = useMemo(
    () => (
      <>
        {!splitTour && (
          <>
            <TourplanHeadRow>
              <span />
              <DateMonthInput value={timestamp} setValue={(value) => proceedWithoutSaving(() => setTimestamp(value))} />
            </TourplanHeadRow>
            <DayOfMonthInput value={timestamp} setValue={(value) => proceedWithoutSaving(() => setTimestamp(value))} />
          </>
        )}
        {splitTour && (
          <SplitTourDate>
            <b>{new Date(timestamp).toLocaleDateString(undefined, { weekday: 'short' })}</b>{' '}
            {new Date(timestamp).toLocaleDateString(undefined, { dateStyle: 'medium' })}
          </SplitTourDate>
        )}
      </>
    ),
    [splitTour, timestamp, setTimestamp, proceedWithoutSaving],
  );

  const ordersView = useMemo(
    () => (
      <>
        {(orders || []).length > 0 && (
          <OrdersRow>
            <span>Aufträge:</span>
            {(orders || []).map((order, index) => (
              <ColoredOrderLabel key={order.id} color={getColorByIndex(index)}>
                {order.displayName}
              </ColoredOrderLabel>
            ))}
          </OrdersRow>
        )}
      </>
    ),
    [orders],
  );

  const filteredTours = useMemo(() => {
    return (
      <>
        {visibleFields.map((scheduledTour: TTourplanScheduledTour & any) => (
          <TourplanScheduledTourTile
            key={scheduledTour.formId}
            // provide edit timestamp to block start date if timestamp > startDate and split schedule
            showDriverAvailabilities={!!splitTour}
            editForTimestamp={timestamp}
            editable={!splitTourView}
            promptForTimeSupplement={!!splitTour}
            scheduledTour={scheduledTour}
            customer={customer}
            update={(data, hasChanged = true) => {
              proceedWithTourSplitting(scheduledTour, () => {
                const index = schedules.fields.findIndex((item) => item === scheduledTour);
                schedules.update(index, {
                  ...data,
                  hasChanged: scheduledTour.hasChanged || hasChanged, // set changed flag
                });
              });
            }}
            remove={(data, onRemoved) => {
              proceedWithTourDeletionOrEnding(data, () => {
                const index = schedules.fields.findIndex((item) => item === data);
                if (index >= 0) {
                  schedules.remove(index);
                  schedulesToDelete.append(data);
                  onRemoved();
                }
              });
            }}
          />
        ))}
      </>
    );
  }, [
    splitTourView,
    visibleFields,
    timestamp,
    splitTour,
    customer,
    proceedWithTourSplitting,
    proceedWithTourDeletionOrEnding,
    schedules,
    schedulesToDelete,
  ]);

  const tourTileAddNew = useMemo(() => {
    return (
      <>
        {!splitTour && (
          <VirtualizedDiv
            render={(visible) =>
              visible ? (
                <TourplanNewTourTile
                  timestamp={timestamp}
                  getCreationAttributes={(draggingItems: any[]) => {
                    const orderTour =
                      orderTours.find(({ passengers = [] }) => passengers.some(({ id }) => id === (draggingItems || [])[0]?.id)) ||
                      splitTour;

                    const {
                      name = customer?.internalId && `${customer.internalId} ${(schedules.fields.length + 1).toString().padStart(2, '0')}`,
                      arrivalDate = orderTours.find((tour) => tour.direction === direction)?.arrivalDate,
                      departureDate = orderTours.find((tour) => tour.direction === direction)?.departureDate,
                    } = orderTour || {};

                    // use start/end dates of order tour, use current timestamp for split tour or fallback
                    const startDate = (splitTour ? undefined : orderTour?.startDate) || getCurrentDayDate(timestamp);
                    const endDate = (splitTour ? undefined : orderTour?.endDate) || getCurrentDayDate(timestamp);

                    // reset search filter
                    setSearchQuery('');

                    return {
                      hasChanged: true, // set changed flag
                      customerId: customer?.id,
                      customer,
                      isException: false,
                      startDate,
                      endDate,
                      arrivalDate,
                      departureDate,
                      name: `F ${name}`,
                    };
                  }}
                />
              ) : (
                <EmptyTourFrame size={'12.5rem'} />
              )
            }
          />
        )}
      </>
    );
  }, [splitTour, orderTours, customer, schedules.fields.length, direction, timestamp]);

  const overplannedPassengers = useMemo(() => {
    const orderPassengersIds = (orders || []).flatMap(({ tours = [] }) =>
      tours.flatMap(({ passengers = [] }) => passengers.map(({ id }) => id)),
    );
    const passengers = schedules.fields.flatMap(({ passengers = [] }: any) =>
      passengers.filter(({ id }: any) => !orderPassengersIds.includes(id)),
    ) as IPassenger[];

    const uniquePassengers = passengers.filter((passenger, index) => passengers.findIndex(({ id }) => id === passenger.id) === index);

    return uniquePassengers;
  }, [orders, schedules.fields]);

  const availableToursOverlay = useMemo(
    () => (
      <>
        {!splitTour && (
          <TourplanAvailableOrdersOverlay
            customer={customer}
            orderTours={orderTours}
            timestamp={timestamp}
            overplannedPassengers={overplannedPassengers}
            onRemoveOverplannedPassengerClick={(passenger) => {
              const index = schedules.fields.findIndex(({ passengers = [] }: any) => passengers.some(({ id }: any) => id === passenger.id));
              if (index >= 0) {
                const schedule = schedules.fields[index] as unknown as IScheduledTour;
                showDialog({
                  headline: 'Fahrgast entfernen',
                  body: (
                    <span>
                      Möchten Sie den Fahrgast <b>{formatName(passenger)}</b> aus der Fahrt{' '}
                      <b>
                        {schedule.name} ({schedule.direction === 'return' ? 'Rück' : 'Hin'})
                      </b>{' '}
                      entfernen?
                    </span>
                  ),
                  buttons: (
                    <>
                      <ButtonPrimary
                        onClick={() => {
                          showDialog(null);

                          // update form
                          schedules.update(index, {
                            ...schedule,
                            passengers: (schedule.passengers || []).filter(({ id }) => id !== passenger.id), // remove passenger
                          });
                        }}
                      >
                        <Trash /> Entfernen
                      </ButtonPrimary>
                      <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
                    </>
                  ),
                });
              }
            }}
          />
        )}
      </>
    ),
    [splitTour, customer, orderTours, overplannedPassengers, showDialog, schedules, timestamp],
  );

  const splitTourOverlay = useMemo(
    () => (
      <>
        {splitTour && (
          <TourplanSplitTourOverlay
            splitTour={splitTour}
            timestamp={timestamp}
            driver={driver}
            setDriver={setDriver}
            companion={companion}
            setCompanion={setCompanion}
          />
        )}
      </>
    ),
    [splitTour, timestamp, driver, setDriver, companion, setCompanion],
  );

  const progressChipTile = useMemo(
    () => (
      <ChipTile
        title={`${plannedOrderTours.length}/${orderTours.length} Vertragstouren abgedeckt`}
        progress={plannedOrderTours.length / orderTours.length}
      >
        {schedules.fields.length} Fahrpläne - {direction === 'outwards' ? 'Hinfahrten' : 'Rückfahrten'}
      </ChipTile>
    ),
    [plannedOrderTours.length, orderTours.length, schedules.fields.length, direction],
  );

  const tourplanButtonsView = useMemo(
    () => (
      <>
        {!splitTour && (
          <SubmitButtonsWrapper>
            {progressChipTile}

            {direction === 'outwards' && (
              <CancelButton
                onClick={(e: any) => {
                  if (hasChanges) {
                    e.preventDefault();
                    proceedWithoutSaving(() => navigate(-1));
                  } else {
                    navigate(-1);
                  }
                }}
              >
                Abbrechen
              </CancelButton>
            )}
            {direction === 'return' && (
              <BackButton
                onClick={(e: any) => {
                  proceedWithoutSaving(() => {
                    const search = new URLSearchParams(window.location.search);
                    search.delete('direction');
                    navigate(`/customers/${customer?.id}/tourplan?${search.toString()}`);
                  });
                }}
              />
            )}
            {hasChanges && (
              <ButtonPrimary
                type={'submit'}
                disabled={submitting}
                onClick={(e: any) => {
                  e.preventDefault();
                  submitForm({});
                }}
              >
                {submitting ? <LoadingSpinner /> : 'Speichern'}
              </ButtonPrimary>
            )}
            {!hasChanges && (
              <ButtonSecondary
                disabled={submitting}
                onClick={(e: any) => {
                  e.preventDefault();
                  proceedWithUnplannedOrderTours(() => {
                    // redirect to return tours or to customer orders page
                    if (direction === 'return') {
                      navigate(`/customers/${customer?.id}#orders`);
                    } else {
                      const search = new URLSearchParams(window.location.search);
                      search.set('direction', 'return');
                      navigate(`/customers/${customer?.id}/tourplan?${search.toString()}`);
                    }
                  });
                }}
              >
                {submitting ? <LoadingSpinner /> : direction === 'return' ? 'Planung abschließen' : 'Weiter zu Rückfahrten'}
              </ButtonSecondary>
            )}
          </SubmitButtonsWrapper>
        )}
      </>
    ),
    [
      splitTour,
      progressChipTile,
      direction,
      customer,
      hasChanges,
      submitting,
      proceedWithoutSaving,
      submitForm,
      navigate,
      proceedWithUnplannedOrderTours,
    ],
  );

  const splitTourButtonsView = useMemo(
    () => <>{splitTour && <SplitTourSubmitButtons submitting={submitting} timestamp={timestamp} onSubmit={submitForm} />}</>,
    [splitTour, submitting, timestamp, submitForm],
  );

  return (
    <DragDropContext
      dataSource={schedules as any}
      itemsKey={'passengers'}
      tourPrefix={customer?.internalId && `F ${customer.internalId}`}
      direction={direction || 'outwards'}
    >
      <Section>
        <Wrapper>
          <TourplanHeadWrapper>
            {headRowView}
            {timestampInputView}
            {ordersView}
            <BookingCompletionHint timestamp={timestamp} style={{ marginBlockStart: '1rem' }} />

            <TourplanSearchWrapper>
              <SearchInput
                placeholder={'Nach Tourbezeichnung filtern...'}
                value={searchQuery}
                setValue={setSearchQuery}
                onReset={() => setSearchQuery('')}
              />
            </TourplanSearchWrapper>
          </TourplanHeadWrapper>

          <TourplanToursWrapper>
            {filteredTours}
            {tourTileAddNew}
          </TourplanToursWrapper>

          {tourplanButtonsView}
          {splitTourButtonsView}

          {availableToursOverlay}
          {splitTourOverlay}
        </Wrapper>
      </Section>
    </DragDropContext>
  );
}
