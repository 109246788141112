export const Edit = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.75 17.25V20.25H6.75L17.25 9.75L14.25 6.75L3.75 17.25Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19.5858 4.58579L19.4142 4.41421C18.6332 3.63316 17.3668 3.63317 16.5858 4.41421L14.25 6.75L17.25 9.75L19.5858 7.41421C20.3668 6.63316 20.3668 5.36683 19.5858 4.58579Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
