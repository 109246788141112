import { useRef, useState } from 'react';
import { Trash } from '../../icons/Trash';
import { ButtonTertiary } from '../inputs/Button';
import styled from 'styled-components';
import { TimeInput } from '../inputs/TimeInput';
import { Colors } from '../../../styles/Colors';
import { Body4 } from '../../../styles/FontStyles';
import { Clickable } from '../atoms/Clickable';

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const SmallTimeInput = styled(TimeInput).attrs({ plain: true })<{ $inlineLabel: string }>`
  position: relative;
  padding-inline-start: 1.5rem;
  border-bottom: 1px solid ${Colors.grey500};

  &::before {
    content: '${({ $inlineLabel = '' }) => $inlineLabel}';
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: -0.125rem;
    color: ${Colors.secondary};
    ${Body4};
  }

  input {
    padding: 0;
    border-bottom: none;
    height: 1rem;
  }
`;

const RemoveButton = styled(Clickable)`
  display: flex;

  margin-inline-start: 1.25rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const SubmitButton = styled(ButtonTertiary)`
  color: currentColor;
  border-color: currentColor;
`;

interface IWrappedTimeInputProps {
  className?: string;
  direction?: string;
  defaultValue?: string;
  onChange: (value: string | undefined) => void;
  submitOnBlur?: boolean;
  autoFocus?: boolean;
}

export default function WrappedTimeInput(props: IWrappedTimeInputProps) {
  const { className, direction, defaultValue, onChange, submitOnBlur = false, autoFocus = submitOnBlur } = props;

  const [fieldValue, setFieldValue] = useState(defaultValue || '');
  const fieldValueRef = useRef<string | undefined>(fieldValue);

  return (
    <InputRow className={className}>
      <SmallTimeInput
        id={'time-input'}
        $inlineLabel={direction === 'return' ? 'ab:' : 'an:'}
        optional
        independent
        value={fieldValue}
        onValueChange={(value) => {
          fieldValueRef.current = value;
          setFieldValue(value);
        }}
        autoFocus={autoFocus}
        onBlur={() => {
          if (submitOnBlur) {
            setTimeout(() => {
              onChange?.(fieldValueRef.current); // delay event on blur
            }, 100);
          }
        }}
        onSubmit={() => {
          onChange?.(fieldValue);
        }}
      />
      <RemoveButton
        onClick={(e: any) => {
          e.stopPropagation();
          fieldValueRef.current = undefined;
          onChange?.(undefined);
        }}
      >
        <Trash />
      </RemoveButton>
      <SubmitButton
        variant={'small'}
        onClick={(e: any) => {
          e.stopPropagation();
          onChange?.(fieldValue);
        }}
      >
        Speichern
      </SubmitButton>
    </InputRow>
  );
}
