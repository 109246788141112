import { Avatar } from '../atoms/Avatar';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Body2 } from '../../../styles/FontStyles';
import { ChevronDown } from '../../icons/ChevronDown';
import { Clickable } from '../atoms/Clickable';
import { ContextMenu, ContextMenuItem, ContextMenuSeparator } from '../atoms/ContextMenu';
import { IUser } from '../../../api/models/User';
import { formatName } from '../../../utils/nameUtils';
import { useShowDialog } from '../../../state/DialogState';
import { ButtonPrimary, ButtonSecondary } from '../inputs/Button';
import AppVersionTitle from '../atoms/AppVersionTitle';

const ProfileWrapper = styled.div<{ collapsed?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  ${({ collapsed = false }) =>
    collapsed &&
    css`
      gap: 0;
      justify-content: center;
    `};
`;

const ProfileName = styled.span.attrs(({ children }: any) => ({ title: children }))<{ children?: string }>`
  flex: 1;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${Body2};
`;

const ChevronDownIcon = styled(ChevronDown)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

interface IProfileMenuProps {
  collapsed: boolean;
  userMe: IUser | null;
  logout: () => void;
}

export const ProfileMenu: FC<IProfileMenuProps> = (props) => {
  const { collapsed, userMe, logout } = props;

  const showDialog = useShowDialog();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Clickable
      onClick={(e) => {
        e.stopPropagation();
        setMenuOpen(!menuOpen);
      }}
    >
      <ProfileWrapper collapsed={collapsed}>
        <Avatar src={''} firstName={userMe?.employee?.firstName} lastName={userMe?.employee?.lastName || userMe?.email} />
        {!collapsed && (
          <>
            <ProfileName>{(userMe?.employee && formatName(userMe.employee)) || userMe?.email}</ProfileName>
            <ChevronDownIcon invert={!menuOpen} />
          </>
        )}
        <ContextMenu open={menuOpen} setOpen={setMenuOpen}>
          {/*<ContextMenuItem href={'/'}>Meine Einstellungen</ContextMenuItem>*/}
          {/*<ContextMenuItem href={'/'}>Benachrichtigungen</ContextMenuItem>*/}
          <ContextMenuItem href={'/'}>Fahrtenübersicht</ContextMenuItem>
          <ContextMenuSeparator />
          <ContextMenuItem
            onClick={() => {
              showDialog({
                headline: 'Logout',
                body: <>Möchten Sie sich wirklich ausloggen?</>,
                buttons: () => (
                  <>
                    <ButtonPrimary
                      onClick={() => {
                        showDialog(null);
                        logout();
                      }}
                    >
                      Logout
                    </ButtonPrimary>
                    <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
                  </>
                ),
              });
            }}
          >
            Logout
          </ContextMenuItem>
          {process.env.NODE_ENV === 'development' && (
            <>
              <ContextMenuSeparator />
              <ContextMenuItem href={'/icons'}>Icons</ContextMenuItem>
            </>
          )}
          <ContextMenuSeparator />
          <AppVersionTitle />
        </ContextMenu>
      </ProfileWrapper>
    </Clickable>
  );
};
