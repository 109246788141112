import React, { useEffect, useState } from 'react';
import { ContentPageLayout } from '../../common/atoms/Layout';
import { Breadcrumb } from '../../common/elements/Breadcrumb';
import { LoadingSpinner } from '../../common/loaders/LoadingSpinner';
import { CalendarExceptionForm } from '../../content/calendar-exceptions/CalendarExceptionForm';
import { useCustomerCategoryAPI } from '../../../api/controllers/CustomerCategoryAPI';
import { ICustomerCategory } from '../../../api/models/CustomerCategory';
import { emptyCalendarException } from '../../../api/models/CalendarException';

export const CalendarExceptionAdd: React.FC = () => {
  const [customerCategories, setCustomerCategories] = useState<ICustomerCategory[] | null>(null);

  const customerCategoryAPI = useCustomerCategoryAPI();
  const [loading, setLoading] = useState(false);

  const fetchCustomerCategories = async () => {
    setLoading(true);
    customerCategoryAPI
      .getCustomerCategories({})
      .then((res) => {
        setCustomerCategories(res.data.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchCustomerCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CalendarExceptionForm
          calendarException={emptyCalendarException}
          customerCategories={customerCategories || []}
        />
      )}
    </ContentPageLayout>
  );
};
